import React, { Component } from 'react';

class NotificationMessage extends Component {
  render() {
    let { notifyBoll, notifyMessage } = this.props;
    return (
      <div
        className="notifyMessage"
        style={{ display: notifyBoll ? 'block' : 'none' }}
      >
        {notifyMessage}
      </div>
    );
  }
}

export default NotificationMessage;
