import React, { PureComponent } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';

export class TripItemModal extends PureComponent {
  render() {
    const { isOpens, togglesShow, data } = this.props;

    return (
      <Modal
        backdrop="static"
        keyboard={false}
        id="TripItemDetailsModal"
        show={isOpens}
        onHide={togglesShow}
      >
        <Modal.Header closeButton>Order Item Details</Modal.Header>

        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Order No</th>
                <th>Billed To</th>
                <th>Date</th>
                <th>First Pickup Date</th>
                <th>Last Pickup Date</th>
                <th>Order Status</th>

                <th>Load Type</th>
                <th>Order Type</th>

                <th>Comodity</th>
                <th>Equipment</th>
                <th>Total</th>
                <th>Invoice No</th>
              </tr>
            </thead>

            {data.map((item) => (
              <tbody>
                <tr key={item.id}>
                  <td>{item.order_no}</td>
                  <td>{item.billed_to.name}</td>
                  <td>{item.order_date != null ? item.order_date : 'N/A'}</td>
                  <td>{item.order_date != null ? item.order_date : 'N/A'}</td>
                  <td>{item.order_date != null ? item.order_date : 'N/A'}</td>
                  <td>
                    {item.order_status != null ? item.order_status.name : 'N/A'}
                  </td>
                  <td>{item.load_type != null ? item.load_type : 'N/A'}</td>
                  <td>
                    {item.order_type != null ? item.order_type.name : 'N/A'}
                  </td>
                  <td>
                    {item.comm_type != null ? item.comm_type.name : 'N/A'}
                  </td>
                  <td>
                    {item.equip_type != null ? item.equip_type.name : 'N/A'}
                  </td>
                  <td>
                    {item.order_cur != null && item.order_cur.key === 1
                      ? '$'
                      : 'C$'}{' '}
                    {item.total != null ? item.total : 'N/A'}
                  </td>
                  <td>{item.invoice !== 0 ? item.invoice : 'N/A'}</td>
                </tr>
                <tr>
                  <td colSpan="12">
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Order#</th>
                          <th>Location</th>
                          <th>Status</th>
                          <th>Request Date</th>
                          <th>Weights</th>
                          <th>Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.items &&
                          item.items.map((x) => (
                            <tr key={x.id.toString()}>
                              <td>{x.type}</td>
                              <td>
                                {x.order != null ? x.order.order_no : 'N/A'}
                              </td>
                              <td>{x.location}</td>
                              <td>{x.item_status && x.item_status.name}</td>
                              <td>{x.req_date}</td>
                              <td>{x.weight}</td>
                              <td>{x.note}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={togglesShow}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
