import React, { Component } from 'react';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import default_menu from '../images/default_menu.svg';
import SnackbarError from '../common/SnackbarError';
import SnackbarSuccess from '../common/SnackbarSuccess';
import { Prompt } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import DatePicker from 'react-datepicker';
import deletebtn from '../images/close-button.svg';
import {
  formatDate,
  getApiAsyn,
  loadierShow,
  baseUrls,
  getFormattedDateNull,
  getApiAsynBlobImage,
  loadingsShow,
} from '../Services/PostAPI';
import { post, put } from 'axios';
import deletebtnred from '../images/close_button_red.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Popup } from '../common/Popup';
import NotificationMessage from '../common/NotificationMessage';
import { APP_URLS } from '../constants/urlConstants';
const cookies = new Cookies();

let FileData = [];

class CreateEmployee extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: JSON.parse(localStorage.getItem('menuAccess')),
      UIValue: {
        total: 0,
      },
      DropDown: {},
      PromptPrevent: false,
      formOption: {},
      errors: {},
      consignValue: [],
      moreModal: false,
      ImageAddedS3: [],
      showCustomerModal: false,
      showDeleteModal: false,
      deleteItemIndex: '',
      textMessage:
        'This will delete all the items of related order. Do you want to continue ?',
      ImageDisplay: [],
    };
  }

  getDRIVER = async (type) => {
    let res = await getApiAsyn(
      `${type}?paged=true&page=1&size=50000`,
      cookies.get('authorization')
    );
    if (res && res.status !== false) {
      let count = res && res.page && res.page.total_elements;
      if (count > 0) {
        if (type === 'employees') {
          let dd = res._embedded.content;
          dd = dd.map((item) => ({
            name: item.first,
            value: item.id,
          }));

          this.setState((prevState) => {
            let { DropDown } = prevState;
            DropDown.DRIVER = dd;
            return { DropDown };
          });
        } else {
          let dd = res._embedded.content;
          dd = dd.map((item) => ({
            name: item.asset_code,
            value: item.id,
          }));

          this.setState((prevState) => {
            let { DropDown } = prevState;
            DropDown.DRIVER = dd;
            return { DropDown };
          });
        }
      } else {
        this.setState((prevState) => {
          let { DropDown } = prevState;
          DropDown.DRIVER = [];
          return { DropDown };
        });
      }
    }
  };
  getCMPLNC_TYPE = async () => {
    let res = await getApiAsyn(
      `types?type=CMPLNC_TYPE`,
      cookies.get('authorization')
    );
    if (res) {
      let dd = res.map((item) => ({
        name: item.value,
        value: item.key,
        code: item.code,
      }));

      this.setState((prevState) => {
        let { DropDown, UIValue } = prevState;
        DropDown.CMPLNC_TYPE = dd;
        return { DropDown, UIValue };
      });
      if (dd.length > 0) {
        this.getCMPLNC_CAT_TYPE(dd[0].code);
        this.setState((prevState) => {
          let { UIValue } = prevState;
          UIValue.cmplnc_type = dd[0].value;
          return { UIValue };
        });
      }
    }
  };

  getCMPLNC_CAT_TYPE = async (id) => {
    let res = await getApiAsyn(
      `types?type=CMPLNC_CAT_TYPE&param1=${id}`,
      cookies.get('authorization')
    );
    if (res.type !== 'error') {
      let dd = res.map((item) => ({
        name: item.value,
        value: item.key,
      }));
      this.setState((prevState) => {
        let { DropDown } = prevState;
        DropDown.CMPLNC_CAT_TYPE = dd;
        return { DropDown };
      });
    } else {
    }
  };
  getCMPLNC_STATUS = async () => {
    let res = await getApiAsyn(
      `types?type=CMPLNC_STATUS`,
      cookies.get('authorization')
    );
    if (res) {
      let dd = res.map((item) => ({
        name: item.value,
        value: item.key,
      }));
      this.setState((prevState) => {
        let { DropDown } = prevState;
        DropDown.CMPLNC_STATUS = dd;
        return { DropDown };
      });
    }
  };
  getCMPLNC_ITEM_STATUS = async () => {
    let res = await getApiAsyn(
      `types?type=CMPLNC_ITEM_STATUS`,
      cookies.get('authorization')
    );
    if (res) {
      let dd = res.map((item) => ({
        name: item.value,
        value: item.key,
      }));
      this.setState((prevState) => {
        let { DropDown } = prevState;
        DropDown.CMPLNC_ITEM_STATUS = dd;
        return { DropDown };
      });
    }
  };
  removeOrderItem = (index, item) => {
    this.setState({
      deleteItemIndex: index,
      orderItem: item,
      showDeleteModal: true,
    });
  };
  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }));
  };

  handleReset = () => {
    window.location.reload();
  };

  getDataByID = async () => {
    if (this.props.match !== undefined && this.props.match !== '') {
      if (
        this.props.match.params.id !== '' &&
        this.props.match.params.id !== undefined
      ) {
        let res = await getApiAsyn(
          `compliances/${this.props.match.params.id}`,
          cookies.get('authorization')
        );
        if (res) {
          let items = [];
          if (res.items && res.items.length > 0) {
            for (let i = 0; i < res.items.length; i++) {
              if (res.items[i].files && res.items[i].files.length > 0) {
                this.getFileData(res.items[i].files);
              }
              let date,
                i_due_date,
                strt_date,
                end_date = null;

              date = getFormattedDateNull(
                res.items[i].date,
                'DD-MM-YYYY hh:mm:ss',
                false
              );

              i_due_date = getFormattedDateNull(
                res.items[i].due_date,
                'DD-MM-YYYY hh:mm:ss',
                false
              );

              strt_date = getFormattedDateNull(
                res.items[i].strt_date,
                'DD-MM-YYYY hh:mm:ss',
                false
              );

              end_date = getFormattedDateNull(
                res.items[i].end_date,
                'DD-MM-YYYY hh:mm:ss',
                false
              );

              items.push({
                id: res.items[i].id,
                date: new Date(date),
                item_desc: res.items[i].item_desc,
                due_date: new Date(i_due_date),
                rem_req: res.items[i].rem_req === true ? 'yes' : 'no',
                status: res.items[i].status,
                rem_action: res.items[i].rem_action,
                ref_no: res.items[i].ref_no,
                fileNm: res.items[i].fileNm,
                item_status:
                  res.items[i].item_status && res.items[i].item_status.id,
                strt_date: new Date(strt_date),
                end_date: new Date(end_date),
                files: res.items[i].files,
              });
            }
          }
          let due_date = '';
          due_date = getFormattedDateNull(
            res.due_date,
            'DD-MM-YYYY hh:mm:ss',
            false
          );

          this.setState((prevState) => {
            let { UIValue, consignValue } = prevState;
            UIValue.cmplnc_type = res.cmplnc_type && res.cmplnc_type.id;
            UIValue.cmplnc_desc = res.cmplnc_desc;
            UIValue.cmplnc_status = res.cmplnc_status && res.cmplnc_status.id;
            UIValue.cmplnc_cat = res.cmplnc_cat && res.cmplnc_cat.id;
            UIValue.driver = res.driver && res.driver.id;
            UIValue.due_date = new Date(due_date);
            UIValue.impct_rslt = res.impct_rslt && res.impct_rslt;
            UIValue.impct_rt = res.impct_rt && res.impct_rt;
            consignValue = items;

            return { UIValue, consignValue };
          });
        }
        console.log(res);
      }
    }
  };

  handleDate = (date, name) => {
    const { UIValue } = this.state;
    UIValue[name] = date;
    this.setState({
      [UIValue]: UIValue,
      PromptPrevent: true,
    });
  };

  handleSeachableDropdonwConsign = (val, name, index) => {
    const { consignValue } = this.state;
    consignValue[index][name] = val;
    this.setState({
      [consignValue]: consignValue,
      PromptPrevent: true,
    });
  };

  handleSeachableDropdonw = (val, name) => {
    const { UIValue } = this.state;
    UIValue[name] = val;
    this.setState({
      [UIValue]: UIValue,
      PromptPrevent: true,
    });
    if (name === 'cmplnc_type') {
      let { DropDown } = this.state;
      let code = DropDown.CMPLNC_TYPE.filter((item) => item.value === val);
      if (code[0].code === 'DR') {
        this.getDRIVER('employees');
      }
      if (code[0].code === 'AST') {
        this.getDRIVER('assets');
      }

      this.getCMPLNC_CAT_TYPE(code[0].code);
    }
  };

  handleChangeGen = (event) => {
    const { UIValue } = this.state;
    UIValue[event.target.name] = event.target.value;
    this.setState({
      [UIValue]: UIValue,
      PromptPrevent: true,
    });
  };

  handleChangeGenFile = (event) => {
    const { UIValue } = this.state;
    if (event.target.value !== '') {
      UIValue[event.target.name] = event.target.value;
      if (event.target.type === 'file')
        UIValue[event.target.name] = event.target.files[0];
      FileData.push(event.target.files[0]);

      UIValue['files'] = event.target.files;

      this.setState({
        [UIValue]: UIValue,
        PromptPrevent: true,
        ImageDisplay: FileData,
      });
    }
  };

  handleRemoveFile = (item, i) => {
    let { UIValue, ImageDisplay } = this.state;

    let data = ImageDisplay;

    let newData = Object.values(data).filter((user, idx) => idx !== i);

    FileData.splice(i, 1);
    this.setState({
      ImageDisplay: newData,
      PromptPrevent: true,
      UIValue: {
        ...UIValue,
        files: newData,
      },
    });
  };

  handleSeachableconsignDropdonw = (val, name) => {
    const { consignValue } = this.state;

    consignValue[name] = val;

    this.setState({
      [consignValue]: consignValue,
      PromptPrevent: true,
    });
  };

  handleChange = (e, index) => {
    let { consignValue } = this.state;
    const { name, value } = e.target;

    consignValue = [...consignValue];
    consignValue[index][name] = value;

    this.setState({ consignValue, PromptPrevent: true });
  };

  handleDateConsig = (date, name, index) => {
    const { consignValue } = this.state;
    consignValue[index][name] = date;
    this.setState({
      [consignValue]: consignValue,
      PromptPrevent: true,
    });
  };

  handleChangeGenChecked = (event) => {
    const { UIValue } = this.state;
    UIValue[event.target.name] = event.currentTarget.value;
    this.setState({
      [UIValue]: UIValue,
      PromptPrevent: true,
    });
  };

  handleValidation = () => {
    const { UIValue } = this.state;

    let errors = {};
    let formIsValid = true;

    // if (!UIValue['source_type']) {
    //   formIsValid = false;
    //   errors['source_type'] = 'This is a mandatory field.';
    // }
    if (!UIValue['cmplnc_type']) {
      formIsValid = false;
      errors['cmplnc_type'] = 'This is a mandatory field.';
    }
    if (!UIValue['cmplnc_cat']) {
      formIsValid = false;
      errors['cmplnc_cat'] = 'This is a mandatory field.';
    }
    if (!UIValue['driver']) {
      formIsValid = false;
      errors['driver'] = 'This is a mandatory field.';
    }
    if (!UIValue['cmplnc_desc']) {
      formIsValid = false;
      errors['cmplnc_desc'] = 'This is a mandatory field.';
    }
    if (!UIValue['cmplnc_status']) {
      formIsValid = false;
      errors['cmplnc_status'] = 'This is a mandatory field.';
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  handelMessage = (Message, Boll) => {
    const { formOption } = this.state;
    formOption[Boll] = true;
    formOption['Message'] = Message;
    this.setState(
      {
        formOption: formOption,
      },
      function () {
        setTimeout(
          function () {
            formOption[Boll] = false;
            this.setState({
              formOption: formOption,
            });
          }.bind(this),
          3000
        );
      }
    );
  };

  toggleMoreModal = (index) => {
    this.setState({
      moreModal: index,
    });
  };

  onChangeOrderItem = (index) => (event) => {
    const { consignValue } = this.state;

    if (event.target.value !== '') {
      consignValue[index][event.target.name] = event.target.value;
      if (event.target.type === 'file')
        consignValue[index][event.target.name] = event.target.files[0];
      FileData.push(event.target.files[0]);

      consignValue[index]['files'] = event.target.files;

      this.setState({
        [consignValue]: consignValue,
        ImageDisplay: FileData,
      });
    }

    this.setState({
      consignValue,
    });
  };

  handleSave = () => {
    if (this.handleValidation()) {
      const { UIValue, consignValue } = this.state;
      let consObject = [];
      let fileObj = [];

      if (consignValue.length === 0) {
        this.handelMessage(
          'Can not create compliances without items',
          'errorBoll'
        );
        return;
      }
      let newdate = new Date(
        Math.max.apply(
          null,
          consignValue.map(function (e) {
            return new Date(e.due_date);
          })
        )
      );
      this.setState((prevState) => {
        let { UIValue } = prevState;
        UIValue.due_date = new Date(newdate);
        return { UIValue };
      });
      for (let i = 0; i < consignValue.length; i++) {
        if (consignValue[i].date === '') {
          this.handelMessage('Date is required', 'errorBoll');
          return false;
        }
        if (
          consignValue[i].due_date === '' ||
          consignValue[i].due_date == null
        ) {
          this.handelMessage('Remidiation/ Due Date is required', 'errorBoll');
          return false;
        }
        if (consignValue[i].item_desc === '') {
          this.handelMessage('Item description is required', 'errorBoll');
          return false;
        }

        if (
          consignValue[i].strt_date === '' ||
          consignValue[i].strt_date == null
        ) {
          this.handelMessage('Start date is required', 'errorBoll');
          return false;
        }
        if (
          consignValue[i].end_date === '' ||
          consignValue[i].end_date == null
        ) {
          this.handelMessage('End date is required', 'errorBoll');
          return false;
        }
        if (consignValue[i].item_status === '') {
          this.handelMessage('Status is required', 'errorBoll');
          return false;
        } else {
          fileObj.push(consignValue[i].files && consignValue[i].files);
          consObject.push({
            id: consignValue[i].id || 0,
            date: formatDate(consignValue[i].date),
            item_desc: consignValue[i].item_desc,
            rem_req:
              consignValue[i].rem_req !== undefined &&
              consignValue[i].rem_req === 'yes'
                ? true
                : false,
            rem_action: consignValue[i].rem_action,
            strt_date: formatDate(consignValue[i].strt_date),
            end_date: formatDate(consignValue[i].end_date),
            due_date: formatDate(consignValue[i].due_date),
            item_status: parseInt(consignValue[i].item_status),
            ref_no: consignValue[i].ref_no,
            status: consignValue[i].status,
            fileNm:
              consignValue[i].files &&
              consignValue[i].files.length > 0 &&
              consignValue[i].files[0].name !== undefined
                ? consignValue[i].files[0].name
                : '',
          });
        }
      }

      let reqData = {
        id: UIValue.id || 0,
        source_id: 3, //UIValue.source_id,
        due_date: formatDate(newdate),
        source_type: 'CMPC',
        cmplnc_type: UIValue.cmplnc_type,
        impct_rt: UIValue.impct_rt,
        impct_rslt: UIValue.impct_rslt,
        cmplnc_cat: UIValue.cmplnc_cat,
        driver: UIValue.driver,
        cmplnc_desc: UIValue.cmplnc_desc,
        cmplnc_status: UIValue.cmplnc_status,
        //order_date: getFormattedDate(UIValue.order_date, 'DD-MM-yyyy', true),
        items: consObject,
      };

      const formPayload = new FormData();
      let files = fileObj;
      if (files !== undefined && files.length > 5) {
        this.handelMessage(
          'You can attach max 5 file in a compliance',
          'errorBoll'
        );
        return false;
      }
      files = FileData;
      if (files !== undefined && files.length > 0 && files.length < 5) {
        for (let i = 0; i < files.length; i++) {
          formPayload.append(`file`, files[i]);
        }
      }
      const json = JSON.stringify(reqData);
      const order = new Blob([json], {
        type: 'application/json',
      });
      formPayload.append('data', order);

      loadierShow('block');
      this.fileUpload(formPayload).then((response) => {
        console.log(response);
        FileData = [];
        loadierShow('none');
        this.setState(
          {
            legalBoll: true,
            PromptPrevent: false,
            successMessage: 'Compliances added successfully!',
          },
          function () {
            setTimeout(
              function () {
                this.setState({
                  legalBoll: false,
                  PromptPrevent: false,
                });
                window.location.replace('#/Compliance');
              }.bind(this),
              1000
            );
          }
        );
      });
    }
  };
  fileUpload = (reqData) => {
    if (this.props.match.params.id) {
      const url = baseUrls + `compliances/${this.props.match.params.id}`;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: cookies.get('authorization'),
        },
      };
      return put(url, reqData, config);
    } else {
      const url = baseUrls + 'compliances';
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: cookies.get('authorization'),
        },
      };
      return post(url, reqData, config);
    }
  };

  formatDates = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  };
  handleAddClick = (id) => {
    this.setState((prevState) => ({
      consignValue: [
        ...prevState.consignValue,
        {
          id: 0,
          date: new Date(),
          item_desc: '',
          strt_date: new Date(),
          due_date: new Date(),
          end_date: new Date(),
          item_status: '',
          status: true,
          files: '',
          rem_req: '',
          rem_action: '',
          ref_no: '',
        },
      ],
    }));
  };

  async componentDidMount() {
    FileData = [];
    await loadingsShow('block');
    // await this.getCMPLNC_CAT_TYPE('CMPLNC_CAT_DR_TYPE');
    await this.getDRIVER('employees');
    await this.getCMPLNC_ITEM_STATUS();
    await this.getCMPLNC_TYPE();
    await this.getCMPLNC_STATUS();
    await this.getDataByID();
    await loadingsShow('none');
  }

  onDragEnd = (result) => {
    const { consignValue } = this.state;
    if (!result.destination) {
      return;
    }

    const updatedItems = this.reorder(
      consignValue,
      result.source.index,
      result.destination.index
    );

    this.setState({
      consignValue: updatedItems,
    });
  };

  handleRemoveClick = (index) => {
    let { consignValue, deleteItemIndex, orderItem } = this.state;
    if (orderItem === 0) {
      consignValue.splice(deleteItemIndex, 1);
      this.setState({
        consignValue,
      });
    } else {
      let updateObject = [];
      consignValue = JSON.parse(JSON.stringify(consignValue));
      consignValue[deleteItemIndex].status = false;

      for (let i = 0; i < consignValue.length; i++) {
        updateObject.push({
          id: consignValue[i].id || 0,
          date: new Date(consignValue[i].date),
          item_desc: consignValue[i].item_desc,
          due_date: new Date(consignValue[i].due_date),
          rem_req: consignValue[i].rem_req,
          rem_action: consignValue[i].rem_action,
          ref_no: consignValue[i].ref_no,
          strt_date: new Date(consignValue[i].strt_date),
          end_date: new Date(consignValue[i].end_date),
          item_status: consignValue[i].item_status,
          status: consignValue[i].status,
          files: consignValue[i].files,
        });
      }
      this.setState({
        consignValue: updateObject,
      });
    }
    this.toggleDeleteModal();
  };

  getFileData = async (data) => {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let res = await getApiAsynBlobImage(
          data[i].url,
          cookies.get('authorization')
        );
        this.setState((prevState) => {
          let { ImageAddedS3 } = prevState;
          ImageAddedS3.push({
            url: res.message,
            id: data[i].id,
            name: data[i].name,
            status: data[i].status,
          });
          return { ImageAddedS3 };
        });
      }
    }
  };

  handleS3Remove = (index) => {
    let { ImageAddedS3 } = this.state;
    ImageAddedS3[index].status = 'N';
    this.setState({ ImageAddedS3 });
  };
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  render() {
    const {
      showDeleteModal,
      DropDown,
      textMessage,
      ImageAddedS3,
      errors,
      UIValue,
      PromptPrevent,
    } = this.state;

    const { formOption, moreModal, consignValue } = this.state;
    const showMoreModal = Boolean(moreModal >= 0 && moreModal !== false);

    //const files = consignValue && showMoreModal && consignValue[moreModal]['files'];
    return (
      <div>
        <SnackbarError
          errorBoll={formOption.errorBoll}
          errorMessage={formOption.Message}
        />
        <NotificationMessage
          notifyBoll={formOption.notifyBoll}
          notifyMessage={formOption.Message}
        />
        <SnackbarSuccess
          legalBoll={formOption.legalBoll}
          successMessage={formOption.Message}
        />
        <div className="paddingbox">
          <div className="containerboxform">
            <div className="title bdrb">
              <h3>
                {this.props.match !== undefined &&
                this.props.match !== '' &&
                this.props.match.params.id !== '' &&
                this.props.match.params.id !== undefined
                  ? 'Update Compliance'
                  : 'Compliance'}
              </h3>
            </div>

            <div className="form">
              <Col sm="12">
                <Row>
                  <Col md={6}>
                    <Form.Group
                      className={
                        errors['cmplnc_type'] ? 'errorshows' : 'errorshides'
                      }
                      as={Row}
                    >
                      <Form.Label column sm="3" className="text-right">
                        Compliance*
                      </Form.Label>
                      <Col sm="8">
                        <SelectSearch
                          container="rowselect"
                          search
                          options={DropDown.CMPLNC_TYPE}
                          select="test"
                          name="cmplnc_type"
                          autoComplete="sdf"
                          onChange={(val) =>
                            this.handleSeachableDropdonw(val, 'cmplnc_type')
                          }
                          value={UIValue.cmplnc_type}
                          placeholder="Type to search"
                        />

                        <span
                          className={
                            errors['cmplnc_type'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['cmplnc_type']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group
                      className={
                        errors['cmplnc_cat'] ? 'errorshows' : 'errorshides'
                      }
                      as={Row}
                    >
                      <Form.Label column sm="3" className="text-right">
                        Category*
                      </Form.Label>
                      <Col sm="8">
                        <SelectSearch
                          container="rowselect"
                          search
                          options={DropDown.CMPLNC_CAT_TYPE}
                          select="test"
                          name="cmplnc_cat"
                          autoComplete="sdf"
                          onChange={(val) =>
                            this.handleSeachableDropdonw(val, 'cmplnc_cat')
                          }
                          value={UIValue.cmplnc_cat}
                          placeholder="Type to search"
                        />

                        <span
                          className={
                            errors['cmplnc_cat'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['cmplnc_cat']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group
                      className={
                        errors['driver'] ? 'errorshows' : 'errorshides'
                      }
                      as={Row}
                    >
                      <Form.Label column sm="3" className="text-right">
                        Asset/Driver Name*
                      </Form.Label>
                      <Col sm="8">
                        <SelectSearch
                          container="rowselect"
                          search
                          options={DropDown.DRIVER}
                          select="test"
                          name="driver"
                          autoComplete="sdf"
                          onChange={(val) =>
                            this.handleSeachableDropdonw(val, 'driver')
                          }
                          value={UIValue.driver}
                          placeholder="Type to search"
                        />

                        <span
                          className={
                            errors['driver'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['driver']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group
                      className={
                        errors['cmplnc_desc'] ? 'errorshows' : 'errorshides'
                      }
                      as={Row}
                    >
                      <Form.Label column sm="3" className="text-right">
                        Description*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="cmplnc_desc"
                          value={UIValue.cmplnc_desc}
                          onChange={this.handleChangeGen}
                        />

                        <span
                          className={
                            errors['cmplnc_desc'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['cmplnc_desc']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group
                      className={
                        errors['cmplnc_status'] ? 'errorshows' : 'errorshides'
                      }
                      as={Row}
                    >
                      <Form.Label column sm="3" className="text-right">
                        Status*
                      </Form.Label>
                      <Col sm="8">
                        <SelectSearch
                          container="rowselect"
                          search
                          options={DropDown.CMPLNC_STATUS}
                          select="test"
                          name="cmplnc_status"
                          autoComplete="sdf"
                          onChange={(val) =>
                            this.handleSeachableDropdonw(val, 'cmplnc_status')
                          }
                          value={UIValue.cmplnc_status}
                          placeholder="Type to search"
                        />

                        <span
                          className={
                            errors['cmplnc_status'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['cmplnc_status']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group
                      className={
                        errors['due_date'] ? 'errorshows' : 'errorshides'
                      }
                      as={Row}
                    >
                      <Form.Label column sm="3" className="text-right">
                        Due Date*
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          disabled
                          selected={UIValue.due_date}
                          dateFormat="dd-MM-yyyy hh:mm:ss"
                          className="form-control inpcalendars"
                          popperPlacement="start"
                          showTimeSelect
                          locale="en-GB"
                          name="due_date"
                          autoComplete="off"
                          value={UIValue.due_date}
                          onChange={(date) => this.handleDate(date, 'due_date')}
                        />

                        <span
                          className={
                            errors['due_date'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['due_date']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group
                      className={
                        errors['impct_rt'] ? 'errorshows' : 'errorshides'
                      }
                      as={Row}
                    >
                      <Form.Label column sm="3" className="text-right">
                        Impact Rate
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="impct_rt"
                          type="number"
                          value={UIValue.impct_rt >= 0 ? UIValue.impct_rt : 0}
                          onChange={this.handleChangeGen}
                        />

                        <span
                          className={
                            errors['impct_rt'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['impct_rt']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group
                      className={
                        errors['impct_rslt'] ? 'errorshows' : 'errorshides'
                      }
                      as={Row}
                    >
                      <Form.Label column sm="3" className="text-right">
                        Impact Result
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="impct_rslt"
                          value={UIValue.impct_rslt}
                          onChange={this.handleChangeGen}
                        />

                        <span
                          className={
                            errors['impct_rslt'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['impct_rslt']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="bdrb">
                  {consignValue.length > 0 ? (
                    <div className="Tablegrid">
                      <div className="listtopbar">
                        <div className="date2">Date</div>
                        <div className="pkgtype">Discritpion</div>
                        <div className="date2">Remidiation/ Due Date*</div>
                        <div className="date2">Remidiation Required</div>
                        <div className="date2">Remidiation Action</div>
                        <div className="date2">Start Date</div>
                        <div className="date2">End Date</div>
                        <div className="unitbox">Status</div>
                        <div className="unitbox">Refference number</div>
                        <div className="notes"></div>
                      </div>
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {this.state.consignValue.map((x, i) => (
                                <Draggable
                                  key={i}
                                  draggableId={i.toString()}
                                  index={i}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      // {...provided.dragHandleProps}
                                    >
                                      {x.status === true ? (
                                        <div className="listdata" key={i}>
                                          <div className="date2">
                                            <DatePicker
                                              selected={x.date}
                                              dateFormat="dd-MM-yyyy hh:mm:ss"
                                              className="form-control inpcalendars"
                                              popperPlacement="start"
                                              showTimeSelect
                                              locale="en-GB"
                                              name="date"
                                              autoComplete="off"
                                              value={x.date}
                                              onChange={(date) =>
                                                this.handleDateConsig(
                                                  date,
                                                  'date',
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="pkgtype">
                                            <Form.Control
                                              name="item_desc"
                                              value={x.item_desc}
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                            />
                                          </div>
                                          <div className="date2">
                                            <DatePicker
                                              selected={x.due_date}
                                              dateFormat="dd-MM-yyyy hh:mm:ss"
                                              className="form-control inpcalendars"
                                              popperPlacement="start"
                                              showTimeSelect
                                              locale="en-GB"
                                              name="due_date"
                                              autoComplete="off"
                                              value={x.due_date}
                                              onChange={(date) =>
                                                this.handleDateConsig(
                                                  date,
                                                  'due_date',
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="date2">
                                            <Form.Control
                                              as="select"
                                              name="rem_req"
                                              value={x.rem_req}
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                            >
                                              <option disabled>select..</option>
                                              <option value="yes">Yes</option>
                                              <option value="no">No</option>
                                            </Form.Control>
                                          </div>
                                          <div className="date2">
                                            <Form.Control
                                              name="rem_action"
                                              value={x.rem_action}
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                            />
                                          </div>

                                          <div className="date2">
                                            <DatePicker
                                              selected={x.strt_date}
                                              dateFormat="dd-MM-yyyy hh:mm:ss"
                                              className="form-control inpcalendars"
                                              popperPlacement="start"
                                              showTimeSelect
                                              locale="en-GB"
                                              name="strt_date"
                                              autoComplete="off"
                                              value={x.strt_date}
                                              onChange={(date) =>
                                                this.handleDateConsig(
                                                  date,
                                                  'strt_date',
                                                  i
                                                )
                                              }
                                            />
                                          </div>

                                          <div className="date2">
                                            <DatePicker
                                              selected={x.end_date}
                                              dateFormat="dd-MM-yyyy hh:mm:ss"
                                              className="form-control inpcalendars"
                                              popperPlacement="start"
                                              showTimeSelect
                                              locale="en-GB"
                                              name="end_date"
                                              autoComplete="off"
                                              value={x.req_date}
                                              onChange={(date) =>
                                                this.handleDateConsig(
                                                  date,
                                                  'end_date',
                                                  i
                                                )
                                              }
                                            />
                                          </div>

                                          <div className="unitbox">
                                            <Form.Control
                                              as="select"
                                              name="item_status"
                                              value={x.item_status}
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                            >
                                              <option disabled>select..</option>
                                              {DropDown &&
                                                DropDown.CMPLNC_ITEM_STATUS &&
                                                DropDown.CMPLNC_ITEM_STATUS.map(
                                                  (item) => (
                                                    <option
                                                      key={item.value}
                                                      value={item.value}
                                                    >
                                                      {item.name}
                                                    </option>
                                                  )
                                                )}
                                            </Form.Control>
                                          </div>

                                          <div className="unitbox">
                                            <Form.Control
                                              name="ref_no"
                                              value={x.ref_no}
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                            />
                                          </div>
                                          <div className="notes">
                                            {UIValue.trip != null ? (
                                              <img
                                                className="delete"
                                                src={deletebtn}
                                                alt="Delete"
                                              />
                                            ) : (
                                              <img
                                                className="delete"
                                                onClick={() =>
                                                  this.removeOrderItem(i, x.id)
                                                }
                                                src={deletebtnred}
                                                alt="Delete"
                                              />
                                            )}

                                            <div className="moremenu moremenus">
                                              <img
                                                src={default_menu}
                                                alt="More"
                                              />
                                              <ul>
                                                <li
                                                  onClick={() =>
                                                    this.toggleMoreModal(i)
                                                  }
                                                >
                                                  Attachment
                                                </li>
                                                <li>Task</li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  ) : null}

                  <div className="btnData">
                    <div className="btnbox">
                      <Button
                        //disabled={UIValue.trip != null}
                        onClick={() => this.handleAddClick('C')}
                        className="primary-button"
                      >
                        + Add
                      </Button>
                    </div>
                  </div>
                </Row>

                <Row>
                  <Col md={12} className="text-right">
                    <Link to={APP_URLS.EMPLOYEES.HOME}>
                      <Button className="secondary-button">Cancel</Button>
                    </Link>
                    <Button
                      onClick={this.handleReset}
                      className="secondary-button"
                    >
                      Reset
                    </Button>

                    <Button
                      onClick={this.handleSave}
                      className="primary-button"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </div>

        <Popup
          isOpen={showDeleteModal}
          onConfirm={this.handleRemoveClick}
          textMessage={textMessage}
          toggleShow={this.toggleDeleteModal}
        />
        {PromptPrevent ? (
          <Prompt
            message={() =>
              'Moving away from this page will remove any unsaved data. Are you sure?'
            }
          />
        ) : null}

        <Modal
          id="moreinfo"
          onHide={() => this.toggleMoreModal(false)}
          show={showMoreModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>More Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <h3>Attachment </h3>

                  <Form.File
                    type="file"
                    name="files"
                    label="Upload files"
                    onChange={this.onChangeOrderItem(moreModal)}
                  />
                  <div className="clr"></div>
                  <div className="filetoupload">
                    <ul>
                      {moreModal !== false &&
                      ImageAddedS3 &&
                      ImageAddedS3.length > 0 &&
                      ImageAddedS3[moreModal] !== undefined ? (
                        <li>
                          {ImageAddedS3[moreModal].name !== undefined ? (
                            <span className="imgName">
                              {' '}
                              {ImageAddedS3[moreModal].name}
                            </span>
                          ) : null}
                          {ImageAddedS3[moreModal].url !== undefined ? (
                            <span className="imgHolder">
                              <img
                                src={ImageAddedS3[moreModal].url}
                                alt="img"
                              />
                            </span>
                          ) : null}
                          <span
                            className="remove"
                            onClick={() => this.handleS3Remove(moreModal)}
                          >
                            x
                          </span>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="secondary-button"
              onClick={() => this.toggleMoreModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CreateEmployee;
