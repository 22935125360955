import React, { useState, useEffect } from 'react';
import { getApiAsyn, deleteApi } from '../../Services/PostAPI';
import Cookies from 'universal-cookie';
import { Col, Table, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import edit from '../../images/pencil.svg';
import deletebtn from '../../images/close-button.svg';
import { Popup } from '../../common/Popup';
const cookies = new Cookies();
export default function OrderTrips(props) {
  const [Data, setData] = useState([]);
  const [openRowIndex, setopenRowIndex] = useState('');
  const [itemtodelete, setitemtodelete] = useState('');
  const [deleteModal, setdeleteModal] = useState(false);
  const [textMessage] = useState(
    'This will delete trip. Do you want to continue ?'
  );
  const getTripsbyOrder = async () => {
    if (props.match !== undefined && props.match !== '') {
      if (props.match.params.id !== '' && props.match.params.id !== undefined) {
        let res = await getApiAsyn(
          `trips?intent=search&paged=true&page=1&size=10&order_id=${props.match.params.id}`,
          cookies.get('authorization')
        );
       
        if (res?.page?.total_elements > 0) {
          setData(res._embedded.content);
        }
      
      }
    }
  };

  const handleDeleteModal = (id) => {
    setdeleteModal(deleteModal === false ? true : false);
    setitemtodelete(id);
  };

  const handleRecordDelete = () => {
    deleteApi(`trips/${itemtodelete}`, cookies.get('authorization')).then(
      (res) => {
        if (res.status === 200) {
          this.setState(
            {
              legalBoll: true,
              successMessage: 'Record deleted successfully',
            },
            function () {
              handleDeleteModal();
            }
          );
          getTripsbyOrder();
        }
      }
    );
  };
  const toggleOpen = (idx) => {
    let i = openRowIndex === idx ? '' : idx;
    setopenRowIndex(i);
  };

  useEffect(() => {
    /*eslint-disable */
    getTripsbyOrder();
  }, []);
  return (
    <div>
      <div className="paddingbox">
        <div className="containerbox">
          <div className="title">
            <h3>Trips Details</h3>
          </div>
          <Row>
            {Data.length > 0 ? (
              <Col md={12}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th colSpan="2">Trip ID</th>
                      <th>Trip Type</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Total Miles</th>
                      <th>First Stop Time</th>
                      <th>Driver</th>
                      <th>Trailer</th>
                      <th>Truck</th>
                      <th colSpan="2">Actions</th>
                    </tr>
                  </thead>

                  {Data.map((item, i) => (
                    <tbody key={item.id.toString()}>
                      <tr className={openRowIndex === i ? 'parentactive' : ''}>
                        <td
                          className={
                            openRowIndex === i
                              ? 'minus cursorpoit'
                              : 'plus cursorpoit'
                          }
                          onClick={() => toggleOpen(i)}
                        >
                          +
                        </td>
                        <td>{item.trip_no}</td>
                        <td>{item.trip_type && item.trip_type.name}</td>
                        <td>{item.trip_date}</td>
                        <td>{item.trip_status && item.trip_status.name}</td>
                        <td>
                          {item.calc_miles != null && item.calc_miles !== ''
                            ? item.calc_miles
                            : 'N/A'}
                        </td>
                        <td>{item.trip_date}</td>
                        <td>{item.driver1 && item.driver1.first}</td>
                        <td>{item.trailer1 && item.trailer1.asset_code}</td>
                        <td>{item.vehicle && item.vehicle.asset_code}</td>

                        <td>
                          <Link
                            to={{ pathname: `/fleet/trip/${item.id}/edit` }}
                          >
                            {' '}
                            <img src={edit} alt="Edit" />
                          </Link>
                        </td>
                        <td>
                          <img
                            className="delete"
                            onClick={() => handleDeleteModal(item.id)}
                            src={deletebtn}
                            alt="Delete"
                          />
                        </td>
                      </tr>

                      <tr
                        className={openRowIndex === i ? 'subactive' : 'subhide'}
                      >
                        <td colSpan="12">
                          <Table bordered hover>
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Order#</th>
                                <th>Location</th>
                                <th>Status</th>
                                <th>Request Date</th>
                                <th>Weights</th>
                                <th>Note</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.items.map((x) => (
                                <tr key={x.id.toString()}>
                                  <td>{x.type}</td>
                                  <td>
                                    {x.order != null ? x.order.order_no : 'N/A'}
                                  </td>
                                  <td>{x.location}</td>
                                  <td>{x.item_status && x.item_status.name}</td>
                                  <td>{x.req_date}</td>
                                  <td>{x.weight}</td>
                                  <td>{x.note}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </Col>
            ) : (
              <div className="norecord">No record found</div>
            )}
          </Row>
        </div>
      </div>
      <Popup
        isOpen={deleteModal}
        onConfirm={handleRecordDelete}
        textMessage={textMessage}
        toggleShow={handleDeleteModal}
      />
    </div>
  );
}
