import React from 'react';
import { Route } from 'react-router-dom';

import InspectionsScreen from '../../containers/InspectionsScreen';
import CreateInspectionContainer from '../../containers/InspectionsScreen/CreateInspection';
import { APP_URLS } from '../../constants/urlConstants';

const InspectionRoutes = (props) => {
  const { access, match } = props;
  //const { create = true, read = true, update = true } = access || {};
  const { create = true, read = true, update = true } = access || {};
  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <InspectionsScreen {...routeProps} screenPermissions={access} />
          )}
        />
      )}
      {!!create && (
        <Route
          path={APP_URLS.INSPECTION.ADD_INSPECTION}
          component={CreateInspectionContainer}
        />
      )}
      {!!update && (
        <Route
          path={APP_URLS.INSPECTION.EDIT_INSPECTION}
          render={(routeProps) => (
            <CreateInspectionContainer {...routeProps} isEdit={true} />
          )}
        />
      )}
    </>
  );
};

export default InspectionRoutes;
