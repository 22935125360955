export const INIT_ACCIDENTS = 'INIT_ACCIDENTS';
export const FETCH_ACCIDENTS_LIST_START = 'FETCH_ACCIDENTS_LIST_START';
export const FETCH_ACCIDENTS_LIST_SUCCESS = 'FETCH_ACCIDENTS_LIST_SUCCESS';
export const FETCH_ACCIDENTS_LIST_ERROR = 'FETCH_ACCIDENTS_LIST_ERROR';

export const DELETE_ACCIDENT_RECORD_START = 'DELETE_ACCIDENT_RECORD_START';
export const DELETE_ACCIDENT_RECORD_SUCCESS = 'DELETE_ACCIDENT_RECORD_SUCCESS';
export const DELETE_ACCIDENT_RECORD_ERROR = 'DELETE_ACCIDENT_RECORD_ERROR';

export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';

export const INIT_CREATE_NEW_ACCIDENT_SCREEN =
  'INIT_CREATE_NEW_ACCIDENT_SCREEN';

export const CREATE_ACCIDENT_RECORD = {
  START: 'CREATE_ACCIDENT_RECORD_START',
  SUCCESS: 'CREATE_ACCIDENT_RECORD_SUCCESS',
  ERROR: 'CREATE_ACCIDENT_RECORD_ERROR',
};

export const EDIT_ACCIDENT_RECORD = {
  START: 'EDIT_ACCIDENT_RECORD_START',
  SUCCESS: 'EDIT_ACCIDENT_RECORD_SUCCESS',
  ERROR: 'EDIT_ACCIDENT_RECORD_ERROR',
};

export const FETCH_ACCIDENT_DETAIL = {
  START: 'FETCH_ACCIDENT_DETAIL_START',
  SUCCESS: 'FETCH_ACCIDENT_DETAIL_SUCCESS',
  ERROR: 'FETCH_ACCIDENT_DETAIL_ERROR',
};


export const TOAST = {
  RESET: 'RESET_TOAST',
};
