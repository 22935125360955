export const INIT_COMPLIANCES = 'INIT_COMPLIANCES';
export const FETCH_COMPLIANCES_LIST_START = 'FETCH_COMPLIANCES_LIST_START';
export const FETCH_COMPLIANCES_LIST_SUCCESS = 'FETCH_COMPLIANCES_LIST_SUCCESS';
export const FETCH_COMPLIANCES_LIST_ERROR = 'FETCH_COMPLIANCES_LIST_ERROR';

export const DELETE_COMPLIANCE_RECORD_START = 'DELETE_COMPLIANCE_RECORD_START';
export const DELETE_COMPLIANCE_RECORD_SUCCESS = 'DELETE_COMPLIANCE_RECORD_SUCCESS';
export const DELETE_COMPLIANCE_RECORD_ERROR = 'DELETE_COMPLIANCE_RECORD_ERROR';

export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';

export const INIT_CREATE_NEW_COMPLIANCE_SCREEN =
  'INIT_CREATE_NEW_COMPLIANCE_SCREEN';

export const CREATE_COMPLIANCE_RECORD = {
  START: 'CREATE_COMPLIANCE_RECORD_START',
  SUCCESS: 'CREATE_COMPLIANCE_RECORD_SUCCESS',
  ERROR: 'CREATE_COMPLIANCE_RECORD_ERROR',
};

export const EDIT_COMPLIANCE_RECORD = {
  START: 'EDIT_COMPLIANCE_RECORD_START',
  SUCCESS: 'EDIT_COMPLIANCE_RECORD_SUCCESS',
  ERROR: 'EDIT_COMPLIANCE_RECORD_ERROR',
};

export const FETCH_COMPLIANCE_DETAIL = {
  START: 'FETCH_COMPLIANCE_DETAIL_START',
  SUCCESS: 'FETCH_COMPLIANCE_DETAIL_SUCCESS',
  ERROR: 'FETCH_COMPLIANCE_DETAIL_ERROR',
};


export const TOAST = {
  RESET: 'RESET_TOAST',
};
