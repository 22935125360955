import { connect } from "react-redux";
import EzControlledAutoSuggestWithFloatingLabel from "../../common/EzForm/EzControlledAutoSuggest/WithFloatingLabel";
import { fetchSuppliers } from "../../Redux/Actions/Suppliers";
import { getSuppliers } from "../../Redux/Selectors/dropDown";
import { FILTER } from "../../Services/common/urlService";
import { payloadCreater } from "../../utils/dropDown/transformtoDropdown";

const Suppliers = (props) => {
  const { name, control, label, errorMsg, isError, fetchSuppliers, suppliers  } = props;
  const searchSuppliers = ({ query }) => 
      fetchSuppliers(payloadCreater(
        {
          query,
          filterField:'name',
          matchStrategy: FILTER.MATCH_STRATEGY.CONTAINS
        })
      ); 

  return <EzControlledAutoSuggestWithFloatingLabel
           name={name}
           control={control}
           suggestions={suppliers}
           completeMethod={({ query }) => (searchSuppliers({ query }))}
           label={label}
           isError={isError}
           errorMsg={errorMsg}
      />
} 

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSuppliers: (payload) => dispatch(fetchSuppliers(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    suppliers: getSuppliers(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);