import { tranformToAssetLocationGridView } from '..';
import {
  FETCH_ASSET_LOCATIONS_HISTORY_LIST_START,
  FETCH_ASSET_LOCATIONS_HISTORY_LIST_ERROR,
  FETCH_ASSET_LOCATIONS_HISTORY_LIST_SUCCESS,
} from '../../../../constants/action-types/assetLocation';


const INITIAL_STATE = {
  assetLocationHistoryListView: {},
  isLoading: false,
  error: null,
};

export const assetLocationsHistoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ASSET_LOCATIONS_HISTORY_LIST_START: 
    return { ...state, isLoading: true };

    case FETCH_ASSET_LOCATIONS_HISTORY_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        assetLocationsHistoryListView: {},
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case FETCH_ASSET_LOCATIONS_HISTORY_LIST_SUCCESS: {
      const { page, content } = action.payload || {};
      const resetState = {
        assetLocationsHistoryListView: null,
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
      };

      if (!content) {
        return resetState;
      }
      const { number, size, total_elements: totalElements, total_pages: totalPages } = page;
      const { assetLocationsListView: assetLocationsHistoryListView } = tranformToAssetLocationGridView(content);

      return {
        ...resetState,
        assetLocationsHistoryListView,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages,
          pageNumber: number,
        },
      };
    }

    default:
      return state;
  }
};
