import { DEFAULT_TOAST_LIFE } from '../../../common/EzToast';
import {
  INIT_COMPLIANCES,
  FETCH_COMPLIANCES_LIST_ERROR,
  FETCH_COMPLIANCES_LIST_START,
  FETCH_COMPLIANCES_LIST_SUCCESS,
  DELETE_COMPLIANCE_RECORD_START,
  DELETE_COMPLIANCE_RECORD_SUCCESS,
  DELETE_COMPLIANCE_RECORD_ERROR,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_COMPLIANCE_SCREEN,
  CREATE_COMPLIANCE_RECORD,
  EDIT_COMPLIANCE_RECORD,
  FETCH_COMPLIANCE_DETAIL,
  TOAST,
} from '../../../constants/action-types/compliance';
import { complianceService } from '../../../Services';
import { fetchEmployees } from '../../Actions/employees';
import {
  attachmentsFilesFetchSuccess,
  attachmentsFilesFetchStart,
  downloadingAllAttachmentStart,
  downloadingAllAttachmentComplete,
  attachmentsFilesFetchError,
  downloadingAllAttachmentError
} from '../common/files'
import { goBack } from '../../../utils/gotoback';



export const initializeComplianceScreen = () => ({ type: INIT_COMPLIANCES });

export const complianceFetchStart = (payload) => ({
  type: FETCH_COMPLIANCES_LIST_START,
  payload,
});

export const complianceFetchSuccess = (payload) => ({
  type: FETCH_COMPLIANCES_LIST_SUCCESS,
  payload,
});

export const complianceFetchError = (payload) => ({
  type: FETCH_COMPLIANCES_LIST_ERROR,
  payload,
});

export const fetchCompliances = (payload) => {
  return (dispatch) => {
    dispatch(complianceFetchStart(payload));

    complianceService.fetchCompliances(payload).then((complianceData) => {
      if (!complianceData.isError) {
        dispatch(fetchEmployees({ shouldPaginate: false }));
        dispatch(complianceFetchSuccess(complianceData));
      } else {
        dispatch(complianceFetchError(complianceData));
      }
    });
  };
};

export const complianceDetailFetchStart = (payload) => ({
  type: FETCH_COMPLIANCE_DETAIL.START,
  payload,
});

export const complianceDetailFetchSuccess = (payload) => ({
  type: FETCH_COMPLIANCE_DETAIL.SUCCESS,
  payload,
});

export const complianceDetailFetchError = (payload) => ({
  type: FETCH_COMPLIANCE_DETAIL.ERROR,
  payload,
});

export const fetchFilesByComplianceId = (payload) => async dispatch => {
  let request = null
  dispatch(attachmentsFilesFetchStart(payload));
  try {
    request = await complianceService.fetchComplianceAttachmentByComplianceId(payload);
    request = request?.items.filter(item => item.id==payload.dataId);
    request = request[0]
  }
  catch {
    dispatch(attachmentsFilesFetchError())
  }
  if (request.files) {
    dispatch(attachmentsFilesFetchSuccess(request.files));
  }
}

export const downloadAllAttachmentByComplianceId = (payload) => async (dispatch) => {
  dispatch(downloadingAllAttachmentStart())
  try {
    await complianceService.downloadAllAttachment(payload);
  }
  catch {
    dispatch(downloadingAllAttachmentError())
  }
  dispatch(downloadingAllAttachmentComplete());
}

export const fetchComplianceByComplianceId = (payload) => {
  return (dispatch) => {
    dispatch(complianceDetailFetchStart(payload));

    complianceService
      .fetchComplianceByComplianceId(payload)
      .then((complianceData) => {
        if (!complianceData.isError) {
          dispatch(complianceDetailFetchSuccess(complianceData));
        } else {
          dispatch(complianceDetailFetchError(complianceData));
        }
      });
  };
};


// delete compliance
export const deleteComplianceRecordStart = () => ({
  type: DELETE_COMPLIANCE_RECORD_START,
});

export const deleteComplianceRecordSuccess = (payload) => ({
  type: DELETE_COMPLIANCE_RECORD_SUCCESS,
  payload,
});

export const deleteComplianceRecordError = (payload) => ({
  type: DELETE_COMPLIANCE_RECORD_ERROR,
  payload,
});

export const deleteComplianceRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteComplianceRecordStart());

    complianceService
      .deleteComplianceRecord({ complianceId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteComplianceRecordSuccess());
          dispatch(fetchCompliances(payload?.fetchComplianceParams));
        } else {
          dispatch(deleteComplianceRecordError(data));
        }
      });
  };
};

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const createNewComplianceScreenInitStart = (payload) => ({
  type: INIT_CREATE_NEW_COMPLIANCE_SCREEN,
  payload,
});

export const initCreateNewComplianceScreen = (payload) => {
  return (dispatch) => {
    dispatch(createNewComplianceScreenInitStart());

  };
};

// create compliance
export const createComplianceRecordStart = () => ({
  type: CREATE_COMPLIANCE_RECORD.START,
});

export const createComplianceRecordSuccess = (payload) => ({
  type: CREATE_COMPLIANCE_RECORD.SUCCESS,
  payload,
});

export const createComplianceRecordError = (payload) => ({
  type: CREATE_COMPLIANCE_RECORD.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createCompliance = (payload) => {
  return (dispatch) => {
    dispatch(createComplianceRecordStart());
    complianceService.createComplianceRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(createComplianceRecordSuccess());
        goBack()
      } else {

        dispatch(createComplianceRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast(data));
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editComplianceRecordStart = () => ({
  type: EDIT_COMPLIANCE_RECORD.START,
});

export const editComplianceRecordSuccess = (payload) => ({
  type: EDIT_COMPLIANCE_RECORD.SUCCESS,
  payload,
});

export const editComplianceRecordError = (payload) => ({
  type: EDIT_COMPLIANCE_RECORD.ERROR,
  payload,
});

export const editCompliance = (payload) => {
  return (dispatch) => {
    dispatch(editComplianceRecordStart());
    complianceService.editComplianceRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(editComplianceRecordSuccess());
        goBack()
      } else {
        dispatch(editComplianceRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
