import React, { PureComponent } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Autosuggestion from '../common/Autosuggestion';

export class CustomerFilter extends PureComponent {
  render() {
    const {
      customerId,
      CustomerIdonChange,
      CustomerIdvalue,
      customerPhone,
      CustomerPhoneonChange,
      CustomerPhoneValue,
      emailId,
      emailonChange,
      emailvalue,
      BrokerNane,
      BrokeronChange,
      Brokervalue,
      bool,
      getAutosuggest,
      onClick,
    } = this.props;
    return (
      <div>
        <Row>
          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Customer Name
              </Form.Label>
              <Col sm="12">
                <Autosuggestion
                  name={customerId}
                  placeholder="Search customer..."
                  value={CustomerIdvalue}
                  onChange={CustomerIdonChange}
                  ref={this.wrapperRef}
                  style={{ display: bool ? 'block' : 'none' }}
                  bool={bool}
                  getAutosuggest={getAutosuggest}
                  onClick={onClick}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Phone Number
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  placeholder="Phone Number"
                  name={customerPhone}
                  autoComplete="sdf"
                  onChange={CustomerPhoneonChange}
                  value={CustomerPhoneValue}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Email ID
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  placeholder="Email ID"
                  name={emailId}
                  autoComplete="sdf"
                  onChange={emailonChange}
                  value={emailvalue}
                />
              </Col>
            </Form.Group>
          </Col>

          {/* <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Status
              </Form.Label>
              <Col sm="12">
                <SelectSearch
                  container="rowselect"
                  search
                  options={CustomerStatusDropdonw}
                  select="test"
                  name={CustomerStatusname}
                  autoComplete="sdf"
                  onChange={CustomerStatusonChange}
                  value={CustomerStatusvalue}
                  placeholder="Type to search"
                />
              </Col>
            </Form.Group>
          </Col> */}

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Broker
              </Form.Label>
              <Col sm="12">
                <Form.Check
                  type="checkbox"
                  name={BrokerNane}
                  autoComplete="sdf"
                  onChange={BrokeronChange}
                  value={Brokervalue}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  }
}
