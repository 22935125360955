import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row, Container } from 'react-bootstrap';
import { Button } from 'primereact/button';
import debouce from  "lodash.debounce"

import EzDialog from '../../../../common/EzDialog';
import EzControlledInputWithFloatingLabel from '../../../../common/EzForm/EzControlledInput/WithFloatingLabel';
import { getFormBaseValidationRule } from '../../../../utils/form/form-utils';
import { createDocumentType, editDocumentType } from '../../../../Redux/Actions/document_new'
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { API_URL } from '../../../../constants/urlConstants';
import EzControlledDropDowntWithFloatingLabel from '../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel';
import { useEffect } from 'react';

const INPUT_FIELDS = {
  DOCUMENT_TYPE_NAME: 'documentTypeName',
  DESCRIPTION: 'description'
}

const CreateDocumentType = props => {

  const { onHide, documentTypes = [], isEditMode, type } = props;
  const dispatch = useDispatch();
  const createDocType = useCallback(payload => dispatch(createDocumentType(payload, onHide)),[dispatch, onHide])
  const editDocType = useCallback((payload, documentTypeId) => dispatch(editDocumentType(payload,documentTypeId, onHide)), [dispatch, onHide])
  const updateDocumentName = debouce(() => {
    const isDocTypeNameExist = documentTypes.some(({ label }) => label.toLowerCase() === documentTypeNameWatch?.toLowerCase());
    setIsDocumentTypeExist(isDocTypeNameExist);
    if(isDocumentTypeExist) {
       setError(INPUT_FIELDS.DOCUMENT_TYPE_NAME, { shouldFocus: true});
      } 
    else clearErrors();
  }, 500)

  const {
    control,
    formState: { errors, isDirty },
    clearErrors,
    setError,
    handleSubmit,
    watch,
    reset,
  } = useForm({});

  const [isDocumentTypeExist, setIsDocumentTypeExist] = useState(false);
  const documentTypeNameWatch = watch(INPUT_FIELDS.DOCUMENT_TYPE_NAME);

  useEffect(() => {
    if(!isDirty) return;
    updateDocumentName();  

  }, [isDirty, updateDocumentName])

  useEffect(() => {
    const docType = documentTypes.length > 0 ? documentTypes.find( ({value}) => value === type ) : {} 
    if(docType && isEditMode) {
      const { name, detail1 } = docType.extraData;
      reset({
        documentTypeName: name,
        description: detail1
      })
  }
  },[type, documentTypes, reset, isEditMode])

  const renderFloatingInput = (name, label, rules = getFormBaseValidationRule().DEFAULT.VALIDATION_RULE) => {
    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={label}
        rules={rules}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderDocumentTypes = () => {
    const name = 'documentType'
    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules = {getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={documentTypes}
        label={`Document Type to edit`}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        labelClassName={'ml-2'}
      />
    );
  }
  
  const getFormErrorMessage = (name) => {
    return ( errors[name] && <small className="p-error">{errors[name].message}</small> );
  };

  const onSubmit = async (data) => {
    const { documentTypeName, description } = data || {};

    const dataPayload = {
      type:API_URL.DROPDOWN_TYPE.BS_DOC_TYPE,
      name: documentTypeName,
      detail1: description 
    }

    if(!isEditMode) createDocType(dataPayload);
    else editDocType(dataPayload, type)
    onHide && onHide();
  };
 
  return (
    <EzDialog  
      width={'70vw'}
      header = {() => <div>Create new document type</div> }
      onHide = {onHide}
      maximizable = {false}>
      <div className="form-demo" style={{}}>
          <div className="card" style={{ background: '#f3faf4BF' }}>
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <Container fluid>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="4">
                    {renderFloatingInput(INPUT_FIELDS.DOCUMENT_TYPE_NAME, 'Name')}
                    { isDocumentTypeExist && <span style={{ color:'red'}}> Document name exist. </span>}
                  </Col>
                  <Col md="5">{renderFloatingInput(INPUT_FIELDS.DESCRIPTION, 'Description', getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE)}</Col>
                  <Col md="3">
                    <Button
                      type="submit"
                      disabled={isDocumentTypeExist}
                      label={ isEditMode ? "Edit Document Type" : "Add Document Type"}
                      className="p-button-raised"
                    /></Col>
                </Row>
                { false &&
                  <Row style={{ minWidth: '20px' }}>
                    <Col md="4">{renderDocumentTypes()}</Col>
                  </Row>
                }
              </Container>
            </form>
          </div>
    </div>
</EzDialog>)

}

export default  CreateDocumentType;