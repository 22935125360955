import { useEffect } from 'react';
import { connect } from 'react-redux';
import { initializeDocumentsScreen ,fetchDocuments, deleteDocumentRecord, downloadAllAttachmentForDocumentId, documentsFetchFilesBydocumentId } from '../../Redux/Actions/document_new';
import { 
          selectdocumentsList, selectIsLoading, selectPaginationInfo,
          selectIsLoadingPage,selectIsPageLevelError,
          selectIsDocumentDeletionError, selectIsDocumentDeletionSuccess,
          selectShouldOpenDeleteConfirmationPopup  } from '../../Redux/Selectors/document_new';
import Documents from '../../components/Document_new';
import { loadingsShow } from '../../Services/PostAPI';
import { setDeleteConfirmationPopupVisibility } from '../../Redux/Actions/document_new';
import { APP_URLS } from '../../constants/urlConstants';
import { generatePath } from 'react-router';

const DocumentsScreen = (props) => {
  const {
    paginationInfo,
    isFetchingGridData,
    showPageLevelLoader,
    documentsListView,
    fetchDocuments,
    deleteDocumentRecord,
    isPageLevelError,
    screenPermissions,
    isDocumentDeletionError,
    isDocumentDeletionSuccess,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    fetchDocumentsAttachmentByDocumentId,
    downloadAttachment,
    history
  } = props;

 
  const moreActionProps = {
      isMoreActionDisabled : false,
      downloadEnabled :true,
      moreActionOptions : [],
      onMoreActionChange : null
  } 

  useEffect(() => {
    initializeDocumentsScreen()
    fetchDocuments({ sortField: 'date' })
  }, [fetchDocuments]);

  
  const createDocumentRecord = () => {
    history.push(APP_URLS.DOCUMENT.ADD_DOCUMENT);
  };

  const editDocumentRecord = (documentId) => {
    const path = generatePath(APP_URLS.DOCUMENT.EDIT_DOCUMENT, { id:documentId });
    history.push(path);
  };

  const documentsProps = {
    paginationInfo,
    isFetchingGridData,
    showPageLevelLoader,
    documentsListView,
    fetchDocuments,
    deleteDocumentRecord,
    isPageLevelError,
    screenPermissions,
    isDocumentDeletionError,
    isDocumentDeletionSuccess,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    createDocumentRecord,
    editDocumentRecord,
    fetchDocumentsAttachmentByDocumentId,
    downloadAttachment,
    moreActionProps
  };
  
  if (showPageLevelLoader) {
    loadingsShow('block');
    return null;
  }
  loadingsShow('none');

  return (
    <>
      <Documents {...documentsProps} />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    paginationInfo: selectPaginationInfo(state),
    isFetchingGridData: selectIsLoading(state),
    showPageLevelLoader: selectIsLoadingPage(state),
    documentsListView:selectdocumentsList(state),
    isPageLevelError: selectIsPageLevelError(state),
    isDocumentDeletionError :selectIsDocumentDeletionError(state),
    isDocumentDeletionSuccess:selectIsDocumentDeletionSuccess(state),
    shouldOpenDeleteConfirmationPopup: selectShouldOpenDeleteConfirmationPopup(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeDocumentsScreen: () => dispatch(initializeDocumentsScreen()),
    fetchDocuments: (payload) => dispatch(fetchDocuments(payload)),
    deleteDocumentRecord: (payload) => dispatch(deleteDocumentRecord(payload)),
    fetchDocumentsAttachmentByDocumentId: (payload) => dispatch(documentsFetchFilesBydocumentId(payload)),
    downloadAttachment: (payload) => dispatch(downloadAllAttachmentForDocumentId(payload)),
    setDeleteConfirmationPopupVisibility: (payload) => dispatch(setDeleteConfirmationPopupVisibility(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsScreen);
