const getAssets = (state) => state.assetsReducer;


export const selectIsPageLevelError = (state) => getAssets(state).isPageLevelError;
export const selectIsAssetDeletionError = (state) => getAssets(state).isAssetDeletionError;
export const selectIsAssetDeletionSuccess = (state) => getAssets(state).isAssetDeletionSuccess;
export const selectPaginationInfo = (state) => getAssets(state).paginationInfo;
export const selectIsLoading = (state) => getAssets(state).isLoading;
export const selectIsLoadingPage = (state) => getAssets(state).isLoadingPage;
export const selectAssetsList = (state) => getAssets(state).assetsListView;
export const selectShouldOpenDeleteConfirmationPopup = (state) => getAssets(state).shouldOpenDeleteConfirmationPopup;
export const selectIsCreateAssetSuccess = state => getAssets(state).isCreateAssetSuccess;
export const selectIsCreateAssetError = state => getAssets(state).isCreateAssetError;
export const selectformFieldValueMap = state => getAssets(state).formFieldValueMap;
export const selectIsAssetDetailSuccess = state => getAssets(state).isAssetDetailSuccess;
export const selectError = state => getAssets(state).error;
export const selectIsAssetDetailError = state => getAssets(state).isAssetDetailError;
export const selectIsEditAssetSuccess = state => getAssets(state).isEditAssetSuccess;
export const selectIsEditAssetError = state => getAssets(state).isEditAssetError;
