import { useEffect } from "react"
import { connect } from "react-redux";
import Asset from "../../../components/Asset/CreateAsset";
import { 
  selectformFieldValueMap,
  selectError,
  selectIsAssetDetailError,
  selectIsAssetDetailSuccess,
  selectIsEditAssetSuccess, 
  selectIsCreateAssetSuccess,
  selectIsEditAssetError} from "../../../Redux/Selectors/assets_new";
import { API_URL, APP_URLS } from "../../../constants/urlConstants";
import { setIsformDirty } from "../../../Redux/Actions/common/form";
import { createAsset,
        editAsset,
        fetchAssetByassetId,
        fetchAssetMake,
        fetchAssetModel
       } from "../../../Redux/Actions/assets";
import { fetchTypes } from "../../../Redux/Actions/types";
import { getEquipmentTypesList, getVehicleTypesList } from "../../../Redux/Selectors/types";
import { selectLoggedInUserInfo } from "../../../Redux/Selectors/profile";
import { getSuppliers } from "../../../Redux/Selectors/dropDown";
import { getMake, getModel } from "../../../Redux/Selectors/dropDown";
import { fetchSuppliers } from "../../../Redux/Actions/Suppliers";

const AssetContainer = props => {
    
    const {
            isEdit,
            history,
            fetchVechiclesTypes,
            fetchEquipmentTypes,
            fetchAssetsByAssetId,
            fetchAssetMake,
            fetchAssetModel,
            fetchSuppliers
         } = props;
    
    const { id: assetId } = props?.match?.params;

    const navigateToAssetSummaryScreen = () => {
        history.push(APP_URLS.ASSETS.HOME);
    }

    useEffect(() => {
       fetchVechiclesTypes({params : API_URL.TYPES.VEHICLE_TYPE });
       fetchEquipmentTypes({params : API_URL.TYPES.EQUIP_TYPE });
       fetchAssetMake();
       fetchAssetModel();
       fetchSuppliers();
       if(isEdit && assetId){
        fetchAssetsByAssetId({assetId})
       }
      }, [assetId, fetchAssetMake, fetchAssetModel, fetchAssetsByAssetId, fetchEquipmentTypes, fetchSuppliers, fetchVechiclesTypes, isEdit]);
    
    const AssetProps = {
      ...props,
      navigateToAssetSummaryScreen,
      assetId
    }
    
    return (
            <>
                {/* <EzToast {...getToastProps()} /> */}
                <Asset {...AssetProps} /> 
            </>
        );
}

const mapStateToProps = (state,onwProps) =>{
    return {
    ...onwProps,
    make: getMake(state),
    model: getModel(state),
    isAssetError: selectError(state),
    formFieldValueMap: selectformFieldValueMap(state),
    isAssetDetailSuccess: selectIsAssetDetailSuccess(state),
    isAssetDetailError: selectIsAssetDetailError(state),
    isCreateAssetSuccess: selectIsCreateAssetSuccess(state),
    isEditAssetSuccess: selectIsEditAssetSuccess(state),
    isEditAssetError: selectIsEditAssetError(state),
    vehcileType: getVehicleTypesList(state),
    equipmentType : getEquipmentTypesList(state),
    companyId : selectLoggedInUserInfo(state),
    suppliers: getSuppliers(state)
 }
}

const mapdispatchToProps = dispatch => {
    return {
      editAsset : payload => dispatch(editAsset(payload)),
      fetchVechiclesTypes : payload => dispatch(fetchTypes(payload)),
      fetchAssetsByAssetId : payload => dispatch(fetchAssetByassetId(payload)),
      fetchEquipmentTypes:  payload => dispatch(fetchTypes(payload)),
      fetchAssetMake: () =>  dispatch(fetchAssetMake()),
      fetchAssetModel: () =>  dispatch(fetchAssetModel()),
      createAsset : payload => dispatch(createAsset(payload)),
      setIsformDirty : isFormDirty => dispatch(setIsformDirty(isFormDirty)),
      fetchSuppliers: () => dispatch(fetchSuppliers())
    }
}

export default connect(mapStateToProps, mapdispatchToProps)(AssetContainer);
