export const INIT_ASSET_LOCATIONS = 'INIT_ASSET_LOCATIONS';
export const FETCH_ASSET_LOCATIONS_LIST_START = 'FETCH_ASSET_LOCATIONS_LIST_START';
export const FETCH_ASSET_LOCATIONS_LIST_SUCCESS = 'FETCH_ASSET_LOCATIONS_LIST_SUCCESS';
export const FETCH_ASSET_LOCATIONS_LIST_ERROR = 'FETCH_ASSET_LOCATIONS_LIST_ERROR';

export const FETCH_ASSET_LOCATIONS_HISTORY_LIST_START = 'FETCH_ASSET_LOCATIONS_LIST_START';
export const FETCH_ASSET_LOCATIONS_HISTORY_LIST_SUCCESS = 'FETCH_ASSET_LOCATIONS_LIST_SUCCESS';
export const FETCH_ASSET_LOCATIONS_HISTORY_LIST_ERROR = 'FETCH_ASSET_LOCATIONS_LIST_ERROR';

export const DELETE_ASSET_LOCATION_RECORD_START = 'DELETE_ASSET_LOCATION_RECORD_START';
export const DELETE_ASSET_LOCATION_RECORD_SUCCESS = 'DELETE_ASSET_LOCATION_RECORD_SUCCESS';
export const DELETE_ASSET_LOCATION_RECORD_ERROR = 'DELETE_ASSET_LOCATION_RECORD_ERROR';

export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';

export const INIT_CREATE_NEW_ASSET_LOCATION_SCREEN =
  'INIT_CREATE_NEW_ASSET_LOCATION_SCREEN';

export const EDIT_ASSET_LOCATION_RECORD = {
  START: 'EDIT_ASSET_LOCATION_RECORD_START',
  SUCCESS: 'EDIT_ASSET_LOCATION_RECORD_SUCCESS',
  ERROR: 'EDIT_ASSET_LOCATION_RECORD_ERROR',
};

export const FETCH_ASSET_LOCATION_DETAIL = {
  START: 'FETCH_ASSET_LOCATION_DETAIL_START',
  SUCCESS: 'FETCH_ASSET_LOCATION_DETAIL_SUCCESS',
  ERROR: 'FETCH_ASSET_LOCATION_DETAIL_ERROR',
};


export const TOAST = {
  RESET: 'RESET_TOAST',
};
