import React from 'react';
import { Route } from 'react-router-dom';

import CreateVaultContainer from '../../containers/VaultScreen/CreateVault';
import { APP_URLS } from '../../constants/urlConstants';
import VaultScreen from '../../containers/VaultScreen';

const VaultRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update } = access;

  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <VaultScreen {...routeProps} screenPermissions={access} />
          )}
        />
      )}
      {!!create && (
        <Route
          path={APP_URLS.VAULT.ADD_VAULT}
          component={CreateVaultContainer}
        />
      )}
      {!!update && (
        <Route
          path={APP_URLS.VAULT.EDIT_VAULT}
          render={(routeProps) => (
            <CreateVaultContainer {...routeProps} isEdit={true} />
          )}
        />
      )}
    </>
  );
};

export default VaultRoutes;
