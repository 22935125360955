import {
  INIT_INSPECTIONS,
  FETCH_INSPECTIONS_LIST_START,
  FETCH_INSPECTIONS_LIST_SUCCESS,
  FETCH_INSPECTIONS_LIST_ERROR,
  DELETE_INSPECTION_RECORD_ERROR,
  DELETE_INSPECTION_RECORD_SUCCESS,
  DELETE_INSPECTION_RECORD_START,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_INSPECTION_SCREEN,
  CREATE_INSPECTION_RECORD,
  EDIT_INSPECTION_RECORD,
  FETCH_INSPECTION_DETAIL,
  TOAST,
} from '../../../constants/action-types/inspections';
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from '../../../utils/table/gridUtils';
import { buildName } from '../../../utils/string';

export const INSPECTION_DATE_DATA_KEY = 'date';
export const DRUG_CHECK_DATE_KEY = 'drug_chk_dt';

const isDateField = (key) => {
  const dateFields = [INSPECTION_DATE_DATA_KEY, DRUG_CHECK_DATE_KEY];
  return dateFields.includes(key);
};

const tranformToInspectionGridView = (inspectionData) => {
  const rows = inspectionData.map((inspection) => {
    const {
      id,
      driver1,
      truck,
      trailer1,
      trailer2,
      date,
      province,
      report_no,
      problem_desc,
    } = inspection;

    const transformedValues = {
      id,
      date,
      driver: {
        name: buildName(driver1),
        toolTip: 'Testing tooltip', // Driver Name - driver1.first + last ( Add tooltip - show supplier name)
      },
      province: province?.value,
      truck: truck?.asset_code,
      trailer1: trailer1?.asset_code,
      trailer2: trailer2?.asset_code,
      report_no,
      problem_desc,
    };
    const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });

  const columns = [
    {
      colLabel: 'Column Id',
      dataKey: 'id',
      extraProps: setColumnDefaultExtraProps({
        hidden: true,
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Inspection Date',
      dataKey: INSPECTION_DATE_DATA_KEY,
      extraProps: setColumnDefaultExtraProps({
        isDateField: true,
      }),
    },
    {
      colLabel: 'Driver Name',
      dataKey: 'driver.name',
      toolTipKey: 'driverInfo.toolTip',
      extraProps: setColumnDefaultExtraProps({
        filterField: 'driver1.first',
        sortField: 'driver1.first',
      }),
    },
    {
      colLabel: 'Truck',
      dataKey: 'truck',
      extraProps: setColumnDefaultExtraProps({
        filterField: 'truck.asset_code',
        sortField: 'truck.asset_code',
      }),
    },
    {
      colLabel: 'Trailer1',
      dataKey: 'trailer1',
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: true,
        isSortable: true,
      }),
    },
    {
      colLabel: 'Trailer2',
      dataKey: 'trailer2',
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: true,
        isSortable: true,
      }),
    },
    {
      colLabel: 'Report#',
      dataKey: 'report_no',
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: true,
        isSortable: true,
      }),
    },
    {
      colLabel: 'Problem Desc',
      dataKey: 'problem_desc',
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: true,
        isSortable: true,
      }),
    },
    {
      colLabel: 'Province',
      dataKey: 'province',
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: true,
        isSortable: true,
      }),
    },
  ];

  return {
    inspectionsListView: { columns, rows },
  };
};

const formFieldValueMap = {
  [INSPECTION_DATE_DATA_KEY]: null,
  injury: false,
  damages: false,
  at_fault: false,
  liability: false,
  drug_chk: false,
  claim_wt_rcvry: 0,
  claim_wo_rcvry: 0,
  impact_rate: 0,
  impact_result: '',
  rem_action: '',
  driver1: null,
  driver2: null,
  province: '',
  truck: null,
  truck_owner: '',
  trailer1: null,
  trailer1_owner: '',
  trailer2: null,
  trailer2_owner: '',
  owner_opr: '',
  trip_no: '',
  claim_no: '',
  problem: false,
  problem_desc: '',
  location: '',
  report_no: '',
  // TODO (subham) - Revisit if we should keep this field in formFieldValueMap
  files: [],
};

const INITIAL_STATE = {
  inspectionsListView: {},
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateInspectionSuccess: false,
  isCreateInspectionError: false,
};

export const inspectionsReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case INIT_INSPECTIONS:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case FETCH_INSPECTIONS_LIST_START:
    case CREATE_INSPECTION_RECORD.START:
    case DELETE_INSPECTION_RECORD_START:
    case FETCH_INSPECTION_DETAIL.START:
    case EDIT_INSPECTION_RECORD.START:
      return { ...state, isLoading: true };

    case FETCH_INSPECTIONS_LIST_SUCCESS: {
      const { page, content } = action.payload;
      let resetState = {
        inspectionsListView: null,
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isCreateInspectionSuccess: false,
        isCreateInspectionError: false,
      };

      if (!content) {
        return resetState;
      }
      const { number, size, total_elements: totalElements, total_pages: totalPages } = page;
      const { inspectionsListView } = tranformToInspectionGridView(content);

      return {
        ...resetState,
        inspectionsListView,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages,
          pageNumber: number,
        },
      };
    }

    case FETCH_INSPECTIONS_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        inspectionsListView: {},
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isInspectionDeletionSuccess: false,
        isInspectionDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_INSPECTION_RECORD_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isInspectionDeletionSuccess: false,
        isInspectionDeletionError: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_INSPECTION_RECORD_SUCCESS: {
      return {
        ...state,
        isInspectionDeletionSuccess: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
      const shouldOpenDeleteConfirmationPopup = action.payload;
      return {
        ...state,
        shouldOpenDeleteConfirmationPopup,
        isInspectionDeletionError: false,
        isInspectionDeletionSuccess: false,
      };
    }

    // TODO(subham): See if this field is needed.
    case INIT_CREATE_NEW_INSPECTION_SCREEN: {
      return { ...INITIAL_STATE };
    }

    case CREATE_INSPECTION_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isCreateInspectionSuccess: false,
        isCreateInspectionError: true,
      };
    }
    case CREATE_INSPECTION_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isCreateInspectionSuccess: true,
        isCreateInspectionError: false,
      };
    }

    case FETCH_INSPECTION_DETAIL.SUCCESS: {
      const temp = {};
      Object.keys(formFieldValueMap).forEach((key) => {
        const value = action.payload[key];
        temp[key] = isDateField(key) ? new Date(value) : value;
      });

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isInspectionDetailSuccess: true,
      };
    }

    case FETCH_INSPECTION_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isInspectionDetailSuccess: false,
        isInspectionDetailError: true,
      };
    }

    case EDIT_INSPECTION_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditInspectionSuccess: false,
        isEditInspectionError: true,
      };
    }

    case EDIT_INSPECTION_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditInspectionSuccess: true,
        isEditInspectionError: false,
      };
    }
    case TOAST.RESET: {
      return {
        ...state,
        isCreateInspectionSuccess: false,
        isCreateInspectionError: false,
        isEditInspectionError: false,
        isEditInspectionSuccess: false,
        isInspectionDetailError: false,
      };
    }
    default:
      return state;
  }
};
