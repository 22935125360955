import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { fetchAssetLocationHistoryByAssetId } from "../../../Redux/Actions/asset-location";
import {  selectAssetLocationsHistoryList,
          selectAssetHistoryIsLoading,
          selectIsLoadingPage,
          selectIsPageLevelError,
          selectPaginationInfo
   } from "../../../Redux/Selectors/assets_new/asset_location/history";
import AssetLocationHistory from "../../../components/AssetLocation/History";

const AssetLocationHistoryScreen = (props) => {
  const dispatch = useDispatch();
  const { id } = props.match.params;
  
  const fetchAssetLocationsHistory = (params) => dispatch(fetchAssetLocationHistoryByAssetId({ itemId: id, params}))

  useEffect( () => {
    dispatch(fetchAssetLocationHistoryByAssetId({ itemId: id}));
  },[id, dispatch])
  
  return <AssetLocationHistory {... {...props, fetchAssetLocationsHistory}} /> 
  
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    assetLocationsHistoryListView: selectAssetLocationsHistoryList(state),
    isFetchingGridData: selectAssetHistoryIsLoading(state),
    showPageLevelLoader: selectIsLoadingPage(state),
    paginationInfo: selectPaginationInfo(state),
    isPageLevelError: selectIsPageLevelError(state),
    isLoading: selectAssetHistoryIsLoading(state)
  };
};


export default connect(mapStateToProps)(AssetLocationHistoryScreen);

