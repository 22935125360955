import React, { Component } from 'react';

import { Button, Col, Table, Form, Row } from 'react-bootstrap';
import { getApi } from '../../Services/GetAPI';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import SnackbarError from '../../common/SnackbarError';
import SnackbarSuccess from '../../common/SnackbarSuccess';
import ReactPaginate from 'react-paginate';
import edit from '../../images/pencil.svg';
import deletebtn from '../../images/close-button.svg';
import SelectSearch from 'react-select-search';
import {
  deleteApi,
  getApiAsyn,
  loadierShow,
  loadingsShow,
  baseUrls,
} from '../../Services/PostAPI';
import { post } from 'axios';
import { Popup } from '../../common/Popup';
import { TripFilter } from './TripFilter';
import default_menu from '../../images/default_menu.svg';
import { ModalPopup } from '../../common/ModalPopup';
import { borderConnect } from '../../Services/business/borderConnect';
const cookies = new Cookies();
let istSearchList = '';

let TypeDD = [
  { name: 'ACI', value: 'ACI' },
  { name: 'ACE', value: 'ACE' },
];
class Trips extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortby:'desc',
      key:'trip_no',
      access: JSON.parse(localStorage.getItem('menuAccess')),
      formOption: {},
      PageNumber: 1,
      PageSize: 10,
      pageCount: 0,
      Data: [],
      ErrorData: [],
      sort: {},
      trips: [],
      UIValue: {},
      errors: {},
      deleteModal: false,
      borderConnectModal: false,
      filterboll: true,
      itemtodelete: '',
      openRowIndex: false,
      FiltredTrip: {},
      textMessage: 'This will delete trip. Do you want to continue ?',
      borderConnectPorts: []
    };
  }
  handleSortBy =(val)=>{
    const {filterboll} =this.state;
   this.setState((prevState) => {
    let { sortby, key } = prevState;
    sortby=sortby==='asc' ? 'desc':'asc'
    key = val;
    return {sortby, key}
   },()=>{
    if(filterboll===false){
      this.getTripsData();
      }
      else{
        this.getFilterTrips()
      }
   })
  }
  handleSortDropdonw = (val, name) => {
    const { sort } = this.state;
    sort[name] = val;
    this.setState({
      [sort]: sort,
    });
    this.getFilterTrips();
    console.log(sort);
  };
  handleChangeGen = (event) => {
    const { sort } = this.state;
    let { name, value } = event.target;
    sort[name] = value;
    this.setState({
      [sort]: sort,
    });

    this.getFilterTrips();
    console.log(sort);
  };
  handleDeleteModal = (id) => {
    this.setState((prevState) => {
      let { deleteModal, itemtodelete } = prevState;
      deleteModal = prevState.deleteModal === false ? true : false;
      itemtodelete = id;

      return { deleteModal, itemtodelete };
    });
  };
  handelMessage = (Message, Boll) => {
    const { formOption } = this.state;
    formOption[Boll] = true;
    formOption['Message'] = Message;
    this.setState(
      {
        formOption: formOption,
      },
      function () {
        setTimeout(
          function () {
            formOption[Boll] = false;
            this.setState({
              formOption: formOption,
            });
          }.bind(this),
          3000
        );
      }
    );
  };
  getFilterTrips = async () => {
    const { PageNumber, PageSize, sort, key, sortby } = this.state;
    //let trp = parseInt(sort.trip_no);
    if (sort.order_id === undefined && sort.order_id === '') {
      this.handelMessage('Please enter order field to filter', 'errorBoll');
      return;
    }
    if (sort.trip_no === undefined && sort.trip_no === '') {
      this.handelMessage(
        'Please enter number in trip no field to filter',
        'errorBoll'
      );
      return;
    } else {
      let id =
        sort.driver_id !== undefined && sort.driver_id !== '-1'
          ? '&driver_id=' + sort.driver_id
          : '';
      id +=
        sort.trailer_id !== undefined && sort.trailer_id !== '-1'
          ? '&trailer_id=' + sort.trailer_id
          : '';
      id +=
        sort.truck_id !== undefined && sort.truck_id !== '-1'
          ? '&truck_id=' + sort.truck_id
          : '';
      id +=
        sort.trip_type !== undefined && sort.trip_type !== '-1'
          ? '&trip_type=' + sort.trip_type
          : '';
      id +=
        sort.trip_status !== undefined && sort.trip_status !== '-1'
          ? '&trip_status=' + sort.trip_status
          : '';

      id +=
        sort.order_id !== undefined && sort.order_id !== ''
          ? '&order_no=' + sort.order_id
          : '';

      id +=
        sort.address !== undefined && sort.address !== ''
          ? '&loc=' + sort.address
          : '';

      id +=
        sort.cstm_trailer !== undefined && sort.cstm_trailer !== ''
          ? '&cstm_trailer=' + sort.cstm_trailer
          : '';

      /* id +=
        sort.trip_no !== undefined && !isNaN(trp)
          ? '&trip_no=' + parseInt(sort.trip_no)
          : '';*/
      id += sort.trip_no !== undefined ? '&trip_no=' + sort.trip_no : '';

      if (id === '') {
        this.getTripsData();
        return;
      }
      let res = await getApiAsyn(
        `trips?intent=search&paged=true&page=${PageNumber}&size=${PageSize}${id}&sort=${key},${sortby}`,
        cookies.get('authorization')
      );
      istSearchList = res && res.page && res.page.total_elements;

      if (res && istSearchList > 0 && res.status !== false) {
        this.setState({
          Data: res._embedded.content,
          pageCount: istSearchList / PageSize,
        });
      } else {
        this.setState({
          Data: [],
        });
      }
    }
  };

  handleRecordDelete = () => {
    let { itemtodelete } = this.state;
    deleteApi(`trips/${itemtodelete}`, cookies.get('authorization')).then(
      (res) => {
        if (res.status === 200) {
          this.setState(
            {
              legalBoll: true,
              successMessage: 'Record deleted successfully',
            },
            function () {
              this.handleDeleteModal();
              setTimeout(
                function () {
                  this.setState({
                    legalBoll: false,
                  });
                }.bind(this),
                3000
              );
            }
          );
          this.getTripsData();
        }
      }
    );
  };
  handleFilter = () => {
    this.setState((prevState) => {
      let { filterboll, sort } = prevState;
      filterboll = prevState.filterboll === false ? true : false;
      if (filterboll === false) {
        sort = {};
        this.getTripsData();
      }
      return { filterboll, sort };
    });
  };
  handleBorderConnectModal = (item) => {
    console.log(item);
    this.setState((prevState) => {
      let { borderConnectModal, FiltredTrip, ErrorData, UIValue } = prevState;
      borderConnectModal =
        prevState.borderConnectModal === false ? true : false;
      FiltredTrip = item;
      ErrorData = [];
      UIValue = {};
      return { borderConnectModal, FiltredTrip, ErrorData, UIValue };
    });
  };

  getTripsData = async () => {
    loadierShow('block');
    const { PageNumber, PageSize, key, sortby } = this.state;
    await getApi(
      `trips?intent=load&paged=true&page=${PageNumber}&size=${PageSize}&sort=${key},${sortby}`,
      cookies.get('authorization')
    ).then((res) => {
      if (res && res.status !== false) {
        istSearchList = res && res.page && res.page.total_elements;
        if (istSearchList > 0) {
          this.getManifestData();
          loadierShow('none');
          let tripsData = res._embedded.content.map((item) => ({
            trip_no: item.trip_no,
            trip_type: item.trip_type && item.trip_type.name,
            trip_date: item.trip_date,
            trip_status: item.trip_status && item.trip_status.name,
          }));
          this.setState({
            Data: res._embedded.content,
            pageCount: istSearchList / PageSize,
            trips: tripsData,
          });
        } else {
          loadierShow('none');
          this.setState({
            Data: [],
            pageCount: [],
            trips: [],
          });
        }
      } else {
        loadierShow('none');
      }
    });
  };

  handleResultperpage = (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    const {filterboll} = this.state;
    this.setState(
      {
        [fieldName]: fleldVal,
      },
      () => {

        
      if(filterboll===false){
        this.getTripsData();
        }
        else{
          this.getFilterTrips()
        }
      
      }
    );
  };

  handlePageClick = (data) => {
    let selected = data.selected + 1;
    let PageNumber = selected;
    const {filterboll} = this.state;
    this.setState({ PageNumber: PageNumber }, () => {
      if(filterboll===false){
        this.getTripsData();
        }
        else{
          this.getFilterTrips()
        }
    });
  };
  async componentDidMount() {
    await loadingsShow('block');
    await this.getTripsData();
    await loadingsShow('none');
  }
  toggleOpen = (idx) => {
    this.setState((prevState) => {
      let { openRowIndex } = prevState;
      openRowIndex = prevState.openRowIndex === idx ? '' : idx;
      return { openRowIndex };
    });
  };

  handleSeachableDropdonw = (val, name) => {
    const { UIValue } = this.state;
    if (name === 'type') {
      UIValue['usPortOfArrival'] = '';
    }
    UIValue[name] = val;
    this.setState({ UIValue });
  };

  getManifestData = async () => {
    await getApi(`manifests`, cookies.get('authorization')).then((res) => {
      if (res && res.status !== false) {
        let totalElements = res?.page?.totalElements
        let mdata =
          res &&
          res?._embedded &&
          res?._embedded.content?.length > 0 &&
          res?._embedded.content;
        console.log(mdata);
        if (totalElements> 0 && mdata?.length > 0) {
          this.setState((prevState) => {
            let { Data } = prevState;
            for (let i = 0; i < Data.length; i++) {
              for (let j = 0; j < mdata.length; j++) {
                if (Data[i].trip_no === mdata[j].manifest_no) {
                  Data[i]['aciace'] = true;
                }
              }
            }
            return { Data };
          });
        }
      }
    });
  };
  handleManifestAPI = async () => {
    const { FiltredTrip, UIValue } = this.state;
    let reqData = {
      id: 0,
      manifest_no: FiltredTrip.trip_no,
      poe: UIValue.usPortOfArrival,
      ref_no: FiltredTrip.trip_no,
      type: UIValue.type,
      cmt: UIValue.cmt,
    };
    const formPayload = new FormData();
    const json = JSON.stringify(reqData);
    const order = new Blob([json], {
      type: 'application/json',
    });
    formPayload.append('data', order);
    this.fileUpload(formPayload).then((res) => {
      loadierShow('none');
      console.log(res);
    });
  };

  fileUpload = (reqData) => {
    const url = baseUrls + 'manifests';
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: cookies.get('authorization'),
      },
    };
    return post(url, reqData, config);
  };

 handleBorderConnect = () => {
   const { FiltredTrip, UIValue } = this.state

   const { cmt: comment, type, usPortOfArrival: poe } = UIValue;
   const { id: trip } = FiltredTrip;

  const payload = {
    trip,
    type,
    poe,
    comment
  }
   borderConnect(payload).then(res => {
    this.handleBorderConnectModal()
   })
 }

  handleChangeGenUI = (event) => {
    const { UIValue } = this.state;
    UIValue[event.target.name] = event.target.value;
    this.setState({
      [UIValue]: UIValue,
    });
  };

  fetchPorts = async (val) => {
    let type;
    if (val === "ACI") {
      type = "ACI_PORTS"
    } else if (val === "ACE") {
      type = "ACE_PORTS"
    }
    let ports = await getApi( `types?type=${type}`, cookies.get('authorization'))
    ports = ports.map(port => ({ ...port, value: port.code, name: port.value }))
    this.setState({ borderConnectPorts: ports });
  }

  dataModal = () => {
    const { UIValue, errors, ErrorData } = this.state;
    return (
      <div>
        <div className="form">
          <Col sm="12">
            <Row>
              <Col md={5}>
                <Form.Group
                  className={errors['type'] ? 'errorshows' : 'errorshides'}
                  as={Row}
                >
                  <Form.Label column sm="4" className="text-left">
                    Type
                  </Form.Label>
                  <Col sm="8">
                    <SelectSearch
                      container="rowselect"
                      search
                      options={TypeDD}
                      select="test"
                      name="type"
                      autoComplete="sdf"
                      onChange={(val) => {
                          this.fetchPorts(val);
                          this.handleSeachableDropdonw(val, 'type')
                        } 
                      }
                      value={UIValue.type}
                      placeholder="Type to search"
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={7}>
                <Form.Group
                  className={
                    errors['usPortOfArrival'] ? 'errorshows' : 'errorshides'
                  }
                  as={Row}
                >
                  <Form.Label column sm="4" className="text-right">
                    Port of entry*
                  </Form.Label>
                  <Col sm="8">
                    <SelectSearch
                      container="rowselect"
                      search
                      options={this.state.borderConnectPorts}
                      select="test"
                      name="usPortOfArrival"
                      autoComplete="sdf"
                      onChange={(val) =>
                        this.handleSeachableDropdonw(val, 'usPortOfArrival')
                      }
                      value={UIValue.usPortOfArrival}
                      placeholder="Type to search"
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Form.Group as={Row}>
                  <Form.Label column sm="2" className="text-left">
                    Message
                  </Form.Label>
                  <Col sm="10" style={{ marginLeft: '-20px' }}>
                    <Form.Control
                      name="cmt"
                      value={UIValue.cmt}
                      onChange={this.handleChangeGenUI}
                    />
                    <span
                      className={errors['cmt'] ? 'errorshow' : 'errorshide'}
                    >
                      {errors['cmt']}
                    </span>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                {ErrorData.length > 0 ? (
                  <div className="errorMessages">
                    <ul>
                      {ErrorData.map((item, index) => (
                        <li key={'item_' + index}>
                          <strong>{item.identifier}</strong> - {item.note}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    );
  };
  render() {
    const {
      PageNumber,
      PageSize,
      pageCount,
      Data,
      deleteModal,
      textMessage,
      sort,
      formOption,
      filterboll,
      borderConnectModal,
      access,
      key,
      sortby
    } = this.state;
    return (
      <div>
        <SnackbarError
          errorBoll={formOption.errorBoll}
          errorMessage={formOption.Message}
        />

        <SnackbarSuccess
          legalBoll={formOption.legalBoll}
          successMessage={formOption.Message}
        />
        <div className="paddingbox">
          <div className="containerbox">
            <div className="title">
              <h3>Trips Details</h3>

              <div className="rightbtn">
                <Button
                  type="button"
                  onClick={this.handleFilter}
                  className="default-button"
                >
                  {filterboll === false ? 'Filter' : 'Reset Filter'}
                </Button>
                <Link to="/fleet/trip/add-new">
                  {' '}
                  <Button className="primary-button">+ NEW</Button>
                </Link>
              </div>
            </div>
            <Row>
              <div className="orderFilter">
                {filterboll ? (
                  <TripFilter
                    TripTypeonChange={(val) =>
                      this.handleSortDropdonw(val, 'trip_type')
                    }
                    TripTypevalue={sort.trip_type}
                    TripTypeName="trip_type"
                    TripStatusName="trip_status"
                    TripStatusonChange={(val) =>
                      this.handleSortDropdonw(val, 'trip_status')
                    }
                    TripStatusvalue={sort.trip_status}
                    DriverName="driver_id"
                    DriveronChange={(val) =>
                      this.handleSortDropdonw(val, 'driver_id')
                    }
                    Drivervalue={sort.driver_id}
                    TrailerNane="trailer_id"
                    TraileronChange={(val) =>
                      this.handleSortDropdonw(val, 'trailer_id')
                    }
                    Trailervalue={sort.trailer_id}
                    TruckNane="truck_id"
                    TruckonChange={(val) =>
                      this.handleSortDropdonw(val, 'truck_id')
                    }
                    Truckalue={sort.truck_id}
                    /*EquipmentNane="eqp_type"
                  EquipmentonChange={(val) =>
                    this.handleSortDropdonw(val, 'eqp_type')
                  }
                   Equipmentvalue={sort.eqp_type}*/
                    OrderNoNane="order_id"
                    OrderNoonChange={this.handleChangeGen}
                    OrderNovalue={sort.order_id}
                    TripNoNane="trip_no"
                    TripNoonChange={this.handleChangeGen}
                    TripNovalue={sort.trip_no}
                    AddressNane="address"
                    AddressonChange={this.handleChangeGen}
                    Addressvalue={sort.address}
                    CstmTrailerNoNane="cstm_trailer"
                    CstmTraileronChange={this.handleChangeGen}
                    CstmTrailerNovalue={sort.cstm_trailer}
                  />
                ) : null}
              </div>
              {Data && Data.length > 0 ? (
                <Col md={12} className='triptbl'>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th class={key==='trip_no' && sortby==='asc' ? 'active cursor' : key==='trip_no' && sortby==='desc' ? 'active cursor' :'cursor'}
                        colSpan="2" onClick={()=>this.handleSortBy('trip_no')}>Trip ID
                        <span class={key==='trip_no' && sortby==='asc' ? 'active p-sortable-column-icon pi pi-fw  pi-sort-amount-up-alt' : key==='trip_no' && sortby==='desc' ? 'active  p-sortable-column-icon pi pi-fw  pi-sort-amount-down-alt' :'p-sortable-column-icon pi pi-fw pi-sort-alt'}></span>
                        </th>
                        <th>Trip Type</th>
                        <th class={key==='trip_date' && sortby==='asc' ? 'active cursor' : key==='trip_date' && sortby==='desc' ? 'active cursor' :'cursor'} onClick={()=>this.handleSortBy('trip_date')}>Date
                        <span class={key==='trip_date' && sortby==='asc' ? 'active p-sortable-column-icon pi pi-fw  pi-sort-amount-up-alt' : key==='trip_date' && sortby==='desc' ? 'active  p-sortable-column-icon pi pi-fw  pi-sort-amount-down-alt' :'p-sortable-column-icon pi pi-fw pi-sort-alt'}></span>
                        </th>
                        <th class={key==='trip_status' && sortby==='asc' ? 'active cursor' : key==='trip_status' && sortby==='desc' ? 'active cursor' :'cursor'} onClick={()=>this.handleSortBy('trip_status')}>Status
                        <span class={key==='trip_status' && sortby==='asc' ? 'active p-sortable-column-icon pi pi-fw  pi-sort-amount-up-alt' : key==='trip_status' && sortby==='desc' ? 'active  p-sortable-column-icon pi pi-fw  pi-sort-amount-down-alt' :'p-sortable-column-icon pi pi-fw pi-sort-alt'}></span>
                        </th>
                        <th>Total Miles</th>
                        <th>First Stop Time</th>
                        <th>Driver</th>
                        <th>Trailer / Container</th>
                        <th>Truck</th>
                        <th>ACI/ACE</th>
                        <th colSpan="3">Actions</th>
                      </tr>
                    </thead>

                    {Data.map((item, i) => (
                      <tbody
                        key={item.id.toString()}
                        className={item.trip_status && item.trip_status.name}
                      >
                        <tr
                          className={
                            this.state.openRowIndex === i ? 'parentactive' : ''
                          }
                        >
                          <td
                            className={
                              this.state.openRowIndex === i
                                ? 'minus cursorpoit'
                                : 'plus cursorpoit'
                            }
                            onClick={() => this.toggleOpen(i)}
                          >
                            +
                          </td>
                          <td>{item.trip_no}</td>
                          <td>{item.trip_type && item.trip_type.name}</td>
                          <td>{item.trip_date}</td>
                          <td>{item.trip_status && item.trip_status.name}</td>
                          <td>
                            {item.calc_miles != null && item.calc_miles !== ''
                              ? item.calc_miles
                              : 'N/A'}
                          </td>
                          <td>
                            {item && item.items && item.items[0].arv_date}
                          </td>
                          <td>{item.driver1 && item.driver1.first}</td>
                          <td>
                            {item.trailer_owner !== 'CT'
                              ? item.trailer1 && item.trailer1.asset_code
                              : item.cust_trailer1}
                          </td>
                          <td>{item.vehicle && item.vehicle.asset_code}</td>
                          <td>
                            {item.aciace === true ? (
                              <span className="created">
                                <a
                                  rel="noreferrer"
                                  target="_blank"
                                  href="https://www.borderconnect.com/borderconnect/index.htm"
                                >
                                  CREATED
                                </a>{' '}
                              </span>
                            ) : (
                              <span
                                className="notcreated"
                                onClick={() =>
                                  this.handleBorderConnectModal(item)
                                }
                              >
                                CREATE Now
                              </span>
                            )}
                          </td>
                          <td>
                            <Link
                              to={{ pathname: `/fleet/trip/${item.id}/edit` }}
                            >
                              {' '}
                              <img src={edit} alt="Edit" />
                            </Link>
                          </td>
                          <td>
                            {access &&
                            access.tripMenu &&
                            access.tripMenu.delete === true &&
                            item.trip_status &&
                            item.trip_status.name !== 'Complete' ? (
                              <img
                                className="delete"
                                onClick={() => this.handleDeleteModal(item.id)}
                                src={deletebtn}
                                alt="Delete"
                              />
                            ) : (
                              <img
                                className="delete drop"
                                title="You are not allowed to delete"
                                src={deletebtn}
                                alt="Delete"
                              />
                            )}
                          </td>
                          <td>
                            <div className="moremenu moremenus">
                              <img src={default_menu} alt="More" />
                              <ul>
                                <li>
                                  <Link
                                    to={{
                                      pathname: `/Reimbursement/add/${item.id}`,
                                    }}
                                  >
                                    {' '}
                                    Reuimburesement
                                  </Link>
                                </li>

                                <li
                                  onClick={() =>
                                    this.handleBorderConnectModal(item)
                                  }
                                >
                                  Border Connect
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>

                        <tr
                          className={
                            this.state.openRowIndex === i
                              ? 'subactive'
                              : 'subhide'
                          }
                        >
                          <td colSpan="14">
                            <Table bordered hover>
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Order#</th>
                                  <th>Location</th>
                                  <th>Status</th>
                                  <th>Request Date</th>
                                  <th>Weights</th>
                                  <th>Note</th>
                                </tr>
                              </thead>
                              <tbody>
                                {item.items.map((x) => (
                                  <tr key={x.id.toString()}>
                                    <td>{x.type}</td>
                                    <td>
                                      {x.order != null && x.order !== ''
                                        ? x.order.order_no
                                        : 'N/A'}
                                    </td>
                                    <td>
                                      <span>
                                        <strong>{x.loc_nm}</strong>
                                      </span>
                                      <br></br>
                                      {x.location}
                                    </td>
                                    <td>
                                      {x.item_status && x.item_status.name}
                                    </td>
                                    <td>{x.req_date}</td>
                                    <td>{x.weight}</td>
                                    <td>{x.note}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </Table>

                  <div className="paginationData">
                    <div className="leftpage">
                      <div className="showing">
                        <span>Showing: </span>
                        {PageSize * PageNumber + 1 - PageSize} -
                        {PageSize > istSearchList
                          ? istSearchList
                          : PageSize * PageNumber > istSearchList
                          ? istSearchList
                          : PageSize * PageNumber}{' '}
                        <span> of</span> {istSearchList} <span>entries. </span>{' '}
                      </div>

                      <div className="rowperpage">
                        <Form.Group>
                          <Form.Label>Showing rows:</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={this.handleResultperpage}
                            name="PageSize"
                            value={PageSize}
                          >
                            <option>5</option>
                            <option>10</option>
                            <option>15</option>
                            <option>20</option>
                            <option>50</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                    </div>

                    <ReactPaginate
                      previousLabel={'previous'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />

                    <div className="clr"></div>
                  </div>
                </Col>
              ) : (
                <div className="norecord">No record found</div>
              )}
            </Row>
          </div>
        </div>

        <Popup
          isOpen={deleteModal}
          onConfirm={this.handleRecordDelete}
          textMessage={textMessage}
          toggleShow={this.handleDeleteModal}
        />
        <ModalPopup
          headerTitle="Border Connect"
          isOpen={borderConnectModal}
          actionLabel="Add To ACI/ACE"
          cancelLable="Cancel"
          onConfirm={() => this.handleBorderConnect()}
          textMessage={this.dataModal()}
          toggleShow={this.handleBorderConnectModal}
        />
      </div>
    );
  }
}

export default Trips;
