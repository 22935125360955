import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';

import EzTable from '../../common/EzTable';
import { EzToast, TOAST_SEVERITY } from '../../common/EzToast';
import EzNotFound from '../../common/EzNotFound';

// TODO (Subham): Find why assets path is not working
//const getIconPath = () => process.env.PUBLIC_URL + '/assets/images/acidents/no-record.png';
const getIconPath = (imgPathDelta = '/no-record.png') =>
  process.env.PUBLIC_URL + imgPathDelta;

const Inspection = (props) => {
  const {
    inspectionsListView,
    createInspectionRecord,
    deleteInspectionRecord,
    editInspectionRecord,
    fetchInspections,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isInspectionDeletionError,
    isInspectionDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    downloadAllAttachmentForInspectionId,
    fetchInspectionAttachmentsByInspectionId,
    moreActionProps
  } = props;

  const [areFiltersVisible, toggleFilterState] = useState(false);

  const getToastProps = () => {
    if (isInspectionDeletionSuccess) {
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle: 'Inspection Deleted Successfully',
        shouldShowToast: true,
      };
    }

    if (isInspectionDeletionError) {
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle: 'Error while Deleting Inspection',
        shouldShowToast: true,
      };
    }
  };

  const renderPageHeaderSection = () => {
    const breadCrumbs = [
      { label: 'Inspections' },
      { label: 'Inspection Summary' },
    ];

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };

    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />

        <div className="rightbtn">
          <Button
            label={areFiltersVisible ? 'Hide Filters' : 'Show Filters'}
            icon={areFiltersVisible ? 'pi pi-filter-slash' : 'pi pi-filter'}
            className={`mr-2 p-button-raised p-button-outlined p-button-secondary`}
            onClick={() => {
              toggleFilterState(!areFiltersVisible);
            }}
          />
          <Button
            label={'Create New Inspection'}
            icon={'pi pi-plus'}
            className={`mr-2 p-button-raised`}
            onClick={createInspectionRecord}
          />
        </div>
      </div>
    );
  };

  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderInspectionRecords = () =>
    inspectionsListView?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderInspectionRecords() && !shouldRenderFullPageError();

  const filters = {
    global: { value: null },
    date: { value: null },
    'driver.name': {
      value: null,
      filterKey: 'driver1.first',
    },
    truck: { value: null, filterKey: 'truck.asset_code' },
    trailer1: { value: null, filterKey: 'trailer1.asset_code' },
    trailer2: { value: null, filterKey: 'trailer2.asset_code' },
    province: { value: null, filterKey: 'province.value' },
  };

  const tableProps = {
    areFiltersVisible,
    columns: inspectionsListView.columns,
    paginationInfo,
    screenPermissions,
    setDeleteConfirmationPopupVisibility,
    shouldOpenDeleteConfirmationPopup,
    onEditRecord: editInspectionRecord,
    loadLazyData: fetchInspections,
    onDeleteRecord: deleteInspectionRecord,
    value: inspectionsListView.rows,
    emptyMessage: 'No Inspections found.',
    globalFilterFields: ['truck', 'desc'],
    filters,
    loading: isFetchingGridData,
    downloadAllAttachments : downloadAllAttachmentForInspectionId,
    fetchAttachmentsById:  fetchInspectionAttachmentsByInspectionId,
    moreActionProps
  };

  return (
    <>
      <EzToast {...getToastProps()} />
      <div className="paddingbox">
        <div className="containerbox">
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: '-70px' }}
                bgImgUrl={getIconPath('something-broke.png')}
                message={'Oops! Something broke, we are working on a fix.'}
              />
            )}
            {shouldRenderInspectionRecords() && (
              <Row>
                <Col md={12}>
                  <EzTable {...tableProps} />
                </Col>
              </Row>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={'No inspection records to show'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Inspection;
