import React from 'react';
import { Route } from 'react-router-dom';

import InventoryTrackersScreen from '../../containers/InventoryTrackersScreen';
import CreateInventoryTrackerScreen from '../../containers/InventoryTrackersScreen/CreateInventoryTrackerScreen';

import { APP_URLS } from '../../constants/urlConstants';

const InventoryTrackersRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update } = access || { read: true, create: true, update: true };
  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <InventoryTrackersScreen {...routeProps} screenPermissions={{ read: true, create: true, update: true}} />
          )}
        />
      )}
      {!!create && (
        <Route
          path={APP_URLS.INVENTORY_TRACKERS.ADD_INVENTORY_TRACKER}
          component={(CreateInventoryTrackerScreen)}
        />
      )}
      {!!update && (
        <Route
          path={APP_URLS.INVENTORY_TRACKERS.EDIT_INVENTORY_TRACKER}
          render={(routeProps) => (
            <CreateInventoryTrackerScreen {...routeProps} isEdit={true} />
          )}
        />
      )}
    </>
  );
};

export default InventoryTrackersRoutes;
