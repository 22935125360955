import { FILTER } from "../../Services/common/urlService";

export const transformEmployees = (employees) => {
    return employees?.map((emp) => {
      const { first, last, code } = emp;
      return {
        label: `${first || ''} ${last || ''}`,
        value: code,
        extraData: emp,
      };
    });
  };


  export const transformSupplier = suppliers => {
    return suppliers?.map(supplier => {
      const { name,middle_name, last_name } = supplier;
      return {
        label: `${name || ''} ${middle_name || ''} ${last_name || ''}`,
        value: name,
        extraData: supplier,
      };
    });
  };

  export const transformAsset = assets => {
    return assets?.map(asset => {
      const { asset_code } = asset;
      return {
        label: `${asset_code || ''}`,
        value: asset_code,
        extraData: asset,
      };
    });
  };

  export const transformCustomersToOwner = owners =>{
    return owners?.map(owner => ({
      label: `${owner?.name || ''}`,
      value: owner?.name,
      extraData: owner
    }))
  }

  export const payloadCreater = ({ filterField, query, matchStrategy }) =>{
    return {
        shouldPaginate: false,
        ...(query ? { filters: [{ filterField, query, matchStrategy: matchStrategy || FILTER.MATCH_STRATEGY.CONTAINS }] } : {}),
    }
  }

  export const transformDocumentTypes = documentTypes => {
    const docType =  documentTypes.map((type) => {
                              const { name, id } = type
                              return {
                                label: name,
                                value: id,
                                extraData:type,
                              };
                            });                                
    return docType;        
  };
  
  
  
  export const transformOwnerTypes = ownertypes => {
    const owerTypes = ownertypes ? Object.values(ownertypes) : null
    let ownType = owerTypes?.filter(type => type?.hasOwnProperty('key'))
                         .map((ownerType) => {
                              const {value, code } = ownerType || {};
                              return {
                                label: `${value || ''}`,
                                value: code,
                                extraData: ownerType
                              };
                            });                        
    return ownType;        
  };

  export const transformTypesToDropDown = (types, labelKey = 'value', valueKey = 'key') => types?.map( type => ({
    label : type[labelKey],
    value : type[valueKey],
    extraData: type
  }) )


  export const transformEquipmentTypes = equipmentType => {
    const equipmentTypes = equipmentType ? Object.values(equipmentType) : null;
    let equipType = equipmentTypes?.filter(type => type?.hasOwnProperty('key'))
                         .map((eqType) => {
                              const {value, code } = eqType || {};
                              return {
                                label: `${value || ''}`,
                                value: code,
                                extraData: eqType
                              };
                            });                        
    return equipType;        
  };