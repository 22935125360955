import { INIT_EMPLOYEES, 
  FETCH_EMPLOYEES_LIST_START,
  FETCH_EMPLOYEES_LIST_SUCCESS, 
  FETCH_EMPLOYEES_LIST_ERROR, 
 } from "../../../constants/action-types/employees";
import { buildName } from "../../../utils/string";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from '../../../utils/table/gridUtils';
import { DATE_STRATEGY } from '../../../utils/date'

const tranformToEmployeeGridView = (employeeData) => {
  const rows = employeeData.map((employee) => {
    const {
      id,
      code,
      first,
      last,
      email,
      dob,
      hired,
      phone,
      address1
    } = employee;
    const transformedValues = {
      id,
      code,
      first: buildName({ first, last }),
      email,
      dob,
      hired,
      phone,
      address1
    };
    const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });
  const columns = [
    {
      colLabel: 'Column Id',
      dataKey: 'id',
      extraProps: setColumnDefaultExtraProps({
        hidden: true,
        sortField: 'id',
      }),
    },
    {
      colLabel: 'Employee Code',
      dataKey: 'code',
    },
   
    {
      colLabel: 'Employee Name',
      dataKey: 'first',
    },
    {
      colLabel: 'Email',
      dataKey: 'email',
    },
    {
      colLabel: 'Date of Birth',
      dataKey: 'dob',
      extraProps: setColumnDefaultExtraProps({
        isDateField: true,
        strategy: DATE_STRATEGY.DATE_ONLY,
      })
    },
    {
      colLabel: 'Date of Hire',
      dataKey: 'hired',
      extraProps: setColumnDefaultExtraProps({
        isDateField: true,
        strategy: DATE_STRATEGY.DATE_ONLY,
      })
    },
    {
      colLabel: 'Phone',
      dataKey: 'phone',
    },
  ];
  return {
    employeesListView: { columns, rows }
  };
};
const INITIAL_STATE = {
  employeesListView: {},
  employees: [],
  isLoading: false,
  error: null,
};
export const employeeReducer = (state = INITIAL_STATE, action) => {
// eslint-disable-next-line default-case
switch (action.type) {
  case INIT_EMPLOYEES:
    return { ...INITIAL_STATE, isLoadingPage: true };
  case FETCH_EMPLOYEES_LIST_START: return { ...state, isLoading: true, isLoadingPage :true }

  case FETCH_EMPLOYEES_LIST_SUCCESS: {
    const { page, content } = action.payload;
    let resetState = {
      employeesListView: null,
      employees: null,
      paginationInfo: null,
      error: null,
      isLoading: false,
      isLoadingPage: false,
      isPageLevelError: false,
      isCreateEmployeeSuccess: false,
      isCreateEmployeeError: false
    };

    if (!content) {
      return resetState;
    }
    
    const { number, size, total_elements: totalElements, total_pages: totalPages } = page;
    const { employeesListView } = tranformToEmployeeGridView(content);
    return {
      ...resetState,
      employeesListView,
      employees: content,
      paginationInfo: {
        pageSize: size,
        totalRecords: totalElements,
        totalPages,
        pageNumber: number
      },
    };
  }

  case FETCH_EMPLOYEES_LIST_ERROR: {
    const { error } = action.payload;

    return {
      ...state,
      employeesListView: {},
      employees: [],
      paginationInfo: null,
      isLoading: false,
      isLoadingPage: false,
      error,
      isPageLevelError: true,
      isAccidentDeletionSuccess: false,
      isAccidentDeletionError: false,
      shouldOpenDeleteConfirmationPopup: false,
    };
  }
  default:
    return state;
}
};