import { DEFAULT_TOAST_LIFE } from '../../../common/EzToast';
import {
  INIT_VAULT,
  FETCH_VAULT_LIST_ERROR,
  FETCH_VAULT_LIST_START,
  FETCH_VAULT_LIST_SUCCESS,
  DELETE_VAULT_RECORD_START,
  DELETE_VAULT_RECORD_SUCCESS,
  DELETE_VAULT_RECORD_ERROR,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_VAULT_SCREEN,
  CREATE_VAULT_RECORD,
  EDIT_VAULT_RECORD,
  FETCH_VAULT_DETAIL,
  TOAST,
} from '../../../constants/action-types/vault';
import { vaultService } from '../../../Services';
import { goBack } from '../../../utils/gotoback';
import { fetchEmployees } from '../employees';

export const initializeVaultScreen = () => ({ type: INIT_VAULT });

export const vaultFetchStart = (payload) => ({
  type: FETCH_VAULT_LIST_START,
  payload,
});

export const vaultFetchSuccess = (payload) => ({
  type: FETCH_VAULT_LIST_SUCCESS,
  payload,
});

export const vaultFetchError = (payload) => ({
  type: FETCH_VAULT_LIST_ERROR,
  payload,
});

export const fetchVault = (payload) => {
  return (dispatch) => {
    dispatch(vaultFetchStart(payload));

    vaultService.fetchVault(payload).then((vaultData) => {
      if (!vaultData.isError) {
        dispatch(vaultFetchSuccess(vaultData));
      } else {
        dispatch(vaultFetchError(vaultData));
      }
    });
  };
};

export const vaultDetailFetchStart = (payload) => ({
  type: FETCH_VAULT_DETAIL.START,
  payload,
});

export const vaultDetailFetchSuccess = (payload) => ({
  type: FETCH_VAULT_DETAIL.SUCCESS,
  payload,
});

export const vaultDetailFetchError = (payload) => ({
  type: FETCH_VAULT_DETAIL.ERROR,
  payload,
});

export const fetchVaultByVaultId = (payload) => {
  return (dispatch) => {
    dispatch(vaultDetailFetchStart(payload));

    vaultService
      .fetchVaultByVaultId(payload)
      .then((vaultData) => {
        if (!vaultData.isError) {
          dispatch(vaultDetailFetchSuccess(vaultData));
        } else {
          dispatch(vaultDetailFetchError(vaultData));
        }
      });
  };
};

// delete vault
export const deleteVaultRecordStart = () => ({
  type: DELETE_VAULT_RECORD_START,
});

export const deleteVaultRecordSuccess = (payload) => ({
  type: DELETE_VAULT_RECORD_SUCCESS,
  payload,
});

export const deleteVaultRecordError = (payload) => ({
  type: DELETE_VAULT_RECORD_ERROR,
  payload,
});

export const deleteVaultRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteVaultRecordStart());

    vaultService
      .deleteVaultRecord({ vaultId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteVaultRecordSuccess());
          dispatch(fetchVault(payload?.fetchVaultParams));
        } else {
          dispatch(deleteVaultRecordError(data));
        }
      });
  };
};

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const createNewVaultScreenInitStart = (payload) => ({
  type: INIT_CREATE_NEW_VAULT_SCREEN,
  payload,
});

export const initCreateNewVaultScreen = (payload) => {
  return (dispatch) => {
    dispatch(createNewVaultScreenInitStart());
    dispatch(fetchEmployees());
  };
};

// create vault
export const createVaultRecordStart = () => ({
  type: CREATE_VAULT_RECORD.START,
});

export const createVaultRecordSuccess = (payload) => ({
  type: CREATE_VAULT_RECORD.SUCCESS,
  payload,
});

export const createVaultRecordError = (payload) => ({
  type: CREATE_VAULT_RECORD.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createVault = (payload) => {
  return (dispatch) => {
    dispatch(createVaultRecordStart());
    vaultService.createVaultRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(createVaultRecordSuccess());
        goBack()
      } else {
        dispatch(createVaultRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editVaultRecordStart = () => ({
  type: EDIT_VAULT_RECORD.START,
});

export const editVaultRecordSuccess = (payload) => ({
  type: EDIT_VAULT_RECORD.SUCCESS,
  payload,
});

export const editVaultRecordError = (payload) => ({
  type: EDIT_VAULT_RECORD.ERROR,
  payload,
});

export const editVault = (payload) => {
  return (dispatch) => {
    dispatch(editVaultRecordStart());
    vaultService.editVaultRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(editVaultRecordSuccess(data));
        goBack()
      } else {
        dispatch(editVaultRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
