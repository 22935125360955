import React from 'react';
import { classNames } from 'primereact/utils';

import EzControlledAutoSuggest from '../';

const EzControlledAutoSuggestWithFloatingLabel = (props) => {
  const {
    control,
    name,
    completeMethod,
    customItemTemplate,
    isDropDownView,
    rules,
    suggestions,
    delay,
    onChange,
    labelClassName,
    label,
    errorMsg,
    isError,
  } = props;

  const getLabelClassName = () => {
    return classNames({
      'p-error': isError,
      labelClassName,
    });
  };

  return (
    <div className="field">
      <span className="p-float-label">
        <EzControlledAutoSuggest
          name={name}
          delay={delay}
          onChange={onChange}
          control={control}
          rules={rules}
          dropdown={isDropDownView}
          suggestions={suggestions}
          completeMethod={completeMethod}
          customItemTemplate={customItemTemplate}
        />
        <label htmlFor={name} className={getLabelClassName()}>
          {rules?.required ? `${label}*` : label}
        </label>
      </span>
      {errorMsg}
    </div>
  );
};

EzControlledAutoSuggestWithFloatingLabel.defaultProps = {
  labelClassName: '',
};

export default EzControlledAutoSuggestWithFloatingLabel;
