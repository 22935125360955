import { SET_IS_FORM_DIRTY } from '../../../../constants/action-types/common/form';

const initialState = {
  isFormDirty: false,
};

const formStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FORM_DIRTY:
      return { ...state, isFormDirty: action.payload.isFormDirty };
    default:
      return state;
  }
};

export default formStateReducer;
