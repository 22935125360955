/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menubar } from 'primereact/menubar';
import { Badge } from 'primereact/badge';
import { Menu } from 'primereact/menu'

import IMG_DEFAULT_PROFILE from '../../images/default_user_profile.jpg';
import COMPANY_LOGO_IMG from '../../images/logo.svg';
import downarrow from '../../images/downarrow.png';
import ConfirmRouteChange from '../ConfirmRouteChange';
import { setIsformDirty } from '../../Redux/Actions/common/form';
import { getIsFormDirty } from '../../Redux/Selectors/formState';
import { fetchUserInfo, fetchUserCompanyInfo, fetchSwitchableAccounts, switchAccount, switchBack } from '../../Redux/Actions/userProfile';
import { selectLoggedInUserInfo, selectLoggedInUserCompany, selectSwitchableAccounts } from '../../Redux/Selectors/profile';
import { APP_URLS } from '../../constants/urlConstants';
import { getItems, getFilteredItems, handleLogout } from './header-utils';
import SwitchAccountDialog from "./SwitchAccountDialog"
import Banner from '../Banner';
import ToastContainer from '../../containers/Notification/ToastContainer';

// eslint-disable-next-line
const comingSoonTemplate = (item) => {
  return (<li role="none" class="p-menuitem">
    <a href="#" role="menuitem" class="p-menuitem-link p-disabled" >
      <span class="p-menuitem-text"> {item.label} </span>
      <Badge value="coming soon!" severity="danger" className={'ml-4'}/>
    </a>
  </li>)
}


const NewHeader = (props) => {
  const {
    history,
    isFormDirty,
    setIsformDirty,
    loggedInUserInfo,
    fetchUserInfo,
    loggedInUserCompany,
    fetchUserCompanyInfo,
    switchableAccounts,
    switchBack,
  } = props;
  const [ logoImgFile, setLogoImgFile ] = useState('');
  const [ firstName, setFirstName ] = useState('');
  const [ profileImgFile, setProfileImgFile ] = useState(IMG_DEFAULT_PROFILE);
  
  const access =  JSON.parse(localStorage.getItem('menuAccess'));
  
  useEffect(() => {
    fetchUserCompanyInfo()
    fetchUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let profileImage = ''
    if (loggedInUserCompany) {
      const { profile_img } = loggedInUserCompany;
      profileImage = profile_img || COMPANY_LOGO_IMG;
      setLogoImgFile(profileImage);
      // cookies.set('companyname', name || '');
    }
    // cookies.set('companyImage', profileImage);
  }, [loggedInUserCompany])

  useEffect(() => {
    if (loggedInUserInfo) {
      const { first, profile_img } = loggedInUserInfo;
      // cookies.set('first', first);
      // cookies.set('userId', id);
      setFirstName(first);
      setProfileImgFile(profile_img || IMG_DEFAULT_PROFILE)
    }
  }, [loggedInUserInfo])

  const goToDashboard = () => {  history.push(APP_URLS.DASHBOARD.HOME) }
  
  const ConfirmRouteChangeProps = {
    isFormDirty,
    history,  
    setIsformDirty
  }

  const fetchSwitchableAccounts = () => {
    props.fetchSwitchableAccounts()
    showAccountsDialog(true)
  }

  const [isAccountDialogVisible, showAccountsDialog] = useState(false);
  const [items, endItems] = getItems(access, history, loggedInUserInfo, goToDashboard, handleLogout, fetchSwitchableAccounts);

  const start = <img src={logoImgFile} onClick={goToDashboard} onError={(e) => e.target.src= COMPANY_LOGO_IMG} height="40" alt="logo" className="mr-2 ml-1 cursorpoit" />;
  const menu = useRef(null);
  
  const cleanupAndSwitchAccounts = (companyCode) => {
    // handleLogout();
    props.switchAccount(companyCode)
  }

  const switchDialogProps = {
    accounts: switchableAccounts,
    showDialog: showAccountsDialog,
    switchAccount: cleanupAndSwitchAccounts,
  }
  const end = (
    <>
      {isAccountDialogVisible && <SwitchAccountDialog {...switchDialogProps} />}
      <Menu model={endItems} popup ref={menu} id="popup_menu" />
      <span className="UserLoginLbl" onClick={(event) => menu.current.toggle(event)} style={{fontSize: '15px', color: '#000000a1', fontWeight: 500 }}>
        <img className="usericon" src={profileImgFile} alt="user"/>
        <span className={'mr-1 ml-1'}>{firstName} </span>
        <img className="downarrow mr-3" src={downarrow} alt="down chevron" />
      </span>
    </>
  )

  const showBanner = () => JSON.parse(sessionStorage.getItem("switchAccountBanner"))
  const ctaProps = {
    ctaText: "Switch back",
    cta: () => { switchBack() }
  }
  return (
    <div>
      <div className="card">
        <ToastContainer />
        <ConfirmRouteChange {...ConfirmRouteChangeProps} />
        <Menubar model={getFilteredItems(items)} start={start} end={end}/>
        {showBanner() && <Banner message={"Operating from switched account"} {...ctaProps} />}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    loggedInUserInfo: selectLoggedInUserInfo(state),
    loggedInUserCompany: selectLoggedInUserCompany(state),
    switchableAccounts: selectSwitchableAccounts(state),
    isFormDirty: getIsFormDirty(state)
  }
}
  
const mapDispatchToProps = dispatch => {
  return {
    fetchUserInfo: () => dispatch(fetchUserInfo()),
    fetchUserCompanyInfo: () => dispatch(fetchUserCompanyInfo()),
    fetchSwitchableAccounts: () => dispatch(fetchSwitchableAccounts()),
    setIsformDirty: isFormDirty => dispatch(setIsformDirty(isFormDirty)),
    switchAccount: companyCode => dispatch(switchAccount(companyCode)),
    switchBack: () => dispatch(switchBack()),
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(NewHeader));