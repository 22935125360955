import { combineReducers } from 'redux';
import {
  INIT_ASSET_LOCATIONS,
  FETCH_ASSET_LOCATIONS_LIST_START,
  FETCH_ASSET_LOCATIONS_LIST_SUCCESS,
  FETCH_ASSET_LOCATIONS_LIST_ERROR,
  INIT_CREATE_NEW_ASSET_LOCATION_SCREEN,
  EDIT_ASSET_LOCATION_RECORD,
  FETCH_ASSET_LOCATION_DETAIL,
  TOAST
} from '../../../constants/action-types/assetLocation';
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from '../../../utils/table/gridUtils';

import {  assetLocationsHistoryReducer } from './history'

const SINCE_DATE_TIME = 'sinceDatetime';
const MOVED_DATE_TIME = 'movedDatetime'
const isDateField = (key) => {
  const dateFields = [SINCE_DATE_TIME, MOVED_DATE_TIME];
  return dateFields.includes(key);
};

export const getColumns = () => [
  {
    colLabel: 'Column Id',
    dataKey: 'id',
    extraProps: setColumnDefaultExtraProps({
      hidden: true,
      isSortable: false,
      shouldFilter: false,
    }),
  },
  {
    colLabel: 'Asset Id',
    dataKey: 'assetId',
    extraProps: setColumnDefaultExtraProps({
      hidden: true,
      isSortable: false,
      shouldFilter: false,
    }),
  },

  {
    colLabel: 'Asset',
    dataKey: 'assetCode',
    extraProps: setColumnDefaultExtraProps({
      filterField: 'asset.asset_code',
      sortField: 'asset.asset_code',
    }),
  },
  {
    colLabel: 'Asset Type',
    dataKey: 'assetType',
    extraProps: setColumnDefaultExtraProps({
      filterField: 'asset.asset_typ',
      sortField: 'asset.asset_typ',
    }),
  },
  // {
  //   colLabel: 'Asset Status',
  //   dataKey: '-',
  // },
  {
    colLabel: 'Current Location',
    dataKey: 'current',
    extraProps: setColumnDefaultExtraProps({
      shouldFilter: true,
    }),
  },
  {
    colLabel: 'Since Date/Time',
    dataKey: SINCE_DATE_TIME,
    extraProps: setColumnDefaultExtraProps({ shouldFilter: false, isDateField: true, sortField: 'time' }),
  },
  {
    colLabel: 'Moved Location',
    dataKey: 'movedLocation',
    extraProps: setColumnDefaultExtraProps({ filterField: 'moved', sortField: 'moved' }),
  },
  {
    colLabel: 'Moved By',
    dataKey: 'movedby',
    extraProps: setColumnDefaultExtraProps({
      isSortable: false,
      shouldFilter: false,
      sortField: 'moved_by.first'
    }),
  },
  {
    colLabel: 'Moved Date/Time',
    dataKey: 'movedDatetime',
    extraProps: setColumnDefaultExtraProps({
      isDateField: true,
      isSortable: false,
      shouldFilter: false,
      sortField: 'moved_time'
    }),
  },
  {
    colLabel: 'Reason',
    dataKey: 'reason',
    extraProps: setColumnDefaultExtraProps({
      isSortable: false,
      shouldFilter: false,
    }),
  },
  {
    colLabel: 'Ref ID',
    dataKey: '',
    extraProps: setColumnDefaultExtraProps({
      hidden: true,
      isSortable: false,
      shouldFilter: false,
    }),
  },
  {
    colLabel: 'Approved By',
    dataKey: '',
    extraProps: setColumnDefaultExtraProps({
      isSortable: false,
      shouldFilter: false,
    }),
  },
];

export const tranformToAssetLocationGridView = (assetLocationData) => {
  const rows = assetLocationData.map((assetLocation) => {
    const {
      id,
      asset,
      current,
      time,
      moved,
      moved_time,
      moved_by,
      comment,
      ref
    } = assetLocation;

    const transformedValues = {
      id,
      assetCode: asset.asset_code,
      assetId: asset.id,
      assetType: asset?.asset_typ?.name,
      current,
      sinceDatetime: time,
      movedLocation: moved,
      movedby: moved_by ? `${moved_by?.first || ''}  ${moved_by?.last || ''}` : null ,
      movedDatetime: moved_time,
      reason: comment,
      refid: ref,
    };

    const decoratedValues = {};
  Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });

  return {
    assetLocationsListView: { columns: getColumns(), rows },
  };
};

const formFieldValueMap = {
  id: '',
  asset: {},
  current: '',
  time: '',
  moved: '',
  moved_time: '',
  moved_by: {},
  comment: '',
  ref: ''
};

const INITIAL_STATE = {
  assetLocationsListView: {},
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateAssetLocationSuccess: false,
  isCreateAssetLocationError: false,
  showPageLevelLoader: false
};

const assetLocationsReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case INIT_ASSET_LOCATIONS:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case FETCH_ASSET_LOCATIONS_LIST_START:
    case FETCH_ASSET_LOCATION_DETAIL.START:
    case EDIT_ASSET_LOCATION_RECORD.START:
      return { ...state, isLoading: true };
    case FETCH_ASSET_LOCATIONS_LIST_SUCCESS: {
      const { page, content } = action.payload;
      let resetState = {
        assetLocationsListView: null,
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isCreateAssetLocationSuccess: false,
        isCreateAssetLocationError: false,
      };

      if (!content) {
        return resetState;
      }
      const { number, size, total_elements: totalElements, total_pages: totalPages } = page;
      const { assetLocationsListView } = tranformToAssetLocationGridView(content);

      return {
        ...resetState,
        assetLocationsListView,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages,
          pageNumber: number,
        },
      };
    }

    case FETCH_ASSET_LOCATIONS_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        assetLocationsListView: {},
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isAssetLocationDeletionSuccess: false,
        isAssetLocationDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }
  
    // TODO(subham): See if this field is needed.
    case INIT_CREATE_NEW_ASSET_LOCATION_SCREEN: {
      return { ...INITIAL_STATE };
    }

    case FETCH_ASSET_LOCATION_DETAIL.SUCCESS: {
      const temp = {};
      Object.keys(formFieldValueMap).forEach((key) => {
        const value = action.payload[key];
        temp[key] = value && isDateField(key) ? new Date(value) : value;
      });

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isAssetLocationDetailSuccess: true,
      };
    }

    case FETCH_ASSET_LOCATION_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isAssetLocationDetailSuccess: false,
        isAssetLocationDetailError: true,
      };
    }

    case EDIT_ASSET_LOCATION_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditAssetLocationSuccess: false,
        isEditAssetLocationError: true,
      };
    }

    case EDIT_ASSET_LOCATION_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditAssetLocationSuccess: true,
        isEditAssetLocationError: false,
      };
    }
    case TOAST.RESET: {
      return {
        ...state,
        isCreateAssetLocationSuccess: false,
        isCreateAssetLocationError: false,
        isEditAssetLocationError: false,
        isEditAssetLocationSuccess: false,
        isAssetLocationDetailError: false,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({ assetLocationsReducer, assetLocationsHistoryReducer })