import React, { PureComponent } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { getApiAsyn } from '../../Services/PostAPI';
import SelectSearch from 'react-select-search';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
export class TripFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      TripTypeDropdonw: [],
      DriverDropdonw: [],
      TrailerDropdonw: [],
      OrderDropdonw: [],
      TripStatusDropdonw: [],
      TruckDropdonw: [],
    };
  }

  getTrailerDropdonw = async () => {
    let ddtrailer = [];
    let ddtruck =[];

    let res = await getApiAsyn(
      `assets?paged:false`,
      cookies.get('authorization')
    );
    if (res && res.status !== false) {
      let count = res && res.page && res.page.total_elements;
      if (count > 0) {
       let ddT = res._embedded.content;
       let ddTC= res._embedded.content;
       ddT = ddT.filter(item=> item?.vehicle_typ?.code ==='TRAILER')
       ddTC = ddTC.filter(item=> item?.vehicle_typ?.code ==='TRUCK')
        ddtrailer = ddT;
        ddtrailer = ddtrailer.map((item) => ({
          name: item.asset_code,
          value: item.id,
        }));
        ddtrailer.unshift({
          name: 'All',
          value: '-1',
        });
        this.setState({
          TrailerDropdonw: ddtrailer,
        });
        ddtruck = ddTC
        ddtruck = ddtruck.map((item) => ({
          name: item.asset_code,
          value: item.id,
        }));
        ddtruck.unshift({
          name: 'All',
          value: '-1',
        });
        this.setState({
          TruckDropdonw: ddtruck,
        });
      


      } else {
        this.setState({
          TrailerDropdonw: [],
          TruckDropdonw: [],
        });
      }
    }
  };

  getTripTypeDropdonw = async () => {
    let ddtriptype = [];
    let res = await getApiAsyn(
      `types?type=TRIP_TYPE`,
      cookies.get('authorization')
    );
    if (res.type !== 'error') {
      ddtriptype = res;
      ddtriptype = ddtriptype.map((item) => ({
        name: item.value,
        value: item.key,
      }));
      ddtriptype.unshift({
        name: 'All',
        value: '-1',
      });
      this.setState({
        TripTypeDropdonw: ddtriptype,
      });
    }
  };
  getTripStatusDropdonw = async () => {
    let ddstatus = [];
    let res = await getApiAsyn(
      `types?type=TRIP_STATUS`,
      cookies.get('authorization')
    );
    ddstatus = res;
    ddstatus = ddstatus.map((item) => ({
      name: item.value,
      value: item.key,
    }));
    ddstatus.unshift({
      name: 'All',
      value: '-1',
    });
    this.setState({
      TripStatusDropdonw: ddstatus,
    });
  };

  getDriverDropdonw = async () => {
    let dddriver = [];

    let res = await getApiAsyn(
      `employees?paged=true&page=1&size=50000`,
      cookies.get('authorization')
    );
    if (res.page.totalElements > 0) {
      dddriver = res._embedded.content;
      dddriver = dddriver.map((item) => ({
        name: item.first,
        value: item.id,
      }));
      dddriver.unshift({
        name: 'All',
        value: '-1',
      });
      this.setState({
        DriverDropdonw: dddriver,
      });
    }
  };

  getOrderType = async () => {
    let ddordertype = [];
    let res = await getApiAsyn(
      `types?type=ORDER_TYPE`,
      cookies.get('authorization')
    );
    ddordertype = res;
    ddordertype = ddordertype.map((item) => ({
      name: item.value,
      value: item.key,
    }));
    ddordertype.unshift({
      name: 'All',
      value: '-1',
    });
    this.setState({
      OrderDropdonw: ddordertype,
    });
  };

  async componentDidMount() {
    this.getTripTypeDropdonw();
    this.getDriverDropdonw();
    this.getTrailerDropdonw();
    this.getTripStatusDropdonw();
  }

  render() {
    const {
      TripTypeonChange,
      TripTypevalue,
      TripTypeName,
      TripStatusName,
      TripStatusonChange,
      TripStatusvalue,
      DriverName,
      DriveronChange,
      Drivervalue,
      TrailerNane,
      TraileronChange,
      Trailervalue,
      //EquipmentonChange,
      //Equipmentvalue,
      //EquipmentNane,
      TruckNane,
      TruckonChange,
      Truckvalue,
      OrderNoNane,
      OrderNoonChange,
      OrderNovalue,

      TripNoNane,
      TripNoonChange,
      TripNovalue,
      AddressNane,
      AddressonChange,
      Addressvalue,

      CstmTrailerNoNane,
      CstmTraileronChange,
      CstmTrailerNovalue,
    } = this.props;
    const {
      DriverDropdonw,
      TrailerDropdonw,
      TruckDropdonw,
      TripTypeDropdonw,
      TripStatusDropdonw,
    } = this.state;

    return (
      <div>
        <Row>
          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                TRIP TYPE
              </Form.Label>
              <Col sm="12">
                <SelectSearch
                  container="rowselect"
                  search
                  options={TripTypeDropdonw}
                  select="test"
                  name={TripTypeName}
                  autoComplete="sdf"
                  onChange={TripTypeonChange}
                  value={TripTypevalue}
                  placeholder="Type to search"
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Trip Status
              </Form.Label>
              <Col sm="12">
                <SelectSearch
                  container="rowselect"
                  search
                  options={TripStatusDropdonw}
                  select="test"
                  name={TripStatusName}
                  autoComplete="sdf"
                  onChange={TripStatusonChange}
                  value={TripStatusvalue}
                  placeholder="Type to search"
                />
              </Col>
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                DRIVER
              </Form.Label>
              <Col sm="12">
                <SelectSearch
                  container="rowselect"
                  search
                  options={DriverDropdonw}
                  select="test"
                  name={DriverName}
                  autoComplete="sdf"
                  onChange={DriveronChange}
                  value={Drivervalue}
                  placeholder="Type to search"
                />
              </Col>
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Trailer
              </Form.Label>
              <Col sm="12">
                <SelectSearch
                  container="rowselect"
                  search
                  options={TrailerDropdonw}
                  select="test"
                  name={TrailerNane}
                  autoComplete="sdf"
                  onChange={TraileronChange}
                  value={Trailervalue}
                  placeholder="Type to search"
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Trip No
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  placeholder="Trip No"
                  name={TripNoNane}
                  autoComplete="sdf"
                  onChange={TripNoonChange}
                  value={TripNovalue}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Truck
              </Form.Label>
              <Col sm="12">
                <SelectSearch
                  container="rowselect"
                  search
                  options={TruckDropdonw}
                  select="test"
                  name={TruckNane}
                  autoComplete="sdf"
                  onChange={TruckonChange}
                  value={Truckvalue}
                  placeholder="Type to search"
                />
              </Col>
            </Form.Group>
          </Col>

          {/* <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Equipment Type
              </Form.Label>
              <Col sm="12">
                <SelectSearch
                  container="rowselect"
                  search
                  options={TrailerDropdonw}
                  select="test"
                  name={EquipmentNane}
                  autoComplete="sdf"
                  onChange={EquipmentonChange}
                  value={Equipmentvalue}
                  placeholder="Type to search"
                />
              </Col>
            </Form.Group>
          </Col> */}
          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Order No
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  placeholder="Order No"
                  name={OrderNoNane}
                  autoComplete="sdf"
                  onChange={OrderNoonChange}
                  value={OrderNovalue}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Custom Trailer
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  placeholder="Custom Trailer"
                  name={CstmTrailerNoNane}
                  autoComplete="sdf"
                  onChange={CstmTraileronChange}
                  value={CstmTrailerNovalue}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Address
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  placeholder="Address"
                  name={AddressNane}
                  autoComplete="sdf"
                  onChange={AddressonChange}
                  value={Addressvalue}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  }
}
