import Cookies from 'universal-cookie';
import {  post, put } from 'axios';
import { getApiAsyn ,deleteApi, baseUrls} from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { API_URL } from '../../../constants/urlConstants';
import { generateImageUrl, getDecoratedUrl } from '../../common/urlService';
import { extractData } from '../../utils';
import { asyncForEach } from '../../../utils/async';
import { getAllAttachment } from '../commonService';

const cookies = new Cookies();

export const fetchDocuments = async (payload) => {
    const url = getDecoratedUrl({
      url: API_URL.DOCUMENTS.FETCH_DOCUMENT,
      payload,
    });
    try {
      let result = await getApiAsyn(url, cookies.get('authorization'));
  
      if (result?.error) {
        throw new APIError(result);
      }
  
      return extractData(result, !payload?.filters);
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };
  
  export const deleteDocumentRecord = async ({ documentId }) => {
    try {
      let result = await deleteApi(
        `${API_URL.DOCUMENTS.DELETE_DOCUMENT}/${documentId}`,
        cookies.get('authorization')
      );
  
      if (result?.error) {
        throw new APIError(result);
      }
      return result;
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };  

  export const fetchCustomers = async (payload , searchById = false) => { 
    const url = !searchById ?  getDecoratedUrl({url:API_URL.CUSTOMERS.FETCH_CUSTOMERS, payload})
                            :API_URL.CUSTOMERS.FETCH_CUSTOMER_BY_ID.replace(':id', payload?.id)
    try {
      let result = await getApiAsyn(url, cookies.get('authorization'));
  
      if (result?.error) {
        throw new APIError(result);
      }
      
      return extractData(result, !payload?.filters);
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };

  export const fetchSuppliers = async (payload , searchById = false) => {
    const url = !searchById ? getDecoratedUrl({url:API_URL.SUPPLIERS.FETCH_SUPPLIERS, payload})
                : API_URL.SUPPLIERS.FETCH_SUPPLIERS_BY_ID.replace(':id', payload?.id)

    try {
      let result = await getApiAsyn(url, cookies.get('authorization'));
  
      if (result?.error) {
        throw new APIError(result);
      }
  
      return extractData(result, !payload?.filters);
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };
  
  export const fetchAsset = async (payload , searchById = false) => {
    const url = !searchById ? getDecoratedUrl({url:API_URL.ASSETS.FETCH_ASSETS, payload})
                : API_URL.ASSETS.FETCH_ASSETS_BY_ID.replace(':id', payload?.id)

    try {
      let result = await getApiAsyn(url, cookies.get('authorization'));
  
      if (result?.error) {
        throw new APIError(result);
      }
  
      return extractData(result, !payload?.filters);
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };
  

  export const fetchDocumentTypes = async (payload) => {
    const url = 'dropdown?filter=type::BS_DOC_TYPE&size=1000'
    try {
      let result = await getApiAsyn(url, cookies.get('authorization'));
  
      if (result?.error) {
        throw new APIError(result);
      }
  
      return extractData(result, !payload?.filters);
      
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };
  
  export const fetchOwnerTypes = async (payload) => {
    const url = API_URL.FETCH_OWNER_TYPES;
    try {
      let result = await getApiAsyn(url, cookies.get('authorization'));
  
      if (result?.error) {
        throw new APIError(result);
      }
      return result;
      //return extractData(result, !payload?.filters);
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };
  
  const multipartDataUploadApi = (url, { files, data }, isEdit) => {
    const formPayload = new FormData();
    // data part of multipart data
    // data.date = 1346524199000
    const json = JSON.stringify(data);
    const blobData = new Blob([json], {
      type: 'application/json',
    });
    formPayload.append('data', blobData);
    
    // file part of multipart data
    files?.forEach((file) => {
      formPayload.append('file', file);
    });
  
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: cookies.get('authorization'),
      },
    };
    if (isEdit) {
      return put(baseUrls + url, formPayload, config);
    } else {
      return post(baseUrls + url, formPayload, config);
    }
  };

  export const createDocumentRecord = async (payload) => {
    try {
      let result = await multipartDataUploadApi(
        `${API_URL.DOCUMENTS.CREATE_DOCUMENT}`,
        payload
      );
  
      if (result?.error) {
        throw new APIError(result);
      }
      return result;
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };

  export const editDocumentRecord = async (payload) => {
    const url = API_URL.DOCUMENTS.EDIT_ACCIDENT.replace(
      ':id',
      payload?.documentId
    );
  
    try {
      let result = await multipartDataUploadApi(url, payload, true);
  
      if (result?.error) {
        throw new APIError(result);
      }
      return result;
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };
  export const fetchDocumentByDocumentId = async (payload) => {
    const url = API_URL.DOCUMENTS.FETCH_BY_DOCUMENT_ID.replace(
      ':id',
      payload?.documentId
    );
    try {
      let result = await getApiAsyn(url, cookies.get('authorization'));
  
      if (result?.error) {
        throw new APIError(result);
      }
      
      result = extractData(result, !payload?.filters);
      if (result?.files?.length) {
        await asyncForEach(result.files, async (file, index, array) => {
          //result.files[index].url = await generateImageUrl(file.url);
          result.files[index].s3Url = await generateImageUrl(file.url);
        });
      }
      return result;
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };


  export const fetchDocumentsAttachmentByDocumentId = async (payload) => {
    const url = API_URL.DOCUMENTS.FETCH_BY_DOCUMENT_ID.replace(':id',payload?.itemId);
    try {
      let result = await getApiAsyn(url, cookies.get('authorization'));
  
      if (result?.error) {
        throw new APIError(result);
      }
      if (result?.files?.length) {
        await asyncForEach(result.files, async (file, index, array) => {
          // result.files[index].url = await generateImageUrl(file.url);
          result.files[index].s3Url = await generateImageUrl(file.url);
        });
      }
      return result;
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };
  
  export const downloadDocumentsAllAttachment = async (payload) => {
    await getAllAttachment('documents', payload)
  };
  
  export const createDocumentType = async (payload) => {
    const url = API_URL.DROPDOWN
    const config = {
      headers: {
        authorization: cookies.get('authorization'),
      },
    };
  
    try {
        const result = await post(baseUrls + url, payload, config);
        return result
    } catch(err) {
      console.log(err, payload);
      return handleAPIError(err);
    }
  }
  
  export const editDocumentType = async (payload, documentTypeId) => {
    const url = API_URL.DROPDOWN + documentTypeId;
    const config = {
      headers: {
        authorization: cookies.get('authorization'),
      },
    };
  
    try {
        const result = await put(baseUrls + url, payload, config);
        return result
    } catch(err) {
      console.log(err, payload);
      return handleAPIError(err);
    }
  }