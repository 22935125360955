import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Employees from '../../components/Employee_New';
import { loadingsShow } from '../../Services/PostAPI';
import {
    initializeEmployeeScreen ,fetchEmployees
} from '../../Redux/Actions/employees';

const TrackingLogsScreen = (props) => {
  const {
    employeesListView,
    fetchEmployees,
    initializeEmployeeScreen,
    isFetchingGridData,
    paginationInfo,
    showPageLevelLoader,
    screenPermissions,
    isPageLevelError,
  } = props;

  
  useEffect(() => {
    initializeEmployeeScreen();
    fetchEmployees({ sortField: 'first' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const employeesProps = {
    employeesListView,
    fetchEmployees,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isPageLevelError,
  };

  if (showPageLevelLoader) {
    loadingsShow('block');
    return null;
  }
  loadingsShow('none');
  return (
    <>
      <Employees {...employeesProps} />;
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeEmployeeScreen: () => dispatch(initializeEmployeeScreen()),
    fetchEmployees: (payload) => dispatch(fetchEmployees(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  
  return {
    ...ownProps,
    employeesListView: selectEmployeesListView(state.employeeReducer),
    isFetchingGridData: selectIsLoading(state.employeeReducer),
    showPageLevelLoader: selectIsLoadingPage(state.employeeReducer),
    paginationInfo: selectPaginationInfo(state.employeeReducer),
    isPageLevelError: selectIsPageLevelError(state.employeeReducer),
  };
};

const selectIsPageLevelError = (employeeReducer) =>
employeeReducer.isPageLevelError;
const selectPaginationInfo = (employeeReducer) =>
employeeReducer.paginationInfo;
const selectIsLoading = (employeeReducer) => employeeReducer.isLoading;
const selectIsLoadingPage = (employeeReducer) =>
employeeReducer.isLoadingPage;
const selectEmployeesListView = (employeeReducer) =>
employeeReducer.employeesListView;

export default connect(mapStateToProps, mapDispatchToProps)(TrackingLogsScreen);
