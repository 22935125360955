import { connect } from 'react-redux';
import { ProgressBar } from 'primereact/progressbar';
import { EzToast, TOAST_SEVERITY } from '../../../common/EzToast';
import CreateInventoryTracker from '../../../components/InventoryTracker/CreateInventoryTracker';
import { APP_URLS } from '../../../constants/urlConstants';
import { fetchEmployees } from '../../../Redux/Actions/employees';
import { fetchAssets } from '../../../Redux/Actions/assets';
import { fetchTypes } from '../../../Redux/Actions/types';
import { selectLoggedInUserInfo } from '../../../Redux/Selectors/profile'

import {
  initCreateNewInventoryTrackerScreen,
  createInventoryTracker,
  editInventoryTracker,
  fetchInventoryTrackerByInventoryTrackerId,
  fetchFilesByInventoryTrackerId,
  downloadAllAttachmentByInventoryTrackerId
} from '../../../Redux/Actions/inventoryTracker';
import { useEffect } from 'react';
import { setIsformDirty } from '../../../Redux/Actions/common/form';
import { fetchOwnerTypes } from '../../../Redux/Actions/OwnerType';
import { getOwnerTypes } from '../../../Redux/Selectors/dropDown';
import { fetchCustomers } from '../../../Redux/Actions/customers';

const CreateInventoryTrackerContainer = (props) => {
  const {
    history,
    createInventoryTracker,
    editInventoryTracker,
    isLoading,
    isCreateInventoryTrackerSuccess,
    isCreateInventoryTrackerError,
    isCreateInventoryTrackerErrorMessage,
    isInventoryTrackerDetailSuccess,
    isInventoryTrackerDetailError,
    isEditInventoryTrackerSuccess,
    isEditInventoryTrackerError,
    isEdit,
    formFieldValueMap,
    fetchInventoryTrackerByInventoryTrackerId,
    fetchFilesByInventoryTrackerId,
    downloadAllAttachmentByInventoryTrackerId,
    shouldBlockNavigation,
    loggedInUserInfo,
    ownerTypes
  } = props;
  const { inventoryTrackerId } = props.match.params;
  const searchTypes = (query) => {
    if (query) {
      fetchTypes({
        params: query,
      });
    }
  };
  useEffect(() => {
    if (isEdit) {
      fetchInventoryTrackerByInventoryTrackerId({ inventoryTrackerId });
      //fetchOwnerTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);
  const navigateToInventoryTrackerSummaryScreen = () => {
    history.push(APP_URLS.INVENTORY_TRACKERS.HOME);
  };
  const createInventoryTrackerProps = {
    navigateToInventoryTrackerSummaryScreen,
    fetchAssets,
    searchTypes,
    fetchEmployees,
    createInventoryTracker,
    editInventoryTracker,
    isLoading,
    isEdit,
    isInventoryTrackerDetailSuccess,
    isCreateInventoryTrackerSuccess,
    isEditInventoryTrackerSuccess,
    isEditInventoryTrackerError,
    formFieldValueMap,
    inventoryTrackerId,
    shouldBlockNavigation,
    fetchFilesByInventoryTrackerId,
    downloadAllAttachmentByInventoryTrackerId,
    loggedInUserInfo,
    ownerTypes
  };
  

  const getToastProps = () => {
    if (isCreateInventoryTrackerSuccess || isEditInventoryTrackerSuccess) {
      const toastTitle = isEdit
        ? 'InventoryTracker Updated Successfully'
        : 'InventoryTracker Created Successfully';
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }
    if (isCreateInventoryTrackerError || isInventoryTrackerDetailError || isEditInventoryTrackerError) {
      let toastTitle = 'Error while Creating InventoryTracker';
      if (isEditInventoryTrackerError) {
        toastTitle = 'Error while updating InventoryTracker';
      } else if (isInventoryTrackerDetailError) {
        toastTitle =
          'Error while performing operation. Please refresh your browser';
      }
      else if (isCreateInventoryTrackerErrorMessage?.response?.data?.status === false) {
        toastTitle = isCreateInventoryTrackerErrorMessage?.response?.data?.error
      }
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };
  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />;
  };
  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateInventoryTracker {...createInventoryTrackerProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ownerTypes : getOwnerTypes(state),
    loggedInUserInfo: selectLoggedInUserInfo(state),
    employees: selectFromEmployeeReducer(state, 'employees'),
    customers: fetchCustomers(state),
    assets: selectFromAssetsReducer(state, 'assets'),
    types: selectFromTypesReducer(state, 'types'),
    isLoading: selectFromInventoryTrackersReducer(state, 'isLoading'),
    isCreateInventoryTrackerSuccess: selectFromInventoryTrackersReducer(
      state,
      'isCreateInventoryTrackerSuccess'
    ),
    isCreateInventoryTrackerError: selectFromInventoryTrackersReducer(
      state,
      'isCreateInventoryTrackerError'
    ),
    isCreateInventoryTrackerErrorMessage: selectFromInventoryTrackersReducer(
      state,
      'error'
    ),
    isInventoryTrackerDetailSuccess: selectFromInventoryTrackersReducer(
      state,
      'isInventoryTrackerDetailSuccess'
    ),
    isInventoryTrackerDetailError: selectFromInventoryTrackersReducer(
      state,
      'isInventoryTrackerDetailError'
    ),
    isEditInventoryTrackerSuccess: selectFromInventoryTrackersReducer(
      state,
      'isEditInventoryTrackerSuccess'
    ),
    isEditInventoryTrackerError: selectFromInventoryTrackersReducer(
      state,
      'isEditInventoryTrackerError'
    ),
    formFieldValueMap: selectFromInventoryTrackersReducer(state, 'formFieldValueMap'),
    ...ownProps,
  };
};
const selectFromAssetsReducer = (state, path) => {
  return selectAssetsReducer(state)[path];
};
const selectFromTypesReducer = (state, path) => {
  return selectTypesReducer(state)[path];
};
const selectFromInventoryTrackersReducer = (state, path) => {
  return selectInventoryTrackersReducer(state)[path];
};
const mapDispatchToProps = (dispatch) => {
  return {
    initCreateNewInventoryTrackerScreen: () => dispatch(initCreateNewInventoryTrackerScreen()),
    fetchEmployees: (payload) => dispatch(fetchEmployees(payload)),
    fetchAssets: (payload) => dispatch(fetchAssets(payload)),
    fetchTypes: (payload) => dispatch(fetchTypes(payload)),
    fetchInventoryTrackerByInventoryTrackerId: (payload) => dispatch(fetchInventoryTrackerByInventoryTrackerId(payload)),
    fetchFilesByInventoryTrackerId: (payload) => dispatch(fetchFilesByInventoryTrackerId(payload)),
    downloadAllAttachmentByInventoryTrackerId:(payload) => dispatch(downloadAllAttachmentByInventoryTrackerId(payload)),
    createInventoryTracker: (payload) => dispatch(createInventoryTracker(payload)),
    editInventoryTracker: (payload) => dispatch(editInventoryTracker(payload)),
    shouldBlockNavigation: isFormDirty => dispatch(setIsformDirty(isFormDirty)),
    fetchOwnerTypes: (payload) => dispatch(fetchOwnerTypes(payload))
  };
};
const selectInventoryTrackersReducer = ({ inventoryTrackersReducer }) => {
  return inventoryTrackersReducer;
};
const selectAssetsReducer = ({ assetsReducer }) => {
  return assetsReducer;
};
const selectTypesReducer = ({ typesReducer }) => {
  return typesReducer;
};
const selectFromEmployeeReducer = (state, path) => {
  return selectEmployeeReducer(state)[path];
};
const selectEmployeeReducer = ({ employeeReducer }) => {
  return employeeReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateInventoryTrackerContainer);