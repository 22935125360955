import { Button } from 'primereact/button';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    getDownloadingAllAttachments,
    getFilesAttachment, getIsFilesFetching
 } from '../../Redux/Selectors/common/files';
import CardComponent from './cardComponent';
import PDF_ICON from '../../images/pdf.png' 
import { ProgressBar } from 'primereact/progressbar';

const isPDF = ({name}) => name?.includes('pdf');

const getImageSource = file => {
    const {name ,s3Url} = file;
    if(name && name.includes('png','jpeg')) return s3Url;
    else if(isPDF({name})) return PDF_ICON;
}

const getCardProps = file => {
    const { s3Url, name } = file;
    
    return {
        imgSrc: getImageSource(file),
        downloadLink: s3Url,
        name
    }   
};
    
const DownloadAttachment = props => {
    const dispatch = useDispatch();
    const { itemId, fetchAttachmentsById, downloadAllAttachments, dataId} = props;
    const files = useSelector(getFilesAttachment);
    const isFetchingFiles = useSelector(getIsFilesFetching)
    const downloadingAttachments = useSelector(getDownloadingAllAttachments)
    useEffect(() => {fetchAttachmentsById({itemId, dataId})}, [fetchAttachmentsById, itemId, dataId])
    const downloadAllAttachmentForItemId = () => dispatch(downloadAllAttachments({itemId, dataId}));
    const renderNoAttachment = () => !isFetchingFiles && !files?.length;
    const getCardContainerStyle = () => {
        return {
            display: 'flex',
            flexDirection: 'column',
            height: (isFetchingFiles || renderNoAttachment()) ? '100%' : '',
            width:  (isFetchingFiles || renderNoAttachment()) ? '100%' : '',
            alignItems: 'center',
            justifyContent:'center'
        }    
    }
    
    return <>
       <div style = {{...getCardContainerStyle()}} > 
        {
            isFetchingFiles && <ProgressBar mode="indeterminate"  style={{height:'6px', width:'50%'}} />
        }
        {
            (!isFetchingFiles && files?.length !== 0) &&
            <>
                <div style={{ marginBottom:'10px',width:'100%'}}>
                    <Button style={{float:'right'}}
                    label = {downloadingAttachments ? 'Downloading': 'Download All'}
                    loading = {downloadingAttachments}
                    className = 'p-button-raised'
                    onClick={downloadAllAttachmentForItemId} />
                </div>
                <div style={{display: 'flex', width: '100%', flexWrap: 'wrap', gap : '25px'}}>
                    {
                        files?.map(file => {
                            const cardProps = getCardProps(file); 
                            return <CardComponent key = {file.id} isPDF = {isPDF(file)} {...cardProps} />
                        })
                    }
                </div>            
            </>
        }
        {
              renderNoAttachment() && 
             <p>No attachments to show</p>
        }
       </div>
        
    </>
}

export default DownloadAttachment;
