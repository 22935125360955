const typeLabelMap = {
  AST: 'Asset',
  EMP: 'Employee',
  CMP: 'Company',
  CST: 'Customer',
}

export const convertLabelToType = query => {
  const type = Object.entries(typeLabelMap)
    .find(entry => {
      const lowerCaseEntry = entry[1]?.toLowerCase();
      const lowerCaseQuery = query?.toLowerCase();
      
      return lowerCaseEntry?.startsWith(lowerCaseQuery?.toLowerCase())
    })
  
  return type?.[0] || ''
}

export const convertTypeToLabel = type => {
  return typeLabelMap[type] || '' 
}
