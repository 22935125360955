import {
  FETCH_CUSTOMERS_LIST_START,
  FETCH_CUSTOMERS_LIST_SUCCESS, 
  FETCH_CUSTOMERS_LIST_ERROR, 
 } from "../../../constants/action-types/customers";

const tranformToCustomerGridView = (customerData) => {
  return {
    customersListView: { columns: [], rows: customerData }
  };
};

const INITIAL_STATE = {
  customersListView: {},
  isLoading: false,
  error: null,
  customers: []
};

export const customerReducer = (state = INITIAL_STATE, action) => {
// eslint-disable-next-line default-case
switch (action.type) {

  case FETCH_CUSTOMERS_LIST_START: return { ...state, isLoading: true, isLoadingPage :true }

  case FETCH_CUSTOMERS_LIST_SUCCESS: {
    const { page, content } = action.payload;
    let resetState = {
      customersListView: null,
      paginationInfo: null,
      error: null,
      isLoading: false,
      isLoadingPage: false,
      isPageLevelError: false,
      isCreateCustomerSuccess: false,
      isCreateCustomerError: false,
      customers: content
    };

    if (!content) {
      return resetState;
    }
    
    const { number, size, total_elements: totalElements, total_pages: totalPages } = page;
    const { customersListView } = tranformToCustomerGridView(content);
    return {
      ...resetState,
      customersListView,
      ...(page ? { paginationInfo: {
        pageSize: size,
        totalRecords: totalElements,
        totalPages,
        pageNumber: number
      }, } : {})
    };
  }

  case FETCH_CUSTOMERS_LIST_ERROR: {
    const { error } = action.payload;

    return {
      ...state,
      customersListView: {},
      customers: [],
      isLoading: false,
      isLoadingPage: false,
      error,
      isPageLevelError: true,
      isAccidentDeletionSuccess: false,
      isAccidentDeletionError: false,
      shouldOpenDeleteConfirmationPopup: false,
    };
  }
  default:
    return state;
}
};