import React from 'react';
import { Controller } from 'react-hook-form';
import { AutoComplete } from 'primereact/autocomplete';

const EzControlledAutoSuggest = (props) => {
  const {
    control,
    name,
    completeMethod,
    customItemTemplate,
    isDropDownView,
    rules,
    suggestions,
    delay,
    onChange,
    itemClassName,
  } = props;

  const itemTemplate = (item) => {
    if (customItemTemplate) {
      return customItemTemplate(item);
    }

    return (
      <div className={itemClassName}>
        <div>{item.label}</div>
      </div>
    );
  };

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => {
        return (
          <AutoComplete
            value={field?.value?.label || field?.value}
            delay={delay}
            suggestions={suggestions}
            completeMethod={completeMethod}
            dropdown={isDropDownView}
            itemTemplate={itemTemplate}
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
              field.onChange(e.value);
            }}
          />
        );
      }}
    />
  );
};

EzControlledAutoSuggest.defaultProps = {
  isDropDownView: true,
  delay: 300,
  itemClassName: '',
  customItemTemplate: null,
};

export default EzControlledAutoSuggest;
