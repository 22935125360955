import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import { EzToast, TOAST_SEVERITY } from '../../common/EzToast';
import EzNotFound from '../../common/EzNotFound';
import EzTable from '../../common/EzTable';

// TODO (Subham): Find why assets path is not working
//const getIconPath = () => process.env.PUBLIC_URL + '/assets/images/acidents/no-record.png';
const getIconPath = (imgPathDelta = '/no-record.png') =>
  process.env.PUBLIC_URL + imgPathDelta;

const Assets = (props) => {
  
  const {
    paginationInfo,
    isFetchingGridData,
    assetsListView,
    fetchAssets,
    deleteAssetRecord, 
    isPageLevelError,
    screenPermissions,
    isAssetDeletionError,
    isAssetDeletionSuccess,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    createAssetRecord,
    editAssetRecord,
    moreActionProps,
    fetchAttachmentsById,
    downloadAllAttachments
  } = props;

  const [areFiltersVisible, toggleFilterState] = useState(false);
  
  const getToastProps = () => {
    if (isAssetDeletionSuccess) {
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle: 'Asset Deleted Successfully',
        shouldShowToast: true,
      };
    }

    if (isAssetDeletionError) {
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle: 'Error while Deleting Asset',
        shouldShowToast: true,
      };
    }
  };

  const renderPageHeaderSection = () => {
    const breadCrumbs = [{ label: 'Asset' }, { label: 'Asset Summary' }];

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };

    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />

        <div className="rightbtn">
          <Button
            label={areFiltersVisible ? 'Hide Filters' : 'Show Filters'}
            icon={areFiltersVisible ? 'pi pi-filter-slash' : 'pi pi-filter'}
            className={`mr-2 p-button-raised p-button-outlined p-button-secondary`}
            onClick={() => {
              toggleFilterState(!areFiltersVisible);
            }}
          />
          <Button
            label={'Create New Asset'}
            icon={'pi pi-plus'}
            className={`mr-2 p-button-raised`}
            onClick={createAssetRecord}
          />
        </div>
      </div>
    );
  };

  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderAssetsRecords = () => assetsListView?.columns?.length > 0;
  const shouldRenderNotFoundView = () => !shouldRenderAssetsRecords() && !shouldRenderFullPageError();

  const filters = {
    global: { value: null },
    asset_code: { value: null },
    owner_typ: { value: null },
    asset_typ: { value: null, filterKey: 'asset_typ.name' },
    asset_own_typ: { value: null},
    vehicle_vin: { value: null},
    province: { value: null, filterKey : 'province.value'},
    vehicle_no: { value: null},
    info: { value: null},
    vehicle_typ : { value: null , filterKey:'vehicle_typ.name' },
    equip_type : { value: null, filterKey: 'equip_type.name' } 
  };

  const loadLazyData = (params) => {
    fetchAssets(params);
  };


  const tableProps = {
    areFiltersVisible,
    columns: assetsListView.columns,
    fetchAssets,
    paginationInfo,
    loadLazyData,
    sortField : 'id',
    value: assetsListView.rows,
    filters,
    onDeleteRecord: deleteAssetRecord,
    onEditRecord: editAssetRecord,
    loading: isFetchingGridData,
    screenPermissions,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    moreActionProps,
    fetchAttachmentsById,
    downloadAllAttachments
  };

  return (
    <>
      <EzToast {...getToastProps()} />
      <div className="paddingbox">
        <div className="containerbox">
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: '-70px' }}
                bgImgUrl={getIconPath('something-broke.png')}
                message={'Oops! Something broke, we are working on a fix.'}
              />
            )}
            {shouldRenderAssetsRecords() && (
              <Row>
                <Col md={12}>
                  <EzTable {...tableProps} />
                </Col>
              </Row>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={'No Asset records to show'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Assets;
