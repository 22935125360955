import {
  INIT_INVENTORY_TRACKERS,
  FETCH_INVENTORY_TRACKERS_LIST_START,
  FETCH_INVENTORY_TRACKERS_LIST_SUCCESS,
  FETCH_INVENTORY_TRACKERS_LIST_ERROR,
  INIT_CREATE_NEW_INVENTORY_TRACKER_SCREEN,
  CREATE_INVENTORY_TRACKER_RECORD,
  EDIT_INVENTORY_TRACKER_RECORD,
  FETCH_INVENTORY_TRACKER_DETAIL,
  TOAST
} from '../../../constants/action-types/inventoryTracker';
import { buildName } from '../../../utils/string';
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from '../../../utils/table/gridUtils';

export const INVENTORY_TRACKER_ID = 'id';
const tranformToInventoryTrackerGridView = (inventoryTrackerData) => {
  const rows = inventoryTrackerData.map((inventoryTracker) => {
    const {
      id,
      issued_to,
      issuer,
      rental_status,
      created_date
    } = inventoryTracker;

    const transformedValues = {
      id,
      created_date,
      issued_to: issued_to?.first ? buildName(issued_to) : buildName(issued_to, 'name'),
      issuer: buildName(issuer),
      rental_status: rental_status.name
    };
    const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });

  const columns = [
    {
      colLabel: 'Inventory Id',
      dataKey: INVENTORY_TRACKER_ID,
      extraProps: setColumnDefaultExtraProps({
        isSortable: true,
        sortField: 'id',
        shouldFilter: true,
      }),
    },
    {
      colLabel: 'created_date',
      dataKey: 'created_date',
      extraProps: setColumnDefaultExtraProps({
        isSortable: true,
        sortField: 'created_date',
        shouldFilter: true,
        hidden: true
      }),
    },
    {
      colLabel: 'Issued to',
      dataKey: 'issued_to',
      extraProps: setColumnDefaultExtraProps({
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Issued By',
      dataKey: 'issuer',
      extraProps: setColumnDefaultExtraProps({
        sortField: 'issuer.first',
        shouldFilter: true,
      }),
    },
    {
      colLabel: 'Status',
      dataKey: 'rental_status',
      extraProps: setColumnDefaultExtraProps({
        sortField: 'rental_status.name',
        shouldFilter: true,
      }),
    }
  ];

  return {
    inventoryTrackersListView: { columns, rows },
  };
};
const formFieldValueMap = {
  id: '',
  type: '',
  issued_to: '',
  issuer: '',
  rental_status: '',
  files: '',
  items: [
    {
      id: INVENTORY_TRACKER_ID ,
      name: '',
      desc: '',
      issued_qty: '',
      issued_date: '',
      item_status: '',
      returned_qty: '',
      returned_date: '',
      pending_qty: '',
      comments: '',
      status: ''
    }
  ]
};

const INITIAL_STATE = {
  inventoryTrackersListView: {},
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateInventoryTrackerSuccess: false,
  isCreateInventoryTrackerError: false,
};

export const inventoryTrackersReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case INIT_INVENTORY_TRACKERS:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case FETCH_INVENTORY_TRACKERS_LIST_START:
    case CREATE_INVENTORY_TRACKER_RECORD.START:
    case FETCH_INVENTORY_TRACKER_DETAIL.START:
    case EDIT_INVENTORY_TRACKER_RECORD.START:
      return { ...state, isLoading: true };
    case FETCH_INVENTORY_TRACKERS_LIST_SUCCESS: {
      const { page, content } = action.payload;
      let resetState = {
        inventoryTrackersListView: null,
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isCreateInventoryTrackerSuccess: false,
        isCreateInventoryTrackerError: false,
      };

      if (!content) {
        return resetState;
      }
      const { number, size, total_elements: totalElements, total_pages: totalPages } = page;
      const { inventoryTrackersListView } = tranformToInventoryTrackerGridView(content);

      return {
        ...resetState,
        inventoryTrackersListView,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages,
          pageNumber: number,
        },
      };
    }

    case FETCH_INVENTORY_TRACKERS_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        inventoryTrackersListView: {},
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
      };
    }

    // TODO(subham): See if this field is needed.
    case INIT_CREATE_NEW_INVENTORY_TRACKER_SCREEN: {
      return { ...INITIAL_STATE };
    }

    case CREATE_INVENTORY_TRACKER_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isCreateInventoryTrackerSuccess: false,
        isCreateInventoryTrackerError: true,
      };
    }
    case CREATE_INVENTORY_TRACKER_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isCreateInventoryTrackerSuccess: true,
        isCreateInventoryTrackerError: false,
      };
    }

    case FETCH_INVENTORY_TRACKER_DETAIL.SUCCESS: {
      const temp = {};
      Object.keys(formFieldValueMap).forEach((key) => {
        const value = action.payload[key]; 
        temp[key] = value
      });

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isInventoryTrackerDetailSuccess: true,
      };
    }

    case FETCH_INVENTORY_TRACKER_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isInventoryTrackerDetailSuccess: false,
        isInventoryTrackerDetailError: true,
      };
    }

    case EDIT_INVENTORY_TRACKER_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditInventoryTrackerSuccess: false,
        isEditInventoryTrackerError: true,
      };
    }

    case EDIT_INVENTORY_TRACKER_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditInventoryTrackerSuccess: true,
        isEditInventoryTrackerError: false,
      };
    }
    case TOAST.RESET: {
      return {
        ...state,
        isCreateInventoryTrackerSuccess: false,
        isCreateInventoryTrackerError: false,
        isEditInventoryTrackerError: false,
        isEditInventoryTrackerSuccess: false,
        isInventoryTrackerDetailError: false,
      };
    }
    default:
      return state;
  }
};
