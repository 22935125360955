export const ORDERS_HEADERS = {
  'Order No#': 'order_no',
  'Billed To': 'billed_to',
  Date: 'order_date',
  Type: 'order_type',
  Total: 'total',
  'Invoice No#': 'invoiced',
  Actions: true,
};

export const ORDERS_SORT_KEYS = {};

export const ORDERS_COL_SPAN = {
  Actions: 2,
};

export const TRIPS_HEADERS = {
  'Trip ID': 'trip_no',
  'Trip Type': 'trip_type',
  Date: 'trip_date',
  'Trip Status': 'trip_status',
  'Invoice No#': 'invoiced',
  Actions: true,
};

export const TRIPS_SORT_KEYS = {};

export const TRIPS_COL_SPAN = {
  Actions: 3,
};

export const SELECT_ORDERS_MODAL_HEADERS = {
  'Order#': 'order_no',
  Customer: 'billed_to',
  Status: 'order_status',
  'Request Date': 'order_date',
  Actions: true,
};

export const SELECT_ORDERS_MODAL_SORT_KEYS = {};

export const SELECT_ORDERS_MODAL_COL_SPAN = {
  Actions: 1,
};

export const DAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const OWNER_TYPES_CODE = {
  CST:'Customer',
  EMP: 'Employee',
  SPLR:'Supplier',
  AST:'Asset'
}

export const OWNER_TYPE_CODE_MAP_TO_OWNER_TYPE = {
  Customer: 'CST',
  Employee: 'EMP',
  Supplier: 'SPLR',
  Asset: 'AST'
}