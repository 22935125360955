/*global google*/
import React, { Component } from 'react';
import { putApiAsynGoogle } from '../../Services/PostAPI';
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
} from 'react-google-maps';
let key = 'AIzaSyDXzpajYWsQgG1UIoWbjBVUpt19Q6NNopY';
class Maps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      directions: null,
    };
  }

  getLatLongOrigin = async () => {
    let originData = this.props.DirectionCenters.origin;
    console.log('originData', originData);
    let res = await putApiAsynGoogle(
      `https://maps.googleapis.com/maps/api/geocode/json?new_forward_geocoder=true&address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=${key}`
    );
    console.log('GEOM', res);
  };

  async componentDidMount() {
    let origin =
      this.props.DirectionData &&
      this.props.DirectionData.routes &&
      this.props.DirectionData.routes[0].legs[0].start_location;
    let destination =
      this.props.DirectionData &&
      this.props.DirectionData.routes &&
      this.props.DirectionData.routes[0].legs[0].end_location;

    const directionsService = new google.maps.DirectionsService();

    //const origin = { lat: 40.756795, lng: -73.954298 };
    //const destination = { lat: 41.756795, lng: -78.954298 };

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          console.log('result', result);
          this.setState({
            directions: result,
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  render() {
    const GoogleMapExample = withGoogleMap((props) => (
      <GoogleMap
        // defaultCenter={{ lat: lat, lng: lng }}
        defaultZoom={15}
      >
        <DirectionsRenderer directions={this.state.directions} />
      </GoogleMap>
    ));

    return (
      <div>
        <GoogleMapExample
          containerElement={<div style={{ height: `500px`, width: '100%' }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
}

export default Maps;
