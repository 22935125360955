export const FETCH_ATTACHMENT_FILES = {
    START: 'FETCH_ATTACHMENT_FILES_START',
    SUCCESS: 'FETCH_ATTACHMENT_FILES_SUCCESS',
    ERROR : 'FETCH_ATTACHMENT_FILES_ERROR'
  }

export const DOWNLOADING_ALL_ATTACHEMENT = {
    START: 'START',
    COMPLETE: 'COMPLETE',
    ERROR: 'ERROR' 
}

