import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';

import Vault from '../../components/Vault';
import { loadingsShow } from '../../Services/PostAPI';
import {
  initializeVaultScreen,
  fetchVault,
  deleteVaultRecord,
  setDeleteConfirmationPopupVisibility,
} from '../../Redux/Actions/vault';
import { APP_URLS } from '../../constants/urlConstants';
import { DEFAULT_SORT_KEY_FOR_VAULT } from '../../Redux/Reducers/vault';

const VaultScreen = (props) => {
  const {
    vaultListView,
    fetchVault,
    history,
    initVaultScreen,
    deleteVaultRecord,
    isFetchingGridData,
    paginationInfo,
    showPageLevelLoader,
    screenPermissions,
    isVaultDeletionError,
    isVaultDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
  } = props;

  
  useEffect(() => {
    initVaultScreen();
    fetchVault({ sortField: DEFAULT_SORT_KEY_FOR_VAULT });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editVaultRecord = (vaultId) => {
    const path = generatePath(APP_URLS.VAULT.EDIT_VAULT, { vaultId });
    history.push(path);
  };

  const createVaultRecord = () => {
    history.push(APP_URLS.VAULT.ADD_VAULT);
  };

  const vaultProps = {
    vaultListView,
    createVaultRecord,
    fetchVault,
    deleteVaultRecord,
    editVaultRecord,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isVaultDeletionError,
    isVaultDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
  };

  if (showPageLevelLoader) {
    loadingsShow('block');
    return null;
  }
  loadingsShow('none');
  return (
    <Vault {...vaultProps} />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initVaultScreen: () => dispatch(initializeVaultScreen()),
    fetchVault: (payload) => dispatch(fetchVault(payload)),
    deleteVaultRecord: (payload) => dispatch(deleteVaultRecord(payload)),
    setDeleteConfirmationPopupVisibility: (payload) => dispatch(setDeleteConfirmationPopupVisibility(payload)),
  };
};

// TODO(Subham):  This selection of data needs improvement, See if we can use UseSelector here.
const mapStateToProps = (state, ownProps) => {
  
  return {
    ...ownProps,
    vaultListView: selectVaultList(state.vaultReducer),
    isFetchingGridData: selectIsLoading(state.vaultReducer),
    showPageLevelLoader: selectIsLoadingPage(state.vaultReducer),
    paginationInfo: selectPaginationInfo(state.vaultReducer),
    isPageLevelError: selectIsPageLevelError(state.vaultReducer),
    
    isVaultDeletionError: selectIsVaultDeletionError(
      state.vaultReducer
    ),
    isVaultDeletionSuccess: selectIsVaultDeletionSuccess(
      state.vaultReducer
    ),
    shouldOpenDeleteConfirmationPopup: selectShouldOpenDeleteConfirmationPopup(
      state.vaultReducer
    ),
  };
};

const selectIsPageLevelError = (vaultReducer) =>
  vaultReducer.isPageLevelError;
const selectIsVaultDeletionError = (vaultReducer) =>
  vaultReducer.isVaultDeletionError;
const selectIsVaultDeletionSuccess = (vaultReducer) =>
  vaultReducer.isVaultDeletionSuccess;
const selectPaginationInfo = (vaultReducer) =>
  vaultReducer.paginationInfo;
const selectIsLoading = (vaultReducer) => vaultReducer.isLoading;
const selectIsLoadingPage = (vaultReducer) =>
  vaultReducer.isLoadingPage;
const selectVaultList = (vaultReducer) =>
  vaultReducer.vaultListView;
const selectShouldOpenDeleteConfirmationPopup = (vaultReducer) =>
  vaultReducer.shouldOpenDeleteConfirmationPopup;

export default connect(mapStateToProps, mapDispatchToProps)(VaultScreen);
