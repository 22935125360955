import React, { PureComponent } from 'react';
import { Button, Modal } from 'react-bootstrap';

export class MessageModal extends PureComponent {
  render() {
    const {
      isOpen,
      toggleShow,
      onConfirm,
      textMessage,
      headerTitle,
      actionLabel,
      cancelLable,
      id,
    } = this.props;

    return (
      <Modal
        backdrop="static"
        keyboard={false}
        id={id}
        show={isOpen}
        onHide={toggleShow}
      >
        <Modal.Header closeButton>{headerTitle}</Modal.Header>

        <Modal.Body>
          <p>{textMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onConfirm}>
            {actionLabel}
          </Button>
          <Button variant="secondary" onClick={toggleShow}>
            {cancelLable}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
