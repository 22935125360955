import Cookies from 'universal-cookie';
import { getApiAsyn } from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { getDecoratedUrl } from '../../common/urlService';
import { API_URL } from '../../../constants/urlConstants';

const cookies = new Cookies();

const getFetchProvinceUrl = (payload) => {
  return getDecoratedUrl({ url: API_URL.PROVINCE.FETCH_PROVINCES, payload });
};

export const fetchProvinces = async (payload) => {
  const url = getFetchProvinceUrl(payload);
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }

    return { content: result };
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
