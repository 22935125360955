import Cookies from 'universal-cookie';

import { 
  getApiAsyn,
  putApiRes
} from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { API_URL } from '../../../constants/urlConstants';
import { extractData } from '../../utils';
import { generateImageUrl, getDecoratedUrl } from '../../common/urlService';
import { asyncForEach } from '../../../utils/async';

const cookies = new Cookies();

export const fetchAssetLocation = async (payload) => {
  const url = getDecoratedUrl({
    url: API_URL.ASSET_LOCATION.FETCH_ASSET_LOCATION,
    payload,
  });
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));
    if (result?.error) {
      throw new APIError(result);
    }

    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchAssetLocationHistoryByAssetId = async (payload) => {
  const url = getDecoratedUrl({
    url: API_URL.ASSET_LOCATION.FETCH_ASSET_LOCATION_HISTORY.replace(':id',payload?.itemId),
    payload: payload?.params,
  });
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));
    if (result?.error) {
      throw new APIError(result);
    }
    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchAssetLocationByAssetLocationId = async (payload) => {
  const url = API_URL.ASSET_LOCATION.FETCH_BY_ASSET_LOCATION_ID.replace(':id', payload?.itemId);
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }
    if (result?.files?.length) {
      await asyncForEach(result.files, async (file, index) => { 
        result.files[index].s3Url = await generateImageUrl(file.url);
      });
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const editAssetLocationRecord = async ({reqBody}) => {
  const { id } = reqBody ;
  const url = API_URL.ASSET_LOCATION.EDIT_ASSET_LOCATION.replace(':id', id)
  try {
    let result = await putApiRes(url, reqBody, cookies.get('authorization'), false);
    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
}

