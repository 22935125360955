import React, { Component } from 'react';
import { Button, Col, Table, Form, Row } from 'react-bootstrap';
import {
  getApiAsyn,
  formatCurrencyToDecimal,
  loadingsShow,
} from '../../Services/PostAPI';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ReactPaginate from 'react-paginate';
import edit from '../../images/pencil.svg';
import deletebtn from '../../images/close-button.svg';
import default_menu from '../../images/default_menu.svg';
import { deleteApi, loadierShow } from '../../Services/PostAPI';
import { Popup } from '../../common/Popup';
import { CustomerFilter } from '../Filters';
import SnackbarError from '../../common/SnackbarError';
import SnackbarSuccess from '../../common/SnackbarSuccess';
const cookies = new Cookies();
let istSearchList = '';
class CustomerAndBrokers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: JSON.parse(localStorage.getItem('menuAccess')),
      PageNumber: 1,
      PageSize: 10,
      pageCount: 0,
      Data: [],
      deleteModal: false,
      itemtodelete: '',
      textMessage: 'Customer record will be deleted. Do you want to continue ?',
      filterboll: false,
      sort: {},
      formOption: {},
      sortby: '',
      orderby: '',
      listData: [],
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setWrapperRef(node) {
    this.WrapperRef = node;
  }
  handleClickOutside(event) {
    this.setState({
      bool: false,
    });
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      alert('You clicked outside of me!');
    }
  }

  getAutosuggest = async (id) => {
    let ddbrokder = [];

    if (id !== undefined && id.length > 2) {
      let res = await getApiAsyn(
        `customers?intent=suggest&begin-with=${id}`,
        cookies.get('authorization')
      );
      if (res && res.status !== false) {
        let count = res && res.page && res.page.total_elements;
        if (count > 0) {
          this.setState({
            bool: true,
          });
          ddbrokder = res._embedded.content;
          ddbrokder = ddbrokder.map((item) => ({
            name: item.name,
            value: item.id,
          }));
          this.setState({
            listData: ddbrokder,
          });
        } else {
          this.setState({
            listData: [],
          });
        }
      } else {
        this.setState({
          listData: [],
        });
      }
    }
  };

  setAutosuggestId = (id, name) => {
    this.setState(
      {
        sort: {
          ...this.state.sort,
          ids: name,
          id: id,
        },
        bool: false,
      },
      () => {
        this.getFilterCustomer();
      }
    );
  };

  handleFilter = () => {
    this.setState((prevState) => {
      let { filterboll, sort } = prevState;
      filterboll = prevState.filterboll === false ? true : false;
      if (filterboll === false) {
        sort = {};
        this.getCustomerData();
      }
      return { filterboll, sort };
    });
  };
  handleChangeGenChecked = (event) => {
    const { sort } = this.state;
    let { name, checked } = event.target;
    sort[name] = checked;
    this.setState({
      [sort]: sort,
    });

    this.getFilterCustomer();
    console.log(sort);
  };
  handleChangeGenCustomer = (event) => {
    const { sort } = this.state;
    let { name, value } = event.target;
    sort[name] = value;
    this.setState({
      [sort]: sort,
    });

    if (name === 'ids') {
      this.getAutosuggest(value);
    }
    if (name === 'ids' && value === '') {
      this.setState(
        (prevState) => {
          let { sort } = prevState;
          sort.id = '';
          sort.ids = '';
          return { sort };
        },
        () => {
          this.getFilterCustomer();
        }
      );
    }
  };

  handleChangeGen = (event) => {
    const { sort } = this.state;
    let { name, value } = event.target;
    sort[name] = value;
    this.setState({
      [sort]: sort,
    });

    this.getFilterCustomer();
    console.log(sort);
  };
  sortByIdName = (id) => {
    this.setState(
      (prevState) => {
        let { sortby, orderby } = prevState;
        sortby = id;
        orderby = prevState.orderby === 'asc' ? 'desc' : 'asc';
        return { sortby, orderby };
      },
      () => {
        this.getCustomerData();
      }
    );
  };

  handleSortDropdonw = (val, name) => {
    const { sort } = this.state;
    sort[name] = val;
    this.setState({
      [sort]: sort,
    });

    this.getFilterCustomer();
    console.log(sort);
  };

  handleSortDate = (date, name) => {
    const { sort } = this.state;
    sort[name] = date;
    this.setState({
      ...sort,
      [sort]: sort,
    });
    this.getFilterCustomer();
    console.log(sort);
  };
  handelMessage = (Message, Boll) => {
    const { formOption } = this.state;
    formOption[Boll] = true;
    formOption['Message'] = Message;
    this.setState(
      {
        formOption: formOption,
      },
      function () {
        setTimeout(
          function () {
            formOption[Boll] = false;
            this.setState({
              formOption: formOption,
            });
          }.bind(this),
          3000
        );
      }
    );
  };
  getFilterCustomer = async () => {
    const { PageNumber, PageSize, sort } = this.state;
    if (sort === undefined) {
      this.handelMessage('Please select customer to filter', 'errorBoll');
      return false;
    } else {
      let id = sort.id !== undefined && sort.id !== '' ? '&id=' + sort.id : '';

      id +=
        sort.phone !== undefined && sort.phone !== ''
          ? '&phone=' + sort.phone
          : '';
      id +=
        sort.emailId !== undefined && sort.emailId !== ''
          ? '&email=' + sort.emailId
          : '';
      id +=
        sort.status !== undefined && sort.status !== ''
          ? '&status=' + sort.status
          : '';
      id +=
        sort.status !== undefined && sort.status !== '-1'
          ? '&status=' + sort.status
          : sort.status === -1
          ? ''
          : '';

      id += sort.broker !== undefined ? '&broker=' + sort.broker : '';
      if (id !== '') {
        let res = await getApiAsyn(
          `customers?intent=search&paged=true&page=${PageNumber}&size=${PageSize}${id}`,
          cookies.get('authorization')
        );

        if (!res.error) {
          console.log('FL D', res);
          istSearchList = res.page.total_elements;
          if (istSearchList > 0) {
            this.setState({
              Data: res._embedded.content,
              pageCount: istSearchList / PageSize,
            });
          } else {
            this.setState({
              Data: [],
            });
          }
        }
      } else {
        this.getCustomerData();
      }
    }
  };

  handleDeleteModal = (id) => {
    this.setState((prevState) => {
      let { deleteModal, itemtodelete } = prevState;
      deleteModal = prevState.deleteModal === false ? true : false;
      itemtodelete = id;
      return { deleteModal, itemtodelete };
    });
  };

  handleRecordDelete = () => {
    loadierShow('block');
    let { itemtodelete } = this.state;
    deleteApi(`customers/${itemtodelete}`, cookies.get('authorization')).then(
      (res) => {
        if (res.status === 200) {
          this.handelMessage('Record deleted successfully', 'legalBoll');
          this.handleDeleteModal();
          this.getCustomerData();
        }
      }
    );
  };
  getCustomerData = async () => {
    loadierShow('block');
    const { PageNumber, PageSize, sortby, orderby } = this.state;

    let res = await getApiAsyn(
      `customers?paged=true&page=${PageNumber}&size=${PageSize}&fields=id,name,phone,tax_no,crm_id,due_amt,rate,order_cnt,invoice_cnt&sort=${sortby},${orderby}`,
      cookies.get('authorization')
    );

    console.log(res);

    if (res && res.status !== false) {
      istSearchList = res && res.page && res.page.total_elements;
      if (istSearchList > 0) {
        loadierShow('none');
        this.setState({
          Data: res._embedded.content,
          pageCount: istSearchList / PageSize,
        });
      } else {
        loadierShow('none');
        this.setState({
          Data: [],
          pageCount: 0,
        });
      }
    } else {
      loadierShow('none');
    }
  };

  handleResultperpage = (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState(
      {
        [fieldName]: fleldVal,
      },
      () => {
        this.getCustomerData();
      }
    );
  };

  handlePageClick = (data) => {
    let selected = data.selected + 1;
    let PageNumber = selected;

    this.setState({ PageNumber: PageNumber }, () => {
      this.getCustomerData();
    });
  };
  componentWillUnmount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }
  async componentDidMount() {
    await loadingsShow('block');
    await this.getCustomerData();
    document.addEventListener('click', this.handleClickOutside, true);
    await loadingsShow('none');
  }
  render() {
    const {
      PageNumber,
      PageSize,
      pageCount,
      Data,
      deleteModal,
      textMessage,
      filterboll,
      sort,
      formOption,
      sortby,
      bool,
      listData,
      orderby,
      access,
    } = this.state;
    return (
      <div>
        <SnackbarError
          errorBoll={formOption.errorBoll}
          errorMessage={formOption.Message}
        />

        <SnackbarSuccess
          legalBoll={formOption.legalBoll}
          successMessage={formOption.Message}
        />
        <div className="paddingbox">
          <div className="containerbox">
            <div className="title">
              <h3>Customer and Broker</h3>

              <div className="rightbtn">
                <Button
                  type="button"
                  onClick={this.handleFilter}
                  className="default-button"
                >
                  {filterboll === false ? 'Filter' : 'Reset Filter'}
                </Button>
                <Link to="/customers/customer/add-new">
                  {' '}
                  <Button className="primary-button">+ NEW</Button>
                </Link>
              </div>
            </div>
            <Row>
              <div className="orderFilter">
                {filterboll ? (
                  <CustomerFilter
                    customerId="ids"
                    CustomerIdonChange={this.handleChangeGenCustomer}
                    CustomerIdvalue={sort.ids}
                    bool={bool}
                    getAutosuggest={listData}
                    onClick={(id, name) => this.setAutosuggestId(id, name)}
                    customerPhone="phone"
                    CustomerPhoneonChange={this.handleChangeGen}
                    CustomerPhoneValue={sort.phone}
                    emailId="emailId"
                    emailonChange={this.handleChangeGen}
                    emailvalue={sort.emailId}
                    CustomerStatusname="status"
                    CustomerStatusvalue={sort.status}
                    CustomerStatusonChange={(val) =>
                      this.handleSortDropdonw(val, 'status')
                    }
                    BrokerNane="broker"
                    BrokeronChange={this.handleChangeGenChecked}
                    Brokervalue={sort.broker}
                  />
                ) : null}
              </div>
            </Row>
            {Data.length > 0 ? (
              <Row>
                <Col md={12}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th
                          className={
                            sortby === 'id' && orderby === 'asc'
                              ? 'asscending'
                              : 'desending'
                          }
                          onClick={() => this.sortByIdName('id')}
                        >
                          ID
                        </th>
                        <th
                          className={
                            sortby === 'name' && orderby === 'asc'
                              ? 'asscending'
                              : 'desending'
                          }
                          onClick={() => this.sortByIdName('name')}
                        >
                          Name
                        </th>
                        <th>Phone Number</th>
                        <th>Tax Number</th>
                        <th>CRM ID</th>
                        {/* <th>Amount Due </th> */}
                        <th>Rate </th>
                        <th>Order</th>
                        <th>Invoice</th>
                        <th colSpan="3">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Data.map((item) => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                          <td>
                            {item.phone != null && item.phone !== ''
                              ? item.phone
                              : 'N/A'}
                          </td>
                          <td>
                            {item.tax_no != null && item.tax_no !== ''
                              ? item.tax_no
                              : 'N/A'}
                          </td>
                          <td>
                            {item.crm_id != null && item.crm_id !== ''
                              ? item.crm_id
                              : 'N/A'}
                          </td>
                          {/* <td>
                            {item.due_amt !== 0
                              ? formatCurrencyToDecimal(item.due_amt)
                              : formatCurrencyToDecimal(item.due_amt)}
                          </td> */}
                          <td>
                            {item.rate ? formatCurrencyToDecimal(item.rate) : '-'}
                          </td>
                          <td>
                            {item.order_cnt != null ? item.order_cnt : 'N/A'}
                          </td>
                          <td>
                            {item.invoice_cnt != null
                              ? item.invoice_cnt
                              : 'N/A'}
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: `/customers/customer/${item.id}/edit`,
                              }}
                            >
                              {' '}
                              <img src={edit} alt="Edit" />
                            </Link>
                          </td>
                          <td>
                            {access &&
                            access.customerMenu &&
                            access.customerMenu.delete === true ? (
                              <img
                                className="delete"
                                onClick={() => this.handleDeleteModal(item.id)}
                                src={deletebtn}
                                alt="Delete"
                              />
                            ) : (
                              <img
                                className="delete drop"
                                src={deletebtn}
                                alt="Delete"
                              />
                            )}
                          </td>
                          <td>
                            <img src={default_menu} alt="Menu" />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <div className="paginationData">
                    <div className="leftpage">
                      <div className="showing">
                        <span>Showing: </span>
                        {PageSize * PageNumber + 1 - PageSize} -
                        {PageSize > istSearchList
                          ? istSearchList
                          : PageSize * PageNumber > istSearchList
                          ? istSearchList
                          : PageSize * PageNumber}{' '}
                        <span> of</span> {istSearchList} <span>entries. </span>{' '}
                      </div>

                      <div className="rowperpage">
                        <Form.Group>
                          <Form.Label>Showing rows:</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={this.handleResultperpage}
                            name="PageSize"
                            value={PageSize}
                          >
                            <option>5</option>
                            <option>10</option>
                            <option>15</option>
                            <option>20</option>
                            <option>50</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                    </div>

                    <ReactPaginate
                      previousLabel={'previous'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />

                    <div className="clr"></div>
                  </div>
                </Col>
              </Row>
            ) : (
              <div className="norecord">No record found</div>
            )}
          </div>
        </div>

        <Popup
          isOpen={deleteModal}
          toggle={this.handleDeleteModal}
          onConfirm={this.handleRecordDelete}
          textMessage={textMessage}
          toggleShow={this.handleDeleteModal}
        />
        {/* <Modal  backdrop="static"
        keyboard={false} id="deleteRecord" show={deleteModal} onHide={this.handleDeleteModal}>
            <Modal.Header closeButton>
              <Modal.Title><span>Delete - </span> Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
<p>You are about to delete the saved record .<br/>
This cannot be undone.</p>

            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleDeleteModal}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleRecordDelete}>
               OK
              </Button>
            </Modal.Footer>
</Modal> */}
      </div>
    );
  }
}

export default CustomerAndBrokers;
