import {
  INIT_COMPLIANCES,
  FETCH_COMPLIANCES_LIST_START,
  FETCH_COMPLIANCES_LIST_SUCCESS,
  FETCH_COMPLIANCES_LIST_ERROR,
  DELETE_COMPLIANCE_RECORD_ERROR,
  DELETE_COMPLIANCE_RECORD_SUCCESS,
  DELETE_COMPLIANCE_RECORD_START,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_COMPLIANCE_SCREEN,
  CREATE_COMPLIANCE_RECORD,
  EDIT_COMPLIANCE_RECORD,
  FETCH_COMPLIANCE_DETAIL,
  TOAST
} from '../../../constants/action-types/compliance';
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from '../../../utils/table/gridUtils';
import { buildCompanyName } from '../../../utils/string';

export const COMPLIANCE_DATE_DATA_KEY = 'type.code';
export const COMPLIANCE_DUE_DATE = 'due_date';
export const COMPLIANCE_DATE = 'date';
export const COMPLIANCE_START_DATE = 'start_date';
export const COMPLIANCE_END_DATE = 'end_date';
const isDateField = (key) => {
  const dateFields = [COMPLIANCE_DUE_DATE, COMPLIANCE_DATE, COMPLIANCE_START_DATE, COMPLIANCE_END_DATE];
  return dateFields.includes(key);
};

const tranformToComplianceGridView = (complianceData) => {
  const rows = complianceData.map((compliance) => {
    const {
      id,
      type,
      cat,
      driver,
      object_type,
      desc,
      due_date,
      cmplnc_status
    } = compliance;

    const transformedValues = {
      id,
      type: type?.name,
      typeCode: type?.code,
      cat: cat?.name,
      driver: driver,
      object_type: buildCompanyName(object_type),
      desc,
      due_date,
      cmplnc_status: cmplnc_status?.name
    };
    const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });

  const columns = [
    {
      colLabel: 'Column Id',
      dataKey: 'id',
      extraProps: setColumnDefaultExtraProps({
        hidden: true,
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Compliance',
      dataKey: 'type',
      extraProps: setColumnDefaultExtraProps({
        sortField: 'type.code',
        shouldFilter: true,
      }),
    },
    {
      colLabel: 'Category',
      dataKey: 'cat',
      extraProps: setColumnDefaultExtraProps({
        sortField: 'cat.name',
        shouldFilter: true,
      }),
    },
    {
      colLabel: 'Name',
      dataKey: 'object_type',
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: false, // will enable once confirmation from tirath about filter field.
        sortField: 'driver',
      }),
    },
    {
      colLabel: 'Description',
      dataKey: 'desc',
      extraProps: setColumnDefaultExtraProps({ shouldFilter: true }),
    },
    {
      colLabel: 'Due Date',
      dataKey: 'due_date',
      extraProps: setColumnDefaultExtraProps({
        isDateField: true,
        shouldFilter: true, // will be enabled once date filter MR approved EZOR 42
      }),
    },
    {
      colLabel: 'Status',
      dataKey: 'cmplnc_status',
      extraProps: setColumnDefaultExtraProps({
        isSortable: false,
        shouldFilter: true,
      }),
    }
  ];

  return {
    compliancesListView: { columns, rows },
  };
};
const formFieldValueMap = {
  id: 0,
  source_id: 3,
  source_type: 'CMPC',
  province: '',
  type: '',
  cat: '',
  desc: '',
  [COMPLIANCE_DUE_DATE]: null,
  impct_rt: '',
  impct_rslt: '',
  driver: '',
  cmplnc_status: '',
  items: [
    {
      id: 0,
      [COMPLIANCE_DATE]: null,
      item_desc: '',
      [COMPLIANCE_START_DATE]: null,
      [COMPLIANCE_DUE_DATE]: null,
      [COMPLIANCE_END_DATE]: null,
      item_status: '',
      status: true,
      province: '',
      rem_req: 'yes',
      rem_action: '',
      ref_no: ''
    }
  ]
};

const INITIAL_STATE = {
  compliancesListView: {},
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateComplianceSuccess: false,
  isCreateComplianceError: false,
};

export const compliancesReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case INIT_COMPLIANCES:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case FETCH_COMPLIANCES_LIST_START:
    case CREATE_COMPLIANCE_RECORD.START:
    case DELETE_COMPLIANCE_RECORD_START:
    case FETCH_COMPLIANCE_DETAIL.START:
    case EDIT_COMPLIANCE_RECORD.START:
      return { ...state, isLoading: true };
    case FETCH_COMPLIANCES_LIST_SUCCESS: {
      const { page, content } = action.payload;
      let resetState = {
        compliancesListView: null,
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isCreateComplianceSuccess: false,
        isCreateComplianceError: false,
      };

      if (!content) {
        return resetState;
      }
      const { number, size, total_elements: totalElements, total_pages: totalPages } = page;
      const { compliancesListView } = tranformToComplianceGridView(content);

      return {
        ...resetState,
        compliancesListView,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages,
          pageNumber: number,
        },
      };
    }

    case FETCH_COMPLIANCES_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        compliancesListView: {},
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isComplianceDeletionSuccess: false,
        isComplianceDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_COMPLIANCE_RECORD_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isComplianceDeletionSuccess: false,
        isComplianceDeletionError: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_COMPLIANCE_RECORD_SUCCESS: {
      return {
        ...state,
        isComplianceDeletionSuccess: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
      const shouldOpenDeleteConfirmationPopup = action.payload;
      return {
        ...state,
        shouldOpenDeleteConfirmationPopup,
        isComplianceDeletionError: false,
        isComplianceDeletionSuccess: false,
      };
    }

    // TODO(subham): See if this field is needed.
    case INIT_CREATE_NEW_COMPLIANCE_SCREEN: {
      return { ...INITIAL_STATE };
    }

    case CREATE_COMPLIANCE_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isCreateComplianceSuccess: false,
        isCreateComplianceError: true,
      };
    }
    case CREATE_COMPLIANCE_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isCreateComplianceSuccess: true,
        isCreateComplianceError: false,
      };
    }

    case FETCH_COMPLIANCE_DETAIL.SUCCESS: {
      const temp = {};
      Object.keys(formFieldValueMap).forEach((key) => {
        const value = action.payload[key];
        temp[key] = value && isDateField(key) ? new Date(value) : value;
      });

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isComplianceDetailSuccess: true,
      };
    }

    case FETCH_COMPLIANCE_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isComplianceDetailSuccess: false,
        isComplianceDetailError: true,
      };
    }

    case EDIT_COMPLIANCE_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditComplianceSuccess: false,
        isEditComplianceError: true,
      };
    }

    case EDIT_COMPLIANCE_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditComplianceSuccess: true,
        isEditComplianceError: false,
      };
    }
    case TOAST.RESET: {
      return {
        ...state,
        isCreateComplianceSuccess: false,
        isCreateComplianceError: false,
        isEditComplianceError: false,
        isEditComplianceSuccess: false,
        isComplianceDetailError: false,
      };
    }
    default:
      return state;
  }
};
