import React, { Component } from 'react';
import { Button, Modal, Col, Form, Row } from 'react-bootstrap';
import { sendSMSPost, getSMSPost } from '../../Services/PostAPI';
export class SendMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      UIValue: {
        receivernumbers: this.props.driverphone,
      },
    };
  }
  handleSend = async () => {
    const { UIValue } = this.state;
    console.log(UIValue);
    let reqData = {
      actorName: '',
      actorNumber: '',
      groupNames: [],
      receiverNumbers: [UIValue.receivernumbers],
      smsText: UIValue.smstext,
    };
    let res = await sendSMSPost(
      `sms/send`,
      reqData,
      localStorage.getItem('smsAccessToken')
    );
    if (res && res.smsId !== undefined) {
      this.toggleShow();
    }
    console.log(res);
  };
  handleChangeGen = (event) => {
    const { UIValue } = this.state;
    UIValue[event.target.name] = event.target.value;
    this.setState({
      [UIValue]: UIValue,
    });
  };
  getSMS = async () => {
    let res2 = await getSMSPost(
      `sms/view?pageNumber=1`,
      localStorage.getItem('smsAccessToken')
    );
    console.log('sent SMS', res2);
  };
  componentDidMount() {
    this.getSMS();
  }
  render() {
    const { isOpen, toggleShow } = this.props;
    const { UIValue, errors } = this.state;
    return (
      <Modal
        backdrop="static"
        keyboard={false}
        id="delModal"
        show={isOpen}
        onHide={toggleShow}
      >
        <Modal.Header closeButton>Send Message</Modal.Header>

        <Modal.Body>
          <Row className="mrb20">
            <Col md={12}>
              <Form.Group
                className={
                  errors['receivernumbers'] ? 'errorshows' : 'errorshides'
                }
                as={Row}
              >
                <Form.Label column sm="3" className="text-right">
                  Mobile No
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    name="receivernumbers"
                    value={UIValue.receivernumbers}
                    onChange={this.handleChangeGen}
                  />

                  <span
                    className={
                      errors['receivernumbers'] ? 'errorshow' : 'errorshide'
                    }
                  >
                    {errors['receivernumbers']}
                  </span>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mrb20">
            <Col md={12}>
              <Form.Group
                className={errors['smstext'] ? 'errorshows' : 'errorshides'}
                as={Row}
              >
                <Form.Label column sm="3" className="text-right">
                  Message
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    name="smstext"
                    type="text"
                    value={UIValue.smstext}
                    onChange={this.handleChangeGen}
                  />

                  <span
                    className={errors['smstext'] ? 'errorshow' : 'errorshide'}
                  >
                    {errors['smstext']}
                  </span>
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.handleSend}>
            Send
          </Button>
          <Button variant="secondary" onClick={toggleShow}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
