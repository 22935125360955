/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row, Container } from 'react-bootstrap';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import EzControlledAutoSuggestWithFloatingLabel from '../../../common/EzForm/EzControlledAutoSuggest/WithFloatingLabel';
import EzControlledInputWithFloatingLabel from '../../../common/EzForm/EzControlledInput/WithFloatingLabel';
import EzControlledCalendarWithFloatingLabel from '../../../common/EzForm/EzControlledCalendar/WithFloatingLabel';
import EzFileUpload from '../../../common/EzFileUpload';
import './index.css';
import { EzToast, TOAST_SEVERITY } from '../../../common/EzToast';
import { getFormBaseValidationRule } from '../../../utils/form/form-utils';
import CreateDocumentType from './CreateDocumentType';
import EzControlledDropDowntWithFloatingLabel from '../../../common/EzForm/EzControlledDropDown/WithFloatingLabel';

const CreateDocument = (props) => {
  
  const {
    documentTypes,
    searchOwnerTypes,
    ownerTypes,
    owners,
    searchOwners,
    formFieldValueMap,
    createDocument,
    editDocument,
    navigateToDocumentSummaryScreen,
    isCreateDocumentSuccess,
    isEditDocumentSuccess,
    isEdit,
    isDocumentDetailSuccess,
    documentId,
    setIsformDirty,
    fetchDocumentTypes
  } = props
  
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [ isCreatePopupVisible, setIsCreatePopupVisible] = useState(false);
  const [ isEditPopupVisible, setIsEditPopupVisible] = useState(false);
  const removedFiles = [];
  
  const onSubmit = (data) => {
    delete data.files;
    const {type ,ownr_typ, ownr_id} = data || {};
    
    const dataPayload = {
      files: removedFiles,
      type,
      "date": new Date(data?.date)?.getTime(),
      "info": data?.info,
      "ref_no": data?.ref_no,
      "ownr_typ": ownr_typ?.extraData?.key || ownerTypes.find(ownerTyp => ownerTyp.label === ownr_typ)?.extraData?.key, 
      "ownr_id": ownr_id?.extraData?.id || owners?.find(owner => owner.label === ownr_id)?.extraData?.id,
      "owner": ownr_id?.extraData?.id || owners?.find(owner => owner.label === ownr_id)?.extraData?.id
  }

      if (isEdit) {
      editDocument({
        documentId,
        data: dataPayload,
        ...(attachedFiles ? { files: attachedFiles } : {}),
      });
    } else {
      createDocument({
        data: dataPayload,
        files: attachedFiles,
      });
   }
  };

  const {
    control,
    formState: { errors ,isDirty},
    watch,
    handleSubmit,
    reset,
    resetField
  } = useForm({defaultValues:useMemo(()=>formFieldValueMap, [formFieldValueMap])});

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  useEffect(() => {
    if (isEditDocumentSuccess || isCreateDocumentSuccess) {
      reset();
      //navigateToDocumentSummaryScreen()
    }
  }, [isCreateDocumentSuccess, isEditDocumentSuccess]);

  useEffect(() => {
    // if single accident is fetched successfully
    if (isDocumentDetailSuccess) {
      reset({
        ...formFieldValueMap,
        ownr_typ:formFieldValueMap?.ownr_typ?.name,
        type: formFieldValueMap?.type?.id
      });
      setAttachedFiles(formFieldValueMap?.files); 
    }
  }, [isDocumentDetailSuccess]);
  const typeWatch = watch('type')
  useEffect(()=> setIsformDirty(isDirty),[isDirty]);

  const ownr_typ = watch('ownr_typ');
  useEffect(()=> isDirty && resetField('ownr_id',{defaultValue:''}),[ownr_typ]);  

  const getOwnerType = () =>{
    const ownerType =   ownr_typ?.label || ownr_typ;
    return ownerType;
  }

  useEffect(()=> {searchOwners(getOwnerType(),{ query:'' })},[ownr_typ]);
      
  const [showErrorToast, setErrorToast] = useState(false);
  const getToastProps = () => {
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle: 'Please fill all mandatory fields',
      shouldShowToast: true,
    };
  };
  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrorToast(true);
    }
  }, [errors]);

  const renderPageHeaderSection = () => {
    const breadCrumbs = [
      { label: 'Document' },
      { label: isEdit ? 'Edit Document' : 'Create Document' },
    ];

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };

    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />
        <div className="rightbtn">
          <Button
            tooltip={'Back to document summary'}
            tooltipOptions={{ position: 'left' }}
            label={''}
            icon={'pi pi-arrow-left'}
            className={`mr-3 p-button-rounded p-button-outlined p-button-info`}
            onClick={navigateToDocumentSummaryScreen}
          />
        </div>
      </div>
    );
  };

  const renderdocumentTypes = () => {
    const name = 'type';
    return ( 
    <span style={{ display: "flex" }}>
        <EzControlledDropDowntWithFloatingLabel
          name={name}
          control={control}
          rules = {getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
          options={documentTypes}
          label={`Document Type`}
          isError={errors[name]}
          errorMsg={getFormErrorMessage(name)}
          labelClassName={'ml-2'}
      />
      <Button
        icon="pi pi-plus"
        type="button"
        className={`ml-2 p-button-sm p-button-raised p-button-rounded p-button-icon-only`}
        onClick={() => {
          setIsCreatePopupVisible(true);
        }}/>
        <Button
        icon="pi pi-pencil"
        type="button"
        className={`ml-2 p-button-sm p-button-raised p-button-rounded p-button-icon-only`}
        onClick={() => {
          setIsEditPopupVisible(true);
        }}/>
  </span>
    );
  };

  const renderOwnerTypes = () => {
    const name = 'ownr_typ';
    
    return (
      <EzControlledAutoSuggestWithFloatingLabel
        name={name}
        control={control}
        suggestions={ownerTypes}
        completeMethod={({ query }) => searchOwnerTypes({ query })}
        label={`Belongs To`}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderOwners = () => {
    const name = 'ownr_id';
    
    return (
      <EzControlledAutoSuggestWithFloatingLabel
           name={name}
           control={control}
           suggestions={owners}
           completeMethod={({ query }) => (searchOwners(getOwnerType(),{ query }))}
           label={`Belongs to ${getOwnerType()}`}
           isError={errors[name]}
           errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderDate = () => {
    const name = 'date';

    return (
      <EzControlledCalendarWithFloatingLabel
        name={name}
        showTime={true}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        control={control}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label={'Date'}
      />
    );
  };

  const renderInfo = () => {
    const name = 'info';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Enter Info'}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderRefernceNumber = () => {
    const name = 'ref_no';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Enter reference number'}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderFileUploader = () => {
    const onSelectFile = (e, field) => {
      setAttachedFiles([...e.files]);
    };

    const onRemoveFile = (fileTobeRemoved) => {
      removedFiles.push(fileTobeRemoved)
    }
    
    return (
      <EzFileUpload
        files={attachedFiles}
        onSelectFile={onSelectFile}
        onRemoveFile={onRemoveFile}
      />
    );
  };

  return (
    <> 
    <div className="paddingbox">
      <div
        className={classNames('containerboxform', { 'p-disabled': false })}
      >
        {renderPageHeaderSection()}
        <div
          className="form-demo"
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
        >
          <div className="card" style={{ background: '#f3faf4BF' }}>
            {showErrorToast && <EzToast {...getToastProps() } />}
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <Container fluid>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderdocumentTypes()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderOwnerTypes()}</Col>
                  <Col md="1" />
                  <Col md="3">{getOwnerType() && renderOwners()}</Col>
                </Row>
                <br></br>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderDate()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderInfo()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderRefernceNumber()}</Col>
                </Row>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3"></Col>
                  <Col md="1" />
                  <Col md="7">{renderFileUploader()}</Col>
                </Row>
              </Container>
              <div
                className={'make-flex mt-3 mr-2'}
                style={{ flexDirection: 'row-reverse', background: 'white' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      label="Cancel"
                      onClick={navigateToDocumentSummaryScreen}
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      type="reset"
                      label="Reset"
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '170px' }}>
                    <Button
                      loading={false}
                      icon={'pi pi-plus'}
                      type="submit"
                      label={isEdit ? 'Edit Document' : 'Create Document'}
                      className="mt-2 p-button-raised"
                    />    
                  </div>
                </div>
              </div>
            </form>
            {(isCreatePopupVisible || isEditPopupVisible) &&
              <CreateDocumentType
                documentTypes={documentTypes}
                type={typeWatch}
                isEditMode={isEditPopupVisible}
                onHide={() => {
                  fetchDocumentTypes()
                  setIsCreatePopupVisible(false)
                  setIsEditPopupVisible(false)
                  }} />}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CreateDocument;
