export const INIT_DOCUMENTS = 'INIT_DOCUMENTS';
export const FETCH_DOCUMENTS_LIST_START = 'FETCH_DOCUMENTS_LIST_START';
export const FETCH_DOCUMENTS_LIST_SUCCESS = 'FETCH_DOCUMENTS_LIST_SUCCESS';
export const FETCH_DOCUMENTS_LIST_ERROR = 'FETCH_DOCUMENTS_LIST_ERROR';

export const DELETE_DOCUMENT_RECORD_START = 'DELETE_DOCUMENT_RECORD_START';
export const DELETE_DOCUMENT_RECORD_SUCCESS = 'DELETE_DOCUMENT_RECORD_SUCCESS';
export const DELETE_DOCUMENT_RECORD_ERROR = 'DELETE_DOCUMENT_RECORD_ERROR';

export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';

export const INIT_CREATE_NEW_DOCUMENT_SCREEN =
  'INIT_CREATE_NEW_DOCUMENT_SCREEN';

export const CREATE_DOCUMENT_RECORD = {
  START: 'CREATE_DOCUMENT_RECORD_START',
  SUCCESS: 'CREATE_DOCUMENT_RECORD_SUCCESS',
  ERROR: 'CREATE_DOCUMENT_RECORD_ERROR',
};

export const EDIT_DOCUMENT_RECORD = {
  START: 'EDIT_DOCUMENT_RECORD_START',
  SUCCESS: 'EDIT_DOCUMENT_RECORD_SUCCESS',
  ERROR: 'EDIT_DOCUMENT_RECORD_ERROR',
};

export const FETCH_DOCUMENT_DETAIL = {
  START: 'FETCH_DOCUMENT_DETAIL_START',
  SUCCESS: 'FETCH_DOCUMENT_DETAIL_SUCCESS',
  ERROR: 'FETCH_DOCUMENT_DETAIL_ERROR',
};

export const CREATE_DOCUMENT_TYPE = {
  START: 'CREATE_DOCUMENT_TYPE_START',
  SUCCESS: 'CREATE_DOCUMENT_TYPE_SUCCESS',
  ERROR: 'CREATE_DOCUMENT_TYPE_ERROR'
}

export const TOAST = {
  RESET: 'RESET_TOAST',
};
