import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Col, Row, Container } from 'react-bootstrap';
import { Button } from 'primereact/button';

import EzDialog from '../../../common/EzDialog';
import EzControlledInputWithFloatingLabel from '../../../common/EzForm/EzControlledInput/WithFloatingLabel';
import EzControlledDropDowntWithFloatingLabel from '../../../common/EzForm/EzControlledDropDown/WithFloatingLabel';
import { getFormBaseValidationRule } from '../../../utils/form/form-utils';
import { createAssetMake, createAssetModal } from '../../../Redux/Actions/assets';
import { connect } from 'react-redux';

export const  DIALOG_VIEW_MODE = {
  MAKE: 'make',
  MODEL: 'model'
}

const CreateMakeAndModalPopup = (props) => {
  const { onHide, createAssetMake, createAssetModal, makeList, dialogView, selectedMake } = props;
  console.log(dialogView, 'dialogView');
  
  const isMakeView = () => dialogView === DIALOG_VIEW_MODE.MAKE;
  const isModelView = () => dialogView === DIALOG_VIEW_MODE.MODEL;

  const formDefaultValues = isModelView() ? 
    { defaultValues: { make: selectedMake } } : {}
  
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm(formDefaultValues);

  const getFormErrorMessage = (name) => {
    return ( errors[name] && <small className="p-error">{errors[name].message}</small> );
  };

  const onSubmitMake = async (data) => {
    const { name, desc } = data || {};
    const dataPayload = { name, desc }
    createAssetMake(dataPayload);
    onHide &&  onHide();
};

const onSubmitModal = async (data) => {
  const { name, desc, make } = data || {};
  const dataPayload = { name, desc, make }
  console.log(dataPayload);
  createAssetModal(dataPayload);
  onHide &&  onHide();
};



  const renderFloatingInput = (name, label, rules = getFormBaseValidationRule().DEFAULT.VALIDATION_RULE) => {
    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={label}
        rules={rules}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderFloatingDropDown = (name, label, options) => {

    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={options}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label={label}
        labelClassName={'ml-2'}
      />
    );
  };
 const getTitle = () => isMakeView() ? 'Create new make' : 'Create new model';

  return (
    <EzDialog  
      width={isMakeView() ? '50vw' : '70vw'}
      header = {() => <div>{getTitle()}</div> }
      onHide = {onHide}
      maximizable = {false}>
      <div className="form-demo" style={{}}>
          <div className="card" style={{ background: '#f3faf4BF' }}>
          {isMakeView() &&<form onSubmit={handleSubmit(onSubmitMake)} className="p-fluid">
              <Container fluid>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="4">{renderFloatingInput('name', 'Make Name')}</Col>
                  <Col md="5">{renderFloatingInput('desc', 'Make Description', getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE)}</Col>
                  <Col md="3">
                    <Button
                      type="submit"
                      label="Add Make"
                      className="p-button-raised"
                    /></Col>
                </Row>
              </Container>
            </form>}

            {isModelView() && <form onSubmit={handleSubmit(onSubmitModal)} className="p-fluid">
              <Container fluid>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderFloatingDropDown('make', 'Make', makeList)}</Col>
                  <Col md="3">{renderFloatingInput('name', 'Model Name')}</Col>
                  <Col md="4">{renderFloatingInput('desc', 'Modal Description', getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE)}</Col>
                  <Col md="2">
                    <Button
                      type="submit"
                      label="Add Model"
                      className="p-button-raised"
                    /></Col>
                </Row>
              </Container>
            </form>}
          </div>
    </div>
</EzDialog>)
}

CreateMakeAndModalPopup.defaultProps = {
  dialogView:'make',
}

CreateMakeAndModalPopup.propTypes = {
  onHide: PropTypes.func.isRequired,
  makeList: PropTypes.array,
  selectedMake: PropTypes.any,
  dialogView: PropTypes.oneOf([DIALOG_VIEW_MODE.MAKE, DIALOG_VIEW_MODE.MODEL]).isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return {
    createAssetMake: (payload) => dispatch(createAssetMake(payload)),
    createAssetModal: (payload) => dispatch(createAssetModal(payload)),
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    state
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMakeAndModalPopup);