import { DEFAULT_TOAST_LIFE } from '../../../common/EzToast';
import {
  INIT_ACCIDENTS,
  FETCH_ACCIDENTS_LIST_ERROR,
  FETCH_ACCIDENTS_LIST_START,
  FETCH_ACCIDENTS_LIST_SUCCESS,
  DELETE_ACCIDENT_RECORD_START,
  DELETE_ACCIDENT_RECORD_SUCCESS,
  DELETE_ACCIDENT_RECORD_ERROR,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_ACCIDENT_SCREEN,
  CREATE_ACCIDENT_RECORD,
  EDIT_ACCIDENT_RECORD,
  FETCH_ACCIDENT_DETAIL,
  TOAST,
} from '../../../constants/action-types/accidents';
import { accidentsService } from '../../../Services';
import { goBack } from '../../../utils/gotoback';
import { fetchEmployees } from '../../Actions/employees';
import {
  attachmentsFilesFetchSuccess ,
  attachmentsFilesFetchStart,
  downloadingAllAttachmentStart,
  downloadingAllAttachmentComplete,
  attachmentsFilesFetchError,
  downloadingAllAttachmentError
} from '../common/files'

export const initializeAccidentScreen = () => ({ type: INIT_ACCIDENTS });

export const accidentsFetchStart = (payload) => ({
  type: FETCH_ACCIDENTS_LIST_START,
  payload,
});

export const accidentsFetchSuccess = (payload) => ({
  type: FETCH_ACCIDENTS_LIST_SUCCESS,
  payload,
});

export const accidentsFetchError = (payload) => ({
  type: FETCH_ACCIDENTS_LIST_ERROR,
  payload,
});

export const fetchAccidents = (payload) => {
  return (dispatch) => {
    dispatch(accidentsFetchStart(payload));

    accidentsService.fetchAccidents(payload).then((accidentsData) => {
      if (!accidentsData.isError) {
        dispatch(accidentsFetchSuccess(accidentsData));
      } else {
        dispatch(accidentsFetchError(accidentsData));
      }
    });
  };
};

export const accidentDetailFetchStart = (payload) => ({
  type: FETCH_ACCIDENT_DETAIL.START,
  payload,
});

export const accidentDetailFetchSuccess = (payload) => ({
  type: FETCH_ACCIDENT_DETAIL.SUCCESS,
  payload,
});

export const accidentDetailFetchError = (payload) => ({
  type: FETCH_ACCIDENT_DETAIL.ERROR,
  payload,
});

export const fetchFilesByAccidentId = payload => async dispatch => {
  let request = null
  dispatch(attachmentsFilesFetchStart(payload));
  try {
    request = await accidentsService.fetchAccidentAttachmentByAccidentId(payload);
  }
  catch{
    dispatch(attachmentsFilesFetchError())
  }
  if(request.files){ 
    dispatch(attachmentsFilesFetchSuccess(request.files));
  }
}

export const downloadAllAttachmentByAccidentId =  (payload) =>  async (dispatch) => {
  dispatch(downloadingAllAttachmentStart())
  try{
    await accidentsService.downloadAllAttachment(payload);
  }
  catch {
    dispatch(downloadingAllAttachmentError())
  }
  dispatch(downloadingAllAttachmentComplete());
}


export const fetchAccidentByAccidentId = (payload) => {
  return (dispatch) => {
    dispatch(accidentDetailFetchStart(payload));

    accidentsService
      .fetchAccidentByAccidentId(payload)
      .then((accidentsData) => {
        if (!accidentsData.isError) {
          dispatch(accidentDetailFetchSuccess(accidentsData));
        } else {
          dispatch(accidentDetailFetchError(accidentsData));
        }
      });
  };
};


// delete accident
export const deleteAccidentRecordStart = () => ({
  type: DELETE_ACCIDENT_RECORD_START,
});

export const deleteAccidentRecordSuccess = (payload) => ({
  type: DELETE_ACCIDENT_RECORD_SUCCESS,
  payload,
});

export const deleteAccidentRecordError = (payload) => ({
  type: DELETE_ACCIDENT_RECORD_ERROR,
  payload,
});

export const deleteAccidentRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteAccidentRecordStart());

    accidentsService
      .deleteAccidentRecord({ accidentId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteAccidentRecordSuccess());
          dispatch(fetchAccidents(payload?.fetchAccidentParams));
        } else {
          dispatch(deleteAccidentRecordError(data));
        }
      });
  };
};

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const createNewAccidentScreenInitStart = (payload) => ({
  type: INIT_CREATE_NEW_ACCIDENT_SCREEN,
  payload,
});

export const initCreateNewAccidentScreen = (payload) => {
  return (dispatch) => {
    dispatch(createNewAccidentScreenInitStart());
    dispatch(fetchEmployees());
  };
};

// create accident
export const createAccidentRecordStart = () => ({
  type: CREATE_ACCIDENT_RECORD.START,
});

export const createAccidentRecordSuccess = (payload) => ({
  type: CREATE_ACCIDENT_RECORD.SUCCESS,
  payload,
});

export const createAccidentRecordError = (payload) => ({
  type: CREATE_ACCIDENT_RECORD.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createAccident = (payload) => {
  return (dispatch) => {
    dispatch(createAccidentRecordStart());
    accidentsService.createAccidentRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(createAccidentRecordSuccess());
        goBack()
      } else {
        dispatch(createAccidentRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editAccidentRecordStart = () => ({
  type: EDIT_ACCIDENT_RECORD.START,
});

export const editAccidentRecordSuccess = (payload) => ({
  type: EDIT_ACCIDENT_RECORD.SUCCESS,
  payload,
});

export const editAccidentRecordError = (payload) => ({
  type: EDIT_ACCIDENT_RECORD.ERROR,
  payload,
});

export const editAccident = (payload) => {
  return (dispatch) => {
    dispatch(editAccidentRecordStart());
    accidentsService.editAccidentRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(editAccidentRecordSuccess());
        goBack()
      } else {
        dispatch(editAccidentRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
