import {
  INIT_TRACKING_LOGS,
  FETCH_TRACKING_LOGS_LIST_START,
  FETCH_TRACKING_LOGS_LIST_SUCCESS,
  FETCH_TRACKING_LOGS_LIST_ERROR,
} from '../../../constants/action-types/trackingLogs';
import { convertTypeToLabel } from '../../../utils/string/acronymUtil';
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from '../../../utils/table/gridUtils';

export const TRACKING_LOG_DATE_DATA_KEY = 'time';

const tranformToTrackingLogsGridView = (trackingLogData) => {

  const rows = trackingLogData.map((trackingLog) => {
    const {
      id,
      type,
      logfor,
      device,
      time,
      event,
      flag,
      latitude,
      longitude
    } = trackingLog;

    const transformedValues = {
      id,
      time,
      type,
      event,
      flag,
      latitude,
      longitude,
      logfor,
      device,
    };
    const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });

  const columns = [
    {
      colLabel: 'Column Id',
      dataKey: 'id',
      extraProps: setColumnDefaultExtraProps({
        hidden: false,
        isSortable: true,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Date & Time',
      dataKey: TRACKING_LOG_DATE_DATA_KEY,
      extraProps: setColumnDefaultExtraProps({
        isDateField: true,
        shouldFilter: true,
        isSortable: true
      }),
    },
    {
      colLabel: 'Belongs to',
      dataKey: 'type',
      body: (rowData) => convertTypeToLabel(rowData.type),
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: true,
        isSortable: true
      }),
    },
    {
      colLabel: 'Name',
      dataKey: 'logfor',
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: true,
        isSortable: true
      }),
    },
    {
      colLabel: 'Device ID',
      dataKey: 'device',
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: true,
        isSortable: true
      }),
    },
    {
      colLabel: 'Longitude',
      dataKey: 'longitude',
      extraProps: setColumnDefaultExtraProps({ shouldFilter: false }),
    },
    {
      colLabel: 'Latitude',
      dataKey: 'latitude',
      extraProps: setColumnDefaultExtraProps({ shouldFilter: false }),
    },
    {
      colLabel: 'Event',
      dataKey: 'event',
      extraProps: setColumnDefaultExtraProps({
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Flag',
      dataKey: 'flag',
      extraProps: setColumnDefaultExtraProps({
        isSortable: true,
        shouldFilter: true,
      }),
    },
    {
      colLabel: 'Map View',
      dataKey: '',
      extraProps: setColumnDefaultExtraProps({
        isSortable: false,
        shouldFilter: false,
        isMapField: true
      }),
    },
  ];

  return {
    trackingLogsListView: { columns, rows },
  };
};

const INITIAL_STATE = {
  trackingLogsListView: {},
  isLoading: false,
  error: null,
};

export const trackingLogsReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case INIT_TRACKING_LOGS:
      return { ...INITIAL_STATE, isLoadingPage: true };
    case FETCH_TRACKING_LOGS_LIST_START:
      return { ...state, isLoading: true };

    case FETCH_TRACKING_LOGS_LIST_SUCCESS: {
      const { page, content } = action.payload;
      let resetState = {
        trackingLogsListView: null,
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
      };

      if (!content) {
        return resetState;
      }
      const { number, size, total_elements: totalElements, total_pages: totalPages } = page;
      const { trackingLogsListView } = tranformToTrackingLogsGridView(content);

      return {
        ...resetState,
        trackingLogsListView,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages,
          pageNumber: number,
        },
      };
    }

    case FETCH_TRACKING_LOGS_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        trackingLogsListView: {},
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
      };
    }

    default:
      return state;
  }
};
