import React from 'react';
import { Route } from 'react-router-dom';

import AccidentsScreen from '../../containers/AccidentsScreen';
import CreateAccidentContainer from '../../containers/AccidentsScreen/CreateAccident';
import { APP_URLS } from '../../constants/urlConstants';

const AccidentRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update } = access;

  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <AccidentsScreen {...routeProps} screenPermissions={access} />
          )}
        />
      )}
      {!!create && (
        <Route
          path={APP_URLS.ACCIDENTS.ADD_ACCIDENT}
          component={CreateAccidentContainer}
        />
      )}
      {!!update && (
        <Route
          path={APP_URLS.ACCIDENTS.EDIT_ACCIDENT}
          render={(routeProps) => (
            <CreateAccidentContainer {...routeProps} isEdit={true} />
          )}
        />
      )}
    </>
  );
};

export default AccidentRoutes;
