import { connect } from "react-redux";
import { useEffect } from "react";

import EzControlledAutoSuggestWithFloatingLabel from '../../common/EzForm/EzControlledAutoSuggest/WithFloatingLabel';
import EzControlledDropDowntWithFloatingLabel from '../../common/EzForm/EzControlledDropDown/WithFloatingLabel';
import { fetchOwnerTypes } from "../../Redux/Actions/OwnerType";
import { getOwnerTypes, getOwners } from "../../Redux/Selectors/dropDown";
import { OWNER_TYPES_CODE } from "../../Fleet/Trips/constants";
import { payloadCreater } from "../../utils/dropDown/transformtoDropdown";
import { fetchOwners } from "../../Redux/Actions/document_new";
import { useState } from "react";
import { useRef } from "react";

const OwnerTypes = (props) => {
  const { 
          name,
          control,
          resetField,
          label,
          rules,
          errorMsg,
          isError,
          fetchOwnerTypes,
          ownerTypes = [],
          ownerField,
          fetchOwners,
          owners,
          optionLabel,
          optionValue,
          enabledOwnerTypeOptions = [],
        } = props;

        const [enabledOwnerType, setEnabledOwnerType] = useState('');
        const handleChangeCalledRef = useRef(false);

        useEffect(() => {
          if(!handleChangeCalledRef.current) setEnabledOwnerType(control._formValues.type);
        }, [control._formValues.type] )
        
        const enabledOwnerTypes = ownerTypes?.filter( ({ label }) => enabledOwnerTypeOptions.includes(label) )

        const onHandlerChange = (event) => {
          handleChangeCalledRef.current = true;
          const lookInExtraData =  optionValue.includes('extraData');
          const pathInExtraData =  optionValue.split('.')[1];

          const enabledOwnerType =  lookInExtraData ?  
             enabledOwnerTypes.find( enabledOwnerType => enabledOwnerType['extraData'][pathInExtraData] === event.value) 
            :  enabledOwnerTypes.find(enabledOwnerType => enabledOwnerType[optionValue] === event.value)
            
            resetField(ownerField, { defaultValue: '' })
            setEnabledOwnerType(enabledOwnerType?.label);
        } 

        const searchOwners = ({ query }) => {
          let payload = null;
          switch(enabledOwnerType){
              case OWNER_TYPES_CODE.CST : payload = payloadCreater({query,filterField:'name'});
              break;
              case OWNER_TYPES_CODE.EMP : payload = payloadCreater({query,filterField:'first'});
              break;
              case OWNER_TYPES_CODE.SPLR : payload = payloadCreater({query,filterField:'name'});
              break;
              case OWNER_TYPES_CODE.AST : payload = payloadCreater({query,filterField:'asset_code'})
              break;
              default: console.log('default case');
          }
          fetchOwners(enabledOwnerType, payload);
      }
   
      
  useEffect(()=> {
    fetchOwnerTypes();
  }, [fetchOwnerTypes])

  return (
    <span style ={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={rules}
        onChange = {onHandlerChange}
        optionLabel={ optionLabel || 'label'}
        optionValue={optionValue || 'value'}
        options={enabledOwnerTypes}
        isError={isError}
        errorMsg={errorMsg}
        label={label}
        labelClassName={'ml-2'}
      />
      { 
        ownerField &&
        <EzControlledAutoSuggestWithFloatingLabel
          name = { ownerField }
          rules={rules}
          control = { control }
          suggestions = {owners}
          completeMethod={({ query }) => (searchOwners( {query} ))}
          label = {`Belongs to ${enabledOwnerType || ''}`}
          isError = {false}
        />
      }
  </span>)

} 

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOwnerTypes: (payload) => dispatch(fetchOwnerTypes(payload)),
    fetchOwners : payload => dispatch(fetchOwners(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    owners : getOwners(state),
    ownerTypes: getOwnerTypes(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerTypes);
