import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { truncateStringWithEllipses } from '../../utils/common';

const CARD_WIDTH = '180px';

const LIMITED_WIDTH = 30;


const CardComponent = props => {    
    const btnClass = 'p-button-sm p-button-raised p-component p-button-rounded p-button-text p-button-icon-only';

    const {
        isPDF,
        imgSrc,
        downloadLink,
        name
    } = props

    const header = <Image  preview = {!isPDF} src={imgSrc} imageStyle = {{width:CARD_WIDTH, height:'90px'}}/>

    const footer = <a href={downloadLink}>
                    <Button
                        style={{marginBottom : '5px'}}
                        tooltipOptions={{ position: 'bottom', className: 'green-tooltip' }}
                        icon="pi pi-download"
                        className={`${btnClass} float-right  p-button-info ml-2`}/>
                    </a>

    const nameOfTheFile = <span className='text-break' style = {{fontSize:'x-small'}}>
                            { truncateStringWithEllipses(LIMITED_WIDTH, name) }
                        </span>
    return <>
            <Card style={{backgroundColor:'#F8F8F8' , height:'220px', width: CARD_WIDTH }}
                 header = {header}
                 footer = {footer}
                 subTitle = {nameOfTheFile} >
            </Card>
    </>
}

export default CardComponent;

