import { AutoComplete } from 'primereact/autocomplete';
import React from 'react';
import { useState } from 'react';
import SnackbarError from '../common/SnackbarError';
import { fetchLocationsByCSName } from '../Services/common/locationService';
export default function LoctionName({ index, value, onChangeOrderItem, selectedLocation, type }) {
  const [locationsStoredInSystem, setLocationsStoredInSystem] = useState([])
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false)
  
  const handelMessage = (Message, Boll) => {
    setMessage(Message);
    setIsError(true);
    setTimeout(() => {
      setIsError(false);
    }, 3000)
  };
  const searchLocationByName = async ({ query }) => {
    if (query.length < 2) {
      handelMessage('Minimum two characters should be entered ', 'errorBoll');
    }
    if (query.length > 1) {
      const locations = await fetchLocationsByCSName(query, type);
      if (locations.isError) {
        locations = []
      }
      setLocationsStoredInSystem(locations)
    }
  }
  return (
    <div style={{ zIndex: 10029 }}>
      <AutoComplete
        name="loc_nm"
        appendTo="self"
        delay={500}
        emptyMessage={'No locations to show'}
        value={selectedLocation}
        dropdown={true}
        suggestions={locationsStoredInSystem}
        completeMethod={searchLocationByName}
        placeholder="Type C/S Name"
        field={'name'}
        itemTemplate={(item) => (item.name + ":" + item.location)}
        onChange={(e) => {
          onChangeOrderItem(index)(e)
        }} />
      <SnackbarError
        errorBoll={isError}
        errorMessage={message}
      />
    </div>
  )
}
