import { DEFAULT_TOAST_LIFE } from '../../../common/EzToast';
import {
  INIT_INVENTORY_TRACKERS,
  FETCH_INVENTORY_TRACKERS_LIST_ERROR,
  FETCH_INVENTORY_TRACKERS_LIST_START,
  FETCH_INVENTORY_TRACKERS_LIST_SUCCESS,
  INIT_CREATE_NEW_INVENTORY_TRACKER_SCREEN,
  CREATE_INVENTORY_TRACKER_RECORD,
  EDIT_INVENTORY_TRACKER_RECORD,
  FETCH_INVENTORY_TRACKER_DETAIL,
  TOAST,
} from '../../../constants/action-types/inventoryTracker';
import { inventoryTrackersService } from '../../../Services';
import { fetchEmployees } from '../employees';

import {
  attachmentsFilesFetchSuccess,
  attachmentsFilesFetchStart,
  downloadingAllAttachmentStart,
  downloadingAllAttachmentComplete,
  attachmentsFilesFetchError,
  downloadingAllAttachmentError
} from '../common/files'

import { goBack } from '../../../utils/gotoback';



export const initializeInventoryTrackerScreen = () => ({ type: INIT_INVENTORY_TRACKERS });

export const inventoryTrackersFetchStart = (payload) => ({
  type: FETCH_INVENTORY_TRACKERS_LIST_START,
  payload,
});

export const inventoryTrackersFetchSuccess = (payload) => ({
  type: FETCH_INVENTORY_TRACKERS_LIST_SUCCESS,
  payload,
});

export const inventoryTrackersFetchError = (payload) => ({
  type: FETCH_INVENTORY_TRACKERS_LIST_ERROR,
  payload,
});

export const fetchInventoryTrackers = (payload) => {
  return (dispatch) => {
    dispatch(inventoryTrackersFetchStart(payload));

    inventoryTrackersService.fetchInventoryTrackerRecords(payload).then((inventoryTrackersData) => {
      if (!inventoryTrackersData.isError) {
        dispatch(fetchEmployees({ shouldPaginate: false }));
        dispatch(inventoryTrackersFetchSuccess(inventoryTrackersData));
      } else {
        dispatch(inventoryTrackersFetchError(inventoryTrackersData));
      }
    });
  };
};

export const inventoryTrackersDetailFetchStart = (payload) => ({
  type: FETCH_INVENTORY_TRACKER_DETAIL.START,
  payload,
});

export const inventoryTrackersDetailFetchSuccess = (payload) => ({
  type: FETCH_INVENTORY_TRACKER_DETAIL.SUCCESS,
  payload,
});

export const inventoryTrackersDetailFetchError = (payload) => ({
  type: FETCH_INVENTORY_TRACKER_DETAIL.ERROR,
  payload,
});

export const fetchFilesByInventoryTrackerId = (payload) => async dispatch => {
  let request = null
  dispatch(attachmentsFilesFetchStart(payload));
  try {
    request = await inventoryTrackersService.fetchInventoryTrackersAttachmentByInventoryTrackersId(payload);
    request = request?.items.filter(item => item.id ===payload.dataId);
    request = request[0]
  }
  catch {
    dispatch(attachmentsFilesFetchError())
  }
  if (request.files) {
    dispatch(attachmentsFilesFetchSuccess(request.files));
  }
}

export const downloadAllAttachmentByInventoryTrackerId = (payload) => async (dispatch) => {
  dispatch(downloadingAllAttachmentStart())
  try {
    await inventoryTrackersService.downloadAllAttachment(payload);
  }
  catch {
    dispatch(downloadingAllAttachmentError())
  }
  dispatch(downloadingAllAttachmentComplete());
}

export const fetchInventoryTrackerByInventoryTrackerId = (payload) => {
  return (dispatch) => {
    dispatch(inventoryTrackersDetailFetchStart(payload));

    inventoryTrackersService
      .fetchInventoryTrackerByInventoryTrackerId(payload)
      .then((inventoryTrackersData) => {
        if (!inventoryTrackersData.isError) {
          dispatch(inventoryTrackersDetailFetchSuccess(inventoryTrackersData));
        } else {
          dispatch(inventoryTrackersDetailFetchError(inventoryTrackersData));
        }
      });
  };
};

export const createNewInventoryTrackerScreenInitStart = (payload) => ({
  type: INIT_CREATE_NEW_INVENTORY_TRACKER_SCREEN,
  payload,
});

export const initCreateNewInventoryTrackerScreen = (payload) => {
  return (dispatch) => {
    dispatch(createNewInventoryTrackerScreenInitStart());

  };
};

// create inventoryTrackers
export const createInventoryTrackerRecordStart = () => ({
  type: CREATE_INVENTORY_TRACKER_RECORD.START,
});

export const createInventoryTrackerRecordSuccess = (payload) => ({
  type: CREATE_INVENTORY_TRACKER_RECORD.SUCCESS,
  payload,
});

export const createInventoryTrackerRecordError = (payload) => ({
  type: CREATE_INVENTORY_TRACKER_RECORD.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createInventoryTracker = (payload) => {
  return (dispatch) => {
    dispatch(createInventoryTrackerRecordStart());
    inventoryTrackersService.createInventoryTrackerRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(createInventoryTrackerRecordSuccess());
        goBack()
      } else {
        dispatch(createInventoryTrackerRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast(data));
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editInventoryTrackerRecordStart = () => ({
  type: EDIT_INVENTORY_TRACKER_RECORD.START,
});

export const editInventoryTrackerRecordSuccess = (payload) => ({
  type: EDIT_INVENTORY_TRACKER_RECORD.SUCCESS,
  payload,
});

export const editInventoryTrackerRecordError = (payload) => ({
  type: EDIT_INVENTORY_TRACKER_RECORD.ERROR,
  payload,
});

export const editInventoryTracker = (payload) => {
  return (dispatch) => {
    dispatch(editInventoryTrackerRecordStart());
    inventoryTrackersService.editInventoryTrackerRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(editInventoryTrackerRecordSuccess());
        goBack()
      } else {
        dispatch(editInventoryTrackerRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
