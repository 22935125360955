import React from 'react';
import { Form } from 'react-bootstrap';
export default function Autocomplete(props) {
  const {
    value,
    placeholder,
    name,
    onChange,
    style,
    getAutosuggest,
    onClick,
  } = props;
  return (
    <div className="autosuggest">
      <Form.Control
        name={name}
        autoComplete="off"
        placeholder={placeholder || 'Search Customer...'}
        value={value}
        onChange={onChange}
      />

      <div className="suggestionlist" style={style}>
        <ul>
          {getAutosuggest() &&
            getAutosuggest().length > 0 &&
            getAutosuggest().map((item) => (
              <li
                onClick={() => onClick(item.value, item.name)}
                key={item.value}
              >
                {item.name}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
