import React, { useState, useEffect, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { formatDate, isValidTimeStamp } from '../../utils/date';
import './index.css';
import useContextMenu from '../ContextMenu/useContextMenu';
import EzDialog from '../EzDialog';
import { ListBox } from 'primereact/listbox';
import DownloadAttachment from '../../components/DownloadAttachment';
import { FILTER } from '../../Services/common/urlService';
import MapComponent from '../Maps';
import { createLabelValueObj } from '../ContextMenu/utils';

const noop = () =>{};
const DownloadOption = createLabelValueObj('View attachment','Download');

const GRID = {
  DEFAULTS: {
    PAGINATION: {
      AVAILABLE_PAGE_SIZES: [1, 5, 10, 20, 50],
      TEMPLATE:
        'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
      CURRENT_PAGE_REPORT_TEMPLATE:
        'Showing record {first} to {last} of {totalRecords} records',
    },
  },
};

const RECORD_DELETION_POPUP = {
  TEXT_MESSAGE: 'Record will be deleted forever. Do you wish to continue?',
};

const getRowsPerPageOptions = (pageSize, totalPages, totalRecords) => {
  return GRID.DEFAULTS.PAGINATION.AVAILABLE_PAGE_SIZES.filter(
    (val) => val <= pageSize * totalPages
  );
};

const filterColumFromDataSet = (dataList, dataKey) => {
  return dataList.filter((col) => col.dataKey !== dataKey);
};

//TODO(subham): Move this to utils.
const isDeleteAllowed = (screenPermissions) => !!screenPermissions?.delete;
const isEditAllowed = (screenPermissions) => !!screenPermissions?.update;

const EzTable = (props) => {
  const {
    screenPermissions,
    setDeleteConfirmationPopupVisibility,
    shouldOpenDeleteConfirmationPopup,
    showGlobalFilter,
    dataKey,
    paginationInfo,
    areFiltersVisible,
    columns,
    onDeleteRecord,
    onEditRecord,
    value,
    emptyMessage,
    globalFilterFields,
    filters,
    loading,
    sortField,
    loadLazyData,
    moreActionProps,
    fetchAttachmentsById = noop(),
    downloadAllAttachments = noop(),
    showMoreActions,
  } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);
  const [mapdata, setMapdata] = useState([]);
  const onDialogHide = () => setShowDialog(false)
  const onMoreActionMenu = (event ,currentRowData) => {
     document.dispatchEvent(new MouseEvent("customContextmenu", event))
     setCurrentRowData(currentRowData)
  }

  const {
         isMoreActionDisabled = true,
         downloadEnabled = false,
         moreActionOptions = [],
         onMoreActionChange = null
        } = moreActionProps || {};
  
  const getMoreActionOption = () =>{
    if(!isMoreActionDisabled){
     if(downloadEnabled && !moreActionOptions.includes(DownloadOption)) moreActionOptions.unshift(DownloadOption)
     return moreActionOptions;
    }
    return [];
  }

  const onMoreActionChangeModified = (option) => {
    if (downloadEnabled && option === DownloadOption.value) {
      setShowDialog(true);
    }
    else onMoreActionChange(option, currentRowData);
  }

  const { anchorPoint, show } = useContextMenu();
  const { pageSize, totalRecords, totalPages, pageNumber } = paginationInfo;

  const [selectedColumns, setSelectedColumns] = useState(
    filterColumFromDataSet(columns, 'id')
  );

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const cm = useRef(null);
  const dt = useRef(null);
  const toBeDeletedRecordId = useRef(-1);
  // eslint-disable-next-line
  const toBePopupinDialog = useRef(-1);

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.dataKey === col.dataKey)
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div style={{ justifyContent: 'space-between', display: 'flex' }}>
        <div style={{ textAlign: 'left' }}>
          <MultiSelect
            className={'grid-multi-select-wrapper'}
            panelClassName={''}
            display={'chip'}
            value={selectedColumns}
            options={filterColumFromDataSet(columns, 'id')}
            optionLabel="colLabel"
            onChange={onColumnToggle}
            style={{ width: '20em' }}
          />
        </div>
        {showGlobalFilter && (
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Global search in table"
            />
          </span>
        )}
      </div>
    );
  };

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: pageSize,
    page: 1,
    sortField,
    sortOrder: null,
    filters: filters,
  });

  // Variable to know, if component has alraedy ran once of not.
  // To Avoid multi fetch of data on first load.
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    loadLazyData(getFetchDataParams(lazyParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyParams]);

  const getFetchDataParams = () => {
    const { sortOrder, sortField, page, rows, filters } = lazyParams;
    let modifiedFilters = [];

    if (filters) {
      const filterKeys = Object.keys(filters);
      modifiedFilters = filterKeys.reduce((acc, filterKey) => {
        if (filters[filterKey]?.value) {
          const { matchStrategy, valueMapper, value } = filters[filterKey]
          return [
            ...acc,
            {
              filterField: filters[filterKey]?.filterKey || filterKey,
              matchStrategy: matchStrategy || FILTER.MATCH_STRATEGY.CONTAINS,
              query: valueMapper ? valueMapper(value) : value,
            },
          ];
        }
        return acc;
      }, modifiedFilters);
    }

    return {
      filters: filters && modifiedFilters,
      sortOrder,
      sortField,
      pageSize: rows,
      // TODO (SUbham): This page number handling is hacky, find a better way
      pageNumber: page ?? pageNumber - 1,
    };
  };

  const editRecord = (rowData) => {
    onEditRecord(rowData.id);
  };

  const deleteRecord = () => {
    let params = getFetchDataParams();
    const { pageNumber, pageSize } = params;

    // TODO (Subham): This entire pagination is hacky.
    let newPageNumber = pageNumber;
    if (totalRecords - 1 === 0) {
      newPageNumber = 0;
    } else if (totalRecords - 1 <= pageNumber * pageSize) {
      newPageNumber = pageNumber - 1;
    }
    onDeleteRecord({
      params: {
        ...params,
        pageNumber: newPageNumber,
      },
      id: toBeDeletedRecordId.current,
    });
  };

  const onPage = (event) => {
    setLazyParams(event);
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const onFilter = (event) => {
    setLazyParams(event);
  };

  const actionBodyTemplate = (rowData) => {

    const btnClass =
      'p-button-sm p-button-raised p-component p-button-rounded p-button-text p-button-icon-only';
    return (
      <React.Fragment>
        {isEditAllowed(screenPermissions) && (
          <Button
            icon="pi pi-pencil"
            className={`${btnClass} mr-2`}
            onClick={() => {
              editRecord(rowData);
            }}
          />
        )}
        {isDeleteAllowed(screenPermissions) && (
          <Button
            icon="pi pi-trash"
            className={`${btnClass} p-button-danger`}
            onClick={() => {
              toBeDeletedRecordId.current = rowData.id;
              setDeleteConfirmationPopupVisibility(true);
            }}
          />
        )}
        {
          showMoreActions && <Button
            disabled = {isMoreActionDisabled}
            tooltip='More Action'
            tooltipOptions={{ position: 'bottom', className: 'green-tooltip' }}
            icon="pi pi-ellipsis-v"
            className={`${btnClass} p-button-info ml-2`}
            onClick={(event) => onMoreActionMenu(event, rowData) }
        />
        }
        {
          show && ReactDOM.createPortal(
            <ListBox className="context-menu"
              style={{ top: anchorPoint.y, left: anchorPoint.x }}   
              options={getMoreActionOption()}
              onChange={(e) => onMoreActionChangeModified(e.target.value)} />
            , document.body.appendChild(document.createElement("DIV")))
        }
      </React.Fragment>
    );
  };


  const getActionBodyColumn = () => {
    return {
      key: 'action key',
      body: actionBodyTemplate,
      field: 'action',
      header: 'Actions',
      sortable: false,
      hidden: false,
    };
  };

  const dataTableProps = {
    ref: dt,
    header: renderHeader(),
    dataKey,
    selectionMode: 'single',
    size: 'small',
    stripedRows: true,
    resizableColumns: true,
    reorderableColumns: true,
    showGridlines: false,
    responsiveLayout: 'scroll',
    value,
    removableSort: true,
    emptyMessage,
    globalFilterFields,
    ...(areFiltersVisible ? { filterDisplay: 'row' } : {}),
    paginator: true,
    paginatorTemplate: GRID.DEFAULTS.PAGINATION.TEMPLATE,
    currentPageReportTemplate:
      GRID.DEFAULTS.PAGINATION.CURRENT_PAGE_REPORT_TEMPLATE,
    rowsPerPageOptions: getRowsPerPageOptions(
      pageSize,
      totalPages,
      totalRecords
    ),
    rows: pageSize,
    contextMenuSelection: selectedRow,
    onContextMenuSelectionChange: (e) => setSelectedRow(e.value),
    onContextMenu: (e) => cm.current.show(e.originalEvent),
    selection: selectedRows,
    onSelectionChange: (e) => setSelectedRows([...selectedRows, e.value]),
    lazy: true,
    totalRecords,
    onSort,
    onFilter,
    onPage,
    first: lazyParams.first,
    sortField: lazyParams.sortField,
    sortOrder: lazyParams.sortOrder,
    filters: lazyParams.filters,
    loading,
  };

  const handleMap = (mapdata) => {
    setShowMap(!showMap)
    let mpdata = [mapdata]
    mpdata = mpdata.map(item => ({
      ...item,
      lat: item.latitude,
      lng: item.longitude,
      name: item.type,
      address: item.logfor
    }))
    setMapdata(mpdata)
  }


  const dateBodyTemplate = (dataKey, rowData, strategy) => {
    const dateValue = rowData[dataKey]
    const value = isValidTimeStamp(dateValue) ? formatDate(dateValue, strategy) : dateValue;
    
    return (
      <span style={{ display: 'flex' }}>
        <span style={{ alignContent: 'center' }}>
          {value}
        </span>
      </span>
    );
  };
  const mapBodyTemplate = (dataKey, rowData) => {
    const btnClass =
      'p-button-sm p-button-raised p-component p-button-rounded p-button-text p-button-icon-only';
    return (

      <Button
        icon="pi pi-map-marker"
        className={`${btnClass} mr-2`}
        onClick={() => {
          handleMap(rowData);
        }}
      />

    );
  };
  const monthNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  const yearNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        monthNavigator
        yearNavigator
        monthNavigatorTemplate={monthNavigatorTemplate}
        yearNavigatorTemplate={yearNavigatorTemplate}
        yearRange={'2020:2040'}
        showIcon
        onChange={(e) => {
          filterDate(e.value, options);
        }}
        dateFormat="dd/mm/yy"
        placeholder="DD/MM/YYYY"
        mask="99/99/99"
      />
    );
  };
  const filterDate = (calendarValue, options) => {
    if (calendarValue) {
      setLazyParams({
        ...lazyParams,
        filters: {
          ...filters,
          date: { value: formatDate(calendarValue) },
        },
      });
    }
  };

  const getFilterProps = (extraProps, dataKey) => {
    if (extraProps) {
      const { isDateField, shouldFilter, showFilterMenu, isMapField, strategy } = extraProps;
      let enrichedFilterProps = {
        filter: shouldFilter ?? false,
        showFilterMenu: showFilterMenu ?? false,
      };

      if (isDateField) {
        enrichedFilterProps = {
          ...enrichedFilterProps,
          dataType: 'date',
          filterField: 'date',
          body: (rowData) => dateBodyTemplate(dataKey, rowData, strategy),
          filterElement: dateFilterTemplate,
        };
      }
      if (isMapField) {
        enrichedFilterProps = {
          body: (rowData) => mapBodyTemplate(dataKey, rowData),
        };
      }


      return enrichedFilterProps;
    }

    return {};
  };
  const showActionColumn = () => {
    return showMoreActions || isEditAllowed(screenPermissions) || isDeleteAllowed(screenPermissions);
  }

  const getColumnProps = (column) => {
    const filterProps = getFilterProps(column?.extraProps, column.dataKey);

    return {
      ...column,
      key: column.dataKey,
      field: column.dataKey,
      header: column.colLabel,
      sortable: column?.extraProps?.isSortable,
      sortField: column?.extraProps?.sortField,
      hidden: column?.extraProps?.hidden,
      ...filterProps,
    };
  };

  const getDialog = () => {
    const attachmentProps = {
      itemId: currentRowData?.id,
      fetchAttachmentsById,
      downloadAllAttachments
    }
    return showDialog &&
      <EzDialog  onHide={onDialogHide} header = {'Attachment'}>
        <DownloadAttachment {...attachmentProps} />
      </EzDialog>
  }
  return (
    <div>
      <div className="card">
        <DataTable {...dataTableProps}>
          {selectedColumns.map((column) => (
            <Column {...getColumnProps(column)} />
          ))}
          {showActionColumn() && <Column {...getActionBodyColumn()} />}
        </DataTable>
      </div>
      <ConfirmDialog
        visible={shouldOpenDeleteConfirmationPopup}
        message={RECORD_DELETION_POPUP.TEXT_MESSAGE}
        header="Please Confirm Deletion"
        position={'top'}
        onHide={() => setDeleteConfirmationPopupVisibility(false)}
        acceptLabel={'Cancel'}
        rejectLabel={'Delete Record'}
        acceptClassName={'p-button-raised'}
        rejectClassName={'p-button-text p-button-danger'}
        reject={() => {
          deleteRecord();
        }}
        accept={() => setDeleteConfirmationPopupVisibility(false)}
      />

      <MapComponent
        title={`${mapdata[0]?.type} ${mapdata[0]?.logfor}`}
        onHide={handleMap}
        show={showMap}
        data={mapdata}
      />
      {getDialog()}
    </div>
  );
};

EzTable.defaultProps = {
  showGlobalFilter: false,
  showMoreActions: true,
  dataKey: 'id',
  sortField: 'date',
  emptyMessage: 'No Record found',
};

export default EzTable;
