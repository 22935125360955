import { DEFAULT_TOAST_LIFE } from '../../../common/EzToast'

import {
  INIT_ASSETS,
  FETCH_ASSETS_START,
  FETCH_ASSETS_SUCCESS,
  FETCH_ASSETS_ERROR,
  DELETE_ASSET_RECORD_START,
  DELETE_ASSET_RECORD_SUCCESS,
  DELETE_ASSET_RECORD_ERROR,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  CREATE_ASSET_RECORD,
  CREATE_ASSET_MAKE,
  TOAST,
  FETCH_ASSET_DETAIL,
  EDIT_ASSET_RECORD
} from '../../../constants/action-types/assets';

import {
  attachmentsFilesFetchStart,
  attachmentsFilesFetchSuccess,
  downloadingAllAttachmentComplete,
  downloadingAllAttachmentStart
 } from "../common/files";

import { assetsService } from '../../../Services';
import { fetchAssetMakeComplete, fetchAssetMakeStart, fetchAssetModelComplete, fetchAssetModelStart } from '../Dropdown/action.plain';
import { transformTypesToDropDown } from '../../../utils/dropDown/transformtoDropdown';

export const initializeAssetsScreen = () => ({ type: INIT_ASSETS });

export const assetsFetchStart = (payload) => ({
  type: FETCH_ASSETS_START,
  payload,
});

export const assetsFetchSuccess = (payload) => ({
  type: FETCH_ASSETS_SUCCESS,
  payload,
});

export const assetsFetchError = (payload) => ({
  type: FETCH_ASSETS_ERROR,
  payload,
});

export const fetchAssets = (payload) => {
  return (dispatch) => {
    dispatch(assetsFetchStart(payload));

    assetsService.fetchAssets(payload).then((assetsData) => {
      if (!assetsData.isError) {
        dispatch(assetsFetchSuccess(assetsData));
      } else {
        dispatch(assetsFetchError(assetsData));
      }
    });
  };
};

// delete accident
export const deleteAssetsRecordStart = () => ({
  type: DELETE_ASSET_RECORD_START,
});

export const deleteAssetsRecordSuccess = (payload) => ({
  type: DELETE_ASSET_RECORD_SUCCESS,
  payload,
});

export const deleteAssetsRecordError = (payload) => ({
  type: DELETE_ASSET_RECORD_ERROR,
  payload,
});

export const deleteAssetRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteAssetsRecordStart());

    assetsService
        .deleteAssetRecord({ assetId: payload?.id })
        .then((data) => {
          if (!data.isError) {
            dispatch(deleteAssetsRecordSuccess());
            dispatch(fetchAssets(payload?.params));
          } else {
            dispatch(deleteAssetsRecordError(data));
          }
        });
    };
  };

  export const setDeleteConfirmationPopupVisibility = (payload) => ({
    type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
    payload,
  });

  
export const createAssetRecordStart = () => ({
  type: CREATE_ASSET_RECORD.START,
});

export const createAssetRecordSuccess = (payload) => ({
  type: CREATE_ASSET_RECORD.SUCCESS,
  payload,
});

export const createAssetRecordError = (payload) => ({
  type: CREATE_ASSET_RECORD.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createAsset = (payload) => {
  return (dispatch) => {
    dispatch(createAssetRecordStart());
    assetsService.createAssetRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(createAssetRecordSuccess());
      } else {
        dispatch(createAssetRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};  

export const createAssetMakeStart = () => ({
  type: CREATE_ASSET_MAKE.START,
});

export const createAssetMakeSuccess = (payload) => ({
  type: CREATE_ASSET_MAKE.SUCCESS,
  payload,
});

export const createAssetMakeError = (payload) => ({
  type: CREATE_ASSET_MAKE.ERROR,
  payload,
});

export const createAssetMake = (payload) => {
  return (dispatch) => {
    dispatch(createAssetMakeStart());
    assetsService.createAssetMake(payload).then((data) => {
      if (!data.isError) {
        dispatch(fetchAssetMake())
        dispatch(createAssetMakeSuccess());
      } else {
        dispatch(createAssetMakeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const createAssetModal = (payload) => {
  return (dispatch) => {
    dispatch(createAssetMakeStart());
    assetsService.createAssetModal(payload).then((data) => {
      if (!data.isError) {
        dispatch(fetchAssetModel())
        dispatch(createAssetMakeSuccess());
      } else {
        dispatch(createAssetMakeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};



export const assetDetailFetchStart = (payload) => { 
  return {
  type: FETCH_ASSET_DETAIL.START,
  payload,
}}

export const assetDetailFetchSuccess = (payload) => ({
  type: FETCH_ASSET_DETAIL.SUCCESS,
  payload,
});

export const assetDetailFetchError = (payload) => ({
  type: FETCH_ASSET_DETAIL.ERROR,
  payload,
});

export const fetchAssetByassetId = (payload) => {
  return async (dispatch) => {
    dispatch(assetDetailFetchStart(payload));
    let assetData = await assetsService.fetchAssetByAssetId(payload);
      if (!assetData.isError) {
        dispatch(assetDetailFetchSuccess(assetData));
      } else {
        dispatch(assetDetailFetchError(assetData));
      } 
  };
};

export const editAssetRecordStart = () => ({
  type: EDIT_ASSET_RECORD.START,
});

export const editAssetRecordSuccess = (payload) => ({
  type: EDIT_ASSET_RECORD.SUCCESS,
  payload,
});

export const editAssetRecordError = (payload) => ({
  type: EDIT_ASSET_RECORD.ERROR,
  payload,
});

export const editAsset = (payload) => {
  return (dispatch) => {
    dispatch(editAssetRecordStart());
    assetsService.editAssetRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(editAssetRecordSuccess());
      } else {
        dispatch(editAssetRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };  
};

export const fetchAssetMake = () => dispatch => {
  dispatch(fetchAssetMakeStart())
  assetsService.fetchAssetMake()
  .then(({ content }) => dispatch(fetchAssetMakeComplete({ make: transformTypesToDropDown(content, 'name', 'id')})))
}

export const fetchAssetModel = () => dispatch => {
  dispatch(fetchAssetModelStart())
  assetsService.fetchAssetModel()
  .then(({ content }) => dispatch(fetchAssetModelComplete({ model: transformTypesToDropDown(content, 'name', 'id')})))
}

export const assetsFetchFilesByAssetId = payload => async (dispatch, getstate) => {
  dispatch(attachmentsFilesFetchStart(payload));
  const {files} = await assetsService.fetchAssetAttachmentByAssetId(payload);

  if(files) dispatch(attachmentsFilesFetchSuccess(files));
}
 
export const downloadAllAttachmentForAssetId =  (payload) =>  async (dispatch) => {
  dispatch(downloadingAllAttachmentStart())
  await assetsService.downloadAssetsAllAttachment(payload);
  dispatch(downloadingAllAttachmentComplete());
}
