import { useEffect } from "react"
import { batch, connect } from "react-redux";
import { createDocument, editDocument, fetchDocumentTypes, fetchOwners } from "../../../Redux/Actions/document_new";
import { getDocumentsTypes, getOwners, getOwnerTypes } from "../../../Redux/Selectors/dropDown";
import CreateDocument from "../../../components/Document_new/CreateDocument";
import { OWNER_TYPES_CODE } from "../../../Fleet/Trips/constants";
import {payloadCreater} from '../../../utils/dropDown/transformtoDropdown'
import { selectformFieldValueMap, selectIsCreateDocumetError, selectIsCreateDocumetSuccess, selectIsDocumentDetailError, selectIsDocumentDetailSuccess, selectIsEditDocumentError, selectIsEditDocumentSuccess } from "../../../Redux/Selectors/document_new";
import { EzToast, TOAST_SEVERITY } from "../../../common/EzToast";
import { fetchDocumentByDocumentId } from "../../../Redux/Actions/document_new";
import { APP_URLS } from "../../../constants/urlConstants";
import { setIsformDirty } from "../../../Redux/Actions/common/form";
import { fetchOwnerTypes } from "../../../Redux/Actions/OwnerType";

const CreateDocumentContainer = props =>{
    
    const {
            fetchDocumentTypes,
            fetchOwnerTypes,
            fetchOwners,
            isCreateDocumentError,
            isCreateDocumentSuccess,
            isEdit,
            isEditDocumentSuccess,
            isDocumentDetailError,
            isEditDocumentError,
            history,
            fetchDocumentByDocumentId,
         } = props;
    
    const { id:documentId } = props?.match?.params;
    
    useEffect(()=> {
      
      batch(()=>{
        fetchDocumentTypes();
      });

      if (isEdit && documentId) {
       fetchDocumentByDocumentId({ documentId });
      }
    },[isEdit,documentId,fetchDocumentTypes,fetchOwners,fetchDocumentByDocumentId]);

    const searchDocumentType = ({ query, filterField = 'value' }) => 
    (fetchDocumentTypes({ query, filterField }))
    
    const searchOwnerTypes = ({ query, filterField = 'label' }) => {
      fetchOwnerTypes({ query, filterField  })
    }
    
    
    const searchOwners = (ownerType,{ query }) => {
        
        let payload = null;
        switch(ownerType){
            case OWNER_TYPES_CODE.CST : payload = payloadCreater({query,filterField:'name'});
            break;
            case OWNER_TYPES_CODE.EMP : payload = payloadCreater({query,filterField:'first'});
            break;
            case OWNER_TYPES_CODE.SPLR : payload = payloadCreater({query,filterField:'name'});
            break;
            case OWNER_TYPES_CODE.AST : payload = payloadCreater({query,filterField:'asset_code'})
            break;
            default: console.log('default case');
        }
        fetchOwners(ownerType, payload);
    }
    
    const navigateToDocumentSummaryScreen = () => {
        history.push(APP_URLS.DOCUMENT.HOME);
      }
    
    
    const getToastProps = () => {
      
        if (isCreateDocumentSuccess || isEditDocumentSuccess) {
          const toastTitle = isEdit
            ? 'Document Updated Successfully'
            : ' Document Created Successfully';
    
          return {
            severity: TOAST_SEVERITY.SUCCESS,
            toastTitle,
            shouldShowToast: true,
          };
        }
    
        if (isCreateDocumentError || isDocumentDetailError || isEditDocumentError) {
          let toastTitle = 'Error while Creating Document';
          if (isEditDocumentError) {
            toastTitle = 'Error while updating Document';
          } else if (isDocumentDetailError) {
            toastTitle =
              'Error while performing operation. Please refresh your browser';
          }
    
          return {
            severity: TOAST_SEVERITY.ERROR,
            toastTitle,
            shouldShowToast: true,
          };
        }
      };

    const createDocumentProps = {
      ...props,
      searchDocumentType,
      searchOwnerTypes,
      searchOwners,
      navigateToDocumentSummaryScreen,
      documentId
    }
    
    return (
            <>
                <EzToast {...getToastProps()} />
                <CreateDocument {...createDocumentProps} />
            </>
        );
}

const mapStateToProps = (state,onwProps) =>{
    return {
    ...onwProps,
    ownerTypes : getOwnerTypes(state),
    owners : getOwners(state),
    documentTypes : getDocumentsTypes(state),
    isCreateDocumentError: selectIsCreateDocumetError(state),
    isCreateDocumentSuccess: selectIsCreateDocumetSuccess(state),
    formFieldValueMap:selectformFieldValueMap(state),
    isDocumentDetailSuccess: selectIsDocumentDetailSuccess(state),
    isDocumentDetailError:selectIsDocumentDetailError(state),
    isEditDocumentSuccess :selectIsEditDocumentSuccess(state),
    isEditDocumentError : selectIsEditDocumentError(state)
    
 }
}

const mapdispatchToProps = dispatch => {
    return {
        fetchOwners: (ownerType, payload) => dispatch(fetchOwners(ownerType, payload)),
        fetchOwnerTypes: (payload) => dispatch(fetchOwnerTypes(payload)),
        fetchDocumentTypes: payload => dispatch(fetchDocumentTypes(payload)),
        createDocument : payload => dispatch(createDocument(payload)),
        editDocument: payload=> dispatch(editDocument(payload)),
        fetchDocumentByDocumentId: ({documentId}) => dispatch(fetchDocumentByDocumentId({documentId})),
        setIsformDirty : isFormDirty => dispatch(setIsformDirty(isFormDirty))
    }
}

export default connect(mapStateToProps, mapdispatchToProps)(CreateDocumentContainer);
