import  * as documents_newService  from '../../../Services/business/documents_newService';
import { transformTypesToDropDown } from '../../../utils/dropDown/transformtoDropdown';
import { 
  fetchOwnerTypesStart,
  fetchOwnerTypesComplete,
  } from "../Dropdown/action.plain";

export const fetchOwnerTypes = (payload) => {
  return async (dispatch) => {
    dispatch(fetchOwnerTypesStart())
    let  ownerTypes = null;
    const ownertypes =  await documents_newService.fetchOwnerTypes(payload);
    ownerTypes = transformTypesToDropDown(ownertypes);
    dispatch(fetchOwnerTypesComplete({ownerTypes}))
  };
};  