import Cookies from 'universal-cookie';
import { post } from 'axios';

import {  baseUrl } from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { API_URL } from '../../../constants/urlConstants';

const cookies = new Cookies();

export const borderConnect = async (payload) => {
  const url = API_URL.TRIPS.BORDER_CONNECT;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get('authorization'),
    }
  }

  try {
    let result = await post(baseUrl + url, payload, config);
    if (result?.error) {
      throw new APIError(result);
    }

    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
