import Cookies from 'universal-cookie';
import { post, put } from 'axios';

import { 
  getApiAsyn,
  deleteApi,
  baseUrl
} from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { API_URL } from '../../../constants/urlConstants';
import { extractData } from '../../utils';
import { generateImageUrl, getDecoratedUrl } from '../../common/urlService';
import { asyncForEach } from '../../../utils/async';
import { getAllAttachment, getAllItemLevelAttachments } from '../commonService';
const cookies = new Cookies();

export const fetchCompliances = async (payload) => {
  const url = getDecoratedUrl({
    url: API_URL.COMPLIANCE.FETCH_COMPLIANCE,
    payload,
  });
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));
    if (result?.error) {
      throw new APIError(result);
    }

    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchComplianceByComplianceId = async (payload) => {
  const url = API_URL.COMPLIANCE.FETCH_BY_COMPLIANCE_ID.replace(
    ':id',
    payload?.complianceId
  );
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }

    result = extractData(result, !payload?.filters);
    if (result?.items) {
      await asyncForEach(result.items, async (item, index) => {
          const modifiedFiles =  item?.files?.map( async(file) => {
            const s3Url =  await generateImageUrl(file.url)
            return {...file, s3Url }
          })
          const filess =  await Promise.all(modifiedFiles)
          result.items[index].files = filess;
        });
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};



export const fetchComplianceAttachmentByComplianceId = async (payload) => {
  const url = API_URL.COMPLIANCE.FETCH_BY_COMPLIANCE_ID.replace(':id',payload?.itemId);
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));
    result = result?.items.filter(item => item.id==payload.dataId);
    result = result[0]
    if (result?.error) {
      throw new APIError(result);
    }
    if (result?.files?.length) {
      await asyncForEach(result.files, async (file, index, array) => {
        result.files[index].s3Url = await generateImageUrl(file.url);
      });
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const downloadAllAttachment = async (payload) => {
  await getAllItemLevelAttachments('compliances', payload)
};

export const deleteComplianceRecord = async ({ complianceId }) => {
  try {
    let result = await deleteApi(
      `${API_URL.COMPLIANCE.DELETE_COMPLIANCE}/${complianceId}`,
      cookies.get('authorization')
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

// TODO (subham)- Refactor this to make it reusable logic
const multipartDataUploadApi = (url, { files, data }, isEdit = false) => {
  const formPayload = new FormData();
  // data part of multipart data
  const json = JSON.stringify(data);
  const blobData = new Blob([json], {
    type: 'application/json',
  });
  formPayload.append('data', blobData);

  // file part of multipart data
  files?.forEach((file) => {
    formPayload.append('file', file);
  });

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      authorization: cookies.get('authorization'),
    },
  };
  if (isEdit) {
    return put(baseUrl + url, formPayload, config);
  } else {
    return post(baseUrl + url, formPayload, config);
  }
};

export const createComplianceRecord = async (payload) => {
  try {
    let result = await multipartDataUploadApi(
      `${API_URL.COMPLIANCE.CREATE_COMPLIANCE}`,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const editComplianceRecord = async (payload) => {
  const url = API_URL.COMPLIANCE.EDIT_COMPLIANCE.replace(
    ':id',
    payload?.complianceId
  );

  try {
    let result = await multipartDataUploadApi(url, payload, true);

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
