export const getEmbeddedContentFromResponse = (_embedded) => {
  return _embedded?.content;
};

export const extractData = (result, isEmbeddedContent = true) => {
  
  //TODO (subham): This structure is hacky
  // if (!isEmbeddedContent) {
  //   delete result.APIResult;
  //   return { content: Array.from(result) };
  // }

  let modifiedResult = {
    ...result,
    content: getEmbeddedContentFromResponse(result?._embedded),
  };
  delete modifiedResult._embedded;
  return modifiedResult;
};
