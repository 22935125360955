import React, { PureComponent } from 'react';
import { Button, Modal } from 'react-bootstrap';
export class PopupRefConfirm extends PureComponent {
    render() {
        const {
            isOpen,
            toggleShow,
            onConfirm,
            textMessage,
            headerTitle,
            data
        } = this.props;
        return (
            <Modal
                backdrop="static"
                keyboard={false}
                id="delModal"
                show={isOpen}
                onHide={toggleShow}
            >
                <Modal.Header closeButton>
                    {' '}
                    {headerTitle === undefined || headerTitle === ''
                        ? 'Please confirm the continuation'
                        : headerTitle}
                </Modal.Header>

                <Modal.Body>
                    <p>{textMessage}</p>

                    {data && data?.length > 0 ?
                        <div>
                            {data.map((item, i) => (
                                <p>{item?.order_no}</p>
                            ))
                            }
                        </div> : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={onConfirm}>
                        Confirm
                    </Button>
                    <Button variant="secondary" onClick={toggleShow}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}