import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';

import InventoryTracker from '../../components/InventoryTracker';
import { loadingsShow } from '../../Services/PostAPI';
import {
  initializeInventoryTrackerScreen,
  fetchInventoryTrackers,
  // downloadAllAttachmentByInventoryTrackerId,
  //fetchFilesByInventoryTrackerId,
} from '../../Redux/Actions/inventoryTracker';
import { APP_URLS } from '../../constants/urlConstants';
//import { COMPLIANCE_DATE_DATA_KEY } from '../../Redux/Reducers/inventoryTrackers';
import { fetchEmployees } from '../../Redux/Actions/employees';
import { fetchAssets } from '../../Redux/Actions/assets';
import { fetchTypes } from '../../Redux/Actions/types';

const InventoryTrackerScreen = (props) => {
  const {
    fetchAssets,
    employees,
    assets,
    types,
    inventoryTrackersListView,
    fetchInventoryTrackers,
    history,
    initInventoryTrackerScreen,
    isFetchingGridData,
    paginationInfo,
    showPageLevelLoader,
    screenPermissions,
    isPageLevelError,
    // downloadAllAttachmentByInventoryTrackerId,
    fetchInventoryTrackerAttachmentsByInventoryTrackerId,
  } = props;


  useEffect(() => {
    initInventoryTrackerScreen();
    fetchAssets({ shouldPaginate: false });
    //fetchTypes({ params: 'CMPLNC_BY&param1=CMPLNC_CMP' });

    fetchInventoryTrackers({ sortField: 'created_date' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editInventoryTrackerRecord = (inventoryTrackerId) => {
    const path = generatePath(APP_URLS.INVENTORY_TRACKERS.EDIT_INVENTORY_TRACKER, { inventoryTrackerId });
    history.push(path);
  };

  const createInventoryTrackersRecord = () => {
    history.push(APP_URLS.INVENTORY_TRACKERS.ADD_INVENTORY_TRACKER);
  };

  const moreActionProps = {
    isMoreActionDisabled: true,
    downloadEnabled: false,
    moreActionOptions: [],
    onMoreActionChange: false
  }
  const handleInventoryTrackerList = (inventoryTrackerData) => {
    return inventoryTrackerData
  }

  const inventoryTrackerProps = {
    inventoryTrackersListView: handleInventoryTrackerList(inventoryTrackersListView, employees, assets, types),
    createInventoryTrackersRecord,
    fetchInventoryTrackers,
    editInventoryTrackerRecord,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isPageLevelError,
    // downloadAllAttachmentByInventoryTrackerId,
    fetchInventoryTrackerAttachmentsByInventoryTrackerId,
    moreActionProps
  };

  if (showPageLevelLoader) {
    loadingsShow('block');
    return null;
  }
  loadingsShow('none');
  return (
    <>
      <InventoryTracker {...inventoryTrackerProps} />;
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initInventoryTrackerScreen: () => dispatch(initializeInventoryTrackerScreen()),
    fetchEmployees: (payload) => dispatch(fetchEmployees(payload)),
    fetchAssets: (payload) => dispatch(fetchAssets(payload)),
    fetchTypes: (payload) => dispatch(fetchTypes(payload)),
    fetchInventoryTrackers: (payload) => dispatch(fetchInventoryTrackers(payload)),
    // downloadAllAttachmentByInventoryTrackerId: (payload) => dispatch(downloadAllAttachmentByInventoryTrackerId(payload)),
    //fetchInventoryTrackerAttachmentsByInventoryTrackerId: payload => dispatch(fetchFilesByInventoryTrackerId(payload))
  };
};

// TODO(Subham):  This selection of data needs improvement, See if we can use UseSelector here.
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    employees: selectFromEmployeeReducer(state, 'employees'),
    assets: selectFromAssetsReducer(state, 'assets'),
    types: selectFromTypesReducer(state, 'types'),
    inventoryTrackersListView: selectInventoryTrackersList(state.inventoryTrackersReducer),
    isFetchingGridData: selectIsLoading(state.inventoryTrackersReducer),
    showPageLevelLoader: selectIsLoadingPage(state.inventoryTrackersReducer),
    paginationInfo: selectPaginationInfo(state.inventoryTrackersReducer),
    isPageLevelError: selectIsPageLevelError(state.inventoryTrackersReducer),
  };
};

const selectFromAssetsReducer = (state, path) => {
  return selectAssetsReducer(state)[path];
};
const selectAssetsReducer = ({ assetsReducer }) => {
  return assetsReducer;
};
const selectFromEmployeeReducer = (state, path) => {
  return selectEmployeeReducer(state)[path];
};
const selectEmployeeReducer = ({ employeeReducer }) => {
  return employeeReducer;
};
const selectFromTypesReducer = (state, path) => {
  return selectTypesReducer(state)[path];
};
const selectTypesReducer = ({ typesReducer }) => {
  return typesReducer;
};
const selectIsPageLevelError = (inventoryTrackerReducer) =>
  inventoryTrackerReducer.isPageLevelError;
const selectPaginationInfo = (inventoryTrackerReducer) =>
  inventoryTrackerReducer.paginationInfo;
const selectIsLoading = (inventoryTrackersReducer) => inventoryTrackersReducer.isLoading;
const selectIsLoadingPage = (inventoryTrackersReducer) =>
  inventoryTrackersReducer.isLoadingPage;
const selectInventoryTrackersList = (inventoryTrackersReducer) =>
  inventoryTrackersReducer.inventoryTrackersListView;


export default connect(mapStateToProps, mapDispatchToProps)(InventoryTrackerScreen);
