const getAssetLocations = (state) => state.assetLocationsReducer.assetLocationsReducer;

export const selectIsPageLevelError = (state) => getAssetLocations(state).isPageLevelError;
export const selectPaginationInfo = (state) => getAssetLocations(state).paginationInfo;
export const selectIsLoading = (state) => getAssetLocations(state).isLoading;
export const selectIsLoadingPage = (state) => getAssetLocations(state).isLoadingPage;
export const selectAssetLocationsList = (state) => getAssetLocations(state).assetLocationsListView; 
export const selectformFieldValueMap = state => getAssetLocations(state).formFieldValueMap;
export const selectIsAssetLocationDetailSuccess = state => getAssetLocations(state).isAssetLocationDetailSuccess;
export const selectError = state => getAssetLocations(state).error;
export const selectIsAssetLocationDetailError = state => getAssetLocations(state).isAssetLocationDetailError;
export const selectIsEditAssetLocationSuccess = state => getAssetLocations(state).isEditAssetLocationSuccess;
export const selectIsEditAssetLocationError = state => getAssetLocations(state).isEditAssetLocationError;
