export const INIT_CREATE_NEW_ASSET_TRACKER_SCREEN =
  'INIT_CREATE_NEW_ASSET_TRACKER_SCREEN';

export const CREATE_ASSET_TRACK_RECORD = {
  START: 'CREATE_ASSET_TRACK_RECORD_START',
  SUCCESS: 'CREATE_ASSET_TRACK_RECORD_SUCCESS',
  ERROR: 'CREATE_ASSET_TRACK_RECORD_ERROR'
};

export const EDIT_ASSET_TRACK_RECORD = {
  START: 'EDIT_ASSET_TRACK_RECORD_START',
  SUCCESS: 'EDIT_ASSET_TRACK_RECORD_SUCCESS',
  ERROR: 'EDIT_ASSET_TRACK_RECORD_ERROR'
};

export const FETCH_ASSET_TRACK_DETAIL = {
  START: 'FETCH_ASSET_TRACK_DETAIL_START',
  SUCCESS: 'FETCH_ASSET_TRACK_DETAIL_SUCCESS',
  ERROR: 'FETCH_ASSET_TRACK_DETAIL_ERROR'
};

export const TOAST = {
  RESET: 'RESET_TOAST',
};