import { baseUrl } from './PostAPI'
export let downloadURL = baseUrl;
//get API services

function loadingShow(hidden) {
  let loading = document.getElementById('loading');
  loading.style.display = hidden;
}
export function getApi(type, token) {
  //loadingShow('block');
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: token,
    },
    method: 'GET',
  })
    .then((response) => {
      if (response.status === 401) {
        loadingShow('none');
        window.location.replace('/');
      }
      return response;
    })
    .then((responseJson) => {
      loadingShow('none');
      return responseJson.json();
    })
    .catch((error) => {
      throw error;
    });
}

//get API services for download
export function getApiDown(type) {
  return fetch(baseUrl + type, {
    headers: {
      // 'Authorization': token
    },
    method: 'GET',
  })
    .then((response) => {
      if (response.status === 401) {
        window.location.replace('/');
      }
      return response;
    })
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      throw error;
    });
}

//get API services with parameter
export function getApiParam(type, param) {
  return fetch(baseUrl + type + param, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      // 'Authorization': token
    },
    method: 'GET',
  })
    .then((response) => {
      if (response.status === 401) {
      }
      return response;
    })
    .then((responseJson) => {
      return responseJson.json();
    })
    .catch((error) => {
      throw error;
    });
}

//get API services for wav file
export function getWaveApi(type) {
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'image/png',
      Accept: 'application/json',
    },
    method: 'GET',
  })
    .then((response) => {
      if (response.status === 401) {
        window.location.replace('/');
      }
      return response;
    })
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      throw error;
    });
}

//get API servicest with body params
export function getApiBody(type, userInput) {
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      // 'Authorization': token
    },
    method: 'GET',
    body: JSON.stringify(userInput),
  })
    .then((response) => {
      if (response.status === 401) {
        window.location.replace('/');
      }
      return response;
    })
    .then((responseJson) => {
      return responseJson.json();
    })
    .catch((error) => {
      throw error;
    });
}
