export const INIT_INVENTORY_TRACKERS = 'INIT_INVENTORY_TRACKERS';
export const FETCH_INVENTORY_TRACKERS_LIST_START = 'FETCH_INVENTORY_TRACKERS_LIST_START';
export const FETCH_INVENTORY_TRACKERS_LIST_SUCCESS = 'FETCH_INVENTORY_TRACKERS_LIST_SUCCESS';
export const FETCH_INVENTORY_TRACKERS_LIST_ERROR = 'FETCH_INVENTORY_TRACKERS_LIST_ERROR';

export const INIT_CREATE_NEW_INVENTORY_TRACKER_SCREEN =
  'INIT_CREATE_NEW_INVENTORY_TRACKER_SCREEN';

export const CREATE_INVENTORY_TRACKER_RECORD = {
  START: 'CREATE_INVENTORY_TRACKER_RECORD_START',
  SUCCESS: 'CREATE_INVENTORY_TRACKER_RECORD_SUCCESS',
  ERROR: 'CREATE_INVENTORY_TRACKER_RECORD_ERROR',
};

export const EDIT_INVENTORY_TRACKER_RECORD = {
  START: 'EDIT_INVENTORY_TRACKER_RECORD_START',
  SUCCESS: 'EDIT_INVENTORY_TRACKER_RECORD_SUCCESS',
  ERROR: 'EDIT_INVENTORY_TRACKER_RECORD_ERROR',
};

export const FETCH_INVENTORY_TRACKER_DETAIL = {
  START: 'FETCH_INVENTORY_TRACKER_DETAIL_START',
  SUCCESS: 'FETCH_INVENTORY_TRACKER_DETAIL_SUCCESS',
  ERROR: 'FETCH_INVENTORY_TRACKER_DETAIL_ERROR',
};


export const TOAST = {
  RESET: 'RESET_TOAST',
};
