const getDocuments = (state) => state.documentsReducer;


export const selectIsPageLevelError = (state) => getDocuments(state).isPageLevelError;
export const selectIsDocumentDeletionError = (state) => getDocuments(state).isDocumentDeletionError;
export const selectIsDocumentDeletionSuccess = (state) => getDocuments(state).isDocumentDeletionSuccess;
export const selectPaginationInfo = (state) => getDocuments(state).paginationInfo;
export const selectIsLoading = (state) => getDocuments(state).isLoading;
export const selectIsLoadingPage = (state) => getDocuments(state).isLoadingPage;
export const selectdocumentsList = (state) => getDocuments(state).documentsListView;
export const selectShouldOpenDeleteConfirmationPopup = (state) => getDocuments(state).shouldOpenDeleteConfirmationPopup;
export const selectIsCreateDocumetSuccess = state => getDocuments(state).isCreateDocumentSuccess;
export const selectIsCreateDocumetError = state => getDocuments(state).isCreateDocumentError;
export const selectformFieldValueMap = state => getDocuments(state).formFieldValueMap;
export const selectIsDocumentDetailSuccess = state => getDocuments(state).isDocumentDetailSuccess;
export const selectError = state => getDocuments(state).error;
export const selectIsDocumentDetailError = state => getDocuments(state).isDocumentDetailError;
export const selectIsEditDocumentSuccess = state => getDocuments(state).isEditDocumentSuccess;
export const selectIsEditDocumentError = state => getDocuments(state).isEditDocumentError;
