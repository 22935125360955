import React from 'react';
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';

const EzControlledPasswordWithFloatingLabel = (props) => {
  const {
    control,
    name,
    rules,
    labelClassName,
    label,
    errorMsg,
    isError,
    toggleMask
  } = props;

  const getLabelClassName = () => {
    return classNames({
      'p-error': isError,
      labelClassName,
    });
  };

  return (
    <div className="field">
      <span className="p-float-label">
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => (
            <Password
              id={field.name}
              {...field}
              className={classNames({
                'p-invalid': fieldState.invalid,
              })}
             
             
              toggleMask={toggleMask} />
            // <InputText
            //   type={type}
            //   id={field.name}
            //   disabled={disabled}
            //   {...field}
            //   className={classNames({
            //     'p-invalid': fieldState.invalid,
            //   })}
            // />
          )}
        />

        <label htmlFor={name} className={getLabelClassName()}>
          {rules?.required ? `${label}*` : label}
        </label>
      </span>
      {errorMsg}
    </div>
  );
};

EzControlledPasswordWithFloatingLabel.defaultProps = {
  labelClassName: '',
  toggleMask: true
};

export default EzControlledPasswordWithFloatingLabel;
