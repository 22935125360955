/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row, Container } from 'react-bootstrap';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Divider } from 'primereact/divider';

import EzControlledInputWithFloatingLabel from '../../../common/EzForm/EzControlledInput/WithFloatingLabel';
import EzControlledDropDowntWithFloatingLabel from '../../../common/EzForm/EzControlledDropDown/WithFloatingLabel';
import EzControlledPasswordWithFloatingLabel from '../../../common/EzForm/EZControlledPassword/WithFloatingLabel';
import { EzToast, TOAST_SEVERITY } from '../../../common/EzToast';
import './index.css';
import { getFormBaseValidationRule } from '../../../utils/form/form-utils';

const VAULT_TYPE_OPTIONS = [
  { label: 'Customer', value: 'CST' },
  { label: 'Company', value: 'CMP' },
];

const CreateVault = (props) => {
  const {
    navigateToVaultSummaryScreen,
    createVault,
    editVault,
    vaultId,
    isLoading,
    isEdit,
    formFieldValueMap,
    isVaultDetailSuccess,
    isCreateVaultSuccess,
    isEditVaultSuccess,
    shouldBlockNavigation,
    loggedInUserCompany,
    fetchCustomers,
    customers
  } = props;
  
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
  
  const type = watch('type');

  useEffect(()=> shouldBlockNavigation(isDirty), [isDirty]);

  useEffect(() => {
    if (isCreateVaultSuccess || isEditVaultSuccess) {
      reset();
      //navigateToVaultSummaryScreen()
    }
  }, [isCreateVaultSuccess]);

  useEffect(() => {
    // if single vault is fetched successfully
    if (isVaultDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
    }
  }, [isVaultDetailSuccess]);
  
  useEffect(() => {
    if (type === VAULT_TYPE_OPTIONS[1].value) {
      setValue('used_by', loggedInUserCompany?.name)
    } else if (type === VAULT_TYPE_OPTIONS[0].value && !customers?.length) {
      fetchCustomers({ shouldPaginate: false })
    }
  }, [type, loggedInUserCompany]);

  const onSubmit = (data) => {
    let usedByVal = data.used_by
    if (data.used_by && (type === VAULT_TYPE_OPTIONS[1].value)) {
      usedByVal = loggedInUserCompany.id
    }
    const dataPayload = {
      ...data,
      ...(data.used_by ? { used_by: usedByVal } : {})
    }  

    if (isEdit) {
      editVault({ ...dataPayload, vaultId  });
    } else {
      createVault({ ...dataPayload });
    }
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const renderPageHeaderSection = () => {
    const breadCrumbs = [
      { label: 'Vaults' },
      { label: isEdit ? 'Edit Vault' : 'Create Vault' },
    ];

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };

    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />
        <div className="rightbtn">
          <Button
            tooltip={'Back to vault summary'}
            tooltipOptions={{ position: 'left' }}
            label={''}
            icon={'pi pi-arrow-left'}
            className={`mr-3 p-button-rounded p-button-outlined p-button-info`}
            onClick={navigateToVaultSummaryScreen}
          />
        </div>
      </div>
    );
  };

  const renderVaultType = () => {
    const name = 'type';

    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={VAULT_TYPE_OPTIONS}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Vault Type"
        labelClassName={'ml-2'}
      />
    );
  };

  const renderUrl = () => {
    const name = 'url';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'URL'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderUserName = () => {
    const name = 'username';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Enter username'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderPassword = () => {
    const name = 'password';

    return (
      <EzControlledPasswordWithFloatingLabel
        name={name}
        control={control}
        label={'Enter password'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderInformation = () => {
    const name = 'info1';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Enter vault info'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderUsedBy = () => {
    const name = 'used_by';
    const label = 'Vault used by';
    
    if (type === VAULT_TYPE_OPTIONS[1].value) {
      return (
        <EzControlledInputWithFloatingLabel
          name={name}
          control={control}
          rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
          label={label}
          isError={errors[name]}
          errorMsg={getFormErrorMessage(name)}
        />
      );
    } else {
      return (
        <EzControlledDropDowntWithFloatingLabel
          name={name}
          control={control}
          options={customers}
          isError={errors[name]}
          rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
          errorMsg={getFormErrorMessage(name)}
          label={label}
          labelClassName={'ml-2'}
        />
      );
    }
    
  };

  const renderUsedFor = () => {
    const name = 'used_for';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        label={'Vault used for'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderDividerRow = (label, align = 'center', type = 'solid') => {
    return (
      <Row>
        <Divider align={align} type={type}>
          <b>{label || ''}</b>
        </Divider>
      </Row>
    );
  };

  const [showErrorToast, setErrorToast] = useState(false);
  const getToastProps = () => {
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle: 'Please fill all mandatory fields',
      shouldShowToast: true,
    };
  };

  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrorToast(true);
    }
  }, [errors]);

  return (
    <div className="paddingbox">
      <div
        className={classNames('containerboxform', { 'p-disabled': isLoading })}
      >
        {renderPageHeaderSection()}
        <div
          className="form-demo"
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
        >
          <div className="card" style={{ background: '#f3faf4BF' }}>
            {showErrorToast && <EzToast {...getToastProps()} />}
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <Container fluid>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderVaultType()}</Col>
                  <Col md="1" />
                  <Col md="8">{renderUrl()}</Col>
                </Row>
                {renderDividerRow('Vault information')}
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderUsedBy()}</Col>
                  <Col md="1" />
                  <Col md="3"> {renderUsedFor()} </Col>
                  <Col md="5">{renderInformation()}</Col>
                </Row>
                
                
                {renderDividerRow('Vault Credentials')}
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderUserName()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderPassword()}</Col>
                </Row>
              </Container>
              <div
                className={'make-flex mt-3 mr-2'}
                style={{ flexDirection: 'row-reverse', background: 'white' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      label="Cancel"
                      onClick={navigateToVaultSummaryScreen}
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      type="reset"
                      label="Reset"
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '170px' }}>
                    <Button
                      loading={isLoading}
                      icon={'pi pi-plus'}
                      type="submit"
                      label={isEdit ? 'Edit Vault' : 'Create Vault'}
                      className="mt-2 p-button-raised"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateVault;
