import { classNames } from "primereact/utils";
import EzControlledAutoSuggestWithFloatingLabel from "../../../common/EzForm/EzControlledAutoSuggest/WithFloatingLabel";
import EzControlledInputWithFloatingLabel from "../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { Col, Row, Container } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { useEffect, useMemo } from "react";
import { useForm } from 'react-hook-form';
import { ProgressBar } from 'primereact/progressbar';
import EzControlledDropDowntWithFloatingLabel from "../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import '../../../../src/App.css';
import { TOAST_SEVERITY, EzToast } from "../../../common/EzToast";
import { useState } from "react";
import { getFormBaseValidationRule } from "../../../utils/form/form-utils";

const AssetTracker = props => {

const {
  assetTrackerStatusOptions,
  assetTrackerDeviceOptions,
  drivers,
  searchDrivers,
  createAssetTracker,
  formFieldValueMap,
  isAssetTrackerDetailSuccess,
  isAssetTrackerDetailError,
  currentAssetRecord,
  editAssetTrackerRecord,
  isEdit,
  closeDialog
} = props;

const { id: assetId, asset_code: assetCode} = currentAssetRecord || {};

const {
  control,
  formState: { errors },
  reset,
  handleSubmit
} = useForm({defaultValues : useMemo(() => ({
  ...formFieldValueMap,
  track_asset: assetCode || ''
}), [assetCode, formFieldValueMap])});

const resetAll = () => {
    reset({
      track_status: "",
      track_device: "",
      track_device_mac: "",
      track_asset_emp: "" 
    })
}

useEffect(() => {
  if (isAssetTrackerDetailSuccess) {
    const {first = '' , last = ''} = formFieldValueMap?.track_asset_emp || {}
    reset({
      ...formFieldValueMap,
    track_asset: assetCode,
    track_status: formFieldValueMap?.track_status?.id,
    track_device: formFieldValueMap?.track_device?.id,
    track_device_mac: formFieldValueMap?.track_device_mac,
    track_asset_emp: `${first} ${last}` 
    });
    }
}, [assetCode, formFieldValueMap, isAssetTrackerDetailSuccess, reset]);

//useEffect(()=> setIsformDirty(isDirty),[isDirty, setIsformDirty]);

const getFormErrorMessage = (name) => {
  return ( errors[name] && <small className="p-error">{errors[name].message}</small> );
};

const isLoading = () => (!isAssetTrackerDetailSuccess && !isAssetTrackerDetailError);

const onSubmit = (data) => {
  const { track_status, track_device, track_asset_emp } = data;
  const reqBody = {
    ...data,
    id: formFieldValueMap?.id,
    track_asset: assetId,
    track_status,
    track_device,
    track_device_mac: data?.track_device_mac,
    track_asset_emp: data?.track_asset_emp?.extraData?.id || drivers?.find(type => type.label === track_asset_emp)?.extraData?.id
  };
  
  if (isEdit){
    editAssetTrackerRecord({ reqBody })
  }
  else {
    createAssetTracker({ reqBody });   
  }
  closeDialog();
};

const [showErrorToast, setErrorToast] = useState(false);
  const getToastProps = () => {
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle: 'Please fill all mandatory fields',
      shouldShowToast: true,
    };
  };

  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrorToast(true);
    }
  }, [errors]);

  
const renderTrackingDevice = () => {
  const name = 'track_device';
  
  return (
    <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={assetTrackerDeviceOptions}
        onChange={(e) => console.log(e)}
        isFloatingLabel={true}
        isControlled={true}
        optionLabel = {'label'}
        optionValue = {'value'} 
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="select Asset owner Type"
        labelClassName={'ml-2'}
      />
    );
}; 

  const renderDriver = () => {
    const name = 'track_asset_emp';
    
    return (
      <EzControlledAutoSuggestWithFloatingLabel
          name={name}
          control={control}
          suggestions={drivers}
          rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
          completeMethod={({ query }) => searchDrivers({ query, filterField: 'first' })}
          label={`select track asset emp`}
          isError={errors[name]}
          errorMsg={getFormErrorMessage(name)}
      />
      );
  };

  const renderTraackingStatus = () => {
    const name = 'track_status';
    
    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        optionLabel = {'label'}
        optionValue = {'value'} 
        options={assetTrackerStatusOptions}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}  
        label="Track Status"
        labelClassName={'ml-2'}
      />
      );
    };
  
  const renderDeviceMac = () => {
    const name = 'track_device_mac';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Enter Track Device mac'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      /> 
    );
  };

  const renderAssetNo = () => {
    const name = 'track_asset';

    return (
      <EzControlledInputWithFloatingLabel
        disabled = {true}
        name={name}
        control={control}
        label={'Track Asset Number'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      /> 
    );
  };



  return (
    
    <div className=" full-height full-width center-display-flex">
    {
      isLoading() &&
            <ProgressBar mode="indeterminate"  style={{height:'6px', width:'50%'}} />
    } 
    { !isLoading() &&
      <div className="paddingbox full-height full-width">
      <div
        className={classNames('containerboxform', { 'p-disabled': false })}
      >
        <div
          className="form-demo"
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
        >
          <div className="card" style={{ background: '#f3faf4BF' }}>
            {showErrorToast && <EzToast {...getToastProps() } />}
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <Container fluid>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderAssetNo()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderTraackingStatus()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderTrackingDevice()}</Col>
                </Row>
                <br></br>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderDeviceMac()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderDriver()}</Col>
                </Row>
              </Container>
              <div
                className={'make-flex mt-3 mr-2'}
                style={{ flexDirection: 'row-reverse', background: 'white' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      type="reset"
                      label="Reset"
                      onClick = { () => { resetAll({})}}
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '170px' }}>
                    <Button
                      loading={false}
                      icon={'pi pi-plus'}
                      type="submit"
                      label={'Save'}
                      className="mt-2 p-button-raised"
                    />    
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      </div>
    }
    </div>
  );

}

export default AssetTracker;