import {
  INIT_ACCIDENTS,
  FETCH_ACCIDENTS_LIST_START,
  FETCH_ACCIDENTS_LIST_SUCCESS,
  FETCH_ACCIDENTS_LIST_ERROR,
  DELETE_ACCIDENT_RECORD_ERROR,
  DELETE_ACCIDENT_RECORD_SUCCESS,
  DELETE_ACCIDENT_RECORD_START,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_ACCIDENT_SCREEN,
  CREATE_ACCIDENT_RECORD,
  EDIT_ACCIDENT_RECORD,
  FETCH_ACCIDENT_DETAIL,
  TOAST
} from '../../../constants/action-types/accidents';
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from '../../../utils/table/gridUtils';
import { buildName } from '../../../utils/string';

export const ACCIDENT_DATE_DATA_KEY = 'date';
export const DRUG_CHECK_DATE_KEY = 'drug_chk_dt';

const isDateField = (key) => {
  const dateFields = [ACCIDENT_DATE_DATA_KEY, DRUG_CHECK_DATE_KEY];
  return dateFields.includes(key);
};

const tranformToAccidentGridView = (accidentData) => {
  const rows = accidentData.map((accident) => {
    const {
      id,
      driver1,
      truck,
      trailer1,
      desc,
      date,
      location,
      injury,
      at_fault,
      drug_chk,
      damages,
    } = accident;

    const transformedValues = {
      id,
      date,
      driver1: {
        name: buildName(driver1),
        toolTip: 'Testing tooltip', // Driver Name - driver1.first + last ( Add tooltip - show supplier name)
      },
      truck: truck?.asset_code,
      trailer1: trailer1?.asset_code,
      desc,
      location,
      isInjured: injury ? 'Yes' : 'No',
      atFault: at_fault ? 'Yes' : 'No',
      isDamaged: damages ? 'Yes' : 'No',
      needDrugTest: drug_chk ? 'Yes' : 'No',
    };
    const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });

  const columns = [
    {
      colLabel: 'Column Id',
      dataKey: 'id',
      extraProps: setColumnDefaultExtraProps({
        hidden: true,
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Accident Date',
      dataKey: ACCIDENT_DATE_DATA_KEY,
      extraProps: setColumnDefaultExtraProps({
        isDateField: true,
      }),
    },
    {
      colLabel: 'Driver Name',
      dataKey: 'driver1.name',
      toolTipKey: 'driverInfo.toolTip',
      extraProps: setColumnDefaultExtraProps({
        filterField: 'driver1.first',
        sortField: 'driver1.first',
      }),
    },
    {
      colLabel: 'Truck',
      dataKey: 'truck',
      extraProps: setColumnDefaultExtraProps({
        filterField: 'truck.asset_code',
        sortField: 'truck.asset_code',
      }),
    },
    {
      colLabel: 'Trailer1',
      dataKey: 'trailer1',
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: true,
        isSortable: true,
      }),
    },
    {
      colLabel: 'Description',
      dataKey: 'desc',
      extraProps: setColumnDefaultExtraProps({ shouldFilter: false }),
    },
    {
      colLabel: 'Location',
      dataKey: 'location',
      extraProps: setColumnDefaultExtraProps({ filterField: 'location' }),
    },
    {
      colLabel: 'Injury',
      dataKey: 'isInjured',
      extraProps: setColumnDefaultExtraProps({
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'At Fault',
      dataKey: 'atFault',
      extraProps: setColumnDefaultExtraProps({
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Phys Damage',
      dataKey: 'isDamaged',
      extraProps: setColumnDefaultExtraProps({
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Drug Test',
      dataKey: 'needDrugTest',
      extraProps: setColumnDefaultExtraProps({
        isSortable: false,
        shouldFilter: false,
      }),
    },
  ];

  return {
    accidentsListView: { columns, rows },
  };
};
const formFieldValueMap = {
  location: '',
  desc: '',
  [ACCIDENT_DATE_DATA_KEY]: null,
  injury: false,
  damages: false,
  at_fault: false,
  liability: false,
  drug_chk: false,
  claim_wt_rcvry: 0,
  claim_wo_rcvry: 0,
  impact_rate: 0,
  impact_result: '',
  rem_action: '',
  driver1: null,
  driver2: null,
  injury_desc: '',
  damage_desc: '',
  at_fault_desc: '',
  liability_desc: '',
  drug_chk_desc: '',
  [DRUG_CHECK_DATE_KEY]: '',
  drug_chk_rslt: null,
  province: '',
  truck: null,
  truck_owner: '',
  trailer1: null,
  trailer1_owner: '',
  trailer2: null,
  trailer2_owner: '',
  owner_opr: '',
  trip_no: '',
  claim_no: '',
  // TODO (subham) - Revisit if we should keep this field in formFieldValueMap
  files: [],
};

const INITIAL_STATE = {
  accidentsListView: {},
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateAccidentSuccess: false,
  isCreateAccidentError: false,
};

export const accidentsReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case INIT_ACCIDENTS:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case FETCH_ACCIDENTS_LIST_START:
    case CREATE_ACCIDENT_RECORD.START:
    case DELETE_ACCIDENT_RECORD_START:
    case FETCH_ACCIDENT_DETAIL.START:
    case EDIT_ACCIDENT_RECORD.START:
      return { ...state, isLoading: true };
    case FETCH_ACCIDENTS_LIST_SUCCESS: {
      const { page, content } = action.payload;
      let resetState = {
        accidentsListView: null,
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isCreateAccidentSuccess: false,
        isCreateAccidentError: false,
      };

      if (!content) {
        return resetState;
      }
      const { number, size, total_elements: totalElements, total_pages: totalPages } = page;
      const { accidentsListView } = tranformToAccidentGridView(content);

      return {
        ...resetState,
        accidentsListView,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages,
          pageNumber: number,
        },
      };
    }

    case FETCH_ACCIDENTS_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        accidentsListView: {},
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isAccidentDeletionSuccess: false,
        isAccidentDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_ACCIDENT_RECORD_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isAccidentDeletionSuccess: false,
        isAccidentDeletionError: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_ACCIDENT_RECORD_SUCCESS: {
      return {
        ...state,
        isAccidentDeletionSuccess: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
      const shouldOpenDeleteConfirmationPopup = action.payload;
      return {
        ...state,
        shouldOpenDeleteConfirmationPopup,
        isAccidentDeletionError: false,
        isAccidentDeletionSuccess: false,
      };
    }

    // TODO(subham): See if this field is needed.
    case INIT_CREATE_NEW_ACCIDENT_SCREEN: {
      return { ...INITIAL_STATE };
    }

    case CREATE_ACCIDENT_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isCreateAccidentSuccess: false,
        isCreateAccidentError: true,
      };
    }
    case CREATE_ACCIDENT_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isCreateAccidentSuccess: true,
        isCreateAccidentError: false,
      };
    }

    case FETCH_ACCIDENT_DETAIL.SUCCESS: {
      const temp = {};
      Object.keys(formFieldValueMap).forEach((key) => {
        const value = action.payload[key];
        temp[key] = value && isDateField(key) ? new Date(value) : value;
      });

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isAccidentDetailSuccess: true,
      };
    }

    case FETCH_ACCIDENT_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isAccidentDetailSuccess: false,
        isAccidentDetailError: true,
      };
    }

    case EDIT_ACCIDENT_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditAccidentSuccess: false,
        isEditAccidentError: true,
      };
    }

    case EDIT_ACCIDENT_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditAccidentSuccess: true,
        isEditAccidentError: false,
      };
    }
    case TOAST.RESET: {
      return {
        ...state,
        isCreateAccidentSuccess: false,
        isCreateAccidentError: false,
        isEditAccidentError: false,
        isEditAccidentSuccess: false,
        isAccidentDetailError: false,
      };
    }
    default:
      return state;
  }
};
