import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import DocumentsGrid from './DocumentsGrid';
import { EzToast, TOAST_SEVERITY } from '../../common/EzToast';
import EzNotFound from '../../common/EzNotFound';

// TODO (Subham): Find why assets path is not working
//const getIconPath = () => process.env.PUBLIC_URL + '/assets/images/acidents/no-record.png';
const getIconPath = (imgPathDelta = '/no-record.png') =>
  process.env.PUBLIC_URL + imgPathDelta;

const Documents = (props) => {
  
  const {
    paginationInfo,
    isFetchingGridData,
    showPageLevelLoader,
    documentsListView,
    fetchDocuments,
    deleteDocumentRecord, 
    isPageLevelError,
    screenPermissions,
    isDocumentDeletionError,
    isDocumentDeletionSuccess,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    createDocumentRecord,
    editDocumentRecord,
    fetchDocumentsAttachmentByDocumentId,
    downloadAttachment,
    moreActionProps
  } = props;

  const [areFiltersVisible, toggleFilterState] = useState(false);
  
  const getToastProps = () => {
    if (isDocumentDeletionSuccess) {
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle: 'Document Deleted Successfully',
        shouldShowToast: true,
      };
    }

    if (isDocumentDeletionError) {
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle: 'Error while Deleting Document',
        shouldShowToast: true,
      };
    }
  };

  const renderPageHeaderSection = () => {
    const breadCrumbs = [{ label: 'Document' }, { label: 'Document Summary' }];

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };

    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />

        <div className="rightbtn">
          <Button
            label={areFiltersVisible ? 'Hide Filters' : 'Show Filters'}
            icon={areFiltersVisible ? 'pi pi-filter-slash' : 'pi pi-filter'}
            className={`mr-2 p-button-raised p-button-outlined p-button-secondary`}
            onClick={() => {
              toggleFilterState(!areFiltersVisible);
            }}
          />
          <Button
            label={'Create New Document'}
            icon={'pi pi-plus'}
            className={`mr-2 p-button-raised`}
            onClick={createDocumentRecord}
          />
        </div>
      </div>
    );
  };

  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderDocumentsRecords = () => documentsListView?.columns?.length > 0;
  const shouldRenderNotFoundView = () => !shouldRenderDocumentsRecords() && !shouldRenderFullPageError();

  const documentsGridProps = {
    areFiltersVisible,
    paginationInfo,
    isFetchingGridData,
    showPageLevelLoader,
    documentsListView,
    fetchDocuments,
    deleteDocumentRecord,
    editDocumentRecord,
    screenPermissions,
    isDocumentDeletionError,
    isDocumentDeletionSuccess,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    fetchDocumentsAttachmentByDocumentId,
    downloadAttachment,
    moreActionProps
  };

  return (
    <>
      
      <EzToast {...getToastProps()} />
      <div className="paddingbox">
        <div className="containerbox">
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: '-70px' }}
                bgImgUrl={getIconPath('something-broke.png')}
                message={'Oops! Something broke, we are working on a fix.'}
              />
            )}  
            {shouldRenderDocumentsRecords() && (
              <Row>
                <Col md={12}>
                  <DocumentsGrid {...documentsGridProps} />
                </Col>
              </Row>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={'No Document records to show'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Documents;
