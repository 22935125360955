import { getApiAsyn } from '../Services/PostAPI';

export const getScreenPermissionInfo = (res, permissionKey) => {
  return res.find((item) => item.code === permissionKey);
};

export const getMenuAccess = async (token) => {
  let res = await getApiAsyn(`screens`, token);

  let ord = res.filter((item) => item.code === 'ORD');
  let cst = res.filter((item) => item.code === 'CST');
  let trp = res.filter((item) => item.code === 'OTRP');
  let db = res.filter((item) => item.code === 'DB');
  let ast = res.filter((item) => item.code === 'AST');
  let rem = res.filter((item) => item.code === 'REIMB');

  const access = {
    orderMenu: ord[0],
    tripMenu: trp[0],
    customerMenu: cst[0],
    dashboardMenu: db[0],
    assetsMenu: ast[0],
    reimbursementsMenu: rem[0],
    employeeMenu: getScreenPermissionInfo(res, 'EMP'),
    complianceMenu: getScreenPermissionInfo(res, 'CMPL'),
    accidentMenu: getScreenPermissionInfo(res, 'ACDNT'),
    inspectionMenu: getScreenPermissionInfo(res, 'INSP'),
    documentMenu: getScreenPermissionInfo(res, 'BDOC'),
    logTrackingMenu: getScreenPermissionInfo(res, 'LTR'),
    vaultMenu: getScreenPermissionInfo(res, 'ACDNT'),
    assetLocationMenu: getScreenPermissionInfo(res, 'AL')
  } 
  localStorage.setItem('menuAccess', JSON.stringify(access));
};