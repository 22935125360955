/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Col, Row, Container } from 'react-bootstrap';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Divider } from 'primereact/divider';
import { ConfirmDialog } from 'primereact/confirmdialog';
import EzControlledInputWithFloatingLabel from '../../../common/EzForm/EzControlledInput/WithFloatingLabel';
import EzControlledCalendarWithFloatingLabel from '../../../common/EzForm/EzControlledCalendar/WithFloatingLabel';
import EzControlledDropDowntWithFloatingLabel from '../../../common/EzForm/EzControlledDropDown/WithFloatingLabel';
import Province from '../../../containers/common/Province';
import './index.css';
import { EzToast, TOAST_SEVERITY } from '../../../common/EzToast';
import { getFormBaseValidationRule } from '../../../utils/form/form-utils';
import EzUploadAttachmentDialog from '../../../EzUploadAttachmentDialog';
import EzDialog from '../../../common/EzDialog';
import DownloadAttachment from '../../DownloadAttachment';
const RECORD_DELETION_POPUP = {
  TEXT_MESSAGE: 'Record will be deleted forever. Do you wish to continue?',
};
const noop = () => { };

const YES_NO_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const CreateCompliance = (props) => {
  const {
    navigateToComplianceSummaryScreen,
    employees,
    trucks,
    cmplncType,
    cmplncItemStatus,
    cmplncCmp,
    cmplnceCatTypeCmp,
    cmplncCatTypeEmp,
    cmplncCatTypeAst,
    fetchEmployees,
    fetchAssets,
    searchTypes,
    createCompliance,
    editCompliance,
    complianceId,
    isLoading,
    isEdit,
    formFieldValueMap,
    isComplianceDetailSuccess,
    isCreateComplianceSuccess,
    isEditComplianceSuccess,
    shouldBlockNavigation,
    fetchFilesByComplianceId,
    downloadAllAttachmentByComplianceId,
  } = props;
  const [catName, setCatName] = useState('');
  const [deleteConfirmationPopupVisibility, setDeleteConfirmationPopupVisibility] = useState(false);
  const [itemIndexAttachment, setItemIndexAttachmentToShow] = useState();
  const [showUploadAttachmentDialog, setShowUploadAttachmentDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);

  const onMoreActionMenu = (event, currentRowData) => {
    setShowDialog(true);
    document.dispatchEvent(new MouseEvent("customContextmenu", event))
    setCurrentRowData(currentRowData)
  }
  const {
    control,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
  const [typesDD, setTypesDD] = useState([]);
  const [catDD, setCatDD] = useState([]);
  const [itemtodelete, setItemtodelete] = useState();

  const { fields, append, update } = useFieldArray({
    control,
    name: "items"
  });
  const watchFieldArray = watch("items");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });

  const handleRemoveClick = (i) => {
    const updatedData = controlledFields.map((item, index) => (
      i === index ? { ...item, status: false } : { ...item }
    ))
    update(i, { status: false, ...updatedData[i] })
  }

  const onDialogHide = () => {
    const getStatus = removedFilePresent => removedFilePresent ? 'N' : 'Y';
    const rFiles = removedFiles[itemIndexAttachment]?.filter(({ id }) => !!id);
    const updatedData = controlledFields.map((item, index) => (
      itemIndexAttachment === index ?
        {
          ...item,
          files: attachedFiles[itemIndexAttachment]?.map(({ id, name }) => ({ id, name, status: getStatus(rFiles?.some((file) => file.id === id)) }))
        }
        : { ...item }
    ))
    update(itemIndexAttachment, { ...updatedData[itemIndexAttachment] });

    setShowUploadAttachmentDialog(false);
  }

  useEffect(() => {
    fetchAssets({
      shouldPaginate: false
    })
    searchTypes(`CMPLNC_CAT_TYPE&param1=CMPLNC_ASSET`)
    searchTypes(`CMPLNC_CAT_TYPE&param1=CMPLNC_EMP`)
    searchTypes(`CMPLNC_CAT_TYPE&param1=CMPLNC_CMP`)
    searchTypes('CMPLNC_TYPE');
    fetchEmployees({
      shouldPaginate: false
    })
    searchTypes('CMPLNC_BY&param1=CMPLNC_CMP');
    searchTypes('CMPLNC_ITEM_STATUS');
  }, [])
  const getTypeAndCategoryDD = (filterApiCode) => {
    let ddtype = [], ddcat = [];
    if (filterApiCode === 'CMPLNC_ASSET') {
      ddtype = trucks
      ddcat = cmplncCatTypeAst
    } else if (filterApiCode === 'CMPLNC_EMP') {
      ddtype = employees
      ddcat = cmplncCatTypeEmp
    } else if (filterApiCode === 'CMPLNC_CMP') {
      ddtype = cmplncCmp
      ddcat = cmplnceCatTypeCmp
    }
    return [ddtype, ddcat];
  }
  const handleDropdownChange = (e) => {
    const { value, id } = e.target;
    if (id === 'type') {
      const [ddtype, ddcat] = getTypeAndCategoryDD(getFilterAPICode(value))
      setTypesDD(ddtype)
      setCatDD(ddcat)
    }
    if (id === 'cat') {
      let cdd = []
      if (getFilterAPICode(type) === 'CMPLNC_ASSET') {
        cdd = cmplncCatTypeAst
      }
      if (getFilterAPICode(type) === 'CMPLNC_EMP') {
        cdd = cmplncCatTypeEmp
      }
      if (getFilterAPICode(type) === 'CMPLNC_CMP') {
        cdd = cmplnceCatTypeCmp
      }
      let getName = cdd.filter(item => item?.value === value)

      setCatName(getName[0]?.label)
    }
  }
  useEffect(() => shouldBlockNavigation(isDirty), [isDirty]);
  useEffect(() => {
    if (isCreateComplianceSuccess || isEditComplianceSuccess) {
      reset();
    }
  }, [isCreateComplianceSuccess]);
  useEffect(() => {
    if (isComplianceDetailSuccess) {
      if (formFieldValueMap?.type?.code) {
        const [ddtype, ddcat] = getTypeAndCategoryDD(getFilterAPICode(formFieldValueMap?.type?.id))
        setTypesDD(ddtype)
        setCatDD(ddcat)
      }
      reset({
        ...formFieldValueMap,
        type: formFieldValueMap?.type?.id,
        cat: formFieldValueMap?.cat?.id,
        due_date: new Date(formFieldValueMap?.due_date),
        cmplnc_status: formFieldValueMap?.cmplnc_status?.id,
        province: formFieldValueMap?.province?.key,
        driver: formFieldValueMap?.driver,
        items: formFieldValueMap?.items.map((item) => ({
          ...item,
          due_date: new Date(item?.due_date),
          date: new Date(item?.date),
          province: item?.province?.key,
          rem_req: item?.rem_req === true ? 'yes' : 'no',
          item_status: item?.item_status?.id,
        }))
      });
      const attachedFilesFromServer = formFieldValueMap.items.reduce((acc, { files }, index) => {
        acc[index] = files;
        return acc;
      }, [])

      setAttachedFiles(attachedFilesFromServer)
    }
  }, [trucks, employees, cmplncCmp, isComplianceDetailSuccess]);
  const type = watch('type');
  const getFilterAPICode = (code) => {
    let filteredData = cmplncType.filter(item => item?.value === code);
    return filteredData?.length ? filteredData[0].code : '';
  }
  const onSubmit = (data) => {
    if (data?.items.length === 0) {
      setToastMessage("Can not create compliances without items")
      setErrorToast(true);
      return
    }
    else {
      data["id"] = data?.id || 0;
      data["desc"] = data?.desc || '';
      data["impct_rslt"] = data?.impct_rslt || '';
      data["impct_rt"] = data?.impct_rt || '';
      data["cmplnc_status"] = data?.items[0].item_status;
      data["due_date"] = new Date(data?.items[0].due_date).getTime();
      let items = data?.items.map(item => ({
        ...item,
        rem_req: item.rem_req === 'yes' ? true : false,
        start_date: new Date(item.date).getTime(),
        strt_date: new Date(item.date).getTime(),
        end_date: new Date(item.due_date).getTime(),
        date: new Date(item.date).getTime(),
        due_date: new Date(item.due_date).getTime(),
      }))

      const allFiles = attachedFiles
        .reduce((collector, attachedFiles) => {
          attachedFiles.forEach(file => collector.push(file))
          return collector;
        }, [])

      const dataPayload = {
        ...data,
        items
      };
      if (isEdit) {
        editCompliance({
          complianceId,
          data: dataPayload,
          files: allFiles
        });
      } else {
        createCompliance({
          data: dataPayload,
          files: allFiles
        });
      }
    }
  };
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const renderPageHeaderSection = () => {
    const breadCrumbs = [
      { label: 'Compliances' },
      { label: isEdit ? 'Edit Compliance' : 'Create Compliance' },
    ];
    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />
        <div className="rightbtn">
          <Button
            tooltip={'Back to compliance summary'}
            tooltipOptions={{ position: 'left' }}
            label={''}
            icon={'pi pi-arrow-left'}
            className={`mr-3 p-button-rounded p-button-outlined p-button-info`}
            onClick={navigateToComplianceSummaryScreen}
          />
        </div>
      </div>
    );
  };
  const handleShowItemModal = (index) => {
    setItemtodelete(index)
    setDeleteConfirmationPopupVisibility(true)
  }
  const handleShowUploadAttachmentDialog = (index) => {
    setShowUploadAttachmentDialog(true);
    setItemIndexAttachmentToShow(index)
    attachedFiles[index] = attachedFiles[index] ?? [];
    setAttachedFiles(attachedFiles)
  }
  //Adding Item
  const handleAddClick = () => {
    append({
      id: 0,
      date: '',
      item_desc: catName,
      strt_date: '',
      due_date: '',
      end_date: '',
      item_status: '',
      status: true,
      province: '',
      rem_req: 'yes',
      rem_action: '',
      ref_no: ''
    })
  };
  //End with Items
  const renderType = () => {
    const name = 'type';
    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        id={name}
        {...register("type")}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={cmplncType}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Compliance"
        labelClassName={'ml-2'}
        onChange={handleDropdownChange}
      />
    );
  };

  const renderTruck = () => {
    const name = 'driver';
    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        id={name}
        {...register("driver")}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={typesDD}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label={'Asset/Driver Name'}
        labelClassName={'ml-2'}
      />
    );
  };
  const renderCategory = () => {
    const name = 'cat';
    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        id={name}
        {...register("cat")}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={catDD}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label={'Category'}
        labelClassName={'ml-2'}
        onChange={handleDropdownChange}
      />
    );
  };
  const renderDescription = () => {
    const name = 'desc';
    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        {...register("desc")}
        control={control}
        label={'Description'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };
  const renderImpactRate = () => {
    const name = 'impct_rt';
    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        {...register("impct_rt")}
        control={control}
        label={'Impact Rate'}
        type="number"
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };
  const renderImpactResult = () => {
    const name = 'impct_rslt';
    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        {...register("impct_rslt")}
        control={control}
        label={'Impact Result'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };
  const renderProvince = () => {
    const name = 'province';
    return (
      <Province
        name={name}
        {...register("province")}
        control={control}
        onChange={(e) => console.log(e)}
        isFloatingLabel={true}
        isControlled={true}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Province"
      />
    );
  };
  const renderDividerRow = (label, align = 'center', type = 'solid') => {
    return (
      <Row>
        <Divider align={align} type={type}>
          <b>{label || ''}</b>
        </Divider>
      </Row>
    );
  };
  const renderRefNo = (index, id) => {
    const refNumber = errors?.items?.[index]?.ref_no;
    return (
      <EzControlledInputWithFloatingLabel
        id={id}
        {...register(`items.${index}.ref_no`)}
        control={control}
        label={`${catName !== '' ? catName : 'Ref No'} #`}
        isError={refNumber}
        errorMsg={getFormErrorMessage(refNumber)}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
      />
    );
  };
  const renderItemDesc = (index, id) => {
    const itemDesc = errors?.items?.[index]?.item_desc;
    return (
      <EzControlledInputWithFloatingLabel
        id={id}
        {...register(`items.${index}.item_desc`)}
        control={control}
        label={'Description'}
        isError={itemDesc}
        errorMsg={getFormErrorMessage(itemDesc)}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
      />
    );
  };

  const renderComplianceItemStartDate = (index, id) => {
    const itemDate = errors?.items?.[index]?.date;
    return (
      <EzControlledCalendarWithFloatingLabel
        id={id}
        name={`items.${index}.date`}
        {...register(`items.${index}.date`)}
        showTime={true}
        maxDate={new Date(2090, 0, 1)}
        control={control}
        isError={itemDate}
        errorMsg={getFormErrorMessage(itemDate)}
        label={'Issue/start date'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
      />
    );
  };
  const renderItemProvince = (index, id) => {
    const itemProvince = errors?.items?.[index]?.province;
    return (
      <Province
        id={id}
        name={`items.${index}.province`}
        control={control}
        {...register(`items.${index}.province`)}
        onChange={(e) => console.log(e)}
        isFloatingLabel={false}
        isControlled={true}
        isError={itemProvince}
        errorMsg={getFormErrorMessage(itemProvince)}
        label="Province"
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
      />
    );
  };
  const renderComplianceItemDueDate = (index, id) => {
    const itemDueDate = errors?.items?.[index]?.due_date;
    return (
      <EzControlledCalendarWithFloatingLabel
        id={id}
        name={`items.${index}.due_date`}
        maxDate={new Date(2090, 0, 1)}
        {...register(`items.${index}.due_date`)}
        showTime={true}
        control={control}
        isError={itemDueDate}
        errorMsg={getFormErrorMessage(itemDueDate)}
        label={'Due/End Date'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
      />
    );
  };
  const renderRemRequireItem = (index, id) => {
    const itemRemRequire = errors?.items?.[index]?.rem_req;
    return (
      <EzControlledDropDowntWithFloatingLabel
        id={id}
        name={id}
        control={control}
        {...register(`items.${index}.rem_req`)}
        options={YES_NO_OPTIONS}
        isError={itemRemRequire}
        errorMsg={getFormErrorMessage(itemRemRequire)}
        label={'Action Required'}
        labelClassName={'ml-2'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
      />
    );
  };
  const renderRemActionItem = (index, id) => {
    const itemRemAction = errors?.items?.[index]?.rem_action;
    return (
      <EzControlledInputWithFloatingLabel
        id={id}
        name={`items.${index}.rem_action`}
        {...register(`items.${index}.rem_action`)}
        control={control}
        label={'Action Description'}
        isError={itemRemAction}
        errorMsg={getFormErrorMessage(itemRemAction)}
        rules={watchFieldArray[index]?.rem_req === 'yes' ? getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE : getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}

      />
    );
  };
  const renderStatusItem = (index, id) => {
    const itemStatus = errors?.items?.[index]?.item_status;
    return (
      <EzControlledDropDowntWithFloatingLabel
        id={id}
        control={control}
        name="item_status"
        {...register(`items.${index}.item_status`)}
        options={cmplncItemStatus}
        isError={itemStatus}
        errorMsg={getFormErrorMessage(itemStatus)}
        label={'Status'}
        labelClassName={'ml-2'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
      />
    );
  };

  const [attachedFiles, setAttachedFiles] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);

  const renderUploadAttachmentDialog = () => {

    const onSelectFile = (e, field) => {
      attachedFiles[itemIndexAttachment] = [...attachedFiles[itemIndexAttachment], ...e.files];
      setAttachedFiles(attachedFiles);
    };

    const onRemoveFile = (fileTobeRemoved) => {
      if (fileTobeRemoved.id !== undefined) {
        removedFiles[itemIndexAttachment] = removedFiles[itemIndexAttachment] ?? [];
        removedFiles[itemIndexAttachment].push(fileTobeRemoved)
        setRemovedFiles(removedFiles);
      } else {
        attachedFiles[itemIndexAttachment] = attachedFiles[itemIndexAttachment]
          .filter(({ objectURL }) => objectURL !== fileTobeRemoved.objectURL);
        setAttachedFiles(attachedFiles);
      }

    }

    return <EzUploadAttachmentDialog
      visible={showUploadAttachmentDialog}
      setShowUploadAttachmentDialog={setShowUploadAttachmentDialog}
      onSelectFile={onSelectFile}
      onRemoveFile={onRemoveFile}
      files={attachedFiles[itemIndexAttachment]}
      onDialogHide={onDialogHide}
    />
  }

  const [toastMessage, setToastMessage] = useState('');
  const [showErrorToast, setErrorToast] = useState(false);
  const getToastProps = () => {
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle: toastMessage,
      shouldShowToast: true,
    };
  };
  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrorToast(true);
    }
  }, [errors]);
  const onDialogBoxHide = () => setShowDialog(false)
  const getDialog = () => {
    const attachmentProps = {
      itemId: complianceId,
      dataId: currentRowData?.id,
      fetchAttachmentsById: fetchFilesByComplianceId,
      downloadAllAttachments: downloadAllAttachmentByComplianceId,

    }
    return showDialog &&
      <EzDialog onHide={onDialogBoxHide} header={'Attachment'}>
        <DownloadAttachment {...attachmentProps} />
      </EzDialog>
  }
  return (
    <div className="paddingbox">
      <div
        className={classNames('containerboxform', { 'p-disabled': isLoading })}
      >
        {renderPageHeaderSection()}
        <div
          className="form-demo"
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
        >
          <div className="card" style={{ background: '#f3faf4BF' }}>
            {showErrorToast && <EzToast {...getToastProps()} />}
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid compliaces">
              <Container fluid>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="4">{renderType()}</Col>
                  <Col md="4">{renderTruck()}</Col>
                  <Col md="4">{renderCategory()}</Col>
                </Row>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="4">{renderDescription()}</Col>
                  <Col md="4">{renderImpactRate()}</Col>
                  <Col md="4">{renderImpactResult()}</Col>
                </Row>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="4">{renderProvince()}</Col>
                </Row>
                <Row style={{ minWidth: '20px' }}>
                  <div style={{ margin: '15px 20px 30px', maxWidth: '270px' }}>
                    <Button
                      icon={'pi pi-plus'}
                      type="button"
                      onClick={handleAddClick}
                      label={'Add compliance item'}
                      className="mt-2 p-button-raised"
                    />
                  </div>
                </Row>
                {controlledFields.map((field, index) => {
                  return (
                    field.status !== false ?
                      <div className='itemrowdata'>
                        {
                          complianceId && (<Button
                            style={{ marginRight: '50px' }}
                            icon="pi pi-download"
                            type="button"
                            className={`custompos2 p-button p-button-sm p-button-raised p-component p-button-rounded p-button-text p-button-icon-only`}
                            onClick={(event) => onMoreActionMenu(event, field)} />)
                        }
                        <Button
                          style={{ marginRight: '50px' }}
                          icon="pi pi-paperclip"
                          type="button"
                          className={`custompos p-button p-button-sm p-button-raised p-component p-button-rounded p-button-text p-button-icon-only`}
                          onClick={() => handleShowUploadAttachmentDialog(index)}
                        />

                        <Button
                          icon="pi pi-trash"
                          type="button"
                          className={`custompos p-button p-button-sm p-button-raised p-component p-button-rounded p-button-text p-button-danger p-button-icon-only`}
                          onClick={() => handleShowItemModal(index)}
                        />
                        {renderDividerRow(field?.item_desc)}
                        <Row className='itemrow'>
                          <Col md="3">
                            {renderRefNo(index, field?.id)}
                          </Col>
                          <Col md="3">
                            {renderItemDesc(index, field?.id)}
                          </Col>
                          <Col md="3">
                            {renderComplianceItemStartDate(index, field?.id)}
                          </Col>
                          <Col md="3">
                            {renderItemProvince(index, field?.id)}
                          </Col>
                        </Row>
                        <Row className='itemrow'>
                          <Col md="3">
                            {renderComplianceItemDueDate(index, field?.id)}
                          </Col>
                          <Col md="3">
                            {renderRemRequireItem(index, field?.id)}
                          </Col>
                          <Col md="3">
                            {renderRemActionItem(index, field?.id)}
                          </Col>
                          <Col md="3">
                            {renderStatusItem(index, field?.id)}
                          </Col>
                        </Row>
                      </div>
                      : null
                  )
                })}
              </Container>

              {getDialog()}
              <div
                className={'make-flex mt-3 mr-2'}
                style={{ flexDirection: 'row-reverse', background: 'white' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      label="Cancel"
                      onClick={navigateToComplianceSummaryScreen}
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      type="reset"
                      label="Reset"
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '170px' }}>
                    <Button
                      loading={isLoading}
                      icon={'pi pi-plus'}
                      type="submit"
                      label={isEdit ? 'Edit Compliance' : 'Create Compliance'}
                      className="mt-2 p-button-raised"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ConfirmDialog
        visible={deleteConfirmationPopupVisibility}
        message={RECORD_DELETION_POPUP.TEXT_MESSAGE}
        header="Please Confirm Deletion"
        position={'top'}
        onHide={() => setDeleteConfirmationPopupVisibility(false)}
        acceptLabel={'Cancel'}
        rejectLabel={'Delete Record'}
        acceptClassName={'p-button-raised'}
        rejectClassName={'p-button-text p-button-danger'}
        reject={() => {
          handleRemoveClick(itemtodelete);
        }}
        accept={() => setDeleteConfirmationPopupVisibility(false)}
      />
      {renderUploadAttachmentDialog()}
    </div>
  );
};
export default CreateCompliance;
