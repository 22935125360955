import {
  INIT_ASSETS,
  FETCH_ASSETS_START,
  FETCH_ASSETS_SUCCESS,
  FETCH_ASSETS_ERROR,
  DELETE_ASSET_RECORD_ERROR,
  DELETE_ASSET_RECORD_SUCCESS,
  DELETE_ASSET_RECORD_START,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  CREATE_ASSET_RECORD,
  EDIT_ASSET_RECORD,
  FETCH_ASSET_DETAIL,
  TOAST,
} from '../../../constants/action-types/assets';

import { decorateEmptyGridValues, setColumnDefaultExtraProps } from '../../../utils/table/gridUtils';

const getOwnersTypeValue = (value) => value === 'C' ? 'Company' : decorateEmptyGridValues(value);

const formFieldValueMap = {
      id: '',
      asset_code: '',
      owner_typ: '',
      asset_owner: '',
      asset_own_typ: '',
      asset_typ: '',
      vehicle_typ: '',
      vehicle_vin: '',
      province: '',
      vehicle_no: '',
      other_party: '',
      info: '',
      equip_type: '',
      vendor: '',
      year: '',
      asset_make: null,
      asset_model: null,
      ownership: 'owned',
      files:[],
      fleet_card1: '',
      fleet_card2: '',
}



const tranformToAssetGridView = (assetData) => {
  const rows = assetData.map((asset) => {
    const {
      id,
      asset_code,
      owner_typ,
      asset_owner,
      asset_own_typ,
      asset_typ,
      vehicle_typ,
      vehicle_vin,
      province,
      vehicle_no,
      other_party,
      info,
      equip_type,
      asset_make,
      asset_model,
      year
    } = asset;

    const transformedValues = {
      id,
      asset_code,
      owner_typ,
      asset_owner,
      asset_own_typ: asset_own_typ?.Business,
      asset_typ: asset_typ?.name ,
      vehicle_typ: vehicle_typ?.name,
      vehicle_vin,
      province,
      vehicle_no,
      other_party,
      info,
      equip_type,
      asset_make: asset_make?.name,
      asset_model: asset_model?.name,
      year
    };
    const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      if(key === 'owner_typ') decoratedValues[key] = getOwnersTypeValue(transformedValues[key])
      else decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });

  const columns = [
    {
      colLabel: 'Column Id',
      dataKey: 'id',
      extraProps: setColumnDefaultExtraProps({
        hidden: true,
        isSortable: true,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Asset',
      dataKey: 'asset_code',
      extraProps: setColumnDefaultExtraProps({
        filterField:'asset_code'
      }),
    },
    {
      colLabel: 'Owner Type',
      dataKey: 'owner_typ',
      extraProps: setColumnDefaultExtraProps({
        sortField: 'owner_typ',
        shouldFilter : false
      }),
    },
    {
      colLabel: 'Asset Type',
      dataKey: 'asset_typ',
      extraProps: setColumnDefaultExtraProps({
        filterField: 'asset_typ.name',
        sortField: 'asset_typ',
      }),
    },
    {
      colLabel: 'Vehicle Type',
      dataKey: 'vehicle_typ',
      extraProps: setColumnDefaultExtraProps({
        isSortable: true,
      }),
    },
    {
      colLabel: 'Vehicle No',
      dataKey: 'vehicle_no',
    },
    {
      colLabel: 'VIN',
      dataKey: 'vehicle_vin',
      extraProps: setColumnDefaultExtraProps({ filterField: 'vehicle_vin' }),
    },
    {
      colLabel: 'Make',
      dataKey: 'asset_make',
    },
    {
      colLabel: 'Model',
      dataKey: 'asset_model',
    },
    {
      colLabel: 'Year',
      dataKey: 'year',
      extraProps: setColumnDefaultExtraProps({
        isSortable: true,
      })
    }
    
  ];

  return {
    assetsListView: { columns, rows },
  };
};

const INITIAL_STATE = {
  assetsListView: {},
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateAssetSuccess: false,
  isCreateAssetError: false,
};

export const assetsReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case INIT_ASSETS:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case FETCH_ASSETS_START:
    case CREATE_ASSET_RECORD.START:
    case DELETE_ASSET_RECORD_START:
    case FETCH_ASSET_DETAIL.START:
    case EDIT_ASSET_RECORD.START:
      return { ...state, isLoading: true };
    case FETCH_ASSETS_SUCCESS: {
      const { page, content } = action.payload;
      let resetState = {
        formFieldValueMap,
        assetsListView: null,
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isCreateAssetSuccess: false,
        isCreateAssetError: false,
      };

      if (!content) {
        return resetState;
      }
      const { number, size, total_elements: totalElements, total_pages: totalPages } = page;
      const { assetsListView } = tranformToAssetGridView(content);

      return {
        ...resetState,
        assetsListView,
        assets: content,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages,
          pageNumber: number,
        },
      };
    }

    case FETCH_ASSETS_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        assetsListView: {},
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isAssetDeletionSuccess: false,
        isAssetDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_ASSET_RECORD_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isAssetDeletionSuccess: false,
        isAssetDeletionError: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_ASSET_RECORD_SUCCESS: {
      return {
        ...state,
        isAssetDeletionSuccess: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
      const shouldOpenDeleteConfirmationPopup = action.payload;
      return {
        ...state,
        shouldOpenDeleteConfirmationPopup,
        isAssetDeletionError: false,
        isAssetDeletionSuccess: false,
      };
    }

    case CREATE_ASSET_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isCreateAssetSuccess: false,
        isCreateAssetError: true,
      };
    }
    case CREATE_ASSET_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isCreateAssetSuccess: true,
        isCreateAssetError: false,
      };
    }

    case FETCH_ASSET_DETAIL.SUCCESS: {
      const temp = {};
      Object.keys(formFieldValueMap).forEach((key) => {
        const value = action.payload[key];
        temp[key] = value;
      });

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isAssetDetailSuccess: true,
      };
    }

    case FETCH_ASSET_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isAssetDetailSuccess: false,
        isAssetDetailError: true,
      };
    }

    case EDIT_ASSET_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditAssetSuccess: false,
        isEditAssetError: true,
      };
    }

    case EDIT_ASSET_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditAssetSuccess: true,
        isEditAssetError: false,
      };
    }
    case TOAST.RESET: {
      return {
        ...state,
        isCreateAssetSuccess: false,
        isCreateAssetError: false,
        isEditAssetError: false,
        isEditAssetSuccess: false,
        isAssetDetailError: false,
      };
    }
    default:
      return state;
  }
};
