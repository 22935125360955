import {
  FETCH_USER_PROFILE_START,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_COMPANY_INFO_START,
  FETCH_USER_COMPANY_INFO_SUCCESS,
  FETCH_USER_COMPANY_INFO_ERROR,
  FETCH_SWITCHABLE_ACCOUNTS_START,
  FETCH_SWITCHABLE_ACCOUNTS_SUCCESS,
  FETCH_SWITCHABLE_ACCOUNTS_ERROR,
  SWITCH_ACCOUNTS_START,
  SWITCH_ACCOUNTS_SUCCESS,
  SWITCH_ACCOUNTS_ERROR
} from '../../../constants/action-types/userProfile'
import { switchAccountService, userProfileService } from '../../../Services'

export const userInfoFetchStart = (payload) => ({
  type: FETCH_USER_PROFILE_START,
  payload,
});

export const userInfoFetchSuccess = (payload) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload,
});

export const userInfoFetchError = (payload) => ({
  type: FETCH_USER_PROFILE_ERROR,
  payload,
});


export const fetchUserInfo = (payload) => {
  return (dispatch) => {
    dispatch(userInfoFetchStart(payload));

    userProfileService.fetchUserInfo(payload).then((userProfileData) => {
      if (!userProfileData.isError) {
        dispatch(userInfoFetchSuccess(userProfileData));
      } else {
        dispatch(userInfoFetchError(userProfileData));
      }
    });
  };
};

// Company Info
export const userCompanyInfoFetchStart = (payload) => ({
  type: FETCH_USER_COMPANY_INFO_START,
  payload,
});

export const userCompanyInfoFetchSuccess = (payload) => ({
  type: FETCH_USER_COMPANY_INFO_SUCCESS,
  payload,
});

export const userCompanyInfoFetchError = (payload) => ({
  type: FETCH_USER_COMPANY_INFO_ERROR,
  payload,
});

export const fetchUserCompanyInfo = (payload) => {
  return (dispatch) => {
    dispatch(userCompanyInfoFetchStart(payload));

    userProfileService.fetchUserCompanyInfo(payload).then((userCompanyData) => {
      if (!userCompanyData.isError) {
        dispatch(userCompanyInfoFetchSuccess(userCompanyData));
      } else {
        dispatch(userCompanyInfoFetchError(userCompanyData));
      }
    });
  };
};

// Company Info
export const switchableAccountsFetchStart = () => ({
  type: FETCH_SWITCHABLE_ACCOUNTS_START
});

export const switchableAccountsFetchSuccess = (payload) => ({
  type: FETCH_SWITCHABLE_ACCOUNTS_SUCCESS,
  payload,
});

export const switchableAccountsFetchError = (payload) => ({
  type: FETCH_SWITCHABLE_ACCOUNTS_ERROR,
  payload,
});

export const fetchSwitchableAccounts = () => {
  return (dispatch) => {
    dispatch(switchableAccountsFetchStart());

    switchAccountService.fetchSwitchableAccounts().then((data) => {
      if (!data.isError) {
        dispatch(switchableAccountsFetchSuccess(data));
      } else {
        dispatch(switchableAccountsFetchError(data));
      }
    });
  };
};

// switch account
export const switchAccountStart = () => ({
  type: SWITCH_ACCOUNTS_START
});

export const switchAccountSuccess = (payload) => ({
  type: SWITCH_ACCOUNTS_SUCCESS,
  payload,
});

export const switchAccountError = (payload) => ({
  type: SWITCH_ACCOUNTS_ERROR,
  payload,
});

export const switchAccount = (companyCode) => {
  return (dispatch) => {
    dispatch(switchAccountStart());

    switchAccountService.switchAccount(companyCode).then((data) => {
      if (!data.isError) {
        dispatch(switchAccountSuccess(data));
      } else {
        dispatch(switchAccountError(data));
      }
    });
  };
};

export const switchBack = () => {
  return (dispatch) => {
    dispatch(switchAccountStart());

    switchAccountService.switchBack().then((data) => {
      if (!data.isError) {
        dispatch(switchAccountSuccess(data));
      } else {
        dispatch(switchAccountError(data));
      }
    });
  };
};
