import {
  INIT_TYPES,
  FETCH_TYPES_START,
  FETCH_TYPES_SUCCESS,
  FETCH_TYPES_ERROR,
} from '../../../constants/action-types/types';
import { typesService } from '../../../Services';

export const initializeTypesScreen = () => ({ type: INIT_TYPES });

export const typesFetchStart = (payload) => ({
  type: FETCH_TYPES_START,
  payload,
});

export const typesFetchSuccess = (payload, typesKey) => ({
  type: FETCH_TYPES_SUCCESS,
  payload,
  typesKey
});

export const typesFetchError = (payload) => ({
  type: FETCH_TYPES_ERROR,
  payload,
});

export const fetchTypes = (payload) => {
  return (dispatch) => {
    dispatch(typesFetchStart(payload));
    typesService.fetchTypes(payload).then((typesData) => {
      if (!typesData.isError) {
        dispatch(typesFetchSuccess(typesData, payload?.params));
      } else {
        dispatch(typesFetchError(typesData));
      }
    });
  };
};

