import { connect } from 'react-redux';
import { ProgressBar } from 'primereact/progressbar';

import { EzToast, TOAST_SEVERITY } from '../../../common/EzToast';
import CreateAccident from '../../../components/Accidents/CreateAccident';
import { APP_URLS } from '../../../constants/urlConstants';
import { fetchEmployees } from '../../../Redux/Actions/employees';
import { fetchAssets } from '../../../Redux/Actions/assets';
import {
  initCreateNewAccidentScreen,
  createAccident,
  editAccident,
  fetchAccidentByAccidentId,
} from '../../../Redux/Actions/accidents';
import { useEffect } from 'react';
import { setIsformDirty } from '../../../Redux/Actions/common/form';
import { FILTER } from '../../../Services/common/urlService';

const ASSET_TYPE = {
  VEHICLE: {
    TRUCK: 'TRUCK',
    TRAILER: 'TRAILER',
  },
};

const transformEmployees = (employees) => {
  return employees?.map((emp) => {
    const { first, last, code } = emp;
    return {
      label: `${first || ''} ${last || ''}`,
      value: code,
      extraData: emp,
    };
  });
};

const transformAssets = (assets, assetType) => {
  const filteredAssets = [];

  assets?.forEach((asset) => {
    const { asset_code, vehicle_typ } = asset;
    if (vehicle_typ?.code === assetType) {
      filteredAssets.push({
        label: asset_code,
        value: asset_code,
        extraData: asset,
      });
    }
  });
  return filteredAssets;
};

const CreateAccidentContainer = (props) => {
  const {
    employees,
    assets,
    history,
    fetchEmployees,
    fetchAssets,
    createAccident,
    editAccident,
    isLoading,
    isCreateAccidentSuccess,
    isCreateAccidentError,
    isAccidentDetailSuccess,
    isAccidentDetailError,
    isEditAccidentSuccess,
    isEditAccidentError,
    isEdit,
    formFieldValueMap,
    fetchAccidentByAccidentId,
    shouldBlockNavigation
  } = props;

  const { accidentId } = props.match.params;

  const searchEmployee = ({ query, filterField }) => {
    if (query) {
      fetchEmployees({
        shouldPaginate: false,
        filters: [{ filterField, query, matchStrategy: FILTER.MATCH_STRATEGY.CONTAINS }],
      });
    } else {
      fetchEmployees();
    }
  };

  const searchAssets = ({ query, filterField }) => {
    if (query) {
      fetchAssets({
        shouldPaginate: false,
        filters: [{ filterField, query, matchStrategy: FILTER.MATCH_STRATEGY.CONTAINS }],
      });
    } else {
      fetchAssets();
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchAccidentByAccidentId({ accidentId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const navigateToAccidentSummaryScreen = () => {
    history.push(APP_URLS.ACCIDENTS.HOME);
  };

  const createAccidentProps = {
    navigateToAccidentSummaryScreen,
    employees: transformEmployees(employees),
    trucks: transformAssets(assets, ASSET_TYPE.VEHICLE.TRUCK),
    trailers: transformAssets(assets, ASSET_TYPE.VEHICLE.TRAILER),
    searchAssets,
    searchEmployee,
    createAccident,
    editAccident,
    isLoading,
    isEdit,
    isAccidentDetailSuccess,
    isCreateAccidentSuccess,
    isEditAccidentSuccess,
    isEditAccidentError,
    formFieldValueMap,
    accidentId,
    shouldBlockNavigation
  };
  const getToastProps = () => {
    if (isCreateAccidentSuccess || isEditAccidentSuccess) {
      const toastTitle = isEdit
        ? 'Accident Updated Successfully'
        : 'Accident Created Successfully';
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (isCreateAccidentError || isAccidentDetailError || isEditAccidentError) {
      let toastTitle = 'Error while Creating Accident';
      if (isEditAccidentError) {
        toastTitle = 'Error while updating Accident';
      } else if (isAccidentDetailError) {
        toastTitle =
          'Error while performing operation. Please refresh your browser';
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateAccident {...createAccidentProps} />;
    </>
  );
};  

const mapStateToProps = (state, ownProps) => {
  return {
    employees: selectFromEmployeeReducer(state, 'employees'),
    assets: selectFromAssetsReducer(state, 'assets'),
    isLoading: selectFromAccidentsReducer(state, 'isLoading'),
    isCreateAccidentSuccess: selectFromAccidentsReducer(
      state,
      'isCreateAccidentSuccess'
    ),
    isCreateAccidentError: selectFromAccidentsReducer(
      state,
      'isCreateAccidentError'
    ),
    isAccidentDetailSuccess: selectFromAccidentsReducer(
      state,
      'isAccidentDetailSuccess'
    ),
    isAccidentDetailError: selectFromAccidentsReducer(
      state,
      'isAccidentDetailError'
    ),
    isEditAccidentSuccess: selectFromAccidentsReducer(
      state,
      'isEditAccidentSuccess'
    ),
    isEditAccidentError: selectFromAccidentsReducer(
      state,
      'isEditAccidentError'
    ),
    formFieldValueMap: selectFromAccidentsReducer(state, 'formFieldValueMap'),
    ...ownProps,
  };
};

const selectFromAssetsReducer = (state, path) => {
  return selectAssetsReducer(state)[path];
};

const selectFromAccidentsReducer = (state, path) => {
  return selectAccidentsReducer(state)[path];
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateNewAccidentScreen: () => dispatch(initCreateNewAccidentScreen()),
    fetchAccidentByAccidentId: (payload) => dispatch(fetchAccidentByAccidentId(payload)),
    fetchEmployees: (payload) => dispatch(fetchEmployees(payload)),
    fetchAssets: (payload) => dispatch(fetchAssets(payload)),
    createAccident: (payload) => dispatch(createAccident(payload)),
    editAccident: (payload) => dispatch(editAccident(payload)),
    shouldBlockNavigation : isFormDirty => dispatch(setIsformDirty(isFormDirty))
  };
};

const selectAccidentsReducer = ({ accidentsReducer }) => {
  return accidentsReducer;
};

const selectAssetsReducer = ({ assetsReducer }) => {
  return assetsReducer;
};

const selectFromEmployeeReducer = (state, path) => {
  return selectEmployeeReducer(state)[path];
};

const selectEmployeeReducer = ({ employeeReducer }) => {
  return employeeReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccidentContainer);
