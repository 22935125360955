import { useEffect } from "react";

import EzControlledDropDowntWithFloatingLabel from '../../common/EzForm/EzControlledDropDown/WithFloatingLabel';
import { fetchTypes } from "../../Redux/Actions/types";
import { connect } from "react-redux";
import { getTypesReducer } from "../../Redux/Selectors/types";
import { transformTypesToDropDown } from "../../utils/dropDown/transformtoDropdown";

const Dropdown = (props) => {
  const { 
          name,
          control,
          label,
          rules,
          errorMsg,
          isError,
          dropdownType,
          fetchTypes,
          allTypes,
          onChange,
          disabled
        } = props;
  
  useEffect(()=> {
    fetchTypes({params : dropdownType })
    }, [dropdownType, fetchTypes]);

  const typeList = transformTypesToDropDown(allTypes?.[dropdownType]) || []

  return (
    <span>
      <EzControlledDropDowntWithFloatingLabel
        disabled={disabled}
        name={name}
        control={control}
        rules={rules}
        onChange={onChange}
        optionLabel={'label'}
        optionValue={'value'}
        options={typeList}
        isError={isError}
        errorMsg={errorMsg}
        label={label}
        labelClassName={'ml-2'}
      />
  </span>)

} 


const mapStateToProps = (state, ownProps) => {
  return {
    allTypes: getTypesReducer(state).types,
    ...ownProps    
  }
}

const mapDispatchToProps = dispatch => {
  return  {
    fetchTypes : payload => dispatch(fetchTypes(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);

