const getAssetTracker = state => state.assetTrackReducer;

export const selectIsCreateAssetTrackerSuccess = state => getAssetTracker(state).isCreateAssetTrackerSuccess;
export const selectIsCreateAssetTrackerError = state => getAssetTracker(state).isCreateAssetTrackerError;
export const selectIsLoading = (state) => getAssetTracker(state).isLoading;
export const selectIsAssetTrackerDetailSuccess = state => getAssetTracker(state).isAssetTrackerDetailSuccess;
export const selectIsAssetTrackerDetailError = state => getAssetTracker(state).isAssetTrackerDetailError;
export const selectAssetTrackerFormValues = state => getAssetTracker(state).formFieldValueMap;  
export const selectIsEditAssetTrackerSuccess = state => getAssetTracker(state).isEditAssetTrackerSuccess;
export const selectIsEditAssetTrackerError = state => getAssetTracker(state).isEditAssetTrackerError;
