import { connect } from 'react-redux';
import { ProgressBar } from 'primereact/progressbar';

import { EzToast, TOAST_SEVERITY } from '../../../common/EzToast';
import CreateInspection from '../../../components/Inspections/CreateInspection';
import { APP_URLS } from '../../../constants/urlConstants';
import { fetchEmployees } from '../../../Redux/Actions/employees';
import { fetchAssets } from '../../../Redux/Actions/assets';
import {
  initCreateNewInspectionScreen,
  createInspection,
  editInspection,
  fetchInspectionByInspectionId,
} from '../../../Redux/Actions/inspections';
import { useEffect } from 'react';
import { setIsformDirty } from '../../../Redux/Actions/common/form';
import { FILTER } from '../../../Services/common/urlService';

const ASSET_TYPE = {
  VEHICLE: {
    TRUCK: 'TRUCK',
    TRAILER: 'TRAILER',
  },
};

const transformEmployees = (employees) => {
  return employees?.map((emp) => {
    const { first, last, code } = emp;
    return {
      label: `${first || ''} ${last || ''}`,
      value: code,
      extraData: emp,
    };
  });
};

const transformAssets = (assets, assetType) => {
  const filteredAssets = [];

  assets?.forEach((asset) => {
    const { asset_code, vehicle_typ } = asset;
    if (vehicle_typ?.code === assetType) {
      filteredAssets.push({
        label: asset_code,
        value: asset_code,
        extraData: asset,
      });
    }
  });
  return filteredAssets;
};

const CreateInspectionContainer = (props) => {
  const {
    employees,
    assets,
    history,
    fetchEmployees,
    fetchAssets,
    createInspection,
    editInspection,
    isLoading,
    isCreateInspectionSuccess,
    isCreateInspectionError,
    isInspectionDetailSuccess,
    isInspectionDetailError,
    isEditInspectionSuccess,
    isEditInspectionError,
    isEdit,
    formFieldValueMap,
    fetchInspectionByInspectionId,
    shouldBlockNavigation
  } = props;

  const { inspectionId } = props.match.params;

  const searchEmployee = ({ query, filterField }) => {
    if (query) {
      fetchEmployees({
        shouldPaginate: false,
        filters: [{ filterField, query, matchStrategy: FILTER.MATCH_STRATEGY.CONTAINS }],
      });
    } else {
      fetchEmployees();
    }
  };

  const searchAssets = ({ query, filterField }) => {
    if (query) {
      fetchAssets({
        shouldPaginate: false,
        filters: [{ filterField, query, matchStrategy: FILTER.MATCH_STRATEGY.CONTAINS }],
      });
    } else {
      fetchAssets();
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchInspectionByInspectionId({ inspectionId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const navigateToInspectionSummaryScreen = () => {
    history.push(APP_URLS.INSPECTION.HOME);
  };

  const createInspectionProps = {
    navigateToInspectionSummaryScreen,
    employees: transformEmployees(employees),
    trucks: transformAssets(assets, ASSET_TYPE.VEHICLE.TRUCK),
    trailers: transformAssets(assets, ASSET_TYPE.VEHICLE.TRAILER),
    searchAssets,
    searchEmployee,
    createInspection,
    editInspection,
    isLoading,
    isEdit,
    isInspectionDetailSuccess,
    isCreateInspectionSuccess,
    isEditInspectionSuccess,
    isEditInspectionError,
    formFieldValueMap,
    inspectionId,
    shouldBlockNavigation
  };

  const getToastProps = () => {
    if (isCreateInspectionSuccess || isEditInspectionSuccess) {
      const toastTitle = isEdit
        ? 'Inspection Updated Successfully'
        : 'Inspection Created Successfully';
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isCreateInspectionError ||
      isInspectionDetailError ||
      isEditInspectionError
    ) {
      let toastTitle = 'Error while Creating Inspection';
      if (isEditInspectionError) {
        toastTitle = 'Error while updating Inspection';
      } else if (isInspectionDetailError) {
        toastTitle =
          'Error while performing operation. Please refresh your browser';
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateInspection {...createInspectionProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    employees: selectFromEmployeeReducer(state, 'employees'),
    assets: selectFromAssetsReducer(state, 'assets'),
    isLoading: selectFromInspectionsReducer(state, 'isLoading'),
    isCreateInspectionSuccess: selectFromInspectionsReducer(
      state,
      'isCreateInspectionSuccess'
    ),
    isCreateInspectionError: selectFromInspectionsReducer(
      state,
      'isCreateInspectionError'
    ),
    isInspectionDetailSuccess: selectFromInspectionsReducer(
      state,
      'isInspectionDetailSuccess'
    ),
    isInspectionDetailError: selectFromInspectionsReducer(
      state,
      'isInspectionDetailError'
    ),
    isEditInspectionSuccess: selectFromInspectionsReducer(
      state,
      'isEditInspectionSuccess'
    ),
    isEditInspectionError: selectFromInspectionsReducer(
      state,
      'isEditInspectionError'
    ),
    formFieldValueMap: selectFromInspectionsReducer(state, 'formFieldValueMap'),
    ...ownProps,
  };
};

const selectFromAssetsReducer = (state, path) => {
  return selectAssetsReducer(state)[path];
};

const selectFromInspectionsReducer = (state, path) => {
  return selectInspectionsReducer(state)[path];
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateNewInspectionScreen: () =>
    dispatch(initCreateNewInspectionScreen()),
    fetchInspectionByInspectionId: (payload) =>
    dispatch(fetchInspectionByInspectionId(payload)),
    fetchEmployees: (payload) => dispatch(fetchEmployees(payload)),
    fetchAssets: (payload) => dispatch(fetchAssets(payload)),
    createInspection: (payload) => dispatch(createInspection(payload)),
    editInspection: (payload) => dispatch(editInspection(payload)),
    shouldBlockNavigation : isFormDirty => dispatch(setIsformDirty(isFormDirty))
  };
};

const selectInspectionsReducer = ({ inspectionsReducer }) => {
  return inspectionsReducer;
};

const selectAssetsReducer = ({ assetsReducer }) => {
  return assetsReducer;
};

const selectFromEmployeeReducer = (state, path) => {
  return selectEmployeeReducer(state)[path];
};

const selectEmployeeReducer = ({ employeeReducer }) => {
  return employeeReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateInspectionContainer);
