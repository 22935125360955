import React, { Component } from 'react';
import CustomersMenu from '../CustomersMenu';

class Invoices extends Component {
  render() {
    return (
      <div>
        <CustomersMenu activess="active" />
        <div className="paddingbox">
          <div className="containerbox">
            <h3>Invoices</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Invoices;
