import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import EzTable from '../../common/EzTable';
import { EzToast } from '../../common/EzToast';
import EzNotFound from '../../common/EzNotFound';
import { INVENTORY_TRACKER_ID} from '../../Redux/Reducers/inventoryTracker';
const getIconPath = (imgPathDelta = '/no-record.png') =>
  process.env.PUBLIC_URL + imgPathDelta;
const InventoryTracker = (props) => {
  const {
    inventoryTrackersListView,
    createInventoryTrackersRecord,
    editInventoryTrackerRecord,
    fetchInventoryTrackers,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isPageLevelError,
    downloadAllAttachmentByInventoryTrackerId,
    fetchInventoryTrackerAttachmentsByInventoryTrackerId,
  } = props;
  const [areFiltersVisible, toggleFilterState] = useState(false);
    const renderPageHeaderSection = () => {
    const breadCrumbs = [{ label: 'InventoryTrackers' }, { label: 'InventoryTracker Summary' }];
    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />
        <div className="rightbtn">
          <Button
            label={areFiltersVisible ? 'Hide Filters' : 'Show Filters'}
            icon={areFiltersVisible ? 'pi pi-filter-slash' : 'pi pi-filter'}
            className={`mr-2 p-button-raised p-button-outlined p-button-secondary`}
            onClick={() => {
              toggleFilterState(!areFiltersVisible);
            }}
          />
          <Button
            label={'Create New Inventory Tracker'}
            icon={'pi pi-plus'}
            className={`mr-2 p-button-raised`}
            onClick={createInventoryTrackersRecord}
          />
        </div>
      </div>
    );
  };
  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderInventoryTrackerRecords = () =>
    inventoryTrackersListView?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderInventoryTrackerRecords() && !shouldRenderFullPageError();
  const filters = {
    global: { value: null },
    issued_to: { filterKey: 'issued_to', value: null },
    issuer: {  filterKey: 'issuer.first', value: null },
    issued_by: {  filterKey: 'issuer.first', value: null },
    rental_status: {  filterKey: 'rental_status.name', value: null },
  };
  const loadLazyData = params => {
    fetchInventoryTrackers(params)
  }
  const tableProps = {
    areFiltersVisible,
    columns: inventoryTrackersListView?.columns,
    paginationInfo,
    screenPermissions,
    onEditRecord: editInventoryTrackerRecord,
    loadLazyData,
    value: inventoryTrackersListView?.rows,
    emptyMessage: 'No InventoryTrackers found.',
    filters,
    loading: isFetchingGridData,
    fetchAttachmentsById: fetchInventoryTrackerAttachmentsByInventoryTrackerId,
    downloadAllAttachments: downloadAllAttachmentByInventoryTrackerId,
    showMoreActions: false,
    sortField: 'created_date',
    dataKey: null
  };
  return (
    <>
      <EzToast  />
      <div className="paddingbox">
        <div className="containerbox">
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: '-70px' }}
                bgImgUrl={getIconPath('something-broke.png')}
                message={'Oops! Something broke, we are working on a fix.'}
              />
            )}
            {shouldRenderInventoryTrackerRecords() && (
              <Row>
                <Col md={12}>
                  <EzTable {...tableProps} />
                </Col>
              </Row>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={'No inventoryTrackers records to show'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default InventoryTracker;
