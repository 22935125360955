import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class CustomersMenu extends Component {
  render() {
    return (
      <div className="submenu">
        <ul>
          <li className={this.props.active}>
            <NavLink to="/customers/customer-and-brokers">
              Customer and Brokers
            </NavLink>
          </li>
          <li className={this.props.actives}>
            <NavLink to="/customers/assets">Assets</NavLink>
          </li>
          <li className={this.props.activess}>
            <NavLink to="/customers/invoices">Invoices</NavLink>
          </li>
        </ul>
        <div className="clr"></div>
      </div>
    );
  }
}

export default CustomersMenu;
