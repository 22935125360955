import { useEffect } from "react"
import { connect } from "react-redux"
import AssetTracker from "../../../components/Asset/AssetTracker"
import { 
        createAssetTracker,
        editAssetTrackerRecord,
        fetchAssetTrackerDetailsByassetId,
        fetchDriver,
        initializeAssetsTrackerScreen
       } from "../../../Redux/Actions/assets/assetTracker"
import { setIsformDirty } from "../../../Redux/Actions/common/form"
import { 
        selectAssetTrackerFormValues,
        selectIsAssetTrackerDetailSuccess,
        selectIsAssetTrackerDetailError,
        selectIsCreateAssetTrackerSuccess,
        selectIsCreateAssetTrackerError,
        selectIsEditAssetTrackerSuccess,
        selectIsEditAssetTrackerError
        } from "../../../Redux/Selectors/assets_new/assetTracker"
import { getEmployeesdropDown } from "../../../Redux/Selectors/dropDown"
import { FILTER } from "../../../Services/common/urlService"
import { fetchTypes } from "../../../Redux/Actions/types";
import { getAssetTrackingStatusList, getTrackingDeviceProvidingList } from "../../../Redux/Selectors/types"

const AssetTrackerContainer = props => {

  const { 
    currentAssetRecord,
    fetchDriver,
    fetchAssetTrackerDetailsByassetId,
    isAssetTrackerDetailSuccess,
    initializeAssetsTrackerScreen
  } = props;

  const isEdit = isAssetTrackerDetailSuccess
  const { id: assetId } = currentAssetRecord || {};

  useEffect( () => { 
    initializeAssetsTrackerScreen();
    fetchDriver();
    fetchAssetTrackerDetailsByassetId({assetId})
  }, [ 
      assetId,
      fetchAssetTrackerDetailsByassetId,
      fetchDriver,
      initializeAssetsTrackerScreen
    ]);
  
  const searchDrivers = ({ query, filterField }) => {
    if (query) {
      fetchDriver({
        shouldPaginate: false,
        filters: [{ filterField, query, matchStrategy: FILTER.MATCH_STRATEGY.CONTAINS }],
      });
    } else {
      fetchDriver();
    }
  };
  
  const assetTrackerProps = {
    searchDrivers,
    isEdit,
    ...props
  }
  return <> 
  <AssetTracker {...assetTrackerProps} />  </>  
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    assetTrackerStatusOptions: getTrackingDeviceProvidingList(state),
    assetTrackerDeviceOptions: getAssetTrackingStatusList(state),
    drivers: getEmployeesdropDown(state),
    formFieldValueMap: selectAssetTrackerFormValues(state),
    isAssetTrackerDetailSuccess: selectIsAssetTrackerDetailSuccess(state),
    isAssetTrackerDetailError:selectIsAssetTrackerDetailError(state),
    isCreateAssetTrackerSuccess: selectIsCreateAssetTrackerSuccess(state),
    isCreateAssetTrackerError: selectIsCreateAssetTrackerError(state),
    isEditAssetTrackerSuccess : selectIsEditAssetTrackerSuccess(state),
    isEditAssetTrackerError : selectIsEditAssetTrackerError(state)
  }
}
const mapDispatchToProps = dispatch => {
  return {
    initializeAssetsTrackerScreen : () => dispatch(initializeAssetsTrackerScreen()),
    fetchAssetTrackerStatus : payload =>  dispatch(fetchTypes(payload)),
    fetchAssetTrackerDevice : payload => dispatch(fetchTypes(payload)),
    fetchDriver : payload => dispatch(fetchDriver(payload)),
    createAssetTracker : payload => dispatch(createAssetTracker(payload)),
    fetchAssetTrackerDetailsByassetId: payload => dispatch(fetchAssetTrackerDetailsByassetId(payload)),
    editAssetTrackerRecord: payload  => dispatch(editAssetTrackerRecord(payload)),
    setIsformDirty: isFormDirty => dispatch(setIsformDirty(isFormDirty))
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetTrackerContainer);