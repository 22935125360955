import "./index.css"
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';

const Banner = ({ message, ctaText, cta }) => {
  return (
    <div className="banner">
      <div className="banner-item">{message}</div>
      {ctaText && <Button label={ctaText} className="p-button-link" style={{ color: "white" }} onClick={cta}></Button>}
    </div>
  )
}

Banner.propTypes = {
  ctaText: PropTypes.string,
  message: PropTypes.string.isRequired,
  cta: PropTypes.func,
}

export default Banner;
