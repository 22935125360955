import { GET_REIMB_STATUS } from '../../Type/ReimbursementType';

const initalState = {
  loading: false,
  reimbStatusList: [],
  error: {},
};

export const reimbReducer = (state = initalState, action) => {
  switch (action.type) {
    case GET_REIMB_STATUS:
      return {
        ...state,
        loading: false,
        reimbStatusList: action.payload,
        error: {},
      };
    default:
      return state;
  }
};
