import React, { PureComponent } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { getApiAsyn } from '../../Services/PostAPI';
import SelectSearch from 'react-select-search';
import Cookies from 'universal-cookie';
import DatePicker from 'react-datepicker';
import Autosuggestion from '../../common/Autosuggestion';

const cookies = new Cookies();
let LoadTypeDropdown = [
  { name: 'All', value: '-1' },
  { name: 'FL', value: 'FL' },
  { name: 'LTL', value: 'LTL' },
  { name: 'Oversized', value: 'Oversized' },
  { name: 'Unknown', value: 'Unknown' },
];
export class Filter extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      OrderDropdonw: [],
      OrderStatusDropdonw: [],
      EQUIP_TYPEDropdown: [],
      COMM_TYPEDropdown: [],
    };
  }

  getOrderType = async () => {
    let ddordertype = [];
    let res = await getApiAsyn(
      `types?type=ORDER_TYPE`,
      cookies.get('authorization')
    );

    ddordertype = res;
    ddordertype = ddordertype.map((item) => ({
      name: item.value,
      value: item.key,
    }));
    ddordertype.unshift({
      name: 'All',
      value: '-1',
    });
    this.setState({
      OrderDropdonw: ddordertype,
    });
  };
  getOrderStatus = async () => {
    let ddorderstatus = [];
    let res = await getApiAsyn(
      `types?type=ORDER_STATUS`,
      cookies.get('authorization')
    );
    ddorderstatus = res;
    ddorderstatus = ddorderstatus.map((item) => ({
      name: item.value,
      value: item.key,
    }));
    ddorderstatus.unshift({
      name: 'All',
      value: '-1',
    });
    this.setState({
      OrderStatusDropdonw: ddorderstatus,
    });
  };

  getEQUIP_TYPEDropdonw = async () => {
    let dddata = [];
    let res = await getApiAsyn(
      `types?type=EQUIP_TYPE`,
      cookies.get('authorization')
    );
    if (res.length > 0) {
      dddata = res;
      dddata = dddata.map((item) => ({
        name: item.value,
        value: item.key,
      }));
      dddata.unshift({
        name: 'All',
        value: '-1',
      });
      this.setState({
        EQUIP_TYPEDropdown: dddata,
      });
    }
  };

  getCOMM_TYPEDropdonw = async () => {
    let dddata = [];
    let res = await getApiAsyn(
      `types?type=COMM_TYPE`,
      cookies.get('authorization')
    );
    if (res.length > 0) {
      dddata = res;
      dddata = dddata.map((item) => ({
        name: item.value,
        value: item.key,
      }));
      dddata.unshift({
        name: 'All',
        value: '-1',
      });
      this.setState({
        COMM_TYPEDropdown: dddata,
      });
    }
  };

  async componentDidMount() {
    this.getOrderType();
    this.getOrderStatus();

    this.getEQUIP_TYPEDropdonw();
    this.getCOMM_TYPEDropdonw();
  }

  render() {
    const {
      customeronChange,
      customervalue,
      customerName,
      OrderStatusname,
      OrderStatusonChange,
      OrderStatusvalue,
      LoadTypeName,
      LoadTypeonChange,
      LoadTypevalue,
      OrderTypeNane,
      OrderTypeonChange,
      OrderTypevalue,
      EquipmentonChange,
      Equipmentvalue,
      EquipmentNane,
      ComodityNane,
      ComodityonChange,
      Comodityvalue,
      StartSelected,
      StartNane,
      StartonChange,
      Startvalue,
      EndSelected,
      EndNane,
      EndonChange,
      Endvalue,
      OrderNoNane,
      OrderNoonChange,
      OrderNovalue,
      ReeferNane,
      ReeferonChange,
      Reefervalue,
      UrgentNane,
      UrgentonChange,
      Urgentvalue,
      AddressNane,
      AddressonChange,
      Addressvalue,
      bool,
      getAutosuggest,
      onClick,

      RefnoNane,
      RefnoonChange,
      Refnovalue,
    } = this.props;
    const {
      OrderDropdonw,
      OrderStatusDropdonw,
      EQUIP_TYPEDropdown,
      COMM_TYPEDropdown,
    } = this.state;

    return (
      <div>
        <Row>
          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Customer
              </Form.Label>
              <Col sm="12">
                <Autosuggestion
                  name={customerName}
                  placeholder="Search customer..."
                  value={customervalue}
                  onChange={customeronChange}
                  ref={this.wrapperRef}
                  style={{ display: bool ? 'block' : 'none' }}
                  bool={bool}
                  getAutosuggest={getAutosuggest}
                  onClick={onClick}
                />

                {/* <SelectSearch
                  container="rowselect"
                  search
                  options={CustomerDropdonw}
                  select="test"
                  name={customerName}
                  autoComplete="sdf"
                  onChange={customeronChange}
                  value={customervalue}
                  placeholder="Type to search"
                /> */}
              </Col>
            </Form.Group>
          </Col>

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Start Date
              </Form.Label>
              <Col sm="12">
                <DatePicker
                  selected={StartSelected}
                  dateFormat="yyyy-MM-dd"
                  className="form-control inpcalendar"
                  popperPlacement="start"
                  name={StartNane}
                  autoComplete="off"
                  onChange={StartonChange}
                  value={Startvalue}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                End Date
              </Form.Label>
              <Col sm="12">
                <DatePicker
                  selected={EndSelected}
                  dateFormat="yyyy-MM-dd"
                  className="form-control inpcalendar"
                  popperPlacement="start"
                  name={EndNane}
                  autoComplete="off"
                  onChange={EndonChange}
                  value={Endvalue}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Order Status
              </Form.Label>
              <Col sm="12">
                <SelectSearch
                  container="rowselect"
                  search
                  options={OrderStatusDropdonw}
                  select="test"
                  name={OrderStatusname}
                  autoComplete="sdf"
                  onChange={OrderStatusonChange}
                  value={OrderStatusvalue}
                  placeholder="Type to search"
                />
              </Col>
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Load Type
              </Form.Label>
              <Col sm="12">
                <SelectSearch
                  container="rowselect"
                  search
                  options={LoadTypeDropdown}
                  select="test"
                  name={LoadTypeName}
                  autoComplete="sdf"
                  onChange={LoadTypeonChange}
                  value={LoadTypevalue}
                  placeholder="Type to search"
                />
              </Col>
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Order Type
              </Form.Label>
              <Col sm="12">
                <SelectSearch
                  container="rowselect"
                  search
                  options={OrderDropdonw}
                  select="test"
                  name={OrderTypeNane}
                  autoComplete="sdf"
                  onChange={OrderTypeonChange}
                  value={OrderTypevalue}
                  placeholder="Type to search"
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Commodity
              </Form.Label>
              <Col sm="12">
                <SelectSearch
                  container="rowselect"
                  search
                  options={COMM_TYPEDropdown}
                  select="test"
                  name={ComodityNane}
                  autoComplete="sdf"
                  onChange={ComodityonChange}
                  value={Comodityvalue}
                  placeholder="Type to search"
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Equipment Type
              </Form.Label>
              <Col sm="12">
                <SelectSearch
                  container="rowselect"
                  search
                  options={EQUIP_TYPEDropdown}
                  select="test"
                  name={EquipmentNane}
                  autoComplete="sdf"
                  onChange={EquipmentonChange}
                  value={Equipmentvalue}
                  placeholder="Type to search"
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Order No
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  placeholder="Order No"
                  name={OrderNoNane}
                  autoComplete="sdf"
                  onChange={OrderNoonChange}
                  value={OrderNovalue}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="1">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Reefer
              </Form.Label>
              <Col sm="12">
                <Form.Check
                  type="checkbox"
                  name={ReeferNane}
                  autoComplete="sdf"
                  onChange={ReeferonChange}
                  value={Reefervalue}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="1">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Urgent Order
              </Form.Label>
              <Col sm="12">
                <Form.Check
                  type="checkbox"
                  name={UrgentNane}
                  autoComplete="sdf"
                  onChange={UrgentonChange}
                  value={Urgentvalue}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                Address
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  placeholder="Address"
                  name={AddressNane}
                  autoComplete="sdf"
                  onChange={AddressonChange}
                  value={Addressvalue}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col md="2">
            <Form.Group>
              <Form.Label column sm="12" className="text-left">
                PO/Ref no
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  placeholder="PO/Ref no"
                  name={RefnoNane}
                  autoComplete="sdf"
                  onChange={RefnoonChange}
                  value={Refnovalue}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  }
}
