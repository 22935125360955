import Cookies from 'universal-cookie';

import { getApiAsyn, deleteApi, postApiAsyn, putApiAsyns } from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { API_URL } from '../../../constants/urlConstants';
import { extractData } from '../../utils';
import { generateImageUrl, getDecoratedUrl } from '../../common/urlService';
import { asyncForEach } from '../../../utils/async';

const cookies = new Cookies();

export const fetchVault = async (payload) => {
  const url = getDecoratedUrl({
    url: API_URL.VAULT.FETCH_VAULT,
    payload,
  });
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }

    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchVaultByVaultId = async (payload) => {
  const url = API_URL.VAULT.FETCH_BY_VAULT_ID.replace(
    ':id',
    payload?.vaultId
  );
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }

    result = extractData(result, !payload?.filters);
    if (result?.files?.length) {
      await asyncForEach(result.files, async (file, index, array) => {
        result.files[index].s3Url = await generateImageUrl(file.url);
      });
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const deleteVaultRecord = async ({ vaultId }) => {
  try {
    let result = await deleteApi(
      `${API_URL.VAULT.DELETE_VAULT}/${vaultId}`,
      cookies.get('authorization')
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const createVaultRecord = async (payload) => {
  try {
    const result =  await postApiAsyn(
      API_URL.VAULT.CREATE_VAULT,
      payload,
      cookies.get('authorization'),
      { showPageBlockingLoader: false }
    )

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const editVaultRecord = async (payload) => {
  const url = API_URL.VAULT.EDIT_VAULT.replace(
    ':id',
    payload?.vaultId
  );
  const id = payload?.vaultId;
  delete payload?.vaultId;
  
  try {
    const result = await putApiAsyns(
      url,
      { ...payload, id },
      cookies.get('authorization'),
      { showPageBlockingLoader: false }
    )

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
