import {
  CREATE_ASSET_TRACK_RECORD,
  EDIT_ASSET_TRACK_RECORD,
  FETCH_ASSET_TRACK_DETAIL,
  INIT_CREATE_NEW_ASSET_TRACKER_SCREEN
 } from "../../../../constants/action-types/assetTracker";


const formFieldValueMap = {
  id: '',
  track_status: '',
  track_device: '',
  track_device_mac: '',
  track_asset_emp: ''
}

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateAssetTrackerSuccess: false,
  isCreateAssetTrackerError: false,
  isEditAssetTrackerSuccess: false,
  isEditAssetTrackerError: false
};

export const assetTrackReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line default-case
  switch(action.type){
    case INIT_CREATE_NEW_ASSET_TRACKER_SCREEN:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case FETCH_ASSET_TRACK_DETAIL.SUCCESS: {
      const temp = {};
      Object.keys(formFieldValueMap).forEach((key) => {
        const value = action.payload[key];
        temp[key] = value;
      });

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isAssetTrackerDetailSuccess: true,
      };
    }

    case FETCH_ASSET_TRACK_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isAssetTrackerDetailSuccess: false,
        isAssetTrackerDetailError: true,
      };
    }

    case CREATE_ASSET_TRACK_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isCreateAssetTrackerSuccess: false,
        isCreateAssetTrackerError: true,
      };
    }
    case CREATE_ASSET_TRACK_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isCreateAssetTrackerSuccess: true,
        isCreateAssetTrackerError: false,
      };
    }
    case EDIT_ASSET_TRACK_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditAssetTrackerSuccess: false,
        isEditAssetTrackError: true,
      };
    }

    case EDIT_ASSET_TRACK_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditAssetTrackerSuccess: true,
        isEditAssetTrackerError: false,
        error:null
      };
    }
    default : return state
  }
}
