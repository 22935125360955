import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';

import EzTable from '../../common/EzTable';
import EzNotFound from '../../common/EzNotFound';

const getIconPath = (imgPathDelta = '/no-record.png') =>
  process.env.PUBLIC_URL + imgPathDelta;

const Employees = (props) => {
  const {
    employeesListView,
    fetchEmployees,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isPageLevelError,
  } = props;

  const [areFiltersVisible, toggleFilterState] = useState(false);

  const renderPageHeaderSection = () => {
    const breadCrumbs = [{ label: 'Employee' }, { label: 'Employee Summary' }];

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };

    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />

        <div className="rightbtn">
          <Button
            label={areFiltersVisible ? 'Hide Filters' : 'Show Filters'}
            icon={areFiltersVisible ? 'pi pi-filter-slash' : 'pi pi-filter'}
            className={`mr-2 p-button-raised p-button-outlined p-button-secondary`}
            onClick={() => {
              toggleFilterState(!areFiltersVisible);
            }}
          />
        </div>
      </div>
    );
  };

  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderEmployeesRecords = () =>
  employeesListView?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderEmployeesRecords() && !shouldRenderFullPageError();

  const filters = {
    global: { value: null },
    code: { value: null, filterKey: 'code' },
    first: { value: null, filterKey: 'first' },
    email: { value: null, filterKey: 'email' },
    date_of_birth: { value: null, filterKey: 'date_of_birth' },
    hire_dt: { value: null, filterKey: 'hire_dt' }
  };

  const tableProps = {
    areFiltersVisible,
    columns: employeesListView.columns,
    paginationInfo,
    screenPermissions,
    loadLazyData: fetchEmployees,
    value: employeesListView.rows,
    emptyMessage: 'No Employee found.',
    filters,
    sortField: 'first',
    showMoreActions: false,
    loading: isFetchingGridData,
  };
  
  return (
    <>
      <div className="paddingbox">
        <div className="containerbox">
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: '-70px' }}
                bgImgUrl={getIconPath('something-broke.png')}
                message={'Oops! Something broke, we are working on a fix.'}
              />
            )}
            {shouldRenderEmployeesRecords() && (
              <Row>
                <Col md={12}>
                  <EzTable {...tableProps} />
                </Col>
              </Row>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={'No Employee records to show'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Employees;
