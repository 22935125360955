export const FETCH_USER_PROFILE_START = 'FETCH_USER_PROFILE_START';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_ERROR = 'FETCH_USER_PROFILE_ERROR';

export const FETCH_USER_COMPANY_INFO_START = 'FETCH_USER_COMPANY_INFO_START';
export const FETCH_USER_COMPANY_INFO_SUCCESS = 'FETCH_USER_COMPANY_INFO_SUCCESS';
export const FETCH_USER_COMPANY_INFO_ERROR = 'FETCH_USER_COMPANY_INFO_ERROR';

export const FETCH_SWITCHABLE_ACCOUNTS_START = "FETCH_SWITCHABLE_ACCOUNTS_START";
export const FETCH_SWITCHABLE_ACCOUNTS_SUCCESS = "FETCH_SWITCHABLE_ACCOUNTS_SUCCESS";
export const FETCH_SWITCHABLE_ACCOUNTS_ERROR = "FETCH_SWITCHABLE_ACCOUNTS_ERROR";

export const SWITCH_ACCOUNTS_START = "SWITCH_ACCOUNTS_START";
export const SWITCH_ACCOUNTS_SUCCESS = "SWITCH_ACCOUNTS_SUCCESS";
export const SWITCH_ACCOUNTS_ERROR = "SWITCH_ACCOUNTS_ERROR";