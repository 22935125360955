import React from 'react';
import { Route } from 'react-router-dom';
import { APP_URLS } from '../../constants/urlConstants';

import AssetLocation from '../../containers/AssetLocation';
import AssetLocationHistory from '../../containers/AssetLocation/History';

const AssetLocationRoutes = (props) => {
  const { access, match } = props;
  const { read } = access;
  
  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <AssetLocation {...routeProps} screenPermissions={access} />
          )}
        />
      )}
      {
        <Route
          path={APP_URLS.ASSETS_LOCATION.HISTORY}
          component={AssetLocationHistory}
        />
      }
      
    </>
  );
};

export default AssetLocationRoutes;
