import React from 'react';
import { Route } from 'react-router-dom';
import AssetScreen from '../../containers/Assets';
import CreateAssets from '../../containers/Assets/createAsset';
import { APP_URLS } from '../../constants/urlConstants';

const AssetRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update } = access;
  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <AssetScreen {...routeProps} screenPermissions={access} />
          )}
        />
      )}
      {!!create && (
        <Route
          path={APP_URLS.ASSETS.ADD_ASSETS}
          component= {CreateAssets}
        />
      )}
      {!!update && (
        <Route
          path={APP_URLS.ASSETS.EDIT_ASSETS}
          render={(routeProps) => (
            <CreateAssets {...routeProps} isEdit={true} />
          )}
        />
      )}
    </>
  );
};

export default AssetRoutes;
