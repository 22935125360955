import Cookies from 'universal-cookie';

import { 
  getApiAsyn,
  getApiAsyn1
} from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { API_URL } from '../../../constants/urlConstants';
import { getMenuAccess } from '../../../Login/login-utils';

const cookies = new Cookies();

export const isUserEligibleForAccountSwitch = async (payload) => {
  const url = API_URL.SWITCH_ACCOUNT.IS_ELIGIBLE_FOR_SWITCH

  try {
    let result = await getApiAsyn1(url, cookies.get('authorization'));
    if (result?.type === "error") {
      return false
    }
    return true
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchSwitchableAccounts = async () => {
  const url =  API_URL.SWITCH_ACCOUNT.FETCH_ACCOUNTS;
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }
    delete result.APIResult;
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const switchAccount = async (companyCode) => {
  const url = API_URL.SWITCH_ACCOUNT.SWITCH_TO_ACCOUNT_ID.replace(':id', companyCode);
  try {
    let result = await getApiAsyn1(url, cookies.get('authorization'));
    if (result?.error) {
      throw new APIError(result);
    }
    await getMenuAccess(result.authorization)
    cookies.set('authorization', result.authorization, {
      path: '/',
      sameSite: true,
    });
    sessionStorage.setItem("switchAccountBanner", true)
    window.location.reload()
    return { isSuccess: true };
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const switchBack = async () => {
  const url = API_URL.SWITCH_ACCOUNT.SWITCH_BACK_TO_SELF_ACCOUNT;
  try {
    let result = await getApiAsyn1(url, cookies.get('authorization'));
    if (result?.error) {
      throw new APIError(result);
    }
    await getMenuAccess(result.authorization)
    cookies.set('authorization', result.authorization, {
      path: '/',
      sameSite: true,
    });
    sessionStorage.setItem("switchAccountBanner", false)
    window.location.reload()
    return { isSuccess: true };
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};


