import React from "react";
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Col, Row, Container } from 'react-bootstrap';
import { Button } from 'primereact/button';

import { getFormBaseValidationRule } from "../../utils/form/form-utils"
import EzDialog from '../EzDialog';
import EzControlledDropDowntWithFloatingLabel from '../../common/EzForm/EzControlledDropDown/WithFloatingLabel';


const SwitchAccountDialog = (props) => {
  const { accounts, showDialog, switchAccount } = props;

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    switchAccount(data.company)
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const renderAccounts = () => {
    const name = 'company';

    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={accounts}
        optionLabel={"name"}
        optionValue={"id"}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Company Name"
        labelClassName={'ml-2'}
      />
    );
  }
  const closeDialog = () => showDialog(false)

  return (
    <EzDialog header={'Switch Profile'} onHide={closeDialog} height={"300px"} maximizable={false}>
      <div className="form-demo" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
        <div className="card" style={{ background: '#f3faf4BF' }}>
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <Container fluid>
              <Row style={{ minWidth: '20px' }}>
                <Col md="1" />
                <Col md="9">{renderAccounts()}</Col>
              </Row>
              </Container>
              <div
                className={'make-flex mt-3 mr-2'}
                style={{ flexDirection: 'row-reverse', background: 'white' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      label="Cancel"
                      onClick={closeDialog}
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '120px' }}>
                    <Button
                      loading={false}
                      type="submit"
                      label={"Switch"}
                      className="mt-2 p-button-raised"
                    />
                  </div>
                </div>
              </div>
            </form>
        </div>
      </div>
    </EzDialog>)
}

SwitchAccountDialog.propTypes = {
  accounts: PropTypes.array,
  showDialog: PropTypes.func,
  swichAccount: PropTypes.func,
}

export default SwitchAccountDialog;