import React from 'react';
import { Form } from 'react-bootstrap';
export default function Autosuggestion(props) {
  const {
    value,
    placeholder,
    name,
    onChange,
    style,
    getAutosuggest,
    onClick,
    ref,
    disabled,
  } = props;
  return (
    <div className="autosuggest">
      <Form.Control
        disabled={disabled}
        name={name}
        autoComplete="off"
        placeholder={placeholder || 'Search Customer...'}
        value={value}
        onChange={onChange}
      />

      <div ref={ref} className="suggestionlist" style={style}>
        <ul>
          {getAutosuggest &&
            getAutosuggest.length > 0 &&
            getAutosuggest.map((item) => (
              <li
                onClick={() => onClick(item.value, item.name)}
                key={item.value}
              >
                {item.name}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
