import React, { Component } from 'react';
import {
  Button,
  Col,
  Modal,
  Table,
  Form,
  Row,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import default_menu from '../../images/default_menu.svg';
import correctImg from '../../images/correction.png';
import wrongImg from '../../images/wrong.png';
import { Prompt } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import { Link } from 'react-router-dom';
import { getApi } from '../../Services/GetAPI';
import SnackbarError from '../../common/SnackbarError';
import SnackbarSuccess from '../../common/SnackbarSuccess';
import uuid from 'react-uuid';
import Cookies from 'universal-cookie';
import PDF_ICON from '../../images/pdf.png';
import IMG_ICON from '../../images/image.png';
import {
  getFormattedDate,
  formatDate,
  loadierShow,
  getApiAsyn,
  baseUrls,
  getApiAsynBlobImage,
  loadingsShow,
  smsAuth,
} from '../../Services/PostAPI';
import DatePicker from 'react-datepicker';
import { post, put } from 'axios';
import deletebtnred from '../../images/close_button_red.svg';
import dragicon from '../../images/dragicon.png';
import IMG_NOTE_ICON from '../../images/notes.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Popup } from '../../common/Popup';
import Autocomplete from '../../common/Autocomplete';
import { TripItemModal } from './TripItemModal';
import { withScriptjs } from 'react-google-maps';
import Maps from './Maps';
import DeviceTrackerMap from './DeviceTrackerMap';
import { PopupLocConfirm } from '../../common/PopupLocConfirm';
import { SendMessage } from './SendMessage';
import { MessageModal } from '../Orders/TripConfirmation';
// import { AutoComplete } from 'primereact/autocomplete';
import { API_URL } from '../../constants/urlConstants';
import LoctionName from '../LoctionName';
let bool = 0;
const cookies = new Cookies();
let googleMapsApiKey = 'AIzaSyDXzpajYWsQgG1UIoWbjBVUpt19Q6NNopY';
let FileData = [];
let isNotify = false;
class CreateTrips extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: JSON.parse(localStorage.getItem('menuAccess')),
      tripValue: {
        trailer_owner: 'CP',
        miletcaltype: 'Auto',
        trip_type: 242,
        trip_date: new Date(),
      },
      formOption: {
        list: [],
      },
      PromptPrevent: false,
      ImageAddedS3: [],
      PackgDropdonw: [],
      driverphone: '',
      items: [
        { content: 'TEst', id: 'Test' },
        { content: 'sfsd', id: 'sdf' },
      ],
      Place: [],
      isSendModal: false,
      load_type: '',
      orderItemModal: false,
      moreModal: false,
      locationModal: false,
      orderSearch: {},
      orderId: '',
      consignValue: [],
      selectedLocation: '',
      countryList: [],
      stateList: [],
      cityList: [],
      currencyList: [],
      defaultAssetList: [],
      revenueList: [],
      taxList: [],
      cntryId: '',
      orderItem: '',
      currentBoxAddress: false,
      stateId: '',
      errors: {},
      orderModal: false,
      tripTypeDropdonw: [],
      OrderList: [],
      StatusDropdonw: [],
      TruckDropdonw: [],
      TempTrailerDropdonw: [],
      TrailerDropdonw: [],
      TrailerDropdonw2: [],
      TempDriverDropdonw: [],
      DriverDropdonw: [],
      DriverDropdonw2: [],
      CustomerAutosuggest: [],
      TripStatusDropdonw: [],
      APPNT_TYPEDropdown: [],
      milestotal: '',
      dragId: '',
      uuidval: "",
      showDeleteModal: false,
      deleteItemIndex: '',
      bool: false,
      textMessage:
        'This will delete all the items of related trip. Do you want to continue ?',
      showItemModal: false,
      Data: [],
      OrderToShow: [],
      showDirectionModal: false,
      DirectionData: [],
      DirectionCenter: {},
      listConsignee: [],
      allChecked: false,
      ImageDisplay: [],
      showConfirmation: false,
      isConfirmation: false,
      loadTypeBool: false,
      loadCnf: false,
      typeMessage: '',
      CSModal: false,
      CSConfirm: false,
      CSMessage: '',
      isTripEditable: false,
      showTrackingModal: false,
      trackingData: [],
    };
    this.AutoRef = React.createRef();
  }

  toggleTrackingModal = (data) => {
    this.setState((prevState) => {
      let { showTrackingModal } = prevState;
      showTrackingModal = prevState.showTrackingModal === false ? true : false;

      if (showTrackingModal === true) {
        this.getSamsaraLocation();
      }
      return { showTrackingModal };
    });
  };

  // Handle More option

  toggleMoreModal = (index) => {
    this.setState({
      moreModal: index,
    });
  };

  // End More option
  handleSMSAuth = async () => {
    let reqData = {
      company: 'SMS',
      email: '101@example.com',
      password: '101123',
    };
    let res = await smsAuth(`auth/login`, reqData);
    if (res && res.status !== false) {
      localStorage.setItem('smsAccessToken', res.access_token);
    }
  };
  handleMessageModal = () => {
    this.setState((prevState) => {
      let { isSendModal } = prevState;
      isSendModal = prevState.isSendModal === false ? true : false;
      if (isSendModal === true) {
        this.handleSMSAuth();
      }
      return { isSendModal };
    });
  };

  toggleDirectionModal = () => {
    const { consignValue } = this.state;
    if (consignValue.length < 2) {
      this.handelMessage(
        'Please add more than one item to use direction map',
        'errorBoll'
      );
    } else {
      this.setState((prevState) => {
        let { showDirectionModal } = prevState;

        showDirectionModal =
          prevState.showDirectionModal === false ? true : false;
        if (showDirectionModal === true) {
          this.getDirection();
        }
        return { showDirectionModal };
      });
    }
  };

  getDirection = async () => {
    const { consignValue } = this.state;
    let len = consignValue.length - 1;
    if (consignValue.length === 0) {
      this.handelMessage('Please add order from list', 'errorBoll');
    } else {
      let waypoint = '',
        origin = '',
        destination = '';
      for (let i = 0; i < consignValue.length; i++) {
        let postalCode = consignValue[i].postal_code;
        waypoint += postalCode + '|';
      }
      origin = consignValue[0].location;
      destination = consignValue[len].location;

      this.setState({
        DirectionCenter: {
          origin: origin,
          destination: destination,
        },
      });
      let res = await getApiAsyn(
        `directions?origin=${origin}&destination=${destination}&waypoints=${waypoint}`,
        cookies.get('authorization')
      );
      if (res && res.status !== false) {
        this.setState({
          DirectionData: res,
        });
      }
    }
  };
  getPackgType = () => {
    let ddpackgype = [];
    getApi(`types?type=PACKAGE_TYPE`, cookies.get('authorization')).then(
      (res) => {
        if (res && res.status !== false) {
          ddpackgype = res;
          ddpackgype = ddpackgype.map((item) => ({
            name: item.value,
            code: item.code,
            value: parseInt(item.key),
          }));
          this.setState({
            PackgDropdonw: ddpackgype,
          });
        }
      }
    );
  };

  getAPPNT_TYPEDropdonw = async () => {
    let dddata = [];
    let res = await getApiAsyn(
      `types?type=APPNT_TYPE`,
      cookies.get('authorization')
    );
    if (res && (res.status !== false || res.status !== undefined)) {
      dddata = res;
      dddata = dddata.map((item) => ({
        name: item.value,
        value: item.key,
        code: item.code,
      }));
      this.setState({
        APPNT_TYPEDropdown: dddata,
      });
    }
  };
  toggleItemModal = (id) => {
    this.setState((prevState) => {
      let { showItemModal, Data, OrderToShow } = prevState;
      showItemModal = prevState.showItemModal === false ? true : false;
      if (id !== '') {
        let orderdata = OrderToShow.filter((item) => item != null);
        let item = orderdata.filter((item) => item.order_no === id);
        Data = item;
      }
      return { showItemModal, Data };
    });
  };
  handleReset = () => {
    window.location.reload();
  };
  handleClickOutside = (event) => {
    const { bool } = this.state;
    if (bool === true) {
      // this.AutoRef.current.getAlert();
    }
  };

  getPlace = async (place) => {
    const { uuidval } = this.state;

    let res = await getApiAsyn(
      `${API_URL.LOCATION.PLACE_AUTOCOMPLETE}?search=${place}&uuid=${uuidval}`,
      cookies.get('authorization')
    );
    if (res.length > 0) {
      let address = res.map((item, i) => ({
        id: i,
        formatted_address: item.description,
        place_id: item.place_id,
      }));
      this.setState({
        Place: address,
      });
    }
  };
  getCustomerData = async () => {
    let ddcustomer = [];
    let res = await getApiAsyn(
      `customers?paged=true&page=1&size=50000&fields=id,name`,
      cookies.get('authorization')
    );

    if (res && res.status !== false) {
      let count = res && res.page && res.page.total_elements;
      if (count > 0) {
        ddcustomer = res._embedded.content;
        ddcustomer = ddcustomer.map((item) => ({
          name: item.name,
          value: item.id,
        }));
        this.setState({
          CustomerAutosuggest: ddcustomer,
        });
      }
    } else {
      this.setState({
        CustomerAutosuggest: [],
      });
    }
  };
  getAutosuggest = () => {
    const { orderSearch, CustomerAutosuggest } = this.state;
    if (orderSearch.customerId !== '') {
      return CustomerAutosuggest.filter((item) =>
        item.name
          .toLowerCase()
          .includes(
            orderSearch.customerId && orderSearch.customerId.toLowerCase()
          )
      );
    } else {
      return CustomerAutosuggest;
    }
  };
  setAutosuggestId = (id, name) => {
    this.setState({
      orderSearch: {
        id: id,
        customerId: name,
      },
      bool: false,
    });
  };

  getImageDownload = async (url, name) => {

    let res = await getApiAsynBlobImage(
      url,
      cookies.get('authorization')
    );
    if (res.status === true) {
      var link = document.createElement('a');
      link.href = res.message;
      link.download = name;
      link.click();
    }
  };
  getFileData = async (data) => {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let res = await getApiAsynBlobImage(
          data[i].url,
          cookies.get('authorization')
        );
        this.setState((prevState) => {
          let { ImageAddedS3 } = prevState;
          ImageAddedS3.push({
            url: res.message,
            id: data[i].id,
            name: data[i].name,
            status: data[i].status,
          });
          return { ImageAddedS3 };
        });
      }
    }
  };

  handleS3Remove = (index) => {
    let { ImageAddedS3 } = this.state;
    ImageAddedS3[index].status = 'N';
    this.setState({ ImageAddedS3 });
  };
  getTripByID = () => {
    if (this.props.match !== undefined && this.props.match !== '') {
      if (
        this.props.match.params.id !== '' &&
        this.props.match.params.id !== undefined
      ) {
        getApi(
          `trips/${this.props.match.params.id}`,
          cookies.get('authorization')
        ).then((res) => {
          if (res && res.status !== false) {
            this.setState((prevState) => {
              let {
                consignValue,
                tripValue,
                OrderToShow,
                driverphone,
                milestotal,
                isTripEditable,
              } = prevState;
              if (res.trip_status && res.trip_status.id === 250) {
                isTripEditable = true;
              }
              if (res.files && res.files.length > 0) {
                this.getFileData(res.files);
              }
              tripValue.id = res.id;
              tripValue.trip_no = res.trip_no;
              tripValue.trip_type = res.trip_type && res.trip_type.id;
              let dates = getFormattedDate(
                res.trip_date,
                'DD-MM-YYYY hh:mm:ss',
                false
              );
              tripValue.trip_date = new Date(dates);
              milestotal = res.calc_miles;
              tripValue.vehicle = res.vehicle && res.vehicle.id;
              tripValue.seal_no = res.seal_no;
              driverphone = res.driver1 && res.driver1.phone;
              tripValue.driver1 = res.driver1 && res.driver1.id;
              tripValue.driver2 = res.driver2 && res.driver2.id;
              tripValue.ref_start = res.ref_start;
              tripValue.ref_end = res.ref_end;
              tripValue.trailer_owner = res.trailer_owner;
              tripValue.odm_start = res.odm_start;
              tripValue.odm_end = res.odm_end;
              tripValue.trailer1 = res.trailer1 && res.trailer1.id;
              tripValue.trailer2 = res.trailer2 && res.trailer2.id;
              tripValue.cust_trailer1 = res.cust_trailer1;
              tripValue.cust_trailer2 = res.cust_trailer2;
              tripValue.notes = res.note;
              tripValue.trip_status = res.trip_status && res.trip_status.id;

              for (let i = 0; i < res.items.length; i++) {
                let xdate = getFormattedDate(
                  res.items[i].req_date,
                  'DD-MM-YYYY hh:mm:ss',
                  false
                );

                let arvdate = getFormattedDate(
                  res.items[i].arv_date,
                  'DD-MM-YYYY hh:mm:ss',
                  false
                );
                let depvdate = getFormattedDate(
                  res.items[i].dep_date,
                  'DD-MM-YYYY hh:mm:ss',
                  false
                );
                OrderToShow.push(res.items[i].order);
                consignValue.push({
                  id: res.items[i].id,
                  stop: res.items[i].stop,

                  type: res.items[i].type,

                  location: res.items[i].location,
                  postal_code: res.items[i].postal_code,
                  loc_nm: res.items[i].loc_nm,
                  province: res.items[i].province,
                  city: res.items[i].city,
                  qty: res.items[i].qty,
                  pkg_type:
                    res &&
                    res.items &&
                    res.items[i].pkg_type &&
                    res.items[i].pkg_type.id,
                  orderids:
                    res.items[i].order != null ? res.items[i].order.id : null,
                  group: res.items[i].group,
                  order:
                    res.items[i].order != null
                      ? res.items[i].order.order_no
                      : res.items[i].order,
                  req_date: new Date(xdate),
                  arv_date: arvdate !== '' ? new Date(arvdate) : null,
                  dep_date: depvdate !== '' ? new Date(depvdate) : null,
                  weight: res.items[i].weight,
                  order_item: res.items[i].order_item,
                  item_status:
                    res.items[i].item_status && res.items[i].item_status.id,
                  note: res.items[i].note,
                  info: res.items[i].info,
                  apnt_type:
                    res.items[i].apnt_type && res.items[i].apnt_type.id,
                  status: res.items[i].status,
                  item_drv_note: res.items[i].item_drv_note,
                  item_verified: res.items[i].item_verified,
                  item_sig_req: res.items[i].item_sig_req,

                  files: res.items[i].files,
                });
              }
              return {
                consignValue,
                tripValue,
                OrderToShow,
                milestotal,
                isTripEditable,
                driverphone,
              };
            });
          }
        });
      }
    }
  };

  removeOrderItem = (index, item) => {
    this.setState({
      deleteItemIndex: index,
      orderItem: item,
      showDeleteModal: true,
    });
  };
  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }));
  };

  // Darg and Drop Event
  toggleOrderItemNoteModal = (index) => {
    this.setState({
      orderItemModal: index,
    });
  };

  toggleLocation = (index) => {
    const { consignValue } = this.state;
    if (index !== false && typeof index === 'number') {
      bool = index;
    }
    if (index === false && consignValue[bool].postal_code === '') {
      this.setState((prevState) => {
        const { consignValue } = prevState;
        for (let i = 0; i < consignValue.length; i++) {
          consignValue[bool].location = '';
        }

        return { consignValue };
      });
    }
    this.setState({
      locationModal: index,
    });
  };

  handleConfirmationClick = () => {
    this.setState({
      isConfirmation: true,
      showConfirmation: false,
    });
  };
  toggleConfirmationModal = () => {
    this.setState((prevState) => {
      let { showConfirmation } = prevState;
      showConfirmation = prevState.showConfirmation === false ? true : false;

      return { showConfirmation };
    });
  };
  handleCnfMeesage = (msg) => {
    this.setState((prevState) => {
      let { showConfirmation, confirmationMessage, typeMessage } = prevState;
      showConfirmation = true;
      confirmationMessage = (
        <div>
          {' '}
          {msg !== '' ? <li>{msg}</li> : ''}{' '}
          {typeMessage !== '' ? <li>{typeMessage}</li> : null}
        </div>
      );

      return { showConfirmation, confirmationMessage };
    });
  };
  handleModalNotifiyCSIsEqual = () => {
    this.setState((prevState) => {
      let { CSModal } = prevState;
      CSModal = prevState.CSModal === false ? true : false;
      return { CSModal };
    });
  };
  getNotifiyCSConfirm = () => {
    this.setState((prevState) => {
      let { CSModal, CSConfirm } = prevState;
      CSConfirm = true;

      CSModal = false;
      return { CSModal, CSConfirm };
    });
  };
  getNotifiyCSIsEqual = () => {
    let formIsValid = true;
    const { consignValue, CSConfirm } = this.state;
    let C = consignValue.filter((item) => item.type === 'C');
    let S = consignValue.filter((item) => item.type === 'S');
    if (C.length > S.length && CSConfirm === false) {
      formIsValid = false;
      this.setState((prevState) => {
        let { CSModal, CSMessage } = prevState;
        CSMessage = (
          <span>
            Consignee total is greater than Shipper total. Are you sure, you
            want to continue?
          </span>
        );
        CSModal = true;

        return { CSModal, CSMessage };
      });
    }
    if (S.length > C.length && CSConfirm === false) {
      formIsValid = false;
      this.setState((prevState) => {
        let { CSModal, CSMessage } = prevState;
        CSMessage = (
          <span>
            Shipper total is greater then Consignee total, Are sure you want to
            continue
          </span>
        );
        CSModal = true;

        return { CSModal, CSMessage };
      });
    }
    return formIsValid;
  };

  getNotifiyShipperConsignee = () => {
    this.getLoadTypeNotificationInOne();
    let countryToShip = [];
    let formIsValid = true;
    const { consignValue, tripValue, isConfirmation } = this.state;
    for (let i = 0; i < consignValue.length; i++) {
      countryToShip.push(
        consignValue[i].location.substring(
          consignValue[i].location.lastIndexOf(',') + 1
        )
      );
    }
    if (tripValue.trip_type === 241) {
      for (let t = 0; t < countryToShip.length; t++) {
        if (
          countryToShip[t + 1] !== undefined &&
          countryToShip[t + 1] !== countryToShip[t]
        ) {
          isNotify = true;
        }
      }
      if (isNotify === true && isConfirmation === false) {
        if (isConfirmation === false) {
          formIsValid = false;
        }

        this.handleCnfMeesage(
          'You have selected trip type domestic but item you have added in trip for different country. Are sure you want to continue',
          'errorBoll'
        );
      }
    }
    if (tripValue.trip_type === 243) {
      for (let t = 0; t < countryToShip.length; t++) {
        if (countryToShip[t] !== ' Canada') {
          isNotify = true;
        }
        if (isNotify === true && isConfirmation === false) {
          if (isConfirmation === false) {
            formIsValid = false;
          }
          this.handleCnfMeesage(
            'You have selected trip type into Canada but item you have added in trip for different country. Are sure you want to continue',
            'errorBoll'
          );
        }
      }
    }
    if (tripValue.trip_type === 242) {
      for (let t = 0; t < countryToShip.length; t++) {
        if (countryToShip[t] !== ' United States') {
          isNotify = true;
        }
        if (isNotify === true && isConfirmation === false) {
          if (isConfirmation === false) {
            formIsValid = false;
          }
          this.handleCnfMeesage(
            'You have selected trip type into United States but item you have added in trip for different country. Are sure you want to continue',
            'errorBoll'
          );
        }
      }
    }

    return formIsValid;
  };

  getLoadTypeNotificationInOne = () => {
    const { consignValue, isConfirmation } = this.state;
    let newData = consignValue.filter(
      (item, index, oldArray) =>
        oldArray.findIndex((t) => t.order === item.order) === index
    );

    let nExit = newData.filter((item) => item.load_type === 'FL');
    if (
      nExit.length === 1 &&
      consignValue.length > 1 &&
      isConfirmation !== true
    ) {
      //this.handLoadModal();
      this.setState((prevState) => {
        let { typeMessage } = prevState;
        typeMessage = `You have already Full Load in the trip. Are sure you want to continue with more item`;
        return { typeMessage };
      });
    }

    if (nExit.length > 1 && isConfirmation !== true) {
      //this.handLoadModal();
      this.setState((prevState) => {
        let { typeMessage } = prevState;
        typeMessage = `You have already Full Load in the trip. Are sure you want to continue with more item`;
        return { typeMessage };
      });
    }
  };
  toggleLocationModal = (index, type) => {
    const { consignValue } = this.state;
    if (index !== false && typeof index === 'number') {
      bool = index;
    }

    if (index === false && consignValue[bool].postal_code === '') {
      this.handelMessage('Postal code is required', 'errorBoll');
    } else {
      this.setState({
        locationModal: index,
        selectedLocation: consignValue[index]?.loc_nm,
        uuidval: uuid()
      });
      if (index === false && type === "save") {
        this.handleCallMiles();
      }
    }
  };

  setAddress = async (val, id) => {
    const { locationModal, consignValue, uuidval } = this.state;

    let res = await getApiAsyn(
      `${API_URL.LOCATION.PLACE_DETAIL}?placeid=${id}&uuid=${uuidval}`,
      cookies.get('authorization')
    );
    let ds = res.result && res.result.address_components;
    let pscode = ds.filter((x) => x.types[0] === 'postal_code');

    let provincecity = ds.find((x) => x.types[0] === 'locality')?.long_name;
    let province = ds.find((x) => x.types[0] === 'administrative_area_level_1')?.short_name;
    if (pscode.length !== 0) {
      consignValue[locationModal].location = val;
      consignValue[locationModal].postal_code = pscode[0].long_name;
      consignValue[locationModal].city = provincecity;
      consignValue[locationModal].province = province;
      this.setState({
        consignValue,
      });
      this.setState({
        currentBoxAddress: false,
      });
    } else {
      this.setState({
        currentBoxAddress: false,
      });
      this.handelMessage('Invalid address postal code is missing', 'errorBoll');
    }
  };
  onChangeOrderItem = (index) => (e) => {
    const { consignValue } = this.state;
    if (e.target.name === 'loc_nm') {
      this.setState({
        selectedLocation: e.value
      })
      const { name, location, city, province, postal_code } = e.value;
      consignValue[index]["loc_nm"] = e?.value.name !== undefined ? name : e?.value;
      consignValue[index]["location"] = location !== undefined ? location : consignValue[index]["location"];
      consignValue[index]["province"] = province !== undefined ? province : consignValue[index]["province"];
      consignValue[index]["city"] = city !== undefined ? city : consignValue[index]["city"];
      consignValue[index]["postal_code"] = postal_code !== undefined ? postal_code : consignValue[index]["postal_code"];
      this.setState({
        consignValue,
        PromptPrevent: true,
      });
      return
    }
    if (e.target.name === 'location' && e.target.value.length > 2) {
      this.getPlace(e.target.value);
      this.setState({
        currentBoxAddress: true,
      });
    }
    if (e.target.name === 'location' && e.target.value.length < 2) {
      this.setState({
        currentBoxAddress: false,
      });
    }
    consignValue[index][e.target.name] = e.target.value;
    this.setState({
      consignValue,
    });
  };
  handleDrag = (ev) => {
    this.setState({
      dragId: ev.currentTarget.id,
    });
  };

  handleDrop = (ev) => {
    let { consignValue, dragId } = this.state;
    let dragBox = consignValue.find((box) => box.stop === dragId);
    let dropBox = consignValue.find((box) => box.stop === ev.currentTarget.id);

    let dragBoxOrder = dragBox.stop;
    let dropBoxOrder = dropBox.stop;
    consignValue = consignValue.map((box) => {
      if (box.stop === dragId) {
        box.stop = dropBoxOrder;
      }
      if (box.stop === ev.currentTarget.id) {
        box.stop = dragBoxOrder;
      }
      return box;
    });

    this.setState({
      consignValue,
    });
  };

  //Drag end

  handleRLDDChange = (newItems) => {
    this.setState({ consignValue: newItems });
  };

  getStatusDropdonw = () => {
    let ddstatus = [];
    getApi(`types?type=TRIP_ITEM_STATUS`, cookies.get('authorization')).then(
      (res) => {
        if (res && res.status !== false) {
          ddstatus = res;
          ddstatus = ddstatus.map((item) => ({
            name: item.value,
            value: item.key,
            code: item.code,
          }));
          this.setState({
            StatusDropdonw: ddstatus,
          });
        }
      }
    );
  };

  getTripStatusDropdonw = () => {
    let ddstatus = [];
    getApi(`types?type=TRIP_STATUS`, cookies.get('authorization')).then(
      (res) => {
        if (res && res.status !== false) {
          ddstatus = res;
          ddstatus = ddstatus.map((item) => ({
            name: item.value,
            value: item.key,
          }));
          this.setState({
            TripStatusDropdonw: ddstatus,
          });
        }
      }
    );
  };


  getTruckDropdonw = () => {
    let ddtrailer = [];
    let ddtruck = [];
    let istSearchList = '';
    getApi(
      `assets?paged=false`,
      cookies.get('authorization')
    ).then((res) => {
      console.log('TT', res)
      if (res && res?._embedded?.content?.length > 0) {
        istSearchList = res?._embedded?.content?.length;
        if (istSearchList > 0) {

          let ddT = res._embedded.content;
          let ddTC = res._embedded.content;
          ddT = ddT.filter(item => item?.vehicle_typ?.code === 'TRAILER')
          ddTC = ddTC.filter(item => item?.vehicle_typ?.code === 'TRUCK')
          ddtrailer = ddT;
          ddtruck = ddTC;
          ddtruck = ddtruck.map((item) => ({
            name: item.asset_code,
            value: item.id,
          }));
          ddtrailer = ddtrailer.map((item) => ({
            name: item.asset_code,
            value: item.id,
          }));
          this.setState({
            TruckDropdonw: ddtruck,
            TrailerDropdonw: ddtrailer,
            TrailerDropdonw2: ddtrailer,
            TempTrailerDropdonw: ddtrailer,

          });
        }
      }
    });
  };


  getDriverDropdonw = () => {
    let dddriver = [];
    getApi(
      `employees?paged=true&page=1&size=50000`,
      cookies.get('authorization')
    ).then((res) => {
      if (res && res.status !== false) {
        let count = res && res.page && res.page.total_elements;
        if (count > 0) {
          dddriver = res._embedded.content;
          dddriver = dddriver.map((item) => ({
            name: item.first,
            value: item.id,
            phone: item.phone,
          }));

          this.setState({
            DriverDropdonw: dddriver,
            DriverDropdonw2: dddriver,
            TempDriverDropdonw: dddriver,
          });
        } else {
          this.setState({
            DriverDropdonw: [],
            DriverDropdonw2: [],
            TempDriverDropdonw: [],
          });
        }
      }
    });
  };

  getTripId = () => {
    getApi(`trips?intent=index`, cookies.get('authorization')).then((res) => {
      if (res && res.status !== false) {
        this.setState({
          tripValue: {
            ...this.state.tripValue,
            trip_no: res.message,
          },
        });
      }
    });
  };
  handLoadConfirm = () => {
    this.setState((prevState) => {
      let { loadCnf, loadTypeBool } = prevState;
      loadCnf = true;
      loadTypeBool = false;

      return { loadCnf, loadTypeBool };
    });
  };
  handLoadModal = () => {
    this.setState((prevState) => {
      let { loadTypeBool } = prevState;
      loadTypeBool = prevState.loadTypeBool === false ? true : false;

      return { loadTypeBool };
    });
  };
  getOrderById = () => {
    this.setState((prevState) => {
      let { consignValue, OrderList, loadCnf, typeMessage } = prevState;
      let data = OrderList.filter((item) => item.isChecked === true);

      let itemData = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].items.length; j++) {
          itemData.push({
            id: null,
            orderids: data[i].id,
            order_item: data[i].items[j].id,
            type: data[i].items[j].type,
            location: data[i].items[j].location,
            loc_nm: data[i].items[j].loc_nm,
            postal_code: data[i].items[j].postal_code,
            qty: data[i].items[j].qty,
            pkg_type: data[i].items[j].pkg_type.id,

            province: data[i].items[j].province,
            city: data[i].items[j].city,
            item_status:
              data[i].items[j].item_status !== '' ||
                data[i].items[j].item_status != null ||
                data[i].items[j].item_status !== undefined
                ? parseInt(data[i].items[j].item_status.id)
                : 228,
            load_type: data[i].load_type,
            info:
              data[i].items[j].info !== '' ||
                data[i].items[j].info != null ||
                data[i].items[j].info !== undefined
                ? data[i].items[j].info
                : '',
            stop: 0,
            order: data[i].order_no,
            apnt_type:
              data[i].items[j].apnt_type !== '' ||
                data[i].items[j].apnt_type != null ||
                data[i].items[j].apnt_type !== undefined
                ? parseInt(data[i].items[j].apnt_type.id)
                : 300,
            req_date: new Date(
              getFormattedDate(
                data[i].items[j].req_date,
                'DD-MM-YYYY hh:mm:ss',
                false
              )
            ),
            arv_date:
              data[i].items[j].arv_date != null
                ? new Date(
                  getFormattedDate(
                    data[i].items[j].arv_date,
                    'DD-MM-YYYY hh:mm:ss',
                    false
                  )
                )
                : new Date(),
            dep_date:
              data[i].items[j].dep_date != null
                ? new Date(
                  getFormattedDate(
                    data[i].items[j].dep_date,
                    'DD-MM-YYYY hh:mm:ss',
                    false
                  )
                )
                : new Date(),
            weight: data[i].items[j].weight,
            note: data[i].items[j].note,
            status: data[i].items[j].status,
          });
        }
      }

      itemData = itemData.concat(consignValue);
      itemData = itemData.map((item, index) => ({
        ...item,
        stop: index + 1,
      }));

      itemData = itemData.filter(
        (item, index, oldArray) =>
          oldArray.findIndex((t) => t.order_item === item.order_item) === index
      );
      consignValue = itemData;
      this.handelMessage('Order successfully added in trip', 'legalBoll');
      this.handleOrderModal();
      return { consignValue, loadCnf, typeMessage };
    });
  };
  getOrderID = async () => {
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.order_id
    ) {
      let id = this.props.location.state.order_id;

      let res = await getApiAsyn(`orders/${id}`, cookies.get('authorization'));

      let itemData = [];
      let data = res.items;

      for (let i = 0; i < data.length; i++) {
        itemData.push({
          id: null,
          orderids: res?.id,
          order_item: data[i]?.id,
          type: data[i]?.type,
          location: data[i]?.location,
          loc_nm: data[i]?.loc_nm,
          postal_code: data[i]?.postal_code,
          province: data[i]?.province,
          city: data[i]?.city,
          qty: data[i]?.qty,
          item_status: data[i]?.item_status?.id,
          info: data[i]?.info,
          load_type: res?.load_type,
          stop: 0,
          order: res?.order_no,
          apnt_type: parseInt(data[i]?.apnt_type?.id),
          pkg_type: parseInt(data[i]?.pkg_type?.id),
          req_date: new Date(
            getFormattedDate(data[i]?.req_date, 'DD-MM-YYYY hh:mm:ss', false)
          ),
          arv_date:
            data[i].arv_date != null
              ? new Date(
                getFormattedDate(
                  data[i]?.arv_date,
                  'DD-MM-YYYY hh:mm:ss',
                  false
                )
              )
              : new Date(),
          dep_date:
            data[i].dep_date != null
              ? new Date(
                getFormattedDate(
                  data[i]?.dep_date,
                  'DD-MM-YYYY hh:mm:ss',
                  false
                )
              )
              : new Date(),
          weight: data[i]?.weight,
          note: data[i]?.note,
          status: data[i]?.status,
        });
      }
      itemData = itemData.map((item, index) => ({
        ...item,
        stop: index + 1,
      }));
      this.setState((prevState) => {
        let { consignValue } = prevState;
        consignValue = itemData;

        return { consignValue };
      });
    }
  };
  handleOrderModal = () => {
    this.setState((prevState) => {
      let { orderModal } = prevState;

      orderModal = prevState.orderModal === false ? true : false;
      return { orderModal };
    });
  };
  handleSeachableconsignDropdonw = (val, name) => {
    const { consignValue } = this.state;
    consignValue[name] = val;
    this.setState({
      [consignValue]: consignValue,
      PromptPrevent: true,
    });
  };

  handleChange = (e, index) => {
    let { consignValue } = this.state;
    const { name, value } = e.target;
    consignValue = [...consignValue];
    consignValue[index][name] = value;
    this.setState({ consignValue });
    this.setState({
      PromptPrevent: true,
    });
  };
  setLocation = () => { };
  handleDateConsig = (date, name, index) => {
    const { consignValue } = this.state;
    consignValue[index][name] = date;
    this.setState({
      [consignValue]: consignValue,
    });
  };
  handleAddClick = (id) => {
    const { StatusDropdonw, APPNT_TYPEDropdown, PackgDropdonw } = this.state;
    let itemstatus = StatusDropdonw.filter((item) => item.code === 'NEW');
    let PKGBOXES = PackgDropdonw.filter((item) => item.code === 'PKGBOXES');
    let APPT = APPNT_TYPEDropdown.filter((item) => item.code === 'NO');
    this.setState((prevState) => ({
      consignValue: [
        ...prevState.consignValue,
        {
          id: 0,
          type: id,
          location: '',
          postal_code: '',
          province: '',
          city: '',
          pkg_type: PKGBOXES && PKGBOXES[0].value,
          item_status: itemstatus && itemstatus[0].value,
          stop: prevState.consignValue.length + 1,
          order: '',
          req_date: new Date(),
          arv_date: null,
          dep_date: null,
          weight: 0,
          note: '',
          info: '',
          apnt_type: APPT && APPT[0].value,
          status: true,
        },
      ],
    }));
  };
  formatDates = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };
  handleMessageDataClose = (Boll) => {
    const { formOption } = this.state;
    formOption[Boll] = false;
    this.setState({
      formOption: formOption,
    });
  };
  handleMessageData = (Message, Boll) => {
    const { formOption } = this.state;
    formOption[Boll] = true;
    formOption['Message'] = Message;

    this.setState({
      formOption: formOption,
    });
  };
  handelMessage = (Message, Boll) => {
    const { formOption } = this.state;
    formOption[Boll] = true;
    formOption['Message'] = Message;
    this.setState(
      {
        formOption: formOption,
      },
      function () {
        setTimeout(
          function () {
            formOption[Boll] = false;
            this.setState({
              formOption: formOption,
            });
          }.bind(this),
          3000
        );
      }
    );
  };

  getOrderSearch = async () => {
    const { orderSearch } = this.state;
    if (orderSearch === undefined) {
    } else {
      /*if (orderSearch.id === undefined) {
      this.handelMessage('Please select customer', 'errorBoll');
    } else if (orderSearch.load === undefined) {
      this.handelMessage('Please select load type', 'errorBoll');
    } 
    
    else if (orderSearch.date1 === undefined) {
      this.handelMessage('Please select from date', 'errorBoll');
    } else if (orderSearch.date2 === undefined) {
      this.handelMessage('Please select To date', 'errorBoll');
    }*/
      let id =
        orderSearch.id !== undefined && orderSearch.id !== '-1'
          ? '&id=' + orderSearch.id
          : '';
      id +=
        orderSearch.load !== undefined && orderSearch.load !== '-1'
          ? '&load=' + orderSearch.load
          : '';
      id +=
        orderSearch.date1 !== undefined && orderSearch.date1 !== '-1'
          ? '&date1=' + this.formatDates(orderSearch.date1)
          : '';
      id +=
        orderSearch.date2 !== undefined && orderSearch.date2 !== '-1'
          ? '&date2=' + this.formatDates(orderSearch.date2)
          : '';
      loadierShow('block');
      let res = await getApiAsyn(
        `orders?intent=search&trips_search=true${id}`,
        cookies.get('authorization')
      );
      if (res && res.status !== false) {
        if (res.page.total_elements > 0) {
          let data = res._embedded.content;
          data = data.filter(
            (item) =>
              item.order_status.name === 'New' ||
              item.order_status.name === 'Received'
          );
          data = data.map((item) => ({
            ...item,
            isChecked: false,
          }));
          loadierShow('none');

          this.setState({
            OrderList: data,
            load_type: res._embedded.content.load_type,
            OrderToShow: data,
          });
        }
      } else {
        loadierShow('none');
        this.setState({
          OrderList: [],
        });
      }
    }
  };

  getTripTypeDropdonw = () => {
    let ddtriptype = [];
    getApi(`types?type=TRIP_TYPE`, cookies.get('authorization')).then((res) => {
      if (res && res.status !== false) {
        ddtriptype = res;
        ddtriptype = ddtriptype.map((item) => ({
          name: item.value,
          value: item.key,
        }));
        this.setState({
          tripTypeDropdonw: ddtriptype,
        });
      }
    });
  };
  handleRemoveClick = (index) => {
    let { consignValue, deleteItemIndex, orderItem } = this.state;
    if (orderItem === 0) {
      this.setState((prevState) => {
        let { consignValue, deleteItemIndex } = prevState;
        consignValue.splice(deleteItemIndex, 1);
        consignValue = consignValue.map((item, i) => ({
          ...item,
          stop: i + 1,
        }));
        return { consignValue, deleteItemIndex };
      });
    } else {
      let updateObject = [];
      consignValue = JSON.parse(JSON.stringify(consignValue));
      consignValue[deleteItemIndex].status = false;

      let n = consignValue;
      let statusFalse = n.filter((item) => item.status === false);
      let statusTrue = n.filter((item) => item.status === true);
      consignValue = statusTrue.concat(statusFalse);
      for (let i = 0; i < consignValue.length; i++) {
        /* let xdate = getFormattedDate(
          consignValue[i].req_date,
          'YYYY-MM-DD hh:mm:ss',
          true
        );
        let arvdate = getFormattedDate(
          consignValue[i].arv_date,
          'YYYY-MM-DD hh:mm:ss',
          true
        );
        let depvdate = getFormattedDate(
          consignValue[i].dep_date,
          'YYYY-MM-DD hh:mm:ss',
          true
        );
        */
        updateObject.push({
          id: consignValue[i].id || 0,
          stop: i + 1,

          group: consignValue[i].group,
          order: consignValue[i].order,
          orderids: consignValue[i].orderids,
          type: consignValue[i].type,
          loc_nm: consignValue[i].loc_nm,
          city: consignValue[i].city,
          location: consignValue[i].location,
          postal_code: consignValue[i].postal_code,
          req_date: new Date(consignValue[i].req_date),
          arv_date: new Date(consignValue[i].arv_date),
          dep_date: new Date(consignValue[i].dep_date),
          weight:
            consignValue[i].weight !== '' ||
              consignValue[i].weight !== undefined
              ? parseInt(consignValue[i].weight)
              : consignValue[i].weight,
          order_item: consignValue[i].order_item || null,
          note: consignValue[i].note,
          apnt_type: consignValue[i].apnt_type,
          item_status: parseInt(consignValue[i].item_status),
          status: consignValue[i].status,
          info: consignValue[i].info,
          item_drv_note: consignValue[i].item_drv_note,
          item_sig_req: consignValue[i].item_sig_req,
          item_verified: consignValue[i].item_verified,
          pkg_type: consignValue[i].pkg_type,
          province: consignValue[i].province,
          qty: consignValue[i].qty,
        });
      }
      this.setState({
        consignValue: updateObject,
      });
    }
    this.toggleDeleteModal();
  };

  handleSeachableDropdonw = (val, name) => {
    const {
      tripValue,
      TempDriverDropdonw,
      TempTrailerDropdonw,
      errors,
    } = this.state;
    tripValue[name] = val;
    this.setState({
      PromptPrevent: true,
      [tripValue]: tripValue,
    });
    if (name === 'driver1') {
      let dd2 = TempDriverDropdonw.filter((item) => item.value !== val);
      let nd = TempDriverDropdonw.filter((item) => item.value === val);

      this.setState((prevState) => {
        let { DriverDropdonw2, driverphone } = prevState;
        dd2.push({
          name: 'Select...',
          value: '-1',
        });
        driverphone = '';
        driverphone = nd && nd[0].phone !== undefined ? nd[0].phone : '';
        DriverDropdonw2 = dd2;
        return { DriverDropdonw2, driverphone };
      });

      errors['driver1'] = '';
      this.setState({ errors: errors });
    }
    if (name === 'driver2') {
      let dd1 = TempDriverDropdonw.filter((item) => item.value !== val);
      this.setState((prevState) => {
        let { DriverDropdonw } = prevState;
        DriverDropdonw = dd1;
        return { DriverDropdonw };
      });
    }

    if (name === 'trailer1') {
      let dd2 = TempTrailerDropdonw.filter((item) => item.value !== val);
      this.setState((prevState) => {
        let { TrailerDropdonw2 } = prevState;
        dd2.push({
          name: 'Select...',
          value: '-1',
        });
        TrailerDropdonw2 = dd2;
        return { TrailerDropdonw2 };
      });
      errors['trailer1'] = '';
      this.setState({ errors: errors });
    }
    if (name === 'trailer2') {
      let dd1 = TempTrailerDropdonw.filter((item) => item.value !== val);
      this.setState((prevState) => {
        let { TrailerDropdonw } = prevState;
        TrailerDropdonw = dd1;
        return { TrailerDropdonw };
      });
    }
    if (name === 'vehicle') {
      errors['vehicle'] = '';
    }
  };

  handleChangeGenOrderSearch = (event) => {
    const { orderSearch } = this.state;
    orderSearch[event.target.name] = event.target.value;
    this.setState({
      PromptPrevent: true,
      [orderSearch]: orderSearch,
    });
    if (event.target.name === 'customerId') {
      this.setState({
        bool: true,
      });
    }
  };
  handleChangeGen = (event) => {
    const { tripValue } = this.state;
    tripValue[event.target.name] = event.target.value;
    this.setState({
      PromptPrevent: true,
      [tripValue]: tripValue,
    });
  };
  handleChangeGenMile = (event) => {
    this.setState({
      milestotal: event.target.value,
    });
  };
  handleChangeGenFile = (event) => {
    const { tripValue } = this.state;
    if (event.target.value !== '') {
      tripValue[event.target.name] = event.target.value;
      let fname = event.target.files[0]?.name;
      fname = fname.substr(0, fname.lastIndexOf("."));
      if (event.target.type === 'file' && fname.length > 2) {
        tripValue[event.target.name] = event.target.files[0];
        FileData.push(event.target.files[0]);
        tripValue['files'] = event.target.files;
        this.setState({
          PromptPrevent: true,
          [tripValue]: tripValue,
          ImageDisplay: FileData,
        });
      }
      else {
        this.handelMessage('File name must have atlest 3 charector which you are trying to upload', 'errorBoll');
      }
    }
  };

  handleRemoveFile = (item, i) => {
    let { tripValue, ImageDisplay } = this.state;

    let data = ImageDisplay;

    let newData = Object.values(data).filter((user, idx) => idx !== i);

    FileData.splice(i, 1);
    this.setState({
      ImageDisplay: newData,
      PromptPrevent: true,
      tripValue: {
        ...tripValue,
        files: newData,
      },
    });
  };
  handleChangeGenChecked = (event) => {
    const { tripValue } = this.state;
    tripValue[event.target.name] = event.currentTarget.value;
    this.setState({
      PromptPrevent: true,
      [tripValue]: tripValue,
    });
  };
  handleChangeGenCheckeds = (event) => {
    let { name, checked } = event.target;
    this.setState((prevState) => {
      let { OrderList, allChecked } = prevState;
      if (name === 'all') {
        allChecked = checked;
        if (allChecked === true) {
          OrderList = OrderList.map((item) => ({
            ...item,
            isChecked: true,
          }));
        } else {
          OrderList = OrderList.map((item) => ({
            ...item,
            isChecked: false,
          }));
        }
      } else {
        OrderList = OrderList.map((item) =>
          item.id === parseInt(name)
            ? { ...item, isChecked: checked }
            : { ...item }
        );
      }
      return { OrderList, allChecked };
    });
  };

  handleDate = (date, name) => {
    const { tripValue, errors } = this.state;
    tripValue[name] = date;
    this.setState({
      PromptPrevent: true,
      [tripValue]: tripValue,
    });
    if (name === 'trip_date') {
      errors['trip_date'] = '';
      this.setState({ errors: errors });
    }
  };

  handleDateOrderSearch = (date, name) => {
    const { orderSearch } = this.state;
    orderSearch[name] = date;
    this.setState({
      ...orderSearch,
      [orderSearch]: orderSearch,
    });
  };

  handleValidation = () => {
    const { tripValue, isConfirmation } = this.state;

    let errors = {};
    let formIsValid = true;
    //Name

    if (isNotify === false && isConfirmation === false) {
      this.getNotifiyShipperConsignee();
    }
    if (!tripValue['trip_type']) {
      formIsValid = false;
      errors['trip_type'] = 'This is a mandatory field.';
    }
    if (!tripValue['trip_no']) {
      formIsValid = false;

      errors['trip_no'] = 'This is a mandatory field.';
    }
    if (!tripValue['trip_date']) {
      formIsValid = false;

      errors['trip_date'] = 'This is a mandatory field.';
    }
    if (!tripValue['vehicle']) {
      formIsValid = false;

      errors['vehicle'] = 'This is a mandatory field.';
    }
    if (!tripValue['driver1']) {
      formIsValid = false;

      errors['driver1'] = 'This is a mandatory field.';
    }
    if (tripValue['trailer_owner'] !== 'CT' && !tripValue['trailer1']) {
      formIsValid = false;

      errors['trailer1'] = 'This is a mandatory field.';
    }
    if (!tripValue['trailer_owner']) {
      formIsValid = false;
      errors['trailer_owner'] = 'This is a mandatory field.';
    }
    if (
      tripValue['ref_start'] > 0 &&
      tripValue['ref_end'] < tripValue['ref_start']
    ) {
      formIsValid = false;
      errors['ref_start'] = 'Ref start can not be greater than ref end ';
    }
    if (tripValue['ref_start'] > 999 || tripValue['ref_end'] > 999) {
      formIsValid = false;
      errors['ref_start'] = 'Ref start and ref end can be maximum 999';
    }
    if (
      tripValue['odm_start'] > 0 &&
      tripValue['odm_end'] < tripValue['odm_start']
    ) {
      formIsValid = false;
      errors['odm_start'] =
        'Odometer start can not be greater than odometer end ';
    }
    if (tripValue['odm_start'] > 99999 || tripValue['odm_end'] > 99999) {
      formIsValid = false;
      errors['odm_start'] =
        'Odometer start and Odometer end can be maximum 999';
    }

    this.setState({ errors: errors });
    return formIsValid;
  };
  handleSave = () => {
    if (
      this.handleValidation() &&
      this.getNotifiyShipperConsignee() &&
      this.getNotifiyCSIsEqual()
    ) {
      const { tripValue, consignValue, milestotal } = this.state;

      let consObject = [];
      for (let i = 0; i < consignValue.length; i++) {
        if (
          consignValue[i].location === '' ||
          consignValue[i].location === undefined
        ) {
          // this.handelMessage('Location is required', 'errorBoll');
          this.handleMessageData('Location is required', 'errorBolls');
          return false;
        }
        if (
          consignValue[i].postal_code === '' ||
          consignValue[i].postal_code === undefined
        ) {
          this.handleMessageData('Postal code is required', 'errorBolls');
          return false;
        }
        if (
          consignValue[i].province === '' ||
          consignValue[i].province === undefined
        ) {
          this.handleMessageData('Province is required', 'errorBolls');
          return false;
        }
        if (consignValue[i].city === '' || consignValue[i].city === undefined) {
          this.handleMessageData('City is required', 'errorBolls');
          return false;
        }
        if (
          consignValue[i].loc_nm === '' ||
          consignValue[i].loc_nm === undefined
        ) {
          this.handleMessageData('C/S Name is required', 'errorBolls');
          return false;
        }
        if (consignValue[i].qty === '' || consignValue[i].qty === undefined) {
          //this.handelMessage('Qty is required', 'errorBoll');
          this.handleMessageData('Qty is required', 'errorBolls');
          return false;
        }
        if (
          consignValue[i].pkg_type == null ||
          consignValue[i].pkg_type === '' ||
          consignValue[i].pkg_type === undefined
        ) {
          // this.handelMessage('Package type is required', 'errorBoll');
          this.handleMessageData('Package type is required', 'errorBolls');
          return false;
        }
        if (
          consignValue[i].item_status === '' ||
          consignValue[i].item_status === undefined
        ) {
          // this.handelMessage('Item status is required', 'errorBoll');
          this.handleMessageData('Item status is required', 'errorBolls');
          return false;
        }
        if (
          consignValue[i].req_date === '' ||
          consignValue[i].req_date === undefined
        ) {
          //this.handelMessage('Request date is required', 'errorBoll');
          this.handleMessageData('Request date is required', 'errorBolls');
          return false;
        }
        if (
          consignValue[i].apnt_type === '' ||
          consignValue[i].apnt_type == null
        ) {
          // this.handelMessage('Appointment type is required', 'errorBoll');
          this.handleMessageData('Appointment type is required', 'errorBolls');
          return false;
        } else {
          consObject.push({
            id: consignValue[i].id || null,
            stop: i + 1,

            group:
              consignValue[i].orderids !== '' &&
                consignValue[i].orderids != null &&
                consignValue[i].orderids !== undefined
                ? 'O'
                : 'M',
            order: consignValue[i].orderids,

            type: consignValue[i].type,
            location: consignValue[i].location,
            province: consignValue[i].province,
            city: consignValue[i].city,
            postal_code: consignValue[i].postal_code,
            loc_nm: consignValue[i].loc_nm,
            pkg_type: parseInt(consignValue[i].pkg_type) || null,
            qty: consignValue[i].qty,
            req_date: formatDate(consignValue[i].req_date),
            arv_date:
              consignValue[i].arv_date != null
                ? formatDate(consignValue[i].arv_date)
                : null,
            dep_date:
              consignValue[i].dep_date != null
                ? formatDate(consignValue[i].dep_date)
                : null,
            weight: consignValue[i].weight,
            order_item: consignValue[i].order_item,
            note: consignValue[i].note,
            info: consignValue[i].info,
            item_status: parseInt(consignValue[i].item_status),
            apnt_type: parseInt(consignValue[i].apnt_type),
            status: consignValue[i].status,
          });
        }
      }
      /* let newData = consignValue.filter(
        (item, index, oldArray) =>
          oldArray.findIndex((t) => t.order === item.order) === index
      );

      let nExit = newData.filter((item) => item.load_type === 'FL');
      if (nExit.length === 1 && consignValue.length > 1 && loadCnf !== true) {
        this.handLoadModal();
        this.setState({
          typeMessage: `You have already Full Load in the trip. Are sure you want to continue with more item`,
        });
        return false;
      }

      if (nExit.length > 1 && loadCnf !== true) {
        this.handLoadModal();
        this.setState({
          typeMessage: `You have already Full Load in the trip. Are sure you want to continue with more item`,
        });
        return false;
      }
      */
      if (consignValue.length === 0) {
        //this.handelMessage('Trip can not run without items', 'errorBoll');
        this.handleMessageData('Trip can not run without items', 'errorBolls');
      }
      if (
        consignValue.length !== 0 &&
        consignValue.length === consObject.length
      ) {
        consObject = consObject.filter(item => (item.id!==0 || item.id!=null) && item.status!==false );
        let reqData = {
          id: tripValue.id || null,
          trip_no: tripValue.trip_no,
          trip_type: tripValue.trip_type,
          trip_date: formatDate(tripValue.trip_date),

          vehicle: tripValue.vehicle,
          seal_no: tripValue.seal_no,
          driver1: tripValue.driver1,
          driver2: tripValue.driver2 !== '-1' ? tripValue.driver2 : null,
          calc_miles: milestotal,
          ref_start: parseInt(tripValue.ref_start),
          ref_end: parseInt(tripValue.ref_end),
          trailer_owner: tripValue.trailer_owner,
          odm_start: parseInt(tripValue.odm_start),
          odm_end: parseInt(tripValue.odm_end),

          trailer1:
            tripValue['trailer_owner'] !== 'CT' ? tripValue.trailer1 : null,
          trailer2: tripValue.trailer2 !== '-1' ? tripValue.trailer2 : null,
          cust_trailer1:
            tripValue.cust_trailer1 !== undefined
              ? tripValue.cust_trailer1
              : '',
          cust_trailer2:
            tripValue.cust_trailer2 !== undefined
              ? tripValue.cust_trailer2
              : '',
          note: tripValue.notes !== undefined ? tripValue.notes : '',
          trip_status: tripValue.trip_status || 247,
          items: consObject,
        };

        loadierShow('block');
        const formPayload = new FormData();

        let files = FileData;
        if (files !== undefined && files.length > 5) {
          // this.handelMessage('You can attach max 5 file in a trip','errorBoll');
          this.handleMessageData(
            'You can attach max 5 file in a trip',
            'errorBolls'
          );
          return false
        }
        if (files !== undefined && files.length > 0 && files.length < 5) {
          for (let i = 0; i < files.length; i++) {
            formPayload.append(`file`, files[i]);
          }
        }
        const json = JSON.stringify(reqData);
        const order = new Blob([json], {
          type: 'application/json',
        });
        formPayload.append('data', order);

        this.fileUpload(formPayload).then((response) => {
          loadierShow('none');
          isNotify = false;
          this.setState(
            {
              legalBoll: true,
              PromptPrevent: false,
              successMessage: 'Trip added successfully!',
            },
            function () {
              setTimeout(
                function () {
                  this.setState({
                    legalBoll: false,
                    PromptPrevent: false,
                  });
                  window.location.replace('#/fleet/trips');
                }.bind(this),
                3000
              );
            }
          );
        });
      }
    }
  };

  fileUpload = (reqData) => {
    if (this.props.match.params.id) {
      const url = baseUrls + `trips/${this.props.match.params.id}`;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: cookies.get('authorization'),
        },
      };
      return put(url, reqData, config);
    } else {
      const url = baseUrls + 'trips';
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: cookies.get('authorization'),
        },
      };
      return post(url, reqData, config);
    }
  };
  handleCallMiles = async () => {
    const { consignValue } = this.state;
    if (consignValue.length === 0) {
      this.handelMessage('Please add order from list', 'errorBoll');
    } else {
      let zipData = '';
      for (let i = 0; i < consignValue.length; i++) {
        let postalCode = consignValue[i].postal_code;
        zipData += postalCode + '|';
      }
      let res = await getApiAsyn(
        `miles?value=${zipData}`,
        cookies.get('authorization')
      );
      if (res.message) {
        let mile = parseInt(res.message);
        this.setState({
          milestotal: Math.round(mile),
        });
      }
    }
  };

  async componentDidMount() {
    isNotify = false;
    await loadingsShow('block');
    await this.getOrderID();
    await this.getTripTypeDropdonw();
    await this.getAPPNT_TYPEDropdonw();
    await this.getPackgType();
    await this.getStatusDropdonw();
    await this.getTripStatusDropdonw();
    await this.getTruckDropdonw();
    await this.getDriverDropdonw();
    await this.getCustomerData();
    await this.getTripByID();
    await loadingsShow('none');
    document.addEventListener('mousedown', this.handleClickOutside);
    if (
      this.props.match.params.id === '' ||
      this.props.match.params.id === undefined
    ) {
      this.getTripId();
    }
  }

  /* onDragEnd = (result) => {
        if (!result.destination) {
          return;
        }
    
        const items = this.reorder(
          this.state.consignValue,
          result.source.index,
          result.destination.index
        );
    
        this.setState({
          items
        });
      }*/
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onDragEnd = (result) => {
    const { consignValue } = this.state;
    if (!result.destination) {
      return;
    }

    let UpdatedIndexedItems = consignValue.map((item, index) => {
      if (index === result.source.index) {
        return { ...item, stop: result.destination.index + 1 };
      }

      if (index === result.destination.index) {
        return { ...item, stop: result.source.index + 1 };
      }

      return item;
    });

    const updatedItems = this.reorder(
      UpdatedIndexedItems,
      result.source.index,
      result.destination.index
    );

    this.setState({
      consignValue: updatedItems,
    });
  };
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  getSamsaraLocation = async () => {
    let res = await getApiAsyn(
      `samsara/locations`,
      cookies.get('authorization')
    );

    let newData = res.data;
    console.log('samsara res', newData);

    newData = newData.map((item) => ({
      id: item.id,
      lat: item.location.latitude,
      lng: item.location.longitude,
      name: item.name,
      address: item.location.reverseGeo.formattedLocation,
    }));

    this.setState({
      trackingData: newData,
    });
  };
  render() {
    const {
      Data,
      trackingData,
      showItemModal,
      orderItemModal,
      driverphone,
      moreModal,
      ImageAddedS3,
      showDeleteModal,
      textMessage,
      isTripEditable,
      locationModal,
      showDirectionModal,
      bool,
      Place,
      currentBoxAddress,
      orderId,
      formOption,
      TripStatusDropdonw,
      tripTypeDropdonw,
      consignValue,
      TrailerDropdonw,
      TrailerDropdonw2,
      DriverDropdonw,
      DriverDropdonw2,
      APPNT_TYPEDropdown,
      errors,
      OrderList,
      orderModal,
      TruckDropdonw,
      orderSearch,
      PackgDropdonw,
      isSendModal,
      StatusDropdonw,
      tripValue,
      milestotal,
      DirectionData,
      DirectionCenter,
      allChecked,
      ImageDisplay,
      showConfirmation,
      confirmationMessage,
      loadTypeBool,
      typeMessage,
      PromptPrevent,
      CSModal,
      CSMessage,
      access,
      showTrackingModal,
    } = this.state;
    const showOrderItemModal = Boolean(
      orderItemModal >= 0 && orderItemModal !== false
    );

    const showMoreModal = Boolean(moreModal >= 0 && moreModal !== false);

    const showLocationModal = Boolean(
      locationModal >= 0 && locationModal !== false
    );

    const noteValue =
      consignValue &&
      showOrderItemModal &&
      consignValue[orderItemModal]['note'];

    const driverNotes =
      consignValue && showMoreModal && consignValue[moreModal]['item_drv_note'];

    const itemSignature =
      consignValue && showMoreModal && consignValue[moreModal]['item_sig_req'];

    const itemVerified =
      consignValue && showMoreModal && consignValue[moreModal]['item_verified'];

    const files =
      consignValue && showMoreModal && consignValue[moreModal]['files'];

    const addressValue =
      consignValue &&
      showLocationModal &&
      consignValue[locationModal]['location'];
    const cityValue =
      consignValue && showLocationModal && consignValue[locationModal]['city'];

    const postalValue =
      consignValue &&
      showLocationModal &&
      consignValue[locationModal]['postal_code'];

    const provenceValue =
      consignValue &&
      showLocationModal &&
      consignValue[locationModal]['province'];

    const loc_nmValue =
      consignValue &&
      showLocationModal &&
      consignValue[locationModal]['loc_nm'];
    //const WrappedMap = withScriptjs(withGoogleMap(Maps));

    const MapLoader = withScriptjs(Maps);

    return (
      <div>
        <SnackbarError
          errorBoll={formOption.errorBoll}
          errorMessage={formOption.Message}
        />
        <SnackbarSuccess
          legalBoll={formOption.legalBoll}
          successMessage={formOption.Message}
        />
        <div className="paddingbox">
          <div className="containerboxform">
            <div className="title bdrb">
              <h3>
                {this.props.match !== undefined &&
                  this.props.match !== '' &&
                  this.props.match.params.id !== '' &&
                  this.props.match.params.id !== undefined
                  ? 'Update Trips'
                  : 'New Trips'}
              </h3>
            </div>

            <div className="form tripbox">
              <Col sm="12">
                <Row className="bdrb">
                  <Col md={6}>
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Trip Type*
                          </Form.Label>
                          <Col sm="8">
                            <SelectSearch
                              disabled={tripValue.default}
                              container="rowselect"
                              search
                              options={tripTypeDropdonw}
                              select="test"
                              name="trip_type"
                              autoComplete="sdf"
                              onChange={(val) =>
                                this.handleSeachableDropdonw(val, 'trip_type')
                              }
                              value={tripValue.trip_type}
                              placeholder="Select trip type"
                            />
                            <span
                              className={
                                errors['trip_type'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['trip_type']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    {this.props.match.params.id !== undefined ? (
                      <Row>
                        <Col sm="12">
                          <Form.Group as={Row}>
                            <Form.Label column sm="3" className="text-right">
                              Trip Status*
                            </Form.Label>
                            <Col sm="8">
                              <SelectSearch
                                disabled={isTripEditable}
                                container="rowselect"
                                search
                                options={TripStatusDropdonw}
                                select="test"
                                name="trip_status"
                                autoComplete="sdf"
                                onChange={(val) =>
                                  this.handleSeachableDropdonw(
                                    val,
                                    'trip_status'
                                  )
                                }
                                value={tripValue.trip_status}
                                placeholder="Select trip type"
                              />
                              <span
                                className={
                                  errors['trip_status']
                                    ? 'errorshow'
                                    : 'errorshide'
                                }
                              >
                                {errors['trip_status']}
                              </span>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : null}
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Seal No
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              name="seal_no"
                              value={tripValue.seal_no}
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['seal_no'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['seal_no']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Trip Start Date*
                          </Form.Label>
                          <Col sm="8">
                            <DatePicker
                              selected={tripValue.trip_date}
                              dateFormat="dd-MM-yyyy hh:mm:ss"
                              className="form-control inpcalendar"
                              popperPlacement="start"
                              showTimeSelect
                              name="trip_date"
                              autoComplete="off"
                              value={tripValue.trip_date}
                              onChange={(date) =>
                                this.handleDate(date, 'trip_date')
                              }
                            />
                            <span
                              className={
                                errors['trip_date'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['trip_date']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Row>
                          <Form.Label column sm="3" className="text-right">
                            Reefer Temp.<span className="celc">°C</span>
                          </Form.Label>
                          <Col sm="4">
                            <Form inline>
                              <InputGroup>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>Beginning</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  name="ref_start"
                                  value={tripValue.ref_start}
                                  onChange={this.handleChangeGen}
                                  placeholder="32 deg"
                                />
                                <span
                                  className={
                                    errors['ref_start']
                                      ? 'errorshow'
                                      : 'errorshide'
                                  }
                                >
                                  {errors['ref_start']}
                                </span>
                              </InputGroup>
                            </Form>
                          </Col>
                          <Col sm="4">
                            <Form inline>
                              <InputGroup>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>End</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  name="ref_end"
                                  value={tripValue.ref_end}
                                  onChange={this.handleChangeGen}
                                  placeholder="20 deg"
                                />
                              </InputGroup>
                            </Form>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Row>
                          <Form.Label column sm="3" className="text-right">
                            Odometer
                          </Form.Label>
                          <Col sm="4">
                            <Form inline>
                              <InputGroup>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>Start</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  name="odm_start"
                                  value={tripValue.odm_start}
                                  onChange={this.handleChangeGen}
                                  placeholder="250 miles"
                                />
                                <span
                                  className={
                                    errors['odm_start']
                                      ? 'errorshow'
                                      : 'errorshide'
                                  }
                                >
                                  {errors['odm_start']}
                                </span>
                              </InputGroup>
                            </Form>
                          </Col>
                          <Col sm="4">
                            <Form inline>
                              <InputGroup>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>End</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  name="odm_end"
                                  value={tripValue.odm_end}
                                  onChange={this.handleChangeGen}
                                  placeholder="500 miles"
                                />
                              </InputGroup>
                            </Form>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Attachment
                          </Form.Label>
                          <Col sm="8">
                            <Form.File
                              custom
                              type="file"
                              name="files"
                              label="Upload files"
                              onChange={this.handleChangeGenFile}
                            />
                            <div className="filetoupload">
                              <ul>
                                {ImageDisplay &&
                                  ImageDisplay.length > 0 &&
                                  ImageDisplay.map((item, i) => (
                                    <li key={`img` + i}>
                                      <span className="imgName">
                                        {' '}
                                        {item.name}
                                      </span>
                                      <span className="imgHolder">
                                        {item.type === 'application/pdf' ? (
                                          <img src={PDF_ICON} alt="img" />
                                        ) : (
                                          <img src={IMG_ICON} alt="img" />
                                        )}
                                      </span>
                                      <span
                                        className="remove"
                                        onClick={() =>
                                          this.handleRemoveFile(item, i)
                                        }
                                      >
                                        x
                                      </span>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </Col>
                        </Form.Group>

                        <div className="clr"></div>
                        <div className="filetoupload_list">
                          <ul>
                            {ImageAddedS3 &&
                              ImageAddedS3.length > 0 &&
                              ImageAddedS3.map((item, index) =>
                                item.status !== 'N' ? (
                                  <li>
                                    {item.name !== undefined ? (
                                      <span className="imgName">
                                        {' '}
                                        {item.name}
                                      </span>
                                    ) : null}
                                    {item.url !== undefined ? (
                                      <span
                                        className="downicon"
                                        onClick={() =>
                                          this.getImageDownload(
                                            item.url,
                                            item.name
                                          )
                                        }
                                      >
                                        Download
                                      </span>
                                    ) : null}
                                    <span
                                      className="remove"
                                      onClick={() => this.handleS3Remove(index)}
                                    >
                                      x
                                    </span>
                                  </li>
                                ) : null
                              )}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6}>
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Trip No
                          </Form.Label>
                          <Col sm="8">
                            <span className="tripno">{tripValue.trip_no}</span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Truck*
                          </Form.Label>
                          <Col sm="8">
                            <SelectSearch
                              container="rowselect"
                              search
                              options={TruckDropdonw}
                              select="test"
                              name="vehicle"
                              autoComplete="sdf"
                              onChange={(val) =>
                                this.handleSeachableDropdonw(val, 'vehicle')
                              }
                              value={tripValue.vehicle}
                              placeholder="Select Vehicle"
                            />
                            <span
                              className={
                                errors['vehicle'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['vehicle']}
                            </span>
                          </Col>
                        </Form.Group>
                        <Button
                          className="trackStatus2"
                          onClick={this.toggleTrackingModal}
                        >
                          Track
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Row>
                          <Form.Label column sm="3" className="text-right">
                            Drivers*
                          </Form.Label>
                          <Col sm="4">
                            <Form inline>
                              <InputGroup>
                                <InputGroup.Prepend>
                                  {/* <InputGroup.Text>Driver 1</InputGroup.Text> */}
                                </InputGroup.Prepend>
                                <SelectSearch
                                  id="driver"
                                  container="rowselect"
                                  search
                                  options={DriverDropdonw}
                                  select="test"
                                  name="driver1"
                                  autoComplete="sdf"
                                  onChange={(val) =>
                                    this.handleSeachableDropdonw(val, 'driver1')
                                  }
                                  value={tripValue.driver1}
                                  placeholder="Select Driver 1"
                                />
                                <span
                                  className={
                                    errors['driver1']
                                      ? 'errorshow'
                                      : 'errorshide'
                                  }
                                >
                                  {errors['driver1']}
                                </span>
                              </InputGroup>
                            </Form>
                          </Col>
                          <Col sm="4">
                            <Form inline>
                              <InputGroup>
                                <InputGroup.Prepend>
                                  {/* <InputGroup.Text>Driver 2</InputGroup.Text> */}
                                </InputGroup.Prepend>
                                <SelectSearch
                                  container="rowselect"
                                  id="driver2"
                                  search
                                  options={DriverDropdonw2}
                                  select="test"
                                  name="driver2"
                                  autoComplete="sdf"
                                  onChange={(val) =>
                                    this.handleSeachableDropdonw(val, 'driver2')
                                  }
                                  value={tripValue.driver2}
                                  placeholder="Select Driver 2"
                                />
                                <span
                                  className={
                                    errors['driver2']
                                      ? 'errorshow'
                                      : 'errorshide'
                                  }
                                >
                                  {errors['driver2']}
                                </span>
                              </InputGroup>
                            </Form>
                          </Col>
                        </Row>
                        {tripValue.driver1 !== undefined ? (
                          <Button
                            className="sendSMS"
                            onClick={this.handleMessageModal}
                          >
                            SMS
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <Form.Group>
                          <Form.Label className="text-right pdt">
                            Trailer Owner*
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col sm="3">
                        <Form.Group as={Row} className="centercheck">
                          <Form.Check
                            type="radio"
                            onChange={this.handleChangeGen}
                            checked={
                              tripValue.trailer_owner === 'CP' ||
                              tripValue.trailer_owner === true
                            }
                            value="CP"
                            name="trailer_owner"
                            id="CP"
                            htmlFor="CP"
                            label="Company"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="3">
                        <Form.Group as={Row} className="centercheck">
                          <Form.Check
                            type="radio"
                            onChange={this.handleChangeGen}
                            checked={
                              tripValue.trailer_owner === 'TP' ||
                              tripValue.trailer_owner === true
                            }
                            value="TP"
                            id="TP"
                            htmlFor="TP"
                            name="trailer_owner"
                            label="3rd Party Trailer"
                          />
                        </Form.Group>
                      </Col>

                      <Col sm="3">
                        <Form.Group as={Row} className="centercheck">
                          <Form.Check
                            type="radio"
                            onChange={this.handleChangeGen}
                            checked={
                              tripValue.trailer_owner === 'CT' ||
                              tripValue.trailer_owner === true
                            }
                            value="CT"
                            id="CT"
                            htmlFor="CT"
                            name="trailer_owner"
                            label="Custom Trailer "
                          />
                        </Form.Group>
                      </Col>

                      <span
                        className={
                          errors['trailer_owner']
                            ? 'errorshow mrle'
                            : 'errorshide mrle'
                        }
                      >
                        {errors['trailer_owner']}
                      </span>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Row>
                          <Form.Label column sm="3" className="text-right">
                            Trailers/ Container*
                          </Form.Label>
                          <Col sm="4">
                            <Form inline>
                              <InputGroup>
                                <InputGroup.Prepend>
                                  {/* <InputGroup.Text>Trailer 1</InputGroup.Text> */}
                                </InputGroup.Prepend>
                                {tripValue.trailer_owner !== 'CT' ? (
                                  <SelectSearch
                                    id="trailer"
                                    container="rowselect"
                                    search
                                    options={TrailerDropdonw}
                                    select="test"
                                    name="trailer1"
                                    autoComplete="sdf"
                                    onChange={(val) =>
                                      this.handleSeachableDropdonw(
                                        val,
                                        'trailer1'
                                      )
                                    }
                                    value={tripValue.trailer1}
                                    placeholder="Select..."
                                  />
                                ) : null}
                                <span
                                  className={
                                    errors['trailer1']
                                      ? 'errorshow'
                                      : 'errorshide'
                                  }
                                >
                                  {errors['trailer1']}
                                </span>
                                <div className="clr"></div>
                                {tripValue.trailer_owner === 'CT' ? (
                                  <FormControl
                                    name="cust_trailer1"
                                    value={tripValue.cust_trailer1}
                                    onChange={this.handleChangeGen}
                                    placeholder="Enter..."
                                  />
                                ) : null}
                              </InputGroup>
                            </Form>
                          </Col>
                          <Col sm="4">
                            <Form inline>
                              <InputGroup>
                                <InputGroup.Prepend>
                                  {/* <InputGroup.Text>Trailer 2</InputGroup.Text> */}
                                </InputGroup.Prepend>
                                {tripValue.trailer_owner !== 'CT' ? (
                                  <SelectSearch
                                    container="rowselect"
                                    id="trailer2"
                                    search
                                    options={TrailerDropdonw2}
                                    select="test"
                                    name="trailer2"
                                    autoComplete="sdf"
                                    onChange={(val) =>
                                      this.handleSeachableDropdonw(
                                        val,
                                        'trailer2'
                                      )
                                    }
                                    value={tripValue.trailer2}
                                    placeholder="Select..."
                                  />
                                ) : null}
                                {tripValue.trailer_owner === 'CT' ? (
                                  <FormControl
                                    name="cust_trailer2"
                                    value={tripValue.cust_trailer2}
                                    onChange={this.handleChangeGen}
                                    placeholder="Enter..."
                                  />
                                ) : null}
                                <span
                                  className={
                                    errors['trailer2']
                                      ? 'errorshow'
                                      : 'errorshide'
                                  }
                                >
                                  {errors['trailer2']}
                                </span>
                              </InputGroup>
                            </Form>
                          </Col>
                        </Row>
                        <Button disabled className="trackStatus">
                          Track
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Notes
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="notes"
                              value={tripValue.notes}
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['notes'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['notes']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {consignValue.length > 0 ? (
                  <div className="Tablegrid">
                    <div className="listtopbar">
                      <div className="stop">Stop</div>
                      <div className="type">Type</div>
                      <div className="order">Order</div>
                      <div className="location">Location(*)</div>
                      <div className="status">Status</div>
                      <div className="date">Request Date</div>
                      <div className="date">Arrival Date</div>
                      <div className="date">Departure Date</div>
                      <div className="weight">Weight</div>
                      <div className="pkgtype">Package Type</div>
                      <div className="qty">Qty</div>
                      <div className="desc">Appt(*)</div>
                      <div className="info">P/D#</div>
                      <div className="notes">Notes</div>
                    </div>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {consignValue.map((item, i) => (
                              <Draggable
                                key={i}
                                draggableId={i.toString()}
                                index={i}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    {...provided.draggableProps}
                                    //{...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    {item.status === true ? (
                                      <div className="listdata" key={item.stop}>
                                        <div
                                          className="stop"
                                          {...provided.dragHandleProps}
                                        >
                                          <img
                                            src={dragicon}
                                            className="btndrag"
                                            alt="Darg"
                                          />
                                          {item.stop}
                                        </div>
                                        <div className="type">
                                          {item.type}
                                          <div className="locInfo">
                                            i
                                            <div className="fulladdress">
                                              {item.type === 'C'
                                                ? 'Consignee'
                                                : item.type === 'S'
                                                  ? 'Shipper'
                                                  : 'Yard'}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="order"
                                          onClick={
                                            item.order !== ''
                                              ? () =>
                                                this.toggleItemModal(
                                                  item.order
                                                )
                                              : ''
                                          }
                                        >
                                          {item.order === ''
                                            ? 'MANUAL'
                                            : item.order}
                                        </div>
                                        <div className="location">
                                          {item.location !== '' ? (
                                            <div className="locInfo">
                                              i
                                              <div className="fulladdress">
                                                <p>
                                                  <strong>C/S Name :</strong>{' '}
                                                  {item.loc_nm}
                                                </p>
                                                <p>
                                                  <strong>Address :</strong>{' '}
                                                  {item.location}
                                                </p>
                                                <p>
                                                  <strong>Postal Code :</strong>{' '}
                                                  {item.postal_code}
                                                </p>
                                              </div>
                                            </div>
                                          ) : null}
                                          <Form.Control
                                            placeholder="search location"
                                            //disabled={item.order!="" && item.order!=null ? true:false}
                                            name="location"
                                            value={item.location}
                                            type="text"
                                            onClick={() =>
                                              this.toggleLocationModal(i)
                                            }
                                          // onChange={(e) =>
                                          //   this.handleChange(e, i)
                                          // }
                                          />

                                          <Form.Control
                                            hidden
                                            //disabled={ item.order !== '' && item.order != null ? true: false}
                                            name="postal_code"
                                            value={item.postal_code}
                                          />
                                        </div>

                                        <div className="status">
                                          <Form.Control
                                            as="select"
                                            name="item_status"
                                            value={item.item_status}
                                            onChange={(e) =>
                                              this.handleChange(e, i)
                                            }
                                          >
                                            <option disabled>select..</option>
                                            {StatusDropdonw.map((item) => (
                                              <option
                                                key={item.value}
                                                value={item.value}
                                              >
                                                {item.name}
                                              </option>
                                            ))}
                                          </Form.Control>
                                        </div>
                                        <div className="date">
                                          <DatePicker
                                            selected={item.req_date}
                                            //disabled={ item.order !== '' && item.order != null ? true: false}
                                            dateFormat="dd-MM-yyyy hh:mm:ss"
                                            className="form-control inpcalendars"
                                            popperPlacement="start"
                                            showTimeSelect
                                            locale="en-GB"
                                            name="req_date"
                                            autoComplete="off"
                                            value={item.req_date}
                                            onChange={(date) =>
                                              this.handleDateConsig(
                                                date,
                                                'req_date',
                                                i
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="date">
                                          <DatePicker
                                            selected={item.arv_date}
                                            dateFormat="dd-MM-yyyy hh:mm:ss"
                                            className="form-control inpcalendars"
                                            popperPlacement="start"
                                            showTimeSelect
                                            locale="en-GB"
                                            name="arv_date"
                                            autoComplete="off"
                                            value={item.arv_date}
                                            onChange={(date) =>
                                              this.handleDateConsig(
                                                date,
                                                'arv_date',
                                                i
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="date">
                                          <DatePicker
                                            selected={item.dep_date}
                                            dateFormat="dd-MM-yyyy hh:mm:ss"
                                            className="form-control inpcalendars"
                                            popperPlacement="start"
                                            showTimeSelect
                                            locale="en-GB"
                                            name="dep_date"
                                            autoComplete="off"
                                            value={item.dep_date}
                                            onChange={(date) =>
                                              this.handleDateConsig(
                                                date,
                                                'dep_date',
                                                i
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="weight">
                                          <Form.Control
                                            //disabled={item.order !== '' && item.order != null ? true : false}
                                            name="weight"
                                            value={item.weight}
                                            type="number"
                                            onChange={(e) =>
                                              this.handleChange(e, i)
                                            }
                                          />
                                        </div>
                                        <div className="pkgtype">
                                          <Form.Control
                                            as="select"
                                            //disabled={orderValue.trip != null}
                                            name="pkg_type"
                                            value={item.pkg_type}
                                            onChange={(e) =>
                                              this.handleChange(e, i)
                                            }
                                          >
                                            <option disabled>select..</option>
                                            {PackgDropdonw.map((item) => (
                                              <option
                                                key={item.value}
                                                value={item.value}
                                              >
                                                {item.name}
                                              </option>
                                            ))}
                                          </Form.Control>
                                        </div>
                                        <div className="qty">
                                          <Form.Control
                                            //disabled={orderValue.trip != null}
                                            name="qty"
                                            value={item.qty}
                                            type="number"
                                            onChange={(e) =>
                                              this.handleChange(e, i)
                                            }
                                          />
                                        </div>
                                        <div className="desc">
                                          <Form.Control
                                            as="select"
                                            //disabled={orderValue.trip != null}
                                            name="apnt_type"
                                            value={item.apnt_type}
                                            onChange={(e) =>
                                              this.handleChange(e, i)
                                            }
                                          >
                                            <option disabled>select..</option>

                                            {APPNT_TYPEDropdown.map((item) => (
                                              <option
                                                key={item.value}
                                                value={item.value}
                                              >
                                                {item.name}
                                              </option>
                                            ))}
                                          </Form.Control>
                                        </div>
                                        <div className="info">
                                          <Form.Control
                                            name="info"
                                            value={item.info}
                                            type="text"
                                            onChange={(e) =>
                                              this.handleChange(e, i)
                                            }
                                          />
                                        </div>
                                        <div className="notes trp">
                                          {item.note !== '' &&
                                            item.note != null ? (
                                            <div className="locInfo">
                                              i
                                              <div className="fulladdress">
                                                <p>{item.note}</p>
                                              </div>
                                            </div>
                                          ) : null}
                                          <img
                                            src={IMG_NOTE_ICON}
                                            alt="note icon"
                                            onClick={() =>
                                              this.toggleOrderItemNoteModal(i)
                                            }
                                          />

                                          <img
                                            className="delete"
                                            onClick={() =>
                                              this.removeOrderItem(i, item.id)
                                            }
                                            src={deletebtnred}
                                            alt="Delete"
                                          />
                                          <div className="moremenu moremenus">
                                            <img
                                              src={default_menu}
                                              alt="More"
                                            />
                                            <ul>
                                              <li
                                                onClick={() =>
                                                  this.toggleMoreModal(i)
                                                }
                                              >
                                                More Info
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                ) : null}
                <Row className="bdrb">
                  <div className="btnpandata">
                    <div className="btndata">
                      <Button
                        onClick={this.handleOrderModal}
                        className="primary-button"
                      >
                        + Order
                      </Button>
                      <Button
                        onClick={() => this.handleAddClick('Y')}
                        className="primary-button"
                      >
                        + Y
                      </Button>
                      <Button
                        onClick={() => this.handleAddClick('C')}
                        className="primary-button"
                      >
                        + C
                      </Button>
                      <Button
                        onClick={() => this.handleAddClick('S')}
                        className="sec-button"
                      >
                        + S
                      </Button>

                      <Button
                        onClick={this.toggleDirectionModal}
                        className="sec-button"
                      >
                        Get Map
                      </Button>
                    </div>
                    <div className="btndata2">
                      <div className="custom">
                        <Form.Control
                          as="select"
                          name="miletcaltype"
                          value={tripValue.miletcaltype}
                          onChange={(e) => this.handleChangeGen(e)}
                        >
                          <option value="Auto">Auto</option>
                          <option value="Manual">Manual</option>
                        </Form.Control>
                      </div>

                      {tripValue.miletcaltype === 'Auto' ? (
                        <div className="custom">
                          <Button
                            onClick={() => this.handleCallMiles()}
                            className="sec-button"
                          >
                            Calc Miles
                          </Button>
                        </div>
                      ) : null}

                      <Form.Group className="grpmile tripmile">
                        <Form.Label column sm="3" className="text-right">
                          Total Miles
                        </Form.Label>
                        <Form.Control
                          disabled={tripValue.miletcaltype === 'Auto'}
                          value={milestotal}
                          onChange={this.handleChangeGenMile}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="clr"></div>
                </Row>
                <Row>
                  <Col md={12} className="text-right">
                    <Link to="/fleet/trips">
                      <Button className="secondary-button">Cancel</Button>
                    </Link>
                    <Button
                      className="secondary-button"
                      onClick={this.handleReset}
                    >
                      Reset
                    </Button>

                    {(this.props.match.params.id &&
                      access &&
                      access.tripMenu &&
                      access.tripMenu.update === true) ||
                      (!this.props.match.params.id &&
                        access &&
                        access.tripMenu &&
                        access.tripMenu.create === true) ? (
                      <Button
                        onClick={
                          isTripEditable === false ? this.handleSave : ''
                        }
                        className="primary-button"
                      >
                        {isTripEditable === false
                          ? 'Save'
                          : 'You can not update trip'}
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </div>
        <Modal
          backdrop="static"
          keyboard={false}
          id="searchRecord"
          show={orderModal}
          onHide={this.handleOrderModal}
        >
          <Modal.Header closeButton>
            <Row>
              <Col md="3">
                <Autocomplete
                  name="customerId"
                  placeholder="Search Customer..."
                  value={orderSearch.customerId}
                  onChange={this.handleChangeGenOrderSearch}
                  ref={this.AutoRef}
                  style={{ display: bool ? 'block' : 'none' }}
                  bool={bool}
                  getAutosuggest={this.getAutosuggest}
                  onClick={(id, name) => this.setAutosuggestId(id, name)}
                />
              </Col>
              <Col md="3" className="padding0">
                <Row>
                  <Col sm="5" className="padding0">
                    <Form.Group>
                      <Form.Label className="text-right ltb">
                        Load Type*
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col sm="3" className="padding0">
                    <Form.Group as={Row} className="centercheck">
                      <Form.Check
                        type="radio"
                        onChange={this.handleChangeGenOrderSearch}
                        checked={
                          orderSearch.load === 'FL' || orderSearch.load === true
                        }
                        value="FL"
                        name="load"
                        label="FL"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="4" className="padding0">
                    <Form.Group as={Row} className="centercheck">
                      <Form.Check
                        type="radio"
                        onChange={this.handleChangeGenOrderSearch}
                        value="LTL"
                        checked={
                          orderSearch.load === 'LTL' ||
                          orderSearch.load === true
                        }
                        name="load"
                        label="LTL"
                      />
                    </Form.Group>
                  </Col>
                  <span className={errors['load'] ? 'errorshow' : 'errorshide'}>
                    {errors['load']}
                  </span>
                </Row>
              </Col>

              <Col md="6">
                <Form.Group as={Row}>
                  <Form.Label column sm="1" className="text-left bold">
                    From
                  </Form.Label>
                  <Col sm="5">
                    <DatePicker
                      selected={orderSearch.date1}
                      dateFormat="yyyy-MM-dd"
                      className="form-control inpcalendar"
                      popperPlacement="start"
                      name="orderSearch"
                      autoComplete="off"
                      value={orderSearch.date1}
                      onChange={(date) =>
                        this.handleDateOrderSearch(date, 'date1')
                      }
                    />
                  </Col>

                  <Form.Label
                    column
                    sm="1"
                    className="text-left bold paddingright0"
                  >
                    To
                  </Form.Label>
                  <Col sm="5" className="paddingleft0">
                    <DatePicker
                      selected={orderSearch.date2}
                      dateFormat="yyyy-MM-dd"
                      className="form-control inpcalendar"
                      popperPlacement="start"
                      name="date2"
                      autoComplete="off"
                      value={orderSearch.date2}
                      onChange={(date) =>
                        this.handleDateOrderSearch(date, 'date2')
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col md="12" className="text-right">
                <Button
                  type="button"
                  variant="primary"
                  onClick={this.getOrderSearch}
                >
                  Search
                </Button>
              </Col>
            </Row>
            {OrderList.length > 0 ? (
              <React.Fragment>
                <Row>
                  <Col md="12">
                    <Table className="cursor" striped bordered hover>
                      <thead>
                        <tr>
                          <th>
                            <Form.Check
                              type="checkbox"
                              onChange={this.handleChangeGenCheckeds}
                              value="all"
                              name="all"
                              checked={allChecked}
                            />
                          </th>
                          <th>Order No</th>
                          <th>Customer</th>
                          <th>Location</th>
                          <th>Status</th>
                          <th>Request Data</th>
                        </tr>
                      </thead>
                      <tbody>
                        {OrderList.map((item, index) => (
                          <tr
                            className={orderId === item.id && 'isActive'}
                            key={item.id}
                          >
                            <td>
                              <Form.Check
                                type="checkbox"
                                onChange={this.handleChangeGenCheckeds}
                                value={item.id}
                                name={item.id}
                                checked={item.isChecked}
                              />
                            </td>
                            <td>{item.order_no}</td>
                            <td>{item.billed_to.name}</td>

                            <td>
                              {item.items[0] != null
                                ? item.items[0].location
                                : 'N/A'}
                            </td>
                            <td>
                              {item.order_status != null
                                ? item.order_status.name
                                : 'N/A'}
                            </td>
                            <td>
                              {item.items[0] != null
                                ? item.items[0].req_date
                                : 'N/A'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <div className="noresfound">No Result found for this search</div>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleOrderModal}>
              Close
            </Button>
            {OrderList.length > 0 ? (
              <Button variant="primary" onClick={this.getOrderById}>
                Save
              </Button>
            ) : null}
          </Modal.Footer>
        </Modal>

        <Modal
          id="moreinfo"
          onHide={() => this.toggleMoreModal(false)}
          show={showMoreModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>More Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="itemSignature">
                    IS Item Signature Required :{' '}
                    <span>{itemSignature === true ? 'Yes' : 'No'}</span>
                    <div className="itemVerified">
                      IS Item Verified{' '}
                      <img
                        src={itemVerified === true ? correctImg : wrongImg}
                        alt="item Verified"
                      />
                    </div>
                  </div>
                  <p>{driverNotes}</p>

                  <div className="itemimage">
                    <ul>
                      {files &&
                        files.length > 0 &&
                        files.map((item) => (
                          <li key={item.id}>
                            {item.name}
                            <span
                              className="downicon"
                              onClick={() =>
                                this.getImageDownload(item.url, item.name)
                              }
                            >
                              Download
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="secondary-button"
              onClick={() => this.toggleMoreModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          onHide={() => this.toggleOrderItemNoteModal(false)}
          show={showOrderItemModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <textarea
                    className="default-input"
                    name="note"
                    value={noteValue}
                    onChange={this.onChangeOrderItem(orderItemModal)}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="secondary-button"
              onClick={() => this.toggleOrderItemNoteModal(false)}
            >
              Cancel
            </Button>
            <Button onClick={() => this.toggleOrderItemNoteModal(false)}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Location  Modal */}
        <Modal
          onHide={() => this.toggleLocationModal(false)}
          show={showLocationModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-lg-12 addaddress">
              <div className="row">
                <div className="col-lg-12">
                  <div className="addblock">
                    <p>C/S Name*</p>
                    {<LoctionName type="trp" index={locationModal} value={loc_nmValue} onChangeOrderItem={this.onChangeOrderItem} selectedLocation={this.state.selectedLocation} />}
                    <p>Address</p>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="location"
                      autoComplete="sfsdfsdfsf"
                      value={addressValue}
                      onChange={this.onChangeOrderItem(locationModal)}
                    />
                    {currentBoxAddress === true ? (
                      <div className="addressdata">
                        <ul>
                          {Place.map((item) => (
                            <li
                              onClick={() =>
                                this.setAddress(
                                  item.formatted_address,
                                  item.place_id
                                )
                              }
                              key={item.id}
                            >
                              {item.formatted_address}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                  <p>City</p>
                  <Form.Control
                    name="city"
                    value={cityValue}
                    onChange={this.onChangeOrderItem(locationModal)}
                  />
                  <p>Province</p>
                  <Form.Control
                    name="province"
                    value={provenceValue}
                    onChange={this.onChangeOrderItem(locationModal)}
                  />
                  <p>Postal Code</p>
                  <Form.Control
                    name="postal_code"
                    value={postalValue}
                    onChange={this.onChangeOrderItem(locationModal)}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="secondary-button"
              onClick={() => this.toggleLocation(false)}
            >
              Cancel
            </Button>
            <Button onClick={() => this.toggleLocationModal(false, 'save')}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Map Direction Modal */}
        <Modal
          onHide={this.toggleDirectionModal}
          show={showDirectionModal}
          id="directionMap"
        >
          <Modal.Header closeButton>
            <Modal.Title>Direction</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-lg-12 addaddress">
              <MapLoader
                DirectionCenters={DirectionCenter}
                DirectionData={DirectionData}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}`}
                loadingElement={<div style={{ height: `100%` }} />}
              />
            </div>
          </Modal.Body>
        </Modal>
        {PromptPrevent ? (
          <Prompt
            message={() =>
              'Moving away from this page will remove any unsaved data. Are you sure?'
            }
          />
        ) : null}
        <Popup
          isOpen={showDeleteModal}
          onConfirm={this.handleRemoveClick}
          textMessage={textMessage}
          toggleShow={this.toggleDeleteModal}
        />
        <PopupLocConfirm
          isOpen={showConfirmation}
          onConfirm={this.handleConfirmationClick}
          textMessage={confirmationMessage}
          toggleShow={this.toggleConfirmationModal}
        />
        <PopupLocConfirm
          isOpen={loadTypeBool}
          onConfirm={this.handLoadConfirm}
          textMessage={typeMessage}
          toggleShow={this.handLoadModal}
        />
        <PopupLocConfirm
          isOpen={CSModal}
          onConfirm={this.getNotifiyCSConfirm}
          textMessage={CSMessage}
          toggleShow={this.handleModalNotifiyCSIsEqual}
        />

        <TripItemModal
          isOpens={showItemModal}
          data={Data}
          togglesShow={() => this.toggleItemModal('')}
        />
        {isSendModal === true ? (
          <SendMessage
            driverphone={driverphone}
            isOpen={isSendModal}
            toggleShow={this.handleMessageModal}
          />
        ) : null}

        {/* Map DeviceTracking Modal */}
        {trackingData && trackingData.length > 0 && (
          <Modal
            onHide={this.toggleTrackingModal}
            show={showTrackingModal}
            id="directionMap"
          >
            <Modal.Header closeButton>
              <Modal.Title>Device Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-lg-12 addaddress">
                <DeviceTrackerMap
                  data={trackingData}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `500px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  center={{
                    lat: trackingData[0].lat,
                    lng: trackingData[0].lng,
                  }}
                  zoom={12}
                />
              </div>
            </Modal.Body>
          </Modal>
        )}
        <MessageModal
          isOpen={formOption.errorBolls}
          id="modalID"
          toggleShow={() => this.handleMessageDataClose('errorBolls')}
          onConfirm={() => this.handleMessageDataClose('errorBolls')}
          textMessage={formOption.Message}
          headerTitle="Error Message"
          actionLabel="OK"
          cancelLable="Cancel"
        />
      </div>
    );
  }
}

export default CreateTrips;
