import {
  FETCH_CUSTOMERS_LIST_START,
  FETCH_CUSTOMERS_LIST_SUCCESS, 
  FETCH_CUSTOMERS_LIST_ERROR, 
} from '../../../constants/action-types/customers';
import { customerService } from '../../../Services';

export const customersFetchStart = (payload) => ({
  type: FETCH_CUSTOMERS_LIST_START,
  payload,
});

export const customersFetchSuccess = (payload) => ({
  type: FETCH_CUSTOMERS_LIST_SUCCESS,
  payload,
});

export const customersFetchError = (payload) => ({
  type: FETCH_CUSTOMERS_LIST_ERROR,
  payload,
});

export const fetchCustomers = (payload) => {
  return (dispatch) => {
    dispatch(customersFetchStart(payload));
    
    customerService.fetchCustomers(payload).then((customersData) => {
      if (!customersData.isError) {
        dispatch(customersFetchSuccess(customersData));
      } else {
        dispatch(customersFetchError(customersData));
      }
    });
  };
};
