import Cookies from 'universal-cookie';
import { getApiAsyn } from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { getDecoratedTypesUrl } from '../../common/urlService';
import { API_URL } from '../../../constants/urlConstants';

const cookies = new Cookies();

const getFetchTypesUrl = (payload) => {
  return getDecoratedTypesUrl({ url: API_URL.TYPES.FETCH_TYPE, payload });
};

export const fetchTypes = async (payload) => {
  const url = getFetchTypesUrl(payload);
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }

    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

