import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';

import EzTable from '../../common/EzTable';
import { EzToast, TOAST_SEVERITY } from '../../common/EzToast';
import EzNotFound from '../../common/EzNotFound';

// TODO (Subham): Find why assets path is not working
//const getIconPath = () => process.env.PUBLIC_URL + '/assets/images/acidents/no-record.png';
const getIconPath = (imgPathDelta = '/no-record.png') =>
  process.env.PUBLIC_URL + imgPathDelta;

const Accident = (props) => {
  const {
    accidentsListView,
    createAccidentRecord,
    deleteAccidentRecord,
    editAccidentRecord,
    fetchAccidents,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isAccidentDeletionError,
    isAccidentDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    downloadAllAttachmentByAccidentId,
    fetchAccidentAttachmentsByAccidentId,
    moreActionProps
  } = props;

  const [areFiltersVisible, toggleFilterState] = useState(false);

  const getToastProps = () => {
    if (isAccidentDeletionSuccess) {
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle: 'Accident Deleted Successfully',
        shouldShowToast: true,
      };
    }

    if (isAccidentDeletionError) {
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle: 'Error while Deleting Accident',
        shouldShowToast: true,
      };
    }
  };

  const renderPageHeaderSection = () => {
    const breadCrumbs = [{ label: 'Accidents' }, { label: 'Accident Summary' }];

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };

    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />

        <div className="rightbtn">
          <Button
            label={areFiltersVisible ? 'Hide Filters' : 'Show Filters'}
            icon={areFiltersVisible ? 'pi pi-filter-slash' : 'pi pi-filter'}
            className={`mr-2 p-button-raised p-button-outlined p-button-secondary`}
            onClick={() => {
              toggleFilterState(!areFiltersVisible);
            }}
          />
          <Button
            label={'Create New Accident'}
            icon={'pi pi-plus'}
            className={`mr-2 p-button-raised`}
            onClick={createAccidentRecord}
          />
        </div>
      </div>
    );
  };

  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderAccidentRecords = () =>
    accidentsListView?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderAccidentRecords() && !shouldRenderFullPageError();

  const filters = {
    global: { value: null },
    date: { value: null },
    'driver1.name': {
      value: null,
      filterKey: 'driver1.first',
    },
    truck: { value: null, filterKey: 'truck.asset_code' },
    trailer1: { value: null, filterKey: 'trailer1.asset_code' },
    location: { value: null },
  };

  const loadLazyData = params => {
    fetchAccidents(params)
  }

  const tableProps = {
    areFiltersVisible,
    columns: accidentsListView.columns,
    paginationInfo,
    screenPermissions,
    setDeleteConfirmationPopupVisibility,
    shouldOpenDeleteConfirmationPopup,
    onEditRecord: editAccidentRecord,
    loadLazyData,
    onDeleteRecord: deleteAccidentRecord,
    value: accidentsListView.rows,
    emptyMessage: 'No Accidents found.',
    globalFilterFields: ['truck', 'desc'],
    filters,
    loading: isFetchingGridData,
    fetchAttachmentsById : fetchAccidentAttachmentsByAccidentId,
    downloadAllAttachments : downloadAllAttachmentByAccidentId,
    moreActionProps
  };
  
  
  return (
    <>
      <EzToast {...getToastProps()} />
      <div className="paddingbox">
        <div className="containerbox">
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: '-70px' }}
                bgImgUrl={getIconPath('something-broke.png')}
                message={'Oops! Something broke, we are working on a fix.'}
              />
            )}
            {shouldRenderAccidentRecords() && (
              <Row>
                <Col md={12}>
                  <EzTable {...tableProps} />
                </Col>
              </Row>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={'No accident records to show'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Accident;
