import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';

import Compliance from '../../components/Compliance';
import { loadingsShow } from '../../Services/PostAPI';
import {
  initializeComplianceScreen,
  fetchCompliances,
  deleteComplianceRecord,
  setDeleteConfirmationPopupVisibility,
  downloadAllAttachmentByComplianceId,
  fetchFilesByComplianceId,
} from '../../Redux/Actions/compliance';
import { APP_URLS } from '../../constants/urlConstants';
import { COMPLIANCE_DATE_DATA_KEY } from '../../Redux/Reducers/compliance';
import { fetchEmployees } from '../../Redux/Actions/employees';
import { fetchAssets } from '../../Redux/Actions/assets';
import { fetchTypes } from '../../Redux/Actions/types';

const ComplianceScreen = (props) => {
  const {
    fetchEmployees,
    fetchAssets,
    fetchTypes,
    employees,
    assets,
    types,
    compliancesListView,
    fetchCompliances,
    history,
    initComplianceScreen,
    deleteComplianceRecord,
    isFetchingGridData,
    paginationInfo,
    showPageLevelLoader,
    screenPermissions,
    isComplianceDeletionError,
    isComplianceDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    downloadAllAttachmentByComplianceId,
    fetchComplianceAttachmentsByComplianceId,
  } = props;


  useEffect(() => {
    initComplianceScreen();
    fetchEmployees({ shouldPaginate: false });
    fetchAssets({ shouldPaginate: false });
    fetchTypes({ params: 'CMPLNC_BY&param1=CMPLNC_CMP' });

    fetchCompliances({ sortField: COMPLIANCE_DATE_DATA_KEY });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editComplianceRecord = (complianceId) => {
    const path = generatePath(APP_URLS.COMPLIANCE.EDIT_COMPLIANCE, { complianceId });
    history.push(path);
  };

  const createCompliancesRecord = () => {
    history.push(APP_URLS.COMPLIANCE.ADD_COMPLIANCE);
  };

  const moreActionProps = {
    isMoreActionDisabled: true,
    downloadEnabled: false,
    moreActionOptions: [],
    onMoreActionChange: false
  }
  const handleComplianceList = (complianceData, empData, assestData, compData) => {
    console.log('data', complianceData)
    return complianceData
  }
  const complianceProps = {
    compliancesListView: handleComplianceList(compliancesListView, employees, assets, types),
    createCompliancesRecord,
    fetchCompliances,
    deleteComplianceRecord,
    editComplianceRecord,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isComplianceDeletionError,
    isComplianceDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    downloadAllAttachmentByComplianceId,
    fetchComplianceAttachmentsByComplianceId,
    moreActionProps
  };




  if (showPageLevelLoader) {
    loadingsShow('block');
    return null;
  }
  loadingsShow('none');
  return (
    <>
      <Compliance {...complianceProps} />;
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initComplianceScreen: () => dispatch(initializeComplianceScreen()),
    fetchEmployees: (payload) => dispatch(fetchEmployees(payload)),
    fetchAssets: (payload) => dispatch(fetchAssets(payload)),
    fetchTypes: (payload) => dispatch(fetchTypes(payload)),
    fetchCompliances: (payload) => dispatch(fetchCompliances(payload)),
    deleteComplianceRecord: (payload) => dispatch(deleteComplianceRecord(payload)),
    setDeleteConfirmationPopupVisibility: (payload) => dispatch(setDeleteConfirmationPopupVisibility(payload)),
    downloadAllAttachmentByComplianceId: (payload) => dispatch(downloadAllAttachmentByComplianceId(payload)),
    fetchComplianceAttachmentsByComplianceId: payload => dispatch(fetchFilesByComplianceId(payload))
  };
};

// TODO(Subham):  This selection of data needs improvement, See if we can use UseSelector here.
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    employees: selectFromEmployeeReducer(state, 'employees'),
    assets: selectFromAssetsReducer(state, 'assets'),
    types: selectFromTypesReducer(state, 'types'),
    compliancesListView: selectCompliancesList(state.compliancesReducer),
    isFetchingGridData: selectIsLoading(state.compliancesReducer),
    showPageLevelLoader: selectIsLoadingPage(state.compliancesReducer),
    paginationInfo: selectPaginationInfo(state.compliancesReducer),
    isPageLevelError: selectIsPageLevelError(state.compliancesReducer),
    isComplianceDeletionError: selectIsComplianceDeletionError(
      state.compliancesReducer
    ),
    isComplianceDeletionSuccess: selectIsComplianceDeletionSuccess(
      state.compliancesReducer
    ),
    shouldOpenDeleteConfirmationPopup: selectShouldOpenDeleteConfirmationPopup(
      state.compliancesReducer
    ),
  };
};

const selectFromAssetsReducer = (state, path) => {
  return selectAssetsReducer(state)[path];
};
const selectAssetsReducer = ({ assetsReducer }) => {
  return assetsReducer;
};
const selectFromEmployeeReducer = (state, path) => {
  return selectEmployeeReducer(state)[path];
};
const selectEmployeeReducer = ({ employeeReducer }) => {
  return employeeReducer;
};
const selectFromTypesReducer = (state, path) => {
  return selectTypesReducer(state)[path];
};
const selectTypesReducer = ({ typesReducer }) => {
  return typesReducer;
};
const selectIsPageLevelError = (complianceReducer) =>
  complianceReducer.isPageLevelError;
const selectIsComplianceDeletionError = (complianceReducer) =>
  complianceReducer.isComplianceDeletionError;
const selectIsComplianceDeletionSuccess = (complianceReducer) =>
  complianceReducer.isComplianceDeletionSuccess;
const selectPaginationInfo = (complianceReducer) =>
  complianceReducer.paginationInfo;
const selectIsLoading = (compliancesReducer) => compliancesReducer.isLoading;
const selectIsLoadingPage = (compliancesReducer) =>
  compliancesReducer.isLoadingPage;
const selectCompliancesList = (compliancesReducer) =>
  compliancesReducer.compliancesListView;
const selectShouldOpenDeleteConfirmationPopup = (compliancesReducer) =>
  compliancesReducer.shouldOpenDeleteConfirmationPopup;

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceScreen);
