import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FileUpload } from 'primereact/fileupload';

import PDF_ICON from '../../images/pdf.png';
const MAX_FILE_SIZE = 10000000;

const EzFileUpload = (props) => {
  const { onSelectFile, onRemoveFile } = props;
  const [files, setFiles] = useState([]);

  const areFilesFetched = useRef(false);
  useEffect(() => {
    if (!areFilesFetched.current && props?.files?.length) {
      areFilesFetched.current = true;
      setFiles(props.files);
    }
  }, [props.files]);

  const headerTemplate = (options) => {
    const { className, chooseButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="make-flex align-items-center flex-column">
        <i
          className="pi pi-upload mt-3 p-3"
          style={{
            fontSize: '2em',
            borderRadius: '50%',
            color: 'var(--primary-color)',
          }}
        />
        <span
          style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
        >
          {'Drag & drop attachments here'}
        </span>
      </div>
    );
  };

  const onSelect = (selectedFile) => {
    setFiles([...files, ...selectedFile.files]);
    if (onSelectFile) {
      onSelectFile(selectedFile);
    }
  };

  const onRemove = ({ file: fileTobeRemoved }) => {
    const filteredFiles = files.filter(
      (file) =>  {
        if (fileTobeRemoved.id) {
          return  file.id ?  file.id !== fileTobeRemoved.id : true 
        }
        else if (fileTobeRemoved.objectURL){
          return file.objectURL ? file.objectURL !== fileTobeRemoved.objectURL : true
        }
        else if(fileTobeRemoved.lastModified) {
          return file.lastModified ? file.lastModified !== fileTobeRemoved.lastModified: true
        }
        
      } 
    );
  
    setFiles(filteredFiles);

    if (onRemoveFile) {
      onRemoveFile({ ...fileTobeRemoved, status: 'N' }, files);
    }
  };

  const isPdf = (item) => {
    return item?.type === 'application/pdf' || item?.name?.endsWith('.pdf');
  };

  const renderAttachedArtifacts = (files = []) => {
    return (
      <div className="filetoupload">
        <ul>
          {files.map((item, i) => (
            // keys depends on item 
            // item.id - means attachment if from database in this case there is no dependance on type of file
            // item.objectURL - file is newly added and is of type of image
            // item.lastModified - file is newly added and is of type pdf
            <li key={item.id || item.objectURL || item.lastModified}  >
              <span className="imgName">{item.name}</span>
              <span className="imgHolder">
                {isPdf(item) ? (
                  <img src={PDF_ICON} alt="pdf" />
                ) : (
                  <img src={item.s3Url || item.objectURL} alt="img" />
                )}
              </span>
              <span className="remove" onClick={() => onRemove({ file: item })}>
                {' '}
                x{' '}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <FileUpload
        name="fileupload"
        multiple
        accept="image/*,.pdf,.txt"
        chooseLabel={'Attach Files'}
        emptyTemplate={emptyTemplate}
        headerTemplate={headerTemplate}
        maxFileSize={MAX_FILE_SIZE}
        onRemove={onRemove}
        onSelect={onSelect}
      />
      <div>{files && renderAttachedArtifacts(files)}</div>
    </div>
  );
};

EzFileUpload.propTypes = {
  onSelectFile: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

export default EzFileUpload;
