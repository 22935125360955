import Cookies from 'universal-cookie';

import { getApiAsyn } from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { API_URL } from '../../../constants/urlConstants';
import { extractData } from '../../utils';
import { getDecoratedUrl } from '../../common/urlService';

const cookies = new Cookies();

export const fetchTrackingLogs = async (payload) => {
  const url = getDecoratedUrl({
    url: API_URL.TRACKING_LOGS.FETCH_TRACKING_LOGS,
    payload,
  });
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }

    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
