import React from 'react';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  InfoWindow,
} from 'react-google-maps';
import Logo from '../../images/marker.png';

class MapWithMarkers extends React.Component {
  state = {
    places: this.props.data,
    isMarker: false,
  };

  handleMarkerTootip = (item) => {
    this.setState({
      isMarker: true,
    });
  };

  render() {
    return (

      <GoogleMap
        defaultZoom={this.props.zoom}
        defaultCenter={this.props.center}
      >


        {this.state.places.map((place) => {
          return (
            <Marker
              icon={Logo}
              onClick={() => this.handleMarkerTootip(place)}
              key={place.id}
              position={{ lat: place.lat, lng: place.lng }}
            >
              {place.key === this.props.activeMarker ? (
                <InfoWindow {...place.infoWindow}>
                  <span>
                    <h2 className="markerTitle">{place.name}</h2>
                    <b>{place.address}</b>
                  </span>
                </InfoWindow>
              ) : null}
            </Marker>
          );
        })}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(MapWithMarkers));
