/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row, Container } from 'react-bootstrap';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Divider } from 'primereact/divider';
import { Chip } from 'primereact/chip';
import { Tooltip } from 'primereact/tooltip';

import EzControlledAutoSuggestWithFloatingLabel from '../../../common/EzForm/EzControlledAutoSuggest/WithFloatingLabel';
import EzControlledCheckBoxWithLabel from '../../../common/EzForm/EzControlledCheckBox/WithLabel';
import EzControlledInputWithFloatingLabel from '../../../common/EzForm/EzControlledInput/WithFloatingLabel';
import EzControlledCalendarWithFloatingLabel from '../../../common/EzForm/EzControlledCalendar/WithFloatingLabel';
import EzFileUpload from '../../../common/EzFileUpload';
import Province from '../../../containers/common/Province';
import { buildName } from '../../../utils/string';
import './index.css';
import { EzToast, TOAST_SEVERITY } from '../../../common/EzToast';
import { getFormBaseValidationRule } from '../../../utils/form/form-utils';

const CreateInspection = (props) => {
  const {
    navigateToInspectionSummaryScreen,
    employees,
    trucks,
    trailers,
    searchEmployee,
    searchAssets,
    createInspection,
    editInspection,
    inspectionId,
    isLoading,
    isEdit,
    formFieldValueMap,
    isInspectionDetailSuccess,
    isCreateInspectionSuccess,
    isEditInspectionSuccess,
    shouldBlockNavigation
  } = props;

  const [attachedFiles, setAttachedFiles] = useState([]);

  const {
    control,
    formState: { errors,isDirty },
    watch,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });

  useEffect(()=> shouldBlockNavigation(isDirty), [isDirty]);

  useEffect(() => {
    if (isCreateInspectionSuccess || isEditInspectionSuccess) {
      reset();
      // navigateToInspectionSummaryScreen()
    }
  }, [isCreateInspectionSuccess]);

  useEffect(() => {
    // if single inspection is fetched successfully
    if (isInspectionDetailSuccess) {
      reset({
        ...formFieldValueMap,
        province: formFieldValueMap?.province?.key,
        driver1: buildName(formFieldValueMap?.driver1),
        driver2: buildName(formFieldValueMap?.driver2),
        truck: formFieldValueMap?.truck?.asset_code,
        trailer1: formFieldValueMap?.trailer1?.asset_code,
        trailer2: formFieldValueMap?.trailer2?.asset_code,
      });

      setAttachedFiles(formFieldValueMap?.files);
    }
  }, [isInspectionDetailSuccess]);

  const truck = watch('truck');
  const trailer1 = watch('trailer1');
  const trailer2 = watch('trailer2');
  const problem = watch('problem');
  let removedFiles = [];

  // truck, trailers & employees are assets to the company
  const getAsset = (assets, assetTypeKey, assetData, assetDataKey = 'label') => {
    if (!assetData?.extraData && (typeof assetData === 'string') && assets?.length) {
      const asset = assets.find(t => t[assetDataKey] === assetData)
      return asset?.extraData
    } else if (assetData?.extraData) {
      return assetData.extraData
    }
    
    if (formFieldValueMap?.[assetTypeKey]) {
      return formFieldValueMap[assetTypeKey]
    }
  }

  const onSubmit = (data) => {
    // TODO (subham) Jhol ??
    if (!isEdit) {
      delete data.files;
    }

    delete data.files;
    // -- jhol end
    const dataPayload = {
      ...data,
      ...(data?.impact_rate ? { impact_rate: Number(data?.impact_rate) } : {}),
      files: removedFiles,
      date: new Date(data.date).getTime(),
      driver1: getAsset(employees, 'driver1', data.driver1)?.id,
      driver2: getAsset(employees, 'driver2', data.driver2)?.id,
      truck: getAsset(trucks, 'truck', data.truck)?.id,
      trailer1: getAsset(trailers, 'trailer1', data.trailer1)?.id,
      trailer2: getAsset(trailers, 'trailer2', data.trailer2)?.id,
    };

    if (isEdit) {
      editInspection({
        inspectionId,
        data: dataPayload,
        ...(attachedFiles ? { files: attachedFiles } : {}),
      });
    } else {
      createInspection({
        data: dataPayload,
        files: attachedFiles,
      });
    }
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const renderPageHeaderSection = () => {
    const breadCrumbs = [
      { label: 'Inspections' },
      { label: isEdit ? 'Edit Inspection' : 'Create Inspection' },
    ];

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };

    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />
        <div className="rightbtn">
          <Button
            tooltip={'Back to inspection summary'}
            tooltipOptions={{ position: 'left' }}
            label={''}
            icon={'pi pi-arrow-left'}
            className={`mr-3 p-button-rounded p-button-outlined p-button-info`}
            onClick={navigateToInspectionSummaryScreen}
          />
        </div>
      </div>
    );
  };

  const renderDriver1 = () => {
    const name = 'driver1';

    return (
      <EzControlledAutoSuggestWithFloatingLabel
        name={name}
        control={control}
        suggestions={employees}
        completeMethod={({ query }) => {
          searchEmployee({ query, filterField: 'first' });
        }}
        label={'Driver 1 Name'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderDriver2 = () => {
    const name = 'driver2';

    return (
      <EzControlledAutoSuggestWithFloatingLabel
        name={name}
        control={control}
        suggestions={employees}
        completeMethod={({ query }) => {
          searchEmployee({ query, filterField: 'first' });
        }}
        label={'Driver 2 Name'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderTruck = () => {
    const name = 'truck';
    return (
      <EzControlledAutoSuggestWithFloatingLabel
        name={name}
        control={control}
        suggestions={trucks}
        completeMethod={({ query }) => {
          searchAssets({ query, filterField: 'asset_code' });
        }}
        label={'Truck'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderTrailer1 = () => {
    const name = 'trailer1';

    return (
      <EzControlledAutoSuggestWithFloatingLabel
        name={name}
        value={trailer1}
        control={control}
        suggestions={trailers}
        completeMethod={({ query }) => {
          searchAssets({ query, filterField: 'asset_code' });
        }}
        label={'Trailer 1 Name'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderTrailer2 = () => {
    const name = 'trailer2';

    return (
      <EzControlledAutoSuggestWithFloatingLabel
        name={name}
        control={control}
        suggestions={trailers}
        completeMethod={({ query }) => {
          searchAssets({ query, filterField: 'asset_code' });
        }}
        label={'Trailer 2 Name'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderInspectionDate = () => {
    const name = 'date';

    return (
      <EzControlledCalendarWithFloatingLabel
        name={name}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        control={control}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label={'Inspection Date'}
      />
    );
  };

  const renderLocation = () => {
    const name = 'location';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Location'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderReportNumber = () => {
    const name = 'report_no';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Report#'}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderProblemCheckBox = () => {
    const name = 'problem';

    return (
      <EzControlledCheckBoxWithLabel
        name={name}
        control={control}
        isError={errors[name]}
        label="Problem found during inspection?"
        labelClassName={'ml-2'}
      />
    );
  };

  const renderProblemDescription = () => {
    const name = 'problem_desc';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Enter problem description'}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderProvince = () => {
    const name = 'province';

    return (
      <Province
        name={name}
        control={control}
        onChange={(e) => console.log(e)}
        isFloatingLabel={true}
        isControlled={true}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Inspection Province"
      />
    );
  };

  const renderDividerRow = (label, align = 'center', type = 'solid') => {
    return (
      <Row>
        <Divider align={align} type={type}>
          <b>{label || ''}</b>
        </Divider>
      </Row>
    );
  };

  const renderControlledInput = ({
    name,
    label,
    type = 'text',
    disabled = false,
    rules = getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE,
  }) => {
    return (
      <EzControlledInputWithFloatingLabel
        type={type}
        name={name}
        disabled={disabled}
        control={control}
        label={label}
        rules={rules}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  // eslint-disable-next-line no-unused-vars
  const renderConvictions = () => {
    return (
      <>
        <Tooltip
          target=".conviction-chip"
          className="green-tooltip"
          position="bottom"
        />

        <a
          className="conviction-chip"
          href="/#"
          rel="noreferrer"
          target="_blank"
          data-pr-tooltip="Coming soon!"
        >
          <Chip
            label="  Convictions/Tickets"
            icon="pi pi-lock"
            className="custom-chip"
          />
        </a>
      </>
    );
  };

  const renderFileUploader = () => {
    const onSelectFile = (e, field) => {
      setAttachedFiles([...e.files]);
    };

    const onRemoveFile = (fileTobeRemoved) => {
      removedFiles.push(fileTobeRemoved);
    };

    return (
      <EzFileUpload
        files={attachedFiles}
        onSelectFile={onSelectFile}
        onRemoveFile={onRemoveFile}
      />
    );
  };

  const [showErrorToast, setErrorToast] = useState(false);
  const getToastProps = () => {
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle: 'Please fill all mandatory fields',
      shouldShowToast: true,
    };
  };

  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrorToast(true);
    }
  }, [errors]);

  const doesVehicleNumberExist = (assetKey) => {
    if (formFieldValueMap) {
      const asset = formFieldValueMap[assetKey];
      return !!asset?.vehicle_no;
    }
    return false;
  };

  return (
    <div className="paddingbox">
      <div
        className={classNames('containerboxform', { 'p-disabled': isLoading })}
      >
        {renderPageHeaderSection()}
        <div
          className="form-demo"
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
        >
          <div className="card" style={{ background: '#f3faf4BF' }}>
            {showErrorToast && <EzToast {...getToastProps()} />}
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <Container fluid>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderInspectionDate()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderDriver1()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderReportNumber()}</Col>
                </Row>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderLocation()}</Col>
                  <Col md="1" />
                  <Col md="3">
                    <div className={''}>{renderDriver2()}</div>
                  </Col>
                  <Col md="1" />
                  <Col md="3">
                    {renderControlledInput({ name: 'trip_no', label: 'Trip#' })}
                  </Col>
                </Row>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">
                    {renderControlledInput({
                      name: 'owner_opr',
                      label: 'Owner-Opr',
                    })}
                  </Col>
                  <Col md="1" />
                  <Col md="3" className={'mt-3'}>
                    {renderProblemCheckBox()}
                  </Col>
                  <Col md="1" />
                  <Col md="3">{problem && renderProblemDescription()}</Col>
                </Row>
                {renderDividerRow('Assets')}
                <Row>
                  <Col md="3">
                    {renderTruck()}
                    {truck &&
                      renderControlledInput({
                        name: 'truck_owner',
                        label: 'Truck belongs to',
                      })}
                    {doesVehicleNumberExist('truck') &&
                      renderControlledInput({
                        name: '',
                        label: formFieldValueMap?.truck?.vehicle_no,
                        disabled: true,
                      })}
                  </Col>
                  <Col md="1" />
                  <Col md="3">
                    {renderTrailer1()}
                    {trailer1 &&
                      renderControlledInput({
                        name: 'trailer1_owner',
                        label: 'Trailer1 belongs to',
                      })}
                    {doesVehicleNumberExist('trailer1') &&
                      renderControlledInput({
                        name: '',
                        label: formFieldValueMap?.trailer1?.vehicle_no,
                        disabled: true,
                      })}
                  </Col>
                  <Col md="1" />
                  <Col md="3">
                    {renderTrailer2()}
                    {trailer2 &&
                      renderControlledInput({
                        name: 'trailer2_owner',
                        label: 'Trailer2 belongs to',
                      })}
                    {doesVehicleNumberExist('trailer2') &&
                      renderControlledInput({
                        name: '',
                        label: formFieldValueMap?.trailer2?.vehicle_no,
                        disabled: true,
                      })}
                  </Col>
                </Row>
                {renderDividerRow('Impact & Claim Info')}
                <Row>
                  <Col md="3">
                    {renderControlledInput({
                      name: 'impact_rate',
                      label: 'Impact rate',
                      type: 'number',
                    })}
                  </Col>
                  <Col md="1" />
                  <Col md="3">
                    {renderControlledInput({
                      name: 'impact_result',
                      label: 'Impact Result',
                    })}
                  </Col>
                  <Col md="1" />
                  <Col md="3">
                    {renderControlledInput({
                      name: 'rem_action',
                      label: 'Remediation action',
                    })}
                  </Col>
                </Row>
                {renderDividerRow()}
                <Row className={'ml-1 mr-1'}>
                  <Col md="6">
                    <Row>
                      <div style={{ minWidth: '500px' }} className={'mt-4'}>
                        {renderProvince()}
                      </div>
                    </Row>
                    <Row className={'mt-3'}>{renderConvictions()}</Row>
                    {/* <Row className={'mt-3'}>{renderConvictions()}</Row>
                    <Row className={'mt-3'}>{renderConvictions()}</Row> */}
                  </Col>
                  <Col md="6">{renderFileUploader()}</Col>
                </Row>
              </Container>
              <div
                className={'make-flex mt-3 mr-2'}
                style={{ flexDirection: 'row-reverse', background: 'white' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      label="Cancel"
                      onClick={navigateToInspectionSummaryScreen}
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      type="reset"
                      label="Reset"
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '170px' }}>
                    <Button
                      loading={isLoading}
                      icon={'pi pi-plus'}
                      type="submit"
                      label={isEdit ? 'Edit Inspection' : 'Create Inspection'}
                      className="mt-2 p-button-raised"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateInspection;
