import Cookies from 'universal-cookie';
import { post, put } from 'axios';
import { baseUrls, deleteApi, getApiAsyn } from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { generateImageUrl, getDecoratedUrl } from '../../common/urlService';
import { API_URL } from '../../../constants/urlConstants';
import { extractData } from '../../utils';
import { asyncForEach } from '../../../utils/async';
import { getAllAttachment } from '../commonService';

const cookies = new Cookies();

const getFetchAssetsUrl = (payload) => {
  return getDecoratedUrl({ url: API_URL.ASSETS.FETCH_ASSETS, payload });
};

export const fetchAssets = async (payload) => {
  const url = getFetchAssetsUrl(payload);
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));
    if (result?.error) {
      throw new APIError(result);
    }

    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchAssetMake = async (payload) => {
  const url = API_URL.ASSETS.FETCH_ASSET_MAKE;
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));
    if (result?.error) {
      throw new APIError(result);
    }

    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchAssetModel = async (payload) => {
  const url = API_URL.ASSETS.FETCH_ASSET_MODEL;
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));
    if (result?.error) {
      throw new APIError(result);
    }
    
    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};


const multipartDataUploadApi = (url, { files, data }, isEdit) => {
  const formPayload = new FormData();
  
  const json = JSON.stringify(data);
  const blobData = new Blob([json], {
    type: 'application/json',
  });
  formPayload.append('data', blobData);
  
  // file part of multipart data
  files?.forEach((file) => {
    formPayload.append('file', file);
  });

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      authorization: cookies.get('authorization'),
    },
  };
  if (isEdit) {
    return put(baseUrls + url, formPayload,config);
  } else {
    return post(baseUrls + url, formPayload,config);
  }
};

export const createAssetRecord = async (payload) => {
  try {
    let result = await multipartDataUploadApi(
      `${API_URL.ASSETS.CREATE_ASSETS}`,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchVehcileTypes = async (payload) => {
  const url = API_URL.FETCH_VEHICLE_TYPE;
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }

    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchEquipmentTypes = async (payload) => {
  const url = API_URL.FETCH_EQUIPMENT_TYPE;
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }

    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchAssetByAssetId = async (payload) => {
  const url = API_URL.ASSETS.FETCH_ASSETS_BY_ID.replace(':id',payload?.assetId);
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }
    result = extractData(result, !payload?.filters);
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

 export const editAssetRecord = async (payload) => {
  const url = API_URL.ASSETS.EDIT_ASSET.replace(
    ':id',
    payload?.assetId
  );
  
  try {
    let result = await multipartDataUploadApi(url, payload,true)

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const deleteAssetRecord = async ({ assetId }) => {
  try {
    let result = await deleteApi(
      `${API_URL.ASSETS.DELETE_ASSETS}/${assetId}`,
      cookies.get('authorization')
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};  

export const fetchAssetAttachmentByAssetId = async (payload) => {
  const url = API_URL.ASSETS.FETCH_BY_ASSET_ID.replace(':id', payload?.itemId);
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }
    if (result?.files?.length) {
      await asyncForEach(result.files, async (file, index, array) => {
        // result.files[index].url = await generateImageUrl(file.url);
        result.files[index].s3Url = await generateImageUrl(file.url);
      });
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const downloadAssetsAllAttachment = async (payload) => {
  await getAllAttachment('assets', payload)
};

export const createAssetMake = async (payload) => {
  const url = API_URL.ASSETS.CREATE_ASSET_MAKE;
  const config = {
    headers: {
      authorization: cookies.get('authorization'),
    },
  };

  try {
      const result = await post(baseUrls + url, payload, config);
      return result
  } catch(err) {
    console.log(err, payload);
    return handleAPIError(err);
  }
}

export const createAssetModal = async (payload) => {
  const url = API_URL.ASSETS.CREATE_ASSET_MODEL;
  const config = {
   headers: {
     authorization: cookies.get('authorization'),
   },
 };
 
  try {
     const result = await post(baseUrls + url, payload, config);
     return result
  } catch(err) {
    console.log(err, payload);
    return handleAPIError(err);
  }
 }