export const INIT_INSPECTIONS = 'INIT_INSPECTIONS';
export const FETCH_INSPECTIONS_LIST_START = 'FETCH_INSPECTIONS_LIST_START';
export const FETCH_INSPECTIONS_LIST_SUCCESS = 'FETCH_INSPECTIONS_LIST_SUCCESS';
export const FETCH_INSPECTIONS_LIST_ERROR = 'FETCH_INSPECTIONS_LIST_ERROR';

export const DELETE_INSPECTION_RECORD_START = 'DELETE_INSPECTION_RECORD_START';
export const DELETE_INSPECTION_RECORD_SUCCESS =
  'DELETE_INSPECTION_RECORD_SUCCESS';
export const DELETE_INSPECTION_RECORD_ERROR = 'DELETE_INSPECTION_RECORD_ERROR';

export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';

export const INIT_CREATE_NEW_INSPECTION_SCREEN =
  'INIT_CREATE_NEW_INSPECTION_SCREEN';

export const CREATE_INSPECTION_RECORD = {
  START: 'CREATE_INSPECTION_RECORD_START',
  SUCCESS: 'CREATE_INSPECTION_RECORD_SUCCESS',
  ERROR: 'CREATE_INSPECTION_RECORD_ERROR',
};

export const EDIT_INSPECTION_RECORD = {
  START: 'EDIT_INSPECTION_RECORD_START',
  SUCCESS: 'EDIT_INSPECTION_RECORD_SUCCESS',
  ERROR: 'EDIT_INSPECTION_RECORD_ERROR',
};

export const FETCH_INSPECTION_DETAIL = {
  START: 'FETCH_INSPECTION_DETAIL_START',
  SUCCESS: 'FETCH_INSPECTION_DETAIL_SUCCESS',
  ERROR: 'FETCH_INSPECTION_DETAIL_ERROR',
};

export const TOAST = {
  RESET: 'RESET_TOAST',
};
