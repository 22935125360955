import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { 
  initializeAssetsScreen,
  fetchAssets,
  deleteAssetRecord,
  assetsFetchFilesByAssetId,
  downloadAllAttachmentForAssetId
 } from '../../Redux/Actions/assets';
import { 
  selectAssetsList,
  selectIsLoading,
  selectPaginationInfo,
  selectIsCreateAssetSuccess,
  selectIsCreateAssetError,
  selectIsEditAssetSuccess,
  selectIsEditAssetError,
  selectIsLoadingPage,
  selectIsPageLevelError,
  selectIsAssetDeletionError,
  selectIsAssetDeletionSuccess,
  selectShouldOpenDeleteConfirmationPopup  } from '../../Redux/Selectors/assets_new';
import Assets from '../../components/Asset';
import { loadingsShow } from '../../Services/PostAPI';
import { setDeleteConfirmationPopupVisibility } from '../../Redux/Actions/assets';
import { APP_URLS } from '../../constants/urlConstants';
import { generatePath } from 'react-router';
import EzDialog from '../../common/EzDialog';
import AssetTracking from './AssetTracking';
import { createLabelValueObj } from '../../common/ContextMenu/utils';
import { initializeAssetsTrackerScreen } from '../../Redux/Actions/assets/assetTracker';
import { 
        selectIsCreateAssetTrackerError,
        selectIsCreateAssetTrackerSuccess,
        selectIsEditAssetTrackerError,
        selectIsEditAssetTrackerSuccess
       } from '../../Redux/Selectors/assets_new/assetTracker';

const OpenAssetDialog = 'OpenAssetDialog';

const AssetsScreen = (props) => {
  const {
    paginationInfo,
    isFetchingGridData,
    showPageLevelLoader,
    assetsListView,
    initializeAssetsScreen,
    fetchAssets,
    deleteAssetRecord,
    isPageLevelError,
    screenPermissions,
    isAssetDeletionError,
    isAssetDeletionSuccess,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    history,
    resetAssetsTrackerScreen,
    fetchAttachmentsById,
    downloadAllAttachments
  } = props;
  
  useEffect(() => {
    initializeAssetsScreen()
    fetchAssets({ sortField: 'id' })
  }, [fetchAssets, initializeAssetsScreen]);
  
  const createAssetRecord = () => history.push(APP_URLS.ASSETS.ADD_ASSETS);

  const editAssetRecord = (assetId) => {
    const path = generatePath(APP_URLS.ASSETS.EDIT_ASSETS, { id:assetId });
    history.push(path);
  };

  const [showdialog, setShowDialog] = useState(false);

  const onHide = () => {
    setShowDialog(false);
    resetAssetsTrackerScreen();
    history.push(APP_URLS.ASSETS.HOME)
  };
 
  const [currentAssetRecord, setCurrentAssetRecord ] = useState(null);

    const moreActionProps = {
        isMoreActionDisabled: false,
        downloadEnabled: true,
        moreActionOptions : [
          createLabelValueObj('Tracking Details', OpenAssetDialog)
        ],
        onMoreActionChange : (optionValue, currentRowData) => {
          if(optionValue === OpenAssetDialog) {
            setShowDialog(true);
            setCurrentAssetRecord(currentRowData);
          }
        }
    } 
    
  const assetsProps = {
    paginationInfo,
    isFetchingGridData,
    showPageLevelLoader,
    assetsListView,
    fetchAssets,
    deleteAssetRecord,
    isPageLevelError,
    screenPermissions,
    isAssetDeletionError,
    isAssetDeletionSuccess,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    createAssetRecord,
    editAssetRecord,
    moreActionProps,
    fetchAttachmentsById,
    downloadAllAttachments
  };

  
  if (showPageLevelLoader) {;
    loadingsShow('block');
    return null;
  }
  loadingsShow('none');

  const header = () => <div> Tracking Details </div> 
  return (
    <>
      {/* <EzToast {...getToastProps()} /> */}
      <Assets {...assetsProps} />
      {
        showdialog &&
        <EzDialog  
          header = {header}
          width = {'80vw'}
          onHide = {onHide}
          onShow = {resetAssetsTrackerScreen}
          >
          <AssetTracking closeDialog = {onHide} currentAssetRecord = {currentAssetRecord}/> 
        </EzDialog>
      }
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isCreateAssetSuccess: selectIsCreateAssetSuccess(state),
    isCreateAssetError: selectIsCreateAssetError(state),
    isEditAssetSuccess : selectIsEditAssetSuccess(state),
    isEditAssetError : selectIsEditAssetError(state),
    paginationInfo: selectPaginationInfo(state),
    isFetchingGridData: selectIsLoading(state),
    showPageLevelLoader: selectIsLoadingPage(state),
    assetsListView:selectAssetsList(state),
    isPageLevelError: selectIsPageLevelError(state),
    isAssetDeletionError : selectIsAssetDeletionError(state),
    isAssetDeletionSuccess:selectIsAssetDeletionSuccess(state),
    shouldOpenDeleteConfirmationPopup: selectShouldOpenDeleteConfirmationPopup(state),
    // for asset Tracker
    isCreateAssetTrackerSuccess: selectIsCreateAssetTrackerSuccess(state),
    isCreateAssetTrackerError: selectIsCreateAssetTrackerError(state),
    isEditAssetTrackerSuccess : selectIsEditAssetTrackerSuccess(state),
    isEditAssetTrackerError : selectIsEditAssetTrackerError(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeAssetsScreen: () => dispatch(initializeAssetsScreen()),
    fetchAssets: (payload) => dispatch(fetchAssets(payload)),
    deleteAssetRecord: (payload) => dispatch(deleteAssetRecord(payload)),
    setDeleteConfirmationPopupVisibility: (payload) => dispatch(setDeleteConfirmationPopupVisibility(payload)),
    resetAssetsTrackerScreen : () => dispatch(initializeAssetsTrackerScreen()),
    fetchAttachmentsById: (payload) => dispatch(assetsFetchFilesByAssetId(payload)),
    downloadAllAttachments: (payload) => dispatch(downloadAllAttachmentForAssetId(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetsScreen);
