import {
  FETCH_USER_PROFILE_START,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_COMPANY_INFO_START,
  FETCH_USER_COMPANY_INFO_SUCCESS,
  FETCH_USER_COMPANY_INFO_ERROR,
  FETCH_SWITCHABLE_ACCOUNTS_START,
  FETCH_SWITCHABLE_ACCOUNTS_SUCCESS,
  FETCH_SWITCHABLE_ACCOUNTS_ERROR,
  SWITCH_ACCOUNTS_START,
  SWITCH_ACCOUNTS_SUCCESS,
  SWITCH_ACCOUNTS_ERROR
 } from "../../../constants/action-types/userProfile";

const INITIAL_STATE = {
  isUserProfileLoading: false,
  userProfileError: null,
  isUserProfileError: false,
  loggedInUserInfo: null,
  
  isUserCompanyInfoLoading: false,
  userCompanyInfoError: false,
  isUserCompanyInfoError: false,
  loggedInUserCompany: null,
};

export const userProfileReducer = (state = INITIAL_STATE, action) => {
// eslint-disable-next-line default-case
  switch (action.type) {
  
    case FETCH_USER_PROFILE_START: return { ...state, isUserProfileLoading: true, isUserProfileError: false }

    case FETCH_USER_PROFILE_SUCCESS: {
      const loggedInUserInfo = action.payload;
      return { ...state, loggedInUserInfo, isUserProfileLoading: false, isUserProfileError: false };
    }

    case FETCH_USER_PROFILE_ERROR: {
      const { error: userProfileError } = action.payload;
      return {
        ...state,
        userProfileError,
        isUserProfileLoading: false,
        isUserProfileError: true,
      };
    }
 
    case FETCH_USER_COMPANY_INFO_START: return { ...state, isUserCompanyInfoLoading: true, isUserCompanyInfoError: false }

    case FETCH_USER_COMPANY_INFO_SUCCESS: {
      const loggedInUserCompany = action.payload;
      return { ...state, loggedInUserCompany, isUserCompanyInfoLoading: false, isUserCompanyInfoError: false };
    }

    case FETCH_USER_COMPANY_INFO_ERROR: {
      const { error: userCompanyInfoError } = action.payload;
      return {
        ...state,
        userCompanyInfoError,
        isUserProfileLoading: false,
        isUserProfileError: true,
      };
    }

    case FETCH_SWITCHABLE_ACCOUNTS_START: { return { ...state, switchableAccountsLoading: true }}
    case FETCH_SWITCHABLE_ACCOUNTS_SUCCESS: {
      const { payload } = action
      return { ...state, switchableAccountsLoading: false, switchableAccounts: payload }
    }
    case FETCH_SWITCHABLE_ACCOUNTS_ERROR: {
      const { payload } = action
      return { ...state, switchableAccountsLoading: false, switchableAccountsError: payload }
    }
    case SWITCH_ACCOUNTS_START: { return state }
    case SWITCH_ACCOUNTS_SUCCESS: {
      return { ...state, isAccountSwitched: true }
    }
    case SWITCH_ACCOUNTS_ERROR: { return state }
    default:
      return state;
  }
};