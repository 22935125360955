import { connect } from 'react-redux';
import { ProgressBar } from 'primereact/progressbar';
import { EzToast, TOAST_SEVERITY } from '../../../common/EzToast';
import CreateCompliance from '../../../components/Compliance/CreateCompliance';
import { APP_URLS } from '../../../constants/urlConstants';
import { fetchEmployees } from '../../../Redux/Actions/employees';
import { fetchAssets } from '../../../Redux/Actions/assets';
import { fetchTypes } from '../../../Redux/Actions/types';
import {
  initCreateNewComplianceScreen,
  createCompliance,
  editCompliance,
  fetchComplianceByComplianceId,
  fetchFilesByComplianceId,
  downloadAllAttachmentByComplianceId
} from '../../../Redux/Actions/compliance';
import { useEffect } from 'react';
import { setIsformDirty } from '../../../Redux/Actions/common/form';
const transformEmployees = (employees) => {
  return employees?.map((emp) => {
    const { first, last, id } = emp;
    return {
      label: `${first || ''} ${last || ''}`,
      value: id,
      extraData: emp,
    };
  });
};
const transformAssets = (assets) => {
  const filteredAssets = [];
  assets?.forEach((asset) => {
    const { id, asset_code } = asset;
    filteredAssets.push({
      label: asset_code,
      value: id,
      extraData: asset,
    });

  });
  return filteredAssets;
};
const transformTypesCMPLNC_TYPE = (types) => {
  const filteredTypes = [];
  types?.CMPLNC_TYPE?.forEach((type) => {
    const { key, value, code } = type;
    filteredTypes.push({
      label: value,
      value: key,
      code: code
    });

  });
  return filteredTypes;
};
const transformTypesCMPLNC_ITEM_STATUS = (types) => {
  const filteredTypes = [];
  types?.CMPLNC_ITEM_STATUS?.forEach((type) => {
    const { key, value, code } = type;
    filteredTypes.push({
      label: value,
      value: key,
      code: code
    });

  });
  return filteredTypes;
};
const transformTypesCMPLNC_CMP = (types) => {
  const filteredTypes = [];
  types?.['CMPLNC_BY&param1=CMPLNC_CMP']?.forEach((type) => {
    const { key, value, code } = type;
    filteredTypes.push({
      label: value,
      value: key,
      code: code
    });

  });
  return filteredTypes;
};
const transformTypesCMPLNC_CAT_TYPE_CMP = (types) => {
  const filteredTypes = [];
  types?.['CMPLNC_CAT_TYPE&param1=CMPLNC_CMP']?.forEach((type) => {
    const { key, value, code } = type;
    filteredTypes.push({
      label: value,
      value: key,
      code: code
    });

  });
  return filteredTypes;
};
const transformTypesCMPLNC_CAT_TYPE_EMP = (types) => {
  const filteredTypes = [];
  types?.['CMPLNC_CAT_TYPE&param1=CMPLNC_EMP']?.forEach((type) => {
    const { key, value, code } = type;
    filteredTypes.push({
      label: value,
      value: key,
      code: code
    });

  });
  return filteredTypes;
};
const transformTypesCMPLNC_CAT_TYPE_AST = (types) => {
  const filteredTypes = [];
  types?.['CMPLNC_CAT_TYPE&param1=CMPLNC_ASSET']?.forEach((type) => {
    const { key, value, code } = type;
    filteredTypes.push({
      label: value,
      value: key,
      code: code
    });

  });
  return filteredTypes;
};
const CreateComplianceContainer = (props) => {
  const {
    employees,
    assets,
    types,
    history,
    fetchEmployees,
    fetchAssets,
    fetchTypes,
    createCompliance,
    editCompliance,
    isLoading,
    isCreateComplianceSuccess,
    isCreateComplianceError,
    isCreateComplianceErrorMessage,
    isComplianceDetailSuccess,
    isComplianceDetailError,
    isEditComplianceSuccess,
    isEditComplianceError,
    isEdit,
    formFieldValueMap,
    fetchComplianceByComplianceId,
    fetchFilesByComplianceId,
    downloadAllAttachmentByComplianceId,
    shouldBlockNavigation
  } = props;
  const { complianceId } = props.match.params;
  const searchTypes = (query) => {
    if (query) {
      fetchTypes({
        params: query,
      });
    }
  };
  useEffect(() => {
    if (isEdit) {
      fetchComplianceByComplianceId({ complianceId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);
  const navigateToComplianceSummaryScreen = () => {
    history.push(APP_URLS.COMPLIANCE.HOME);
  };
  const createComplianceProps = {
    navigateToComplianceSummaryScreen,
    employees: transformEmployees(employees),
    trucks: transformAssets(assets),
    cmplncType: transformTypesCMPLNC_TYPE(types),
    cmplncItemStatus: transformTypesCMPLNC_ITEM_STATUS(types),
    cmplncCmp: transformTypesCMPLNC_CMP(types),
    cmplnceCatTypeCmp: transformTypesCMPLNC_CAT_TYPE_CMP(types),
    cmplncCatTypeEmp: transformTypesCMPLNC_CAT_TYPE_EMP(types),
    cmplncCatTypeAst: transformTypesCMPLNC_CAT_TYPE_AST(types),
    fetchAssets,
    searchTypes,
    fetchEmployees,
    createCompliance,
    editCompliance,
    isLoading,
    isEdit,
    isComplianceDetailSuccess,
    isCreateComplianceSuccess,
    isEditComplianceSuccess,
    isEditComplianceError,
    formFieldValueMap,
    complianceId,
    shouldBlockNavigation,
    fetchFilesByComplianceId,
    downloadAllAttachmentByComplianceId,
  };
  console.log(shouldBlockNavigation, 'shouldBlockNavigation')
  const getToastProps = () => {
    if (isCreateComplianceSuccess || isEditComplianceSuccess) {
      const toastTitle = isEdit
        ? 'Compliance Updated Successfully'
        : 'Compliance Created Successfully';
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }
    if (isCreateComplianceError || isComplianceDetailError || isEditComplianceError) {
      let toastTitle = 'Error while Creating Compliance';
      if (isEditComplianceError) {
        toastTitle = 'Error while updating Compliance';
      } else if (isComplianceDetailError) {
        toastTitle =
          'Error while performing operation. Please refresh your browser';
      }
      else if (isCreateComplianceErrorMessage?.response?.data?.status === false) {
        toastTitle = isCreateComplianceErrorMessage?.response?.data?.error
      }
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };
  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />;
  };
  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateCompliance {...createComplianceProps} />;
    </>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    employees: selectFromEmployeeReducer(state, 'employees'),
    assets: selectFromAssetsReducer(state, 'assets'),
    types: selectFromTypesReducer(state, 'types'),
    isLoading: selectFromCompliancesReducer(state, 'isLoading'),
    isCreateComplianceSuccess: selectFromCompliancesReducer(
      state,
      'isCreateComplianceSuccess'
    ),
    isCreateComplianceError: selectFromCompliancesReducer(
      state,
      'isCreateComplianceError'
    ),
    isCreateComplianceErrorMessage: selectFromCompliancesReducer(
      state,
      'error'
    ),
    isComplianceDetailSuccess: selectFromCompliancesReducer(
      state,
      'isComplianceDetailSuccess'
    ),
    isComplianceDetailError: selectFromCompliancesReducer(
      state,
      'isComplianceDetailError'
    ),
    isEditComplianceSuccess: selectFromCompliancesReducer(
      state,
      'isEditComplianceSuccess'
    ),
    isEditComplianceError: selectFromCompliancesReducer(
      state,
      'isEditComplianceError'
    ),
    formFieldValueMap: selectFromCompliancesReducer(state, 'formFieldValueMap'),
    ...ownProps,
  };
};
const selectFromAssetsReducer = (state, path) => {
  return selectAssetsReducer(state)[path];
};
const selectFromTypesReducer = (state, path) => {
  return selectTypesReducer(state)[path];
};
const selectFromCompliancesReducer = (state, path) => {
  return selectCompliancesReducer(state)[path];
};
const mapDispatchToProps = (dispatch) => {
  return {
    initCreateNewComplianceScreen: () => dispatch(initCreateNewComplianceScreen()),
    fetchEmployees: (payload) => dispatch(fetchEmployees(payload)),
    fetchAssets: (payload) => dispatch(fetchAssets(payload)),
    fetchTypes: (payload) => dispatch(fetchTypes(payload)),
    fetchComplianceByComplianceId: (payload) => dispatch(fetchComplianceByComplianceId(payload)),
    fetchFilesByComplianceId: (payload) => dispatch(fetchFilesByComplianceId(payload)),
    downloadAllAttachmentByComplianceId:(payload) => dispatch(downloadAllAttachmentByComplianceId(payload)),
    createCompliance: (payload) => dispatch(createCompliance(payload)),
    editCompliance: (payload) => dispatch(editCompliance(payload)),
    shouldBlockNavigation: isFormDirty => dispatch(setIsformDirty(isFormDirty))
  };
};
const selectCompliancesReducer = ({ compliancesReducer }) => {
  return compliancesReducer;
};
const selectAssetsReducer = ({ assetsReducer }) => {
  return assetsReducer;
};
const selectTypesReducer = ({ typesReducer }) => {
  return typesReducer;
};
const selectFromEmployeeReducer = (state, path) => {
  return selectEmployeeReducer(state)[path];
};
const selectEmployeeReducer = ({ employeeReducer }) => {
  return employeeReducer;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateComplianceContainer);