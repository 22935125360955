import {
   DOWNLOADING_ALL_ATTACHEMENT,
   FETCH_ATTACHMENT_FILES
  } from "../../../constants/action-types/common/files"


export const attachmentsFilesFetchStart = (payload) => ({
    type:FETCH_ATTACHMENT_FILES.START,
    payload
  })
  export const attachmentsFilesFetchError = (payload) => ({
    type:FETCH_ATTACHMENT_FILES.ERROR,
    payload
  })
  export const attachmentsFilesFetchSuccess = (payload) => ({
    type:FETCH_ATTACHMENT_FILES.SUCCESS,
    payload
  })
  
  export const downloadingAllAttachmentStart = () => ({
    type : DOWNLOADING_ALL_ATTACHEMENT.START
  })
  
  export const downloadingAllAttachmentComplete = () => ({
    type : DOWNLOADING_ALL_ATTACHEMENT.COMPLETE
  })
  export const downloadingAllAttachmentError = () => ({
    type : DOWNLOADING_ALL_ATTACHEMENT.ERROR
  })