import Cookies from 'universal-cookie';
import { post, put } from 'axios';

import { 
  getApiAsyn,
  deleteApi,
  baseUrl
} from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { API_URL } from '../../../constants/urlConstants';
import { extractData } from '../../utils';
import { generateImageUrl, getDecoratedUrl } from '../../common/urlService';
import { asyncForEach } from '../../../utils/async';
import { getAllAttachment } from '../commonService';

const cookies = new Cookies();

export const fetchAccidents = async (payload) => {
  const url = getDecoratedUrl({
    url: API_URL.ACCIDENTS.FETCH_ACCIDENT,
    payload,
  });
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));
    if (result?.error) {
      throw new APIError(result);
    }

    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchAccidentByAccidentId = async (payload) => {
  const url = API_URL.ACCIDENTS.FETCH_BY_ACCIDENT_ID.replace(
    ':id',
    payload?.accidentId
  );
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }

    result = extractData(result, !payload?.filters);
    if (result?.files?.length) {
      await asyncForEach(result.files, async (file, index, array) => {
        result.files[index].s3Url = await generateImageUrl(file.url);
      });
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};


export const fetchAccidentAttachmentByAccidentId = async (payload) => {
  const url = API_URL.ACCIDENTS.FETCH_BY_ACCIDENT_ID.replace(':id',payload?.itemId);
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }
    if (result?.files?.length) {
      await asyncForEach(result.files, async (file, index, array) => {
        // result.files[index].url = await generateImageUrl(file.url);
        result.files[index].s3Url = await generateImageUrl(file.url);
      });
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const downloadAllAttachment = async (payload) => {
  getAllAttachment('accidents', payload)
};

export const deleteAccidentRecord = async ({ accidentId }) => {
  try {
    let result = await deleteApi(
      `${API_URL.ACCIDENTS.DELETE_ACCIDENT}/${accidentId}`,
      cookies.get('authorization')
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

// TODO (subham)- Refactor this to make it reusable logic
const multipartDataUploadApi = (url, { files, data }, isEdit = false) => {
  const formPayload = new FormData();
  // data part of multipart data
  const json = JSON.stringify(data);
  const blobData = new Blob([json], {
    type: 'application/json',
  });
  formPayload.append('data', blobData);

  // file part of multipart data
  files?.forEach((file) => {
    formPayload.append('file', file);
  });

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      authorization: cookies.get('authorization'),
    },
  };
  if (isEdit) {
    return put(baseUrl + url, formPayload, config);
  } else {
    return post(baseUrl + url, formPayload, config);
  }
};

export const createAccidentRecord = async (payload) => {
  try {
    let result = await multipartDataUploadApi(
      `${API_URL.ACCIDENTS.CREATE_ACCIDENT}`,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const editAccidentRecord = async (payload) => {
  const url = API_URL.ACCIDENTS.EDIT_ACCIDENT.replace(
    ':id',
    payload?.accidentId
  );

  try {
    let result = await multipartDataUploadApi(url, payload, true);

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
