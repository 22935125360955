import Cookies from 'universal-cookie';
import { getApiAsyn } from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { getDecoratedUrl, generateImageUrl } from '../../common/urlService';
import { API_URL } from '../../../constants/urlConstants';
import { asyncForEach } from '../../../utils/async';
import { extractData } from '../../utils';

const cookies = new Cookies();

const getFetchCustomerUrl = (payload) => {
  return getDecoratedUrl({ url: API_URL.CUSTOMERS.FETCH_CUSTOMERS, payload });
};

export const fetchCustomers = async (payload , searchById = false) => {
  const url = getFetchCustomerUrl(payload);
                          
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }

    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchCustomerByCustomerId = async (payload) => {
  const url = API_URL.CUSTOMERS.FETCH_CUSTOMER_BY_ID.replace(
    ':id',
    payload?.id
  );
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }

    if (result?.files?.length) {
      await asyncForEach(result.files, async (file, index, array) => {
        result.files[index].s3Url = await generateImageUrl(file.url);
      });
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
