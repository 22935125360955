import React from 'react';
import { Route } from 'react-router-dom';

import ComplianceScreen from '../../containers/ComplianceScreen';
import CreateComplianceContainer from '../../containers/ComplianceScreen/CreateCompliance';
import { APP_URLS } from '../../constants/urlConstants';

const ComplianceRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update } = access;
  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <ComplianceScreen {...routeProps} screenPermissions={access} />
          )}
        />
      )}
      {!!create && (
        <Route
          path={APP_URLS.COMPLIANCE.ADD_COMPLIANCE}
          component={CreateComplianceContainer}
        />
      )}
      {!!update && (
        <Route
          path={APP_URLS.COMPLIANCE.EDIT_COMPLIANCE}
          render={(routeProps) => (
            <CreateComplianceContainer {...routeProps} isEdit={true} />
          )}
        />
      )}
    </>
  );
};

export default ComplianceRoutes;
