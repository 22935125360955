import Cookies from 'universal-cookie';
import { getApiAsyn } from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { getDecoratedUrl } from '../../common/urlService';
import { API_URL } from '../../../constants/urlConstants';
import { extractData } from '../../utils';

const cookies = new Cookies();

const getFetchEmployeeUrl = (payload) => {
  return getDecoratedUrl({ url: API_URL.EMPLOYEES.FETCH_EMPLOYEES, payload });
};

export const fetchEmployees = async (payload,searchById = false) => {
  const url = !searchById ?  getFetchEmployeeUrl(payload) : API_URL.EMPLOYEES.FETCH_EMPLOYEES_BY_ID.replace(':id', payload?.id);
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }

    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
