import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import TrackingLog from '../../components/TrackingLog';
import { loadingsShow } from '../../Services/PostAPI';
import { TRACKING_LOG_DATE_DATA_KEY } from '../../Redux/Reducers/trackingLogs';
import {
  initializeTrackingLogScreen,
  fetchTrackingLogs,
} from '../../Redux/Actions/trackingLogs';

const TrackingLogsScreen = (props) => {
  const {
    trackingLogsListView,
    fetchTrackingLogs,
    initTrackingLogScreen,
    isFetchingGridData,
    paginationInfo,
    showPageLevelLoader,
    screenPermissions,
    isPageLevelError,
  } = props;

  
  useEffect(() => {
    initTrackingLogScreen();
    fetchTrackingLogs({ sortField: TRACKING_LOG_DATE_DATA_KEY });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackingLogProps = {
    trackingLogsListView,
    fetchTrackingLogs,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isPageLevelError,
  };

  if (showPageLevelLoader) {
    loadingsShow('block');
    return null;
  }
  loadingsShow('none');
  return (
    <>
      <TrackingLog {...trackingLogProps} />;
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initTrackingLogScreen: () => dispatch(initializeTrackingLogScreen()),
    fetchTrackingLogs: (payload) => dispatch(fetchTrackingLogs(payload)),
  };
};

// TODO(Subham):  This selection of data needs improvement, See if we can use UseSelector here.
const mapStateToProps = (state, ownProps) => {
  
  return {
    ...ownProps,
    trackingLogsListView: selectTrackingLogsList(state.trackingLogsReducer),
    isFetchingGridData: selectIsLoading(state.trackingLogsReducer),
    showPageLevelLoader: selectIsLoadingPage(state.trackingLogsReducer),
    paginationInfo: selectPaginationInfo(state.trackingLogsReducer),
    isPageLevelError: selectIsPageLevelError(state.trackingLogsReducer),
  };
};

const selectIsPageLevelError = (trackingLogReducer) =>
  trackingLogReducer.isPageLevelError;
const selectPaginationInfo = (trackingLogReducer) =>
  trackingLogReducer.paginationInfo;
const selectIsLoading = (trackingLogsReducer) => trackingLogsReducer.isLoading;
const selectIsLoadingPage = (trackingLogsReducer) =>
  trackingLogsReducer.isLoadingPage;
const selectTrackingLogsList = (trackingLogsReducer) =>
  trackingLogsReducer.trackingLogsListView;

export default connect(mapStateToProps, mapDispatchToProps)(TrackingLogsScreen);
