/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row, Container } from 'react-bootstrap';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Divider } from 'primereact/divider';
import { Chip } from 'primereact/chip';
import { Tooltip } from 'primereact/tooltip';

import EzControlledAutoSuggestWithFloatingLabel from '../../../common/EzForm/EzControlledAutoSuggest/WithFloatingLabel';
import EzControlledCheckBoxWithLabel from '../../../common/EzForm/EzControlledCheckBox/WithLabel';
import EzControlledInputWithFloatingLabel from '../../../common/EzForm/EzControlledInput/WithFloatingLabel';
import EzControlledCalendarWithFloatingLabel from '../../../common/EzForm/EzControlledCalendar/WithFloatingLabel';
import EzControlledDropDowntWithFloatingLabel from '../../../common/EzForm/EzControlledDropDown/WithFloatingLabel';
import EzFileUpload from '../../../common/EzFileUpload';
import { EzToast, TOAST_SEVERITY } from '../../../common/EzToast';
import Province from '../../../containers/common/Province';
import { buildName } from '../../../utils/string';
import { getFormBaseValidationRule } from "../../../utils/form/form-utils"
import './index.css';

const YES_NO_OPTIONS = [
  { label: 'Positive', value: 'yes' },
  { label: 'Negative', value: 'no' },
];

const CreateAccident = (props) => {
  const {
    navigateToAccidentSummaryScreen,
    employees,
    trucks,
    trailers,
    searchEmployee,
    searchAssets,
    createAccident,
    editAccident,
    accidentId,
    isLoading,
    isEdit,
    formFieldValueMap,
    isAccidentDetailSuccess,
    isCreateAccidentSuccess,
    isEditAccidentSuccess,
    shouldBlockNavigation
  } = props;
  
  const [attachedFiles, setAttachedFiles] = useState([]);
  const {
    control,
    formState: { errors,isDirty },
    watch,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
  
  useEffect(()=> shouldBlockNavigation(isDirty),[isDirty]);
  useEffect(() => {
    if (isCreateAccidentSuccess || isEditAccidentSuccess) {
      reset();
      //navigateToAccidentSummaryScreen()
    }
  }, [isCreateAccidentSuccess]);

  useEffect(() => {
    // if single accident is fetched successfully
    if (isAccidentDetailSuccess) {
      reset({
        ...formFieldValueMap,
        province: formFieldValueMap?.province?.key,
        driver1: buildName(formFieldValueMap?.driver1),
        driver2: buildName(formFieldValueMap?.driver2),
        truck: formFieldValueMap?.truck?.asset_code,
        trailer1: formFieldValueMap?.trailer1?.asset_code,
        trailer2: formFieldValueMap?.trailer2?.asset_code,
      });

      setAttachedFiles(formFieldValueMap?.files);
    }
  }, [isAccidentDetailSuccess]);

  const injury = watch('injury');
  const damages = watch('damages');
  const liability = watch('liability');
  const at_fault = watch('at_fault');
  const drug_chk = watch('drug_chk');
  const truck = watch('truck');
  const trailer1 = watch('trailer1');
  const trailer2 = watch('trailer2');
  let removedFiles = [];

  // truck, trailers & employees are assets to the company
  const getAsset = (assets, assetTypeKey, assetData, assetDataKey = 'label') => {
    if (!assetData?.extraData && (typeof assetData === 'string') && assets?.length) {
      const asset = assets.find(t => t[assetDataKey] === assetData)
      return asset?.extraData
    } else if (assetData?.extraData) {
      return assetData.extraData
    }
    
    if (formFieldValueMap?.[assetTypeKey]) {
      return formFieldValueMap[assetTypeKey]
    }
  }

  const onSubmit = (data) => {
    // TODO (subham): What is this jhol jhal ??
    if (!isEdit) {
      delete data.files;
    }

    delete data.files;
   // --- jhol end 
    const dataPayload = {
      ...data,
      files: removedFiles,
      date: new Date(data.date).getTime(),
      drug_chk_dt: new Date(data.drug_chk_dt).getTime(),
      driver1: getAsset(employees, 'driver1', data.driver1)?.id,
      driver2: getAsset(employees, 'driver2', data.driver2)?.id,
      truck: getAsset(trucks, 'truck', data.truck)?.id,
      trailer1: getAsset(trailers, 'trailer1', data.trailer1)?.id,
      trailer2: getAsset(trailers, 'trailer2', data.trailer2)?.id,
    };

    if (isEdit) {
      editAccident({
        accidentId,
        data: dataPayload,
        ...(attachedFiles ? { files: attachedFiles } : {}),
      });
    } else {
      createAccident({
        data: dataPayload,
        files: attachedFiles,
      });
    }
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const renderPageHeaderSection = () => {
    const breadCrumbs = [
      { label: 'Accidents' },
      { label: isEdit ? 'Edit Accident' : 'Create Accident' },
    ];

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };

    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />
        <div className="rightbtn">
          <Button
            tooltip={'Back to accident summary'}
            tooltipOptions={{ position: 'left' }}
            label={''}
            icon={'pi pi-arrow-left'}
            className={`mr-3 p-button-rounded p-button-outlined p-button-info`}
            onClick={navigateToAccidentSummaryScreen}
          />
        </div>
      </div>
    );
  };

  const renderDriver1 = () => {
    const name = 'driver1';
    return (
      <EzControlledAutoSuggestWithFloatingLabel
        name={name}
        control={control}
        suggestions={employees}
        completeMethod={({ query }) => {
          searchEmployee({ query, filterField: 'first' });
        }}
        label={'Driver 1 Name'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderDriver2 = () => {
    const name = 'driver2';

    return (
      <EzControlledAutoSuggestWithFloatingLabel
        name={name}
        control={control}
        suggestions={employees}
        completeMethod={({ query }) => {
          searchEmployee({ query, filterField: 'first' });
        }}
        label={'Driver 2 Name'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderTruck = () => {
    const name = 'truck';
    return (
      <EzControlledAutoSuggestWithFloatingLabel
        name={name}
        control={control}
        suggestions={trucks}
        completeMethod={({ query }) => {
          searchAssets({ query, filterField: 'asset_code' });
        }}
        label={'Truck'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderTrailer1 = () => {
    const name = 'trailer1';

    return (
      <EzControlledAutoSuggestWithFloatingLabel
        name={name}
        value={trailer1}
        control={control}
        suggestions={trailers}
        completeMethod={({ query }) => {
          searchAssets({ query, filterField: 'asset_code' });
        }}
        label={'Trailer 1 Name'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderTrailer2 = () => {
    const name = 'trailer2';

    return (
      <EzControlledAutoSuggestWithFloatingLabel
        name={name}
        control={control}
        suggestions={trailers}
        completeMethod={({ query }) => {
          searchAssets({ query, filterField: 'asset_code' });
        }}
        label={'Trailer 2 Name'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderAccidentDate = () => {
    const name = 'date';

    return (
      <EzControlledCalendarWithFloatingLabel
        name={name}
        showTime={true}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        control={control}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label={'Accident Date'}
      />
    );
  };

  const renderLocation = () => {
    const name = 'location';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Location'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderInjuryCheckBox = () => {
    const name = 'injury';

    return (
      <EzControlledCheckBoxWithLabel
        name={name}
        control={control}
        label="Did any one suffer Injury?"
        isError={errors[name]}
        labelClassName={'ml-2'}
      />
    );
  };

  const renderInjuryDescription = () => {
    const name = 'injury_desc';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Enter Injury Description'}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderDamagesCheckBox = () => {
    const name = 'damages';

    return (
      <EzControlledCheckBoxWithLabel
        name={name}
        control={control}
        isError={errors[name]}
        label="Damages occurred?"
        labelClassName={'ml-2'}
      />
    );
  };

  const renderDamageDescription = () => {
    const name = 'damage_desc';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Enter description of Damages'}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderAtFaultCheckBox = () => {
    const name = 'at_fault';

    return (
      <EzControlledCheckBoxWithLabel
        name={name}
        control={control}
        isError={errors[name]}
        label="Was the driver at fault?"
        labelClassName={'ml-2'}
      />
    );
  };

  const renderAtFaultDescription = () => {
    const name = 'at_fault_desc';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Enter driver fault description'}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderIsLiabiltyCheckBox = () => {
    const name = 'liability';

    return (
      <EzControlledCheckBoxWithLabel
        name={name}
        control={control}
        isError={errors[name]}
        label="Was any liability created?"
        labelClassName={'ml-2'}
      />
    );
  };

  const renderLiabilityDescription = () => {
    const name = 'liability_desc';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Enter liability description'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderIsDrugTest = () => {
    const name = 'drug_chk';

    return (
      <EzControlledCheckBoxWithLabel
        name={name}
        control={control}
        isError={errors[name]}
        label="Was drug test done?"
        labelClassName={'ml-2'}
      />
    );
  };

  const renderDrugTestDescription = () => {
    const name = 'drug_chk_desc';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Enter drug test description'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderDrugTestDate = () => {
    const name = 'drug_chk_dt';

    return (
      <EzControlledCalendarWithFloatingLabel
        name={name}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        control={control}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label={'Drug Test Date'}
      />
    );
  };

  const renderDrugTestResult = () => {
    const name = 'drug_chk_rslt';

    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={YES_NO_OPTIONS}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Drug Test Result"
        labelClassName={'ml-2'}
      />
    );
  };

  const renderProvince = () => {
    const name = 'province';

    return (
      <Province
        name={name}
        control={control}
        onChange={(e) => console.log(e)}
        isFloatingLabel={true}
        isControlled={true}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Accident Province"
      />
    );
  };

  const renderDividerRow = (label, align = 'center', type = 'solid') => {
    return (
      <Row>
        <Divider align={align} type={type}>
          <b>{label || ''}</b>
        </Divider>
      </Row>
    );
  };

  const renderControlledInput = (
    name,
    label,
    type = 'text',
    rules = getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE
  ) => {
    return (
      <EzControlledInputWithFloatingLabel
        type={type}
        name={name}
        control={control}
        label={label}
        rules={rules}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  // eslint-disable-next-line no-unused-vars
  const renderConvictions = () => {
    return (
      <>
        <Tooltip
          target=".conviction-chip"
          className="green-tooltip"
          position="bottom"
        />

        <a
          className="conviction-chip"
          href="/#"
          rel="noreferrer"
          target="_blank"
          data-pr-tooltip="Coming soon!"
        >
          <Chip
            label="  Convictions/Tickets"
            icon="pi pi-lock"
            className="custom-chip"
          />
        </a>
      </>
    );
  };

  const renderFileUploader = () => {
    const onSelectFile = (e, field) => {
      setAttachedFiles([...e.files]);
    };

    const onRemoveFile = (fileTobeRemoved) => {
      removedFiles.push(fileTobeRemoved);
    };

    return (
      <EzFileUpload
        files={attachedFiles}
        onSelectFile={onSelectFile}
        onRemoveFile={onRemoveFile}
      />
    );
  };

  const [showErrorToast, setErrorToast] = useState(false);
  const getToastProps = () => {
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle: 'Please fill all mandatory fields',
      shouldShowToast: true,
    };
  };

  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrorToast(true);
    }
  }, [errors]);

  return (
    <div className="paddingbox">
      <div
        className={classNames('containerboxform', { 'p-disabled': isLoading })}
      >
        {renderPageHeaderSection()}
        <div
          className="form-demo"
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
        >
          <div className="card" style={{ background: '#f3faf4BF' }}>
            {showErrorToast && <EzToast {...getToastProps()} />}
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <Container fluid>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderAccidentDate()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderInjuryCheckBox()}</Col>
                  <Col md="5">{injury && renderInjuryDescription()}</Col>
                </Row>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderLocation()}</Col>
                  <Col md="1" />
                  <Col md="3">
                    <div className={''}>{renderDamagesCheckBox()}</div>
                  </Col>
                  <Col md="5">{damages && renderDamageDescription()}</Col>
                </Row>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderDriver1()}</Col>
                  <Col md="1" />
                  <Col md="3">
                    <div className={''}>{renderAtFaultCheckBox()}</div>
                  </Col>
                  <Col md="5">{at_fault && renderAtFaultDescription()}</Col>
                </Row>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderDriver2()}</Col>
                  <Col md="1" />
                  <Col md="3">
                    <div className={''}>{renderIsLiabiltyCheckBox()}</div>
                  </Col>
                  <Col md="5">{liability && renderLiabilityDescription()}</Col>
                </Row>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">
                    {renderControlledInput('owner_opr', 'Owner-Opr')}
                  </Col>
                  <Col md="1" />
                  <Col md="3">{renderControlledInput('trip_no', 'Trip#')}</Col>
                  <Col md="5">
                    <div className={''}>
                      {renderControlledInput('desc', 'Accident Description')}
                    </div>
                  </Col>
                </Row>
                {renderDividerRow('Drug Test')}
                <Row style={{ minWidth: '20px' }} className={'mt-2'}>
                  <Col md="2" className={'mt-3'}>
                    {renderIsDrugTest()}
                  </Col>
                  <Col md="1" />
                  <Col md="3">
                    {drug_chk && (
                      <div className={''}>{renderDrugTestDate()}</div>
                    )}
                  </Col>
                  <Col md="3">{drug_chk && renderDrugTestResult()}</Col>
                  <Col md="3">{drug_chk && renderDrugTestDescription()}</Col>
                </Row>
                {renderDividerRow('Assets')}
                <Row>
                  <Col md="3">
                    {renderTruck()}
                    {truck &&
                      renderControlledInput('truck_owner', 'Truck belongs to')}
                  </Col>
                  <Col md="1" />
                  <Col md="3">
                    {renderTrailer1()}
                    {trailer1 &&
                      renderControlledInput(
                        'trailer1_owner',
                        'Trailer1 belongs to'
                      )}
                  </Col>
                  <Col md="1" />
                  <Col md="3">
                    {renderTrailer2()}
                    {trailer2 &&
                      renderControlledInput(
                        'trailer2_owner',
                        'Trailer2 belongs to'
                      )}
                  </Col>
                </Row>
                {renderDividerRow('Impact & Claim Info')}
                <Row>
                  <Col md="3">
                    {renderControlledInput(
                      'impact_rate',
                      'Impact rate',
                      'number'
                    )}
                  </Col>
                  <Col md="1" />
                  <Col md="3">
                    {renderControlledInput('impact_result', 'Impact Result')}
                  </Col>
                  <Col md="1" />
                  <Col md="3">
                    {renderControlledInput('rem_action', 'Remediation action')}
                  </Col>
                </Row>
                <Row className={'mt-2'}>
                  <Col md="3">
                    {renderControlledInput('claim_no', 'Claim#')}
                  </Col>
                  <Col md="1" />
                  <Col md="3">
                    {renderControlledInput(
                      'claim_wt_rcvry',
                      'Claim with Recovery',
                      'number'
                    )}
                  </Col>
                  <Col md="1" />
                  <Col md="3">
                    {renderControlledInput(
                      'claim_wo_rcvry',
                      'Claim without Recovery',
                      'number'
                    )}
                  </Col>
                </Row>
                {renderDividerRow()}
                <Row className={'ml-1 mr-1'}>
                  <Col md="6">
                    <Row>
                      <div style={{ minWidth: '500px' }} className={'mt-4'}>
                        {renderProvince()}
                      </div>
                    </Row>
                    <Row className={'mt-3'}>{renderConvictions()}</Row>
                    {/* <Row className={'mt-3'}>{renderConvictions()}</Row>
                    <Row className={'mt-3'}>{renderConvictions()}</Row> */}
                  </Col>
                  <Col md="6">{renderFileUploader()}</Col>
                </Row>
              </Container>
              <div
                className={'make-flex mt-3 mr-2'}
                style={{ flexDirection: 'row-reverse', background: 'white' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      label="Cancel"
                      onClick={navigateToAccidentSummaryScreen}
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      type="reset"
                      label="Reset"
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '170px' }}>
                    <Button
                      loading={isLoading}
                      icon={'pi pi-plus'}
                      type="submit"
                      label={isEdit ? 'Edit Accident' : 'Create Accident'}
                      className="mt-2 p-button-raised"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccident;
