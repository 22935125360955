import Cookies from 'universal-cookie';
import { getApiAsyn } from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { API_URL } from '../../../constants/urlConstants';
import { switchAccountService } from '../..';

const cookies = new Cookies();

export const fetchUserInfo = async () => {
  const url =  API_URL.PROFILE.FETCH_USER_INFO;
  
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));
    if (result?.error) {
      throw new APIError(result);
    }
    const isUserEligibleForAccountSwitch = await switchAccountService.isUserEligibleForAccountSwitch() || false
    return { ...result, isUserEligibleForAccountSwitch };
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchUserCompanyInfo = async () => {
  const url =  API_URL.PROFILE.FETCH_USER_COMPANY_INFO;
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};