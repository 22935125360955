/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Col, Row, Container } from 'react-bootstrap';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Divider } from 'primereact/divider';
import { ConfirmDialog } from 'primereact/confirmdialog';
import EzControlledInputWithFloatingLabel from '../../../common/EzForm/EzControlledInput/WithFloatingLabel';
import EzControlledCalendarWithFloatingLabel from '../../../common/EzForm/EzControlledCalendar/WithFloatingLabel';
import './index.css';
import { EzToast, TOAST_SEVERITY } from '../../../common/EzToast';
import { getFormBaseValidationRule } from '../../../utils/form/form-utils';
import EzUploadAttachmentDialog from '../../../EzUploadAttachmentDialog';
import EzDialog from '../../../common/EzDialog';
import Ownertype from '../../../containers/common/Ownertype';
import DownloadAttachment from '../../DownloadAttachment';
import TypeDropdown from '../../../containers/common/TypeDropdown';
import { API_URL } from '../../../constants/urlConstants';
import { buildName } from '../../../utils/string';
import { OWNER_TYPES_CODE, OWNER_TYPE_CODE_MAP_TO_OWNER_TYPE } from '../../../Fleet/Trips/constants';

const STATUS_DROPDOWN = {
  OPEN: 2483,
  CLOSED: 2484
}

const enabledOwnerTypeOptions = [OWNER_TYPES_CODE.CST, OWNER_TYPES_CODE.EMP, OWNER_TYPES_CODE.SPLR];
const RECORD_DELETION_POPUP = {
  TEXT_MESSAGE: 'Record will be deleted. Do you wish to continue?',
};

const FORM_FIELDS = {
  returned_qty: 'returned_qty',
  issued_qty: 'issued_qty'
}

const  getLoggedInUserFullName = (loggedInUserInfo) =>{
  return  buildName(loggedInUserInfo);
}

const CreateInventoryTracker = (props) => {
  const {
    navigateToInventoryTrackerSummaryScreen,
    createInventoryTracker,
    editInventoryTracker,
    inventoryTrackerId,
    isLoading,
    isEdit,
    formFieldValueMap,
    loggedInUserInfo,
    isInventoryTrackerDetailSuccess,
    isCreateInventoryTrackerSuccess,
    isEditInventoryTrackerSuccess,
    shouldBlockNavigation,
    fetchFilesByInventoryTrackerId,
    downloadAllAttachmentByInventoryTrackerId,
    ownerTypes
  } = props;

  const [deleteConfirmationPopupVisibility, setDeleteConfirmationPopupVisibility] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [itemIndexAttachment, setItemIndexAttachmentToShow] = useState();
  const [showUploadAttachmentDialog, setShowUploadAttachmentDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);
  const [itemStatusFieldDisabled, setItemStatusFieldDisabled] = useState(true);

  const onMoreActionMenu = (event, currentRowData) => {
    setShowDialog(true);
    document.dispatchEvent(new MouseEvent("customContextmenu", event))
    setCurrentRowData(currentRowData)
  }
  const {
    control,
    formState: { errors, isDirty },
    resetField,
    watch,
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return {rental_status: STATUS_DROPDOWN.OPEN};
    }, [formFieldValueMap]),
  });
  
  useEffect( () => {  
      (resetField('issuer', { defaultValue: getLoggedInUserFullName(loggedInUserInfo) }))
  }, [loggedInUserInfo])
  
  const [itemtodelete, setItemtodelete] = useState();
  const { fields, append, update } = useFieldArray({
    control,
    name: "items"
  });
  const watchFieldArray = watch("items");

  useEffect(() => {
    const itemStatusDisabled = watchFieldArray?.some(({item_status}) => item_status === STATUS_DROPDOWN.OPEN )
    if(watchFieldArray && watchFieldArray.length !== 0){
      setItemStatusFieldDisabled(itemStatusDisabled);
    }

  },[watchFieldArray])
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });

  const handleRemoveClick = (i) => {
    const updatedData = controlledFields.map((item, index) => (
      i === index ? { ...item, status: false } : { ...item }
    ))
    update(i, { status: false, ...updatedData[i] })
  }
  

  const onDialogHide = () => {
    const getStatus = removedFilePresent => removedFilePresent ? 'N' : 'Y';
    const rFiles = removedFiles[itemIndexAttachment]?.filter(({ id }) => !!id);
    const updatedData = controlledFields.map((item, index) => (
      itemIndexAttachment === index ?
        {
          ...item,
          files: attachedFiles[itemIndexAttachment]?.map(({ id, name }) => ({ id, name, status: getStatus(rFiles?.some((file) => file.id === id)) }))
        }
        : { ...item }
    ))
    update(itemIndexAttachment, { ...updatedData[itemIndexAttachment] });

    setShowUploadAttachmentDialog(false);
  }
  
  
  useEffect(() => {
                  if (isCreateInventoryTrackerSuccess || isEditInventoryTrackerSuccess ){ 
                    shouldBlockNavigation(false)
                  }
                  else shouldBlockNavigation(isDirty)
                }, [isDirty, isCreateInventoryTrackerSuccess, isEditInventoryTrackerSuccess ]);
  const issued_to_id_ref = useRef(null);
  useEffect(() => {
    if(isInventoryTrackerDetailSuccess){
      issued_to_id_ref.current = formFieldValueMap?.issued_to?.id 
      reset({
        ...formFieldValueMap,
        type: ownerTypes?.find(({ extraData }) => extraData.code === formFieldValueMap.type).label,
        issued_to: formFieldValueMap?.issued_to?.first || formFieldValueMap?.issued_to?.name,
        rental_status: formFieldValueMap?.rental_status?.id, 
        issuer: getLoggedInUserFullName(loggedInUserInfo),
        items: formFieldValueMap?.items.map( item => ({
          ...item,
          item_status: item.item_status.id,
          issued_date: new Date(item.issued_date),
          returned_date: new Date(item.returned_date)
        }) )
      })
      const attachedFilesFromServer = formFieldValueMap.items.reduce((acc, { files }, index) => {
        acc[index] = files;
        return acc;
      }, [])

      setAttachedFiles(attachedFilesFromServer)
    }
  }, [isInventoryTrackerDetailSuccess])

  useEffect(() => {
    if (isCreateInventoryTrackerSuccess || isEditInventoryTrackerSuccess) {
      reset();
    }
  }, [isCreateInventoryTrackerSuccess]);
  
  
  const onSubmit = (data) => {  
    const errorInItemRecord = data?.items?.some(({returned_qty, issued_qty, item_status, issued_date, returned_date }) => {
      if(returned_qty > issued_qty){
        setToastMessage(" return quantity cannot be more than issued quantity");
        setErrorToast(true);
        return true
      } 
      else if(item_status === STATUS_DROPDOWN.CLOSED && parseFloat(issued_qty) !== parseFloat(returned_qty)){
        setToastMessage("return quantity cannot be more than issued quantity");
        setErrorToast(true);
        return true;
      }
      else if(new Date(issued_date).getTime() > new Date(returned_date).getTime()){
        setToastMessage("return Date should come later than start Date");
        setErrorToast(true);
        return true
      }

      return false
    })

    if(errorInItemRecord) return;
    if (data?.items.length === 0) {
      setToastMessage("Can not create inventory tracker without items")
      setErrorToast(true);
      return
    }
    else {
      const {
        type,
        rental_status,
        items,
        issued_to
      } = data;
      const issued_to_id = issued_to?.extraData?.id || issued_to_id_ref.current || null
      const payload = {
        id: inventoryTrackerId || 0,
        type: enabledOwnerTypeOptions.includes(type) ? OWNER_TYPE_CODE_MAP_TO_OWNER_TYPE[type] : type,
        issued_customer:[OWNER_TYPES_CODE.CST, "CST"].includes(type) ?  issued_to_id : null ,
        issued_supplier:[OWNER_TYPES_CODE.SPLR, "SPLR"].includes(type) ? issued_to_id : null,
        issued_employee:[OWNER_TYPES_CODE.EMP, "EMP"].includes(type) ? issued_to_id : null,
        issuer: loggedInUserInfo.id,
        rental_status,
        files: removedFiles,
        internal: true,
        items: items.map(({
          id,
          name,
          desc, 
          issued_qty,
          issued_date,
          returned_date,
          returned_qty,
          item_status,
          comments,
          status,
          ...item
        }) => ({
            ...item,
            id,
            name,
            desc,
            issued_qty,
            issued_date: new Date(issued_date).getTime(),
            returned_date: new Date(returned_date).getTime(),
            returned_qty,
            item_status,
            pending_qty: issued_qty - returned_qty,
            comments,
            status: status === false ? status : true
        }))
      }
      
      const allFiles = attachedFiles
        .reduce((collector, attachedFiles) => {
          attachedFiles.forEach(file => collector.push(file))
          return collector;
        }, [])

      if (isEdit) {
        editInventoryTracker({
          inventoryTrackerId,
          data: payload,
          files: allFiles
        });
      } else {
        createInventoryTracker({
          data: payload ,
          files: allFiles
        });
      }
    }
  };
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const renderPageHeaderSection = () => {
    const breadCrumbs = [
      { label: 'InventoryTrackers' },
      { label: isEdit ? 'Edit Inventory Tracker' : 'Create Inventory Tracker' },
    ];
    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />
        <div className="rightbtn">
          <Button
            tooltip={'Back to Inventory Tracker summary'}
            tooltipOptions={{ position: 'left' }}
            label={''}
            icon={'pi pi-arrow-left'}
            className={`mr-3 p-button-rounded p-button-outlined p-button-info`}
            onClick={navigateToInventoryTrackerSummaryScreen}
          />
        </div>
      </div>
    );
  };
  const handleShowItemModal = (index) => {
    setItemtodelete(index)
    setDeleteConfirmationPopupVisibility(true)
  }
  const handleShowUploadAttachmentDialog = (index) => {
    setShowUploadAttachmentDialog(true);
    setItemIndexAttachmentToShow(index)
    attachedFiles[index] = attachedFiles[index] ?? [];
    setAttachedFiles(attachedFiles)
  }
  //Adding Item
  const handleAddClick = () => {
    append({
      id: 0,
      name:'',
      returned_date: '',
      returned_qty: '',
      issued_date: '',
      issued_qty: '',
      item_status: '',
      desc: '',
      comments: '',
      status:true
    })
  };
  //End with Items

  const renderIssuedTo = () => {
    const name = 'type';
    
    return (
      <Ownertype
        name = {name}
        control = {control}
        resetField={resetField} 
        rules = {getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        optionLabel={'label'}
        optionValue={'extraData.code'}
        errorMsg={getFormErrorMessage(name)}
        enabledOwnerTypeOptions={enabledOwnerTypeOptions}
        ownerField='issued_to'
        label = "Issued to"
      />
    );
  };
  
  const renderStatus = () => {
    const name = 'rental_status';
    return (
      <TypeDropdown
        disabled={itemStatusFieldDisabled}
        name = {name}
        control = {control}
        rules = {getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label = "Status"
        dropdownType={API_URL.TYPES.RENTAL_STATUS} 
       />
    );
  };
  const renderIssuedBy = () => {
    const name = 'issuer';
    return (
      <EzControlledInputWithFloatingLabel
        disabled
        name={name}
        {...register("issuer")}
        control={control}
        label={'Issued by'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  // const renderFileUploader = () => {
  //   const onSelectFile = (e, field) => {
  //     setAttachedFiles([...e.files]);
  //   };
  
  //   const onRemoveFile = (fileTobeRemoved) => {
  //     removedFiles.push(fileTobeRemoved)
  //     setRemovedFiles(removedFiles)
  //   }
    
  //   return (
  //     <EzFileUpload
  //       files={attachedFiles}
  //       onSelectFile={onSelectFile}
  //       onRemoveFile={onRemoveFile}
  //     />
  //   );
  // };

  const renderDividerRow = (label, align = 'center', type = 'solid') => {
    return (
      <Row>
        <Divider align={align} type={type}>
          <b>{label || ''}</b>
        </Divider>
      </Row>
    );
  };
  const renderItem = (index, id) => {
    const refNumber = errors?.items?.[index]?.name;
    return (
      <EzControlledInputWithFloatingLabel
        id={id}
        {...register(`items.${index}.name`)}
        control={control}
        label='item'
        isError={refNumber}
        errorMsg={getFormErrorMessage(refNumber)}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
      />
    );
  };

  const renderDescription = (index, id) => {
    const refNumber = errors?.items?.[index]?.desc;
    return (
      <EzControlledInputWithFloatingLabel
        id={id}
        {...register(`items.${index}.desc`)}
        control={control}
        label='Description'
        isError={refNumber}
        errorMsg={getFormErrorMessage(refNumber)}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
      />
    );
  };

  const renderIssueQty = (index, id) => {
    const refNumber = errors?.items?.[index]?.issued_qty;
    return (
      <EzControlledInputWithFloatingLabel
        id={id}
        {...register(`items.${index}.issued_qty`)}
        control={control}
        label='Issued qty'
        isError={refNumber}
        errorMsg={getFormErrorMessage(refNumber)}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
      />
    );
  };

  const renderReturnQty = (index, id) => {
    const refNumber = errors?.items?.[index]?.returned_qty;
    return (
      <EzControlledInputWithFloatingLabel
        id={id}
        {...register(`items.${index}.${FORM_FIELDS.returned_qty}`)}
        control={control}
        label='Return Qty'
        isError={refNumber}
        errorMsg={getFormErrorMessage(refNumber)}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
      />
    );
  };

  const renderComment = (index, id) => {
    const refNumber = errors?.items?.[index]?.comments;
    return (
      <EzControlledInputWithFloatingLabel
        id={id}
        {...register(`items.${index}.comments`)}
        control={control}
        label='Comments'
        isError={refNumber}
        errorMsg={getFormErrorMessage(refNumber)}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
      />
    );
  };


  const renderIssuedDate = (index, id) => {
    const itemDate = errors?.items?.[index]?.issued_date;
    return (
      <EzControlledCalendarWithFloatingLabel
        id={id}
        name={`items.${index}.issued_date`}
        {...register(`items.${index}.issued_date`)}
        showTime={false}
        maxDate={new Date(2090, 0, 1)}
        control={control}
        isError={itemDate}
        errorMsg={getFormErrorMessage(itemDate)}
        label={'Issued date'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
      />
    );
  };

  const renderReturnDate = (index, id) => {
    const itemDate = errors?.items?.[index]?.returned_date;
    return (
      <EzControlledCalendarWithFloatingLabel
        id={id}
        name={`items.${index}.returned_date`}
        {...register(`items.${index}.returned_date`)}
        showTime={false}
        maxDate={new Date(2090, 0, 1)}
        control={control}
        isError={itemDate}
        errorMsg={getFormErrorMessage(itemDate)}
        label={'Return date'}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
      />
    );
  };
  const updateItemLevelStatus = (itemIndex, itemStatus) => {
    const updatedData = controlledFields.map((item, index) => (
      itemIndex === index ?
        {
          ...item,
          item_status: itemStatus
        }
        : { ...item }
    ))
    update(itemIndex, { ...updatedData[itemIndex] });
  }

  const renderStatusItem = (index, id) => {
    const itemStatus = errors?.items?.[index]?.item_status;
    return (
      <TypeDropdown
        id={id}
        control={control}
        name={`items.${index}.item_status`}
        {...register(`items.${index}.item_status`)}
        isError={itemStatus}
        onChange={({value}) => updateItemLevelStatus(index, value)}
        errorMsg={getFormErrorMessage(itemStatus)}
        label={'Status'}
        labelClassName={'ml-2'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        dropdownType={API_URL.TYPES.RENTAL_STATUS} 
      />
    );
  };

 const renderUploadAttachmentDialog = () => {

    const onSelectFile = (e, field) => {
      attachedFiles[itemIndexAttachment] = [...attachedFiles[itemIndexAttachment], ...e.files];
      setAttachedFiles(attachedFiles);
    };

    const onRemoveFile = (fileTobeRemoved) => {
      if (fileTobeRemoved.id !== undefined) {
        removedFiles[itemIndexAttachment] = removedFiles[itemIndexAttachment] ?? [];
        removedFiles[itemIndexAttachment].push(fileTobeRemoved)
        setRemovedFiles(removedFiles);
      } else {
        attachedFiles[itemIndexAttachment] = attachedFiles[itemIndexAttachment]
          .filter(({ objectURL }) => objectURL !== fileTobeRemoved.objectURL);
        setAttachedFiles(attachedFiles);
      }

    }

    return <EzUploadAttachmentDialog
      visible={showUploadAttachmentDialog}
      setShowUploadAttachmentDialog={setShowUploadAttachmentDialog}
      onSelectFile={onSelectFile}
      onRemoveFile={onRemoveFile}
      files={attachedFiles?.[itemIndexAttachment] || []}
      onDialogHide={onDialogHide}
    />
  }

  const [toastMessage, setToastMessage] = useState('');
  const [showErrorToast, setErrorToast] = useState(false);

  const getToastProps = () => {
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle: toastMessage,
      shouldShowToast: true,
    };
  };
  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrorToast(true);
    }
  }, [errors]);
  const onDialogBoxHide = () => setShowDialog(false)
  const getDialog = () => {
    const attachmentProps = {
      itemId: inventoryTrackerId,
      dataId: currentRowData?.id,
      fetchAttachmentsById: fetchFilesByInventoryTrackerId,
      downloadAllAttachments: downloadAllAttachmentByInventoryTrackerId,

    }
    return showDialog &&
      <EzDialog onHide={onDialogBoxHide} header={'Attachment'}>
        <DownloadAttachment {...attachmentProps} />
      </EzDialog>
  }
  return (
    <div className="paddingbox">
      <div
        className={classNames('containerboxform', { 'p-disabled': isLoading })}
      >
        {renderPageHeaderSection()}
        <div
          className="form-demo"
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
        >
          <div className="card" style={{ background: '#f3faf4BF' }}>
            {showErrorToast && <EzToast {...getToastProps()} />}
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid compliaces">
              <Container fluid>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="6">{renderIssuedTo()}</Col>
                  <Col md="3">{renderIssuedBy()}</Col>
                  <Col md="3">{renderStatus()}</Col>
                </Row>
                {/* <Row style={{ minWidth: '20px' }} className='itemrow'>
                  <Col md="6" />
                  <Col md="6">{renderFileUploader()}</Col>
                </Row> */}
                <Row style={{ minWidth: '20px' }}>
                  <div style={{ margin: '15px 20px 30px', maxWidth: '270px' }}>
                    <Button
                      icon={'pi pi-plus'}
                      type="button"
                      onClick={handleAddClick}
                      label={'Add Inventory Tracker Item'}
                      className="mt-2 p-button-raised"
                    />
                  </div>
                </Row>
                {controlledFields.map((field, index) => {
                  return (
                    field.status !== false ?
                      <div className='itemrowdata'>
                        {renderDividerRow(field?.name)}
                        <div style={{ marginTop: '40px'}}>
                        {
                          (<Button
                            style={{ marginRight: '50px' }}
                            icon="pi pi-download"
                            type="button"
                            className={`custompos2 p-button p-button-sm p-button-raised p-component p-button-rounded p-button-text p-button-icon-only`}
                            onClick={(event) => onMoreActionMenu(event, field)} />)
                        }
                        <Button
                          style={{ marginRight: '50px' }}
                          icon="pi pi-paperclip"
                          type="button"
                          className={`custompos p-button p-button-sm p-button-raised p-component p-button-rounded p-button-text p-button-icon-only`}
                          onClick={() => handleShowUploadAttachmentDialog(index)}
                        />

                        <Button
                          icon="pi pi-trash"
                          type="button"
                          className={`custompos p-button p-button-sm p-button-raised p-component p-button-rounded p-button-text p-button-danger p-button-icon-only`}
                          onClick={() => handleShowItemModal(index)}
                        />
                        </div>
                        
                        <Row className='itemrow'>
                          <Col md="1">
                            {renderItem(index, field?.id)}
                          </Col>
                          <Col md="2">
                            {renderComment(index, field?.id)}
                          </Col>
                          <Col md="2">
                          {renderIssuedDate(index, field?.id)}
                          </Col>
                          <Col md="1">
                          {renderIssueQty(index, field?.id)}
                            
                          </Col>
                        {/* </Row> */}
                        {/* <Row className='itemrow'> */}
                          <Col md="2">
                          {renderReturnDate(index, field?.id)}
                          </Col>
                          <Col md="1">
                          {renderReturnQty(index, field?.id)}
                          </Col>
                          <Col md="2">
                          {renderDescription(index, field?.id)}
                          </Col>
                          <Col md="1">
                          {renderStatusItem(index, field?.id)}
                            
                          </Col>
                        </Row>
                      </div>
                      : null
                  )
                })}
              </Container>

              {getDialog()}
              <div
                className={'make-flex mt-3 mr-2'}
                style={{ flexDirection: 'row-reverse', background: 'white' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      label="Cancel"
                      onClick={navigateToInventoryTrackerSummaryScreen}
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      type="reset"
                      label="Reset"
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '170px' }}>
                    <Button
                      loading={isLoading}
                      icon={'pi pi-plus'}
                      type="submit"
                      label={isEdit ? 'Edit Inventory Tracker' : 'Create Inventory Tracker'}
                      className="mt-2 p-button-raised"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ConfirmDialog
        visible={deleteConfirmationPopupVisibility}
        message={RECORD_DELETION_POPUP.TEXT_MESSAGE}
        header="Please Confirm Deletion"
        position={'top'}
        onHide={() => setDeleteConfirmationPopupVisibility(false)}
        acceptLabel={'Cancel'}
        rejectLabel={'Delete Record'}
        acceptClassName={'p-button-raised'}
        rejectClassName={'p-button-text p-button-danger'}
        reject={() => {
          handleRemoveClick(itemtodelete);
        }}
        accept={() => setDeleteConfirmationPopupVisibility(false)}
      />
      {renderUploadAttachmentDialog()}
    </div>
  );
};
export default CreateInventoryTracker;
