import { getApiAsynBlobZip } from "../../PostAPI";
import Cookies from "universal-cookie";


export  const getAllAttachment = async (url, payload) => {
    const { itemId } = payload;
    const cookies = new Cookies()
    let res = await getApiAsynBlobZip(
      `${url}/${itemId}/zip`,
      cookies.get('authorization')
    );
    var blob = new Blob([res], { type: 'application/zip' });
  
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'o';
    link.click();
  };
  export  const getAllItemLevelAttachments = async (url, payload) => {
    const { itemId, dataId } = payload;
    const cookies = new Cookies()
    let res = await getApiAsynBlobZip(
      `${url}/${itemId}/item/${dataId}/zip`,
      cookies.get('authorization')
    );
    var blob = new Blob([res], { type: 'application/zip' });
  
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'o';
    link.click();
  };