import React from "react";
import { connect } from "react-redux";
import EditAssetLocation from '../../../components/AssetLocation/EditAssetLocation'
import { selectformFieldValueMap, selectIsAssetLocationDetailError, selectIsAssetLocationDetailSuccess, selectIsLoading } from "../../../Redux/Selectors/assets_new/asset_location";
import { editAssetLocation, fetchAssetLocationByAssetLocationId } from "../../../Redux/Actions/asset-location";
import { useEffect } from "react";

const EditAssetLocationScreen = props => {
  const { 
    assetLocationId,
    fetchAssetLocationByAssetLocationId,
    isAssetLocationDetailSuccess,
    isLoading
    } = props;  

  useEffect(()=> {
    fetchAssetLocationByAssetLocationId({ itemId: assetLocationId });
  },[assetLocationId, fetchAssetLocationByAssetLocationId]);

  return <>{  !isLoading && isAssetLocationDetailSuccess && <EditAssetLocation {...props} /> }</>
}

export const mapStateToProps = state => ({
  formFieldValueMap : selectformFieldValueMap(state),
  isAssetLocationDetailSuccess : selectIsAssetLocationDetailSuccess(state),
  isAssetLocationDetailError: selectIsAssetLocationDetailError(state),
  isLoading: selectIsLoading(state),
});

export const mapDispatchToProps = dispatch => ({
  fetchAssetLocationByAssetLocationId : payload => dispatch(fetchAssetLocationByAssetLocationId(payload)),
  editAssetLocation: payload => dispatch(editAssetLocation(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EditAssetLocationScreen));