import { DEFAULT_TOAST_LIFE } from '../../../common/EzToast';
import {
  INIT_INSPECTIONS,
  FETCH_INSPECTIONS_LIST_ERROR,
  FETCH_INSPECTIONS_LIST_START,
  FETCH_INSPECTIONS_LIST_SUCCESS,
  DELETE_INSPECTION_RECORD_START,
  DELETE_INSPECTION_RECORD_SUCCESS,
  DELETE_INSPECTION_RECORD_ERROR,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_INSPECTION_SCREEN,
  CREATE_INSPECTION_RECORD,
  EDIT_INSPECTION_RECORD,
  FETCH_INSPECTION_DETAIL,
  TOAST,
} from '../../../constants/action-types/inspections';
import { inspectionsService } from '../../../Services';
import { goBack } from '../../../utils/gotoback';
import { fetchEmployees } from '../../Actions/employees';
import {
  attachmentsFilesFetchStart,
  attachmentsFilesFetchSuccess,
  downloadingAllAttachmentComplete,
  downloadingAllAttachmentStart
} from '../common/files';

export const initializeInspectionScreen = () => ({ type: INIT_INSPECTIONS });

export const inspectionsFetchStart = (payload) => ({
  type: FETCH_INSPECTIONS_LIST_START,
  payload,
});

export const inspectionsFetchSuccess = (payload) => ({
  type: FETCH_INSPECTIONS_LIST_SUCCESS,
  payload,
});

export const inspectionsFetchError = (payload) => ({
  type: FETCH_INSPECTIONS_LIST_ERROR,
  payload,
});

export const fetchInspections = (payload) => {
  return (dispatch) => {
    dispatch(inspectionsFetchStart(payload));

    inspectionsService.fetchInspections(payload).then((inspectionsData) => {
      if (!inspectionsData.isError) {
        dispatch(inspectionsFetchSuccess(inspectionsData));
      } else {
        dispatch(inspectionsFetchError(inspectionsData));
      }
    });
  };
};

export const inspectionDetailFetchStart = (payload) => ({
  type: FETCH_INSPECTION_DETAIL.START,
  payload,
});

export const inspectionDetailFetchSuccess = (payload) => ({
  type: FETCH_INSPECTION_DETAIL.SUCCESS,
  payload,
});

export const inspectionDetailFetchError = (payload) => ({
  type: FETCH_INSPECTION_DETAIL.ERROR,
  payload,
});

export const fetchInspectionByInspectionId = (payload) => {
  return (dispatch) => {
    dispatch(inspectionDetailFetchStart(payload));

    inspectionsService
      .fetchInspectionByInspectionId(payload)
      .then((inspectionsData) => {
        if (!inspectionsData.isError) {
          dispatch(inspectionDetailFetchSuccess(inspectionsData));
        } else {
          dispatch(inspectionDetailFetchError(inspectionsData));
        }
      });
  };
};

// delete inspection
export const deleteInspectionRecordStart = () => ({
  type: DELETE_INSPECTION_RECORD_START,
});

export const deleteInspectionRecordSuccess = (payload) => ({
  type: DELETE_INSPECTION_RECORD_SUCCESS,
  payload,
});

export const deleteInspectionRecordError = (payload) => ({
  type: DELETE_INSPECTION_RECORD_ERROR,
  payload,
});

export const deleteInspectionRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteInspectionRecordStart());

    inspectionsService
      .deleteInspectionRecord({ inspectionId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteInspectionRecordSuccess());
          dispatch(fetchInspections(payload?.fetchInspectionParams));
        } else {
          dispatch(deleteInspectionRecordError(data));
        }
      });
  };
};

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const createNewInspectionScreenInitStart = (payload) => ({
  type: INIT_CREATE_NEW_INSPECTION_SCREEN,
  payload,
});

export const initCreateNewInspectionScreen = (payload) => {
  return (dispatch) => {
    dispatch(createNewInspectionScreenInitStart());
    dispatch(fetchEmployees());
  };
};

// create inspection
export const createInspectionRecordStart = () => ({
  type: CREATE_INSPECTION_RECORD.START,
});

export const createInspectionRecordSuccess = (payload) => ({
  type: CREATE_INSPECTION_RECORD.SUCCESS,
  payload,
});

export const createInspectionRecordError = (payload) => ({
  type: CREATE_INSPECTION_RECORD.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createInspection = (payload) => {
  return (dispatch) => {
    dispatch(createInspectionRecordStart());
    inspectionsService.createInspectionRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(createInspectionRecordSuccess());
        goBack()
      } else {
        dispatch(createInspectionRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editInspectionRecordStart = () => ({
  type: EDIT_INSPECTION_RECORD.START,
});

export const editInspectionRecordSuccess = (payload) => ({
  type: EDIT_INSPECTION_RECORD.SUCCESS,
  payload,
});

export const editInspectionRecordError = (payload) => ({
  type: EDIT_INSPECTION_RECORD.ERROR,
  payload,
});

export const editInspection = (payload) => {
  return (dispatch) => {
    dispatch(editInspectionRecordStart());
    inspectionsService.editInspectionRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(editInspectionRecordSuccess());
        goBack()
      } else {
        dispatch(editInspectionRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


export const inspectionsFetchFilesByInspectionId = payload => async dispatch => {
  dispatch(attachmentsFilesFetchStart(payload));
  const {files} = await inspectionsService.fetchInspectionsAttachmentByInspectionId(payload);
  
  if(files) dispatch(attachmentsFilesFetchSuccess(files));
}

export const downloadAllAttachmentForInspectionId =  (payload) =>  async (dispatch) =>{
  dispatch(downloadingAllAttachmentStart())
  await inspectionsService.downloadAllAttachment(payload);
  dispatch(downloadingAllAttachmentComplete());
}