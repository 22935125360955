import { useEffect } from "react";
import { connect } from "react-redux";
import { EzToast } from "../../common/EzToast";
import { resetToast } from "../../Redux/Actions/common/notification";
import {  getToastSeverity,
          getToastTitle,
          getShouldShowToast
 } from "../../Redux/Selectors/notification";

const ToastContainer = props => {
  const { toastTitle, toastBody, shouldShowToast, severity, resetToast } = props
  
  const ezToastProps = {
    toastTitle,
    toastBody,
    shouldShowToast,
    severity
  }
  useEffect(()=> resetToast());

  return <EzToast { ...ezToastProps } />
}


const mapDispatchToProps = dispatch => ({
  resetToast: () => dispatch(resetToast())
})

const mapStateToProps = state => ({
  severity: getToastSeverity(state),
  toastTitle: getToastTitle(state),
  shouldShowToast: getShouldShowToast(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ToastContainer);