import React, { useState, useEffect } from 'react';
import {
  validateEmail,
  loadierShow,
  validationForSpecialchar,
  getApiAsyn,
  baseUrls,
} from '../Services/PostAPI';
import './Profile.css';
import Cookies from 'universal-cookie';
import SnackbarError from '../common/SnackbarError';
import SnackbarSuccess from '../common/SnackbarSuccess';
import { Col, Row, Form, Button } from 'react-bootstrap';
import IMG_CAM from '../images/camera.png';
import IMG_DEFAULT_PROFILE from '../images/default_user_profile.jpg';
import DatePicker from 'react-datepicker';
import SelectSearch from 'react-select-search';
import { put } from 'axios';
const cookies = new Cookies();
export default function Profile(props) {
  const [formOption, setformOption] = useState({});
  const [profilevalue, setprofilevalue] = useState({});

  const [profileimg, setProfileimg] = useState();
  const [getprofileimg, setgetprofileimg] = useState();
  const [errors, seterrors] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const handleChangeGen = (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    setprofilevalue({ ...profilevalue, [fieldName]: fleldVal });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (
      !profilevalue['first'] ||
      validationForSpecialchar(profilevalue['first'])
    ) {
      formIsValid = false;
      errors['first'] = 'This is a mandatory field with valid character.';
      setformOption({
        errorBoll: false,
      });
    } else if (
      !profilevalue['last'] ||
      validationForSpecialchar(profilevalue['last'])
    ) {
      formIsValid = false;
      errors['last'] = 'This is a mandatory field with valid character.';
    } else if (
      !profilevalue['email'] ||
      !validateEmail(profilevalue['email'])
    ) {
      formIsValid = false;
      errors['email'] = 'This is a mandatory field with valid email id.';
    } else if (
      !profilevalue['address1'] ||
      validationForSpecialchar(profilevalue['address1'])
    ) {
      formIsValid = false;
      errors['address1'] = 'This is a mandatory';
    }

    seterrors(errors);
    return formIsValid;
  };
  const handleSave = () => {
    if (handleValidation()) {
      let imgData =
        (profileimg && profileimg[0].name) || profilevalue.profile_img;
      let reqData = {
        profile_id: profilevalue.profile_id,
        title: profilevalue.title,
        first: profilevalue.first,
        middle: profilevalue.middle,
        last: profilevalue.last,
        dob:
          profilevalue.dob != null
            ? profilevalue.dob?.getTime()
            : null,
        email: profilevalue.email,
        phone: profilevalue.phone,
        phone2: profilevalue.phone2,
        address1: profilevalue.address1,
        country: profilevalue.country,
        state: profilevalue.state,
        city: profilevalue.city,
        postal_code: profilevalue.postal_code,

        profile_img: imgData,
      };
      const formPayload = new FormData();
      const json = JSON.stringify(reqData);
      const order = new Blob([json], {
        type: 'application/json',
      });
      formPayload.append('data', order);
      if (profileimg !== undefined) {
        formPayload.append('file', profileimg[0]);
      }
      loadierShow('block');
      fileUpload(formPayload).then((response) => {
        console.log('Profile', response);
        loadierShow('none');
      });
    }
  };
  const handleDate = (date, name) => {
    let fieldName = name;
    let fleldVal = new Date(date);
    setprofilevalue({ ...profilevalue, [fieldName]: fleldVal });
  };

  const handleSeachableDropdonw = (val, name) => {
    let fieldName = name;
    let fleldVal = val;
    profilevalue[fieldName] = fleldVal;
    setprofilevalue(profilevalue);
    if (name === 'country') {
      getState(fleldVal);
    }
    if (name === 'state') {
      getCity(profilevalue.country, fleldVal);
    }
  };

  const getCountry = async () => {
    let ddCoounty = [];
    let res = await getApiAsyn(`countries`, cookies.get('authorization'));
    ddCoounty = res;
    ddCoounty = ddCoounty.map((item) => ({
      name: item.value,
      value: item.key,
    }));
    setCountryList(ddCoounty);
  };

  const getState = async (id) => {
    let ddState = [];
    let res = await getApiAsyn(
      `countries/${id}/states`,
      cookies.get('authorization')
    );
    if (res.length > 0) {
      ddState = res;
      ddState = ddState.map((item) => ({
        name: item.value,
        value: item.key,
      }));

      setStateList(ddState);
    }
  };

  const getCity = async (id, cid) => {
    let ddCity = [];
    let res = await getApiAsyn(
      `countries/${id}/states/${cid}/cities`,
      cookies.get('authorization')
    );
    if (res.length > 0) {
      ddCity = res;
      ddCity = ddCity.map((item) => ({
        name: item.value,
        value: item.key,
      }));
      setCityList(ddCity);
    }
  };

  const selectFiles = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setProfileimg(selectedFiles);
  };

  const getProfile = async () => {
    let res = await getApiAsyn(`profile/user`, cookies.get('authorization'));
    if (res.APIResult === 1) {
      if (res.dob != null) {
        //let date = getFormattedDate(res.dob,'yyyy-MM-DD',true)
        res.dob = new Date(res.dob);
      }
      setprofilevalue(res);
      setgetprofileimg(res.profile_img);
      await getState(res.country?.key);
      await getCity(res.country?.key, res.state?.key);
    }
  };

  const fileUpload = (reqData) => {
    const url = baseUrls + 'profile/user';
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: cookies.get('authorization'),
      },
    };
    return put(url, reqData, config);
  };

  useEffect(() => {
    /*eslint-disable */
    getCountry();
    getProfile();
  }, []);

  const profileimgFile =
    profileimg && profileimg.length
      ? URL.createObjectURL(profileimg[0])
      : false;
  const profileImage = profileimgFile || getprofileimg || IMG_DEFAULT_PROFILE;
  return (
    <div>
      <SnackbarError
        errorBoll={formOption.errorBoll}
        errorMessage={formOption.errorMessage}
      />
      <SnackbarSuccess
        legalBoll={formOption.legalBoll}
        successMessage={formOption.successMessage}
      />

      <div className="paddingbox">
        <div className="containerboxform">
          <div className="title bdrb">
            <h3>User Profile</h3>
          </div>

          <div className="userbox form">
            <Row>
              <Col md="2" className="text-right">
                <div className="profileimg">
                  <label htmlFor="file" className="imgicon">
                    <img src={IMG_CAM} alt="Click to Change" />
                  </label>
                  <Form.Control
                    type="file"
                    name="file"
                    id="file"
                    onChange={selectFiles}
                  />
                  <img
                    src={profileImage}
                    className="imageview"
                    alt="Click to Change"
                  />
                </div>
              </Col>
              <Col md="5">
                <Row>
                  <Col md="12">
                    <Form.Group as={Row}>
                      <Form.Label column sm="4" className="text-right">
                        First Name*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="first"
                          value={profilevalue.first}
                          onChange={handleChangeGen}
                        />
                        <span
                          className={
                            errors['first'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['first']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <Form.Group as={Row}>
                      <Form.Label column sm="4" className="text-right">
                        Last Name*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="last"
                          value={profilevalue.last}
                          onChange={handleChangeGen}
                        />
                        <span
                          className={
                            errors['last'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['last']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Form.Group as={Row}>
                      <Form.Label column sm="4" className="text-right">
                        Email Id*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="email"
                          value={profilevalue.email}
                          onChange={handleChangeGen}
                        />
                        <span
                          className={
                            errors['email'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['email']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Form.Group as={Row}>
                      <Form.Label column sm="4" className="text-right">
                        Primary Phone
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="phone"
                          value={profilevalue.phone}
                          onChange={handleChangeGen}
                        />
                        <span
                          className={
                            errors['phone'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['phone']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Form.Group as={Row}>
                      <Form.Label column sm="4" className="text-right">
                        Alternate Phone
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          placeholder="Alternate Phone"
                          name="phone2"
                          value={profilevalue.phone2}
                          onChange={handleChangeGen}
                        />
                        <span
                          className={
                            errors['phone2'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['phone2']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md="5">
                <Row>
                  <Col md="12">
                    <Form.Group as={Row}>
                      <Form.Label column sm="4" className="text-right">
                        Middle Name
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="middle"
                          value={profilevalue.middle}
                          onChange={handleChangeGen}
                        />
                        <span
                          className={
                            errors['middle'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['middle']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <Form.Group as={Row}>
                      <Form.Label column sm="4" className="text-right">
                        Date Of Birth
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          selected={profilevalue.dob}
                          dateFormat="yyyy-MM-dd"
                          className="form-control inpcalendar"
                          popperPlacement="start"
                          name="dob"
                          autoComplete="off"
                          value={profilevalue.dob}
                          onChange={(date) => handleDate(date, 'dob')}
                        />
                        <span
                          className={
                            errors['dob'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['dob']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <Form.Group as={Row}>
                      <Form.Label column sm="4" className="text-right">
                        Address*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="address1"
                          value={profilevalue.address1}
                          onChange={handleChangeGen}
                        />
                        <span
                          className={
                            errors['address1'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['address1']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm="6">
                    <SelectSearch
                      container="rowselect"
                      search
                      options={countryList}
                      select="test"
                      name="country_id"
                      autoComplete="sdf"
                      onChange={(val) =>
                        handleSeachableDropdonw(val, 'country')
                      }
                      value={profilevalue.country?.key}
                      placeholder="Select Country"
                    />
                    <span
                      className={
                        errors['country_id'] ? 'errorshow' : 'errorshide'
                      }
                    >
                      {errors['country_id']}
                    </span>
                  </Col>
                  <Col sm="6">
                    <SelectSearch
                      container="rowselect"
                      search
                      options={stateList}
                      select="test"
                      name="state_id"
                      autoComplete="sdf"
                      onChange={(val) =>
                        handleSeachableDropdonw(val, 'state')
                      }
                      value={profilevalue.state?.key}
                      placeholder="Select State"
                    />
                    <span
                      className={
                        errors['state_id'] ? 'errorshow' : 'errorshide'
                      }
                    >
                      {errors['state_id']}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col sm="6">
                    <SelectSearch
                      container="rowselect"
                      autoComplete="sdf"
                      search
                      options={cityList}
                      select="test"
                      name="city"
                      onChange={(val) =>
                        handleSeachableDropdonw(val, 'city')
                      }
                      value={profilevalue.city?.key}
                      placeholder="Select City"
                    />
                    <span
                      className={errors['city_id'] ? 'errorshow' : 'errorshide'}
                    >
                      {errors['city_id']}
                    </span>
                  </Col>
                  <Col sm="6">
                    <Form.Control
                      name="postal_code"
                      placeholder="Zip/Postal code"
                      value={profilevalue.postal_code}
                      onChange={handleChangeGen}
                    />
                    <span
                      className={
                        errors['postal_code'] ? 'errorshow' : 'errorshide'
                      }
                    >
                      {errors['postal_code']}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="text-right">
                <Button className="secondary-button">Reset</Button>
                <Button
                  onClick={
                    props.match.params.id !== undefined
                      ? handleSave
                      : handleSave
                  }
                  className="primary-button"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}
