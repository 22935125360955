import { GET_TAX_TYPE } from '../../Type/ReimbursementType';

const initalState = {
  loading: false,
  taxList: [],
  error: {},
};

export const taxReducer = (state = initalState, action) => {
  switch (action.type) {
    case GET_TAX_TYPE:
      return {
        ...state,
        loading: false,
        taxList: action.payload,
        error: {},
      };
    default:
      return state;
  }
};
