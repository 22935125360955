import {
  INIT_VAULT,
  FETCH_VAULT_LIST_START,
  FETCH_VAULT_LIST_SUCCESS,
  FETCH_VAULT_LIST_ERROR,
  DELETE_VAULT_RECORD_ERROR,
  DELETE_VAULT_RECORD_SUCCESS,
  DELETE_VAULT_RECORD_START,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_VAULT_SCREEN,
  CREATE_VAULT_RECORD,
  EDIT_VAULT_RECORD,
  FETCH_VAULT_DETAIL,
  TOAST,
} from '../../../constants/action-types/vault';
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from '../../../utils/table/gridUtils';
import { Password } from 'primereact/password';
import { convertTypeToLabel } from '../../../utils/string/acronymUtil';
import { buildName } from '../../../utils/string';
import { DATE_STRATEGY } from '../../../utils/date'
export const DEFAULT_SORT_KEY_FOR_VAULT = 'id';
export const VAULT_DATE_DATA_KEY = 'modified_date';


const isDateField = (key) => {
  const dateFields = [VAULT_DATE_DATA_KEY];
  return dateFields.includes(key);
};

const bodyTemplateForPassword = (rowData) => {
  const { password } = rowData
  const passwordInputStyle = { 
    pointerEvents: 'none',
    border: 'none',
    background: 'transparent',
    color: '#000',
    fontWeight: 600,
    width: "150px"
  }
 return  (<Password value={password} feedback={false} toggleMask inputStyle={passwordInputStyle}/>)
}

const tranformToVaultGridView = (vaultData) => {
  const rows = vaultData.map((vault) => {
    const transformedValues = { ...vault,  modified_by: { name: buildName(vault.modified_by) } };
    const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });

  const columns = [
    {
      colLabel: 'Column Id',
      dataKey: 'id',
      extraProps: setColumnDefaultExtraProps({
        hidden: true,
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Url',
      dataKey: 'url',
      body: (rowData) => (<a href={rowData.url} target="_blank" rel="noreferrer"> {rowData.url} </a>),
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: true,
        isSortable: false,
      }),
    },
    {
      colLabel: 'Username',
      dataKey: 'username',
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: true,
        isSortable: true,
      }),
    },
    {
      colLabel: 'Password',
      dataKey: 'password',
      body: bodyTemplateForPassword
    },
    {
      colLabel: 'Info',
      dataKey: 'info1',
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: false,
        isSortable: false,
      }),
    },
    {
      colLabel: 'Vault Type',
      dataKey: "type",
      body: (rowData) =>  convertTypeToLabel(rowData.type),
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: true,
        isSortable: true,
      }),
    },
    {
      colLabel: 'Used by',
      dataKey: 'usedByObject.name',
    },
    {
      colLabel: 'Used For',
      dataKey: 'used_for',
    },
    {
      colLabel: 'Modified By',
      dataKey: 'modified_by.name',
      extraProps: setColumnDefaultExtraProps({
        isSortable: false,
        shouldFilter: false,
        sortField: 'modified_by.first_name'
      }),
    },
    {
      colLabel: 'Modified Date',
      dataKey: 'modified_date',
      extraProps: setColumnDefaultExtraProps({
        isSortable: false,
        shouldFilter: false,
        isDateField: true,
        strategy: DATE_STRATEGY.DATE_ONLY,
      })
    },

  ];

  return {
    vaultListView: { columns, rows },
  };
};

const formFieldValueMap = {
  type: 'CMP',
  used_by: '',
  used_for: '',
  url: '',
  username: '',
  password: '',
  info1: '',
};

const INITIAL_STATE = {
  vaultListView: {},
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateVaultSuccess: false,
  isCreateVaultError: false,
};

export const vaultReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case INIT_VAULT:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case FETCH_VAULT_LIST_START:
    case CREATE_VAULT_RECORD.START:
    case DELETE_VAULT_RECORD_START:
    case FETCH_VAULT_DETAIL.START:
    case EDIT_VAULT_RECORD.START:
      return { ...state, isLoading: true };

    case FETCH_VAULT_LIST_SUCCESS: {
      const { page, content } = action.payload;
      let resetState = {
        vaultListView: null,
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isCreateVaultSuccess: false,
        isCreateVaultError: false,
      };

      if (!content) {
        return resetState;
      }
      const { number, size, total_elements: totalElements, total_pages: totalPages } = page;
      const { vaultListView } = tranformToVaultGridView(content);

      return {
        ...resetState,
        vaultListView,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages,
          pageNumber: number,
        },
      };
    }

    case FETCH_VAULT_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        vaultListView: {},
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isVaultDeletionSuccess: false,
        isVaultDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_VAULT_RECORD_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isVaultDeletionSuccess: false,
        isVaultDeletionError: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_VAULT_RECORD_SUCCESS: {
      return {
        ...state,
        isVaultDeletionSuccess: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
      const shouldOpenDeleteConfirmationPopup = action.payload;
      return {
        ...state,
        shouldOpenDeleteConfirmationPopup,
        isVaultDeletionError: false,
        isVaultDeletionSuccess: false,
      };
    }

    // TODO(subham): See if this field is needed.
    case INIT_CREATE_NEW_VAULT_SCREEN: {
      return { ...INITIAL_STATE };
    }

    case CREATE_VAULT_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isCreateVaultSuccess: false,
        isCreateVaultError: true,
      };
    }
    case CREATE_VAULT_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isCreateVaultSuccess: true,
        isCreateVaultError: false,
      };
    }

    case FETCH_VAULT_DETAIL.SUCCESS: {
      const temp = {};
      Object.keys(formFieldValueMap).forEach((key) => {
        const value = action.payload[key];
        temp[key] = isDateField(key) ? new Date(value) : value;
      });

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isVaultDetailSuccess: true,
      };
    }

    case FETCH_VAULT_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isVaultDetailSuccess: false,
        isVaultDetailError: true,
      };
    }

    case EDIT_VAULT_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditVaultSuccess: false,
        isEditVaultError: true,
      };
    }

    case EDIT_VAULT_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditVaultSuccess: true,
        isEditVaultError: false,
      };
    }
    case TOAST.RESET: {
      return {
        ...state,
        isCreateVaultSuccess: false,
        isCreateVaultError: false,
        isEditVaultError: false,
        isEditVaultSuccess: false,
        isVaultDetailError: false,
      };
    }
    default:
      return state;
  }
};
