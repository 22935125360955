export const INIT_ASSETS = 'INIT_ASSETS';
export const FETCH_ASSETS_START = 'FETCH_ASSETS_START';
export const FETCH_ASSETS_SUCCESS = 'FETCH_ASSETS_SUCCESS';
export const FETCH_ASSETS_ERROR = 'FETCH_ASSETS_ERROR';


export const FETCH_ASSETS_LIST_START = 'FETCH_ASSETS_LIST_START';
export const FETCH_ASSETS_LIST_SUCCESS = 'FETCH_ASSETS_LIST_SUCCESS';
export const FETCH_ASSETS_LIST_ERROR = 'FETCH_ASSETS_LIST_ERROR';

export const DELETE_ASSET_RECORD_START = 'DELETE_ASSET_RECORD_START';
export const DELETE_ASSET_RECORD_SUCCESS = 'DELETE_ASSET_RECORD_SUCCESS';
export const DELETE_ASSET_RECORD_ERROR = 'DELETE_ASSET_RECORD_ERROR';

export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';

export const CREATE_ASSET_RECORD = {
  START: 'CREATE_ASSET_RECORD_START',
  SUCCESS: 'CREATE_ASSET_RECORD_SUCCESS',
  ERROR: 'CREATE_ASSET_RECORD_ERROR',
};

export const CREATE_ASSET_MAKE = {
  START: 'CREATE_ASSET_MAKE_START',
  SUCCESS: 'CREATE_ASSET_MAKE_SUCCESS',
  ERROR: 'CREATE_ASSET_MAKE_ERROR',
};

export const EDIT_ASSET_RECORD = {
  START: 'EDIT_ASSET_RECORD_START',
  SUCCESS: 'EDIT_ASSET_RECORD_SUCCESS',
  ERROR: 'EDIT_ASSET_RECORD_ERROR',
};

export const FETCH_ASSET_DETAIL = {
  START: 'FETCH_ASSET_DETAIL_START',
  SUCCESS: 'FETCH_ASSET_DETAIL_SUCCESS',
  ERROR: 'FETCH_ASSET_DETAIL_ERROR',
};

export const TOAST = {
  RESET: 'RESET_TOAST',
};
