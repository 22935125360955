import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import EzControlledDropDowntWithFloatingLabel from '../../../common/EzForm/EzControlledDropDown/WithFloatingLabel';

import { fetchProvinces } from '../../../Redux/Actions/province';

const Province = (props) => {
  const {
    name,
    provinces,
    rules,
    label,
    onChange,
    isControlled,
    control,
    isFloatingLabel,
    fetchProvinces,
    isError,
    errorMsg,
  } = props;

  useEffect(() => {
    fetchProvinces({ paged: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderControlledDDWithFloatingLabel = () => {
    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={rules}
        onChange={onChange}
        optionLabel={'value'}
        optionValue={'key'}
        options={provinces}
        isError={isError}
        errorMsg={errorMsg}
        label={label}
        labelClassName={'ml-2'}
      />
    );
  };

  if (isFloatingLabel || isControlled) {
    return renderControlledDDWithFloatingLabel();
  }
};

Province.defaultProps = {
  isFloatingLabel: false,
  isControlled: false,
  label: '',
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProvinces: (payload) => dispatch(fetchProvinces(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    provinces: selectValFromProvinceReducer(state, 'provinces'),
  };
};
const selectValFromProvinceReducer = (state, key) => {
  return selectProvinceReducer(state)[key];
};

const selectProvinceReducer = ({ provinceReducer }) => {
  return provinceReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(Province);
