export const buildName = (nameObj, firstNameKey = 'first', lastNameKey = 'last', middleNamekey = 'middle') => {
  if (!!nameObj) {
    return `${nameObj?.[firstNameKey] || ''} ${nameObj?.[middleNamekey] || ''} ${nameObj?.[lastNameKey] || ''}`;
  }
  return '-';
};

export const buildCompanyName = (nameObj, firstNameKey = 'first', lastNameKey = 'last', asset_code='asset_code', name='name') => {
  if (!!nameObj) {
    return `${nameObj?.[firstNameKey] || ''} ${nameObj?.[lastNameKey] || ''}  ${nameObj?.[asset_code] || ''} ${nameObj?.[name] || ''}`;
  }
  return '-';
};