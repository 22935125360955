import { handleActions } from 'redux-actions'
import { 
    fetchDocumentTypesStart,
    fetchDocumentTypesComplete,
    fetchOwnerTypesComplete,
    fetchOwnerTypesStart,
    fetchOwnerStart,
    fetchOwnerComplete,
    fetchSuppliersStart,
    fetchSuppliersComplete,
    fetchAssetMakeStart,
    fetchAssetMakeComplete,
    fetchAssetModelStart,
    fetchAssetModelComplete
 } from '../../Actions/Dropdown/action.plain'


export const  dropDownReducer =  handleActions(
{
    [fetchDocumentTypesStart]: (state, {payload}) => ({ ...state ,documentTypesIsLoading :true}),
    [fetchDocumentTypesComplete]:(state ,{payload}) =>  ({...state , documentTypesIsLoading: false,
         documentTypes : payload.documentTypes}),
    [fetchOwnerTypesStart]:(state, {payload}) => ({ ...state ,ownerTypesIsLoading :true}),
    [fetchOwnerTypesComplete]:(state ,{payload}) => ({...state , ownerTypesIsLoading: false,
        ownerTypes : payload.ownerTypes}),
    [fetchOwnerStart]:state => ({...state, ownerIsLoading: true}),
    [fetchOwnerComplete]: (state, {payload}) => ({...state, ownerIsLoading:false, owners : payload.owners}), 
    [fetchAssetMakeStart]: (state) => ({ ...state, isMakeLoading: true}),
    [fetchAssetMakeComplete]: (state, {payload}) => ({ ...state, make: payload.make, isMakeLoading: false }),
    [fetchAssetModelStart]: (state) => ({...state, isModelLoading: true}),
    [fetchAssetModelComplete]: (state, {payload}) => ({ ...state, model: payload.model, isModelLoading: false}),
    [fetchSuppliersStart]:state => ({...state, suppliersIsLoading: true}),
    [fetchSuppliersComplete]: (state, { payload }) =>  ({...state, suppliersIsLoading:false, suppliers : payload.suppliers})    
},
{   documentTypes : null,
    documentTypesIsLoading : false,
    isModelLoading: false,
    isMakeLoading: false 
}
)



    


