import { Button } from 'primereact/button';
import EzDialog from "./common/EzDialog";
import EzFileUpload from "./common/EzFileUpload";

const EzUploadAttachmentDialog = props => {
  const { visible, onDialogHide, header, height, width, onSelectFile, onRemoveFile, files } = props;
  const footer =  <Button
                  type="button"
                  onClick={onDialogHide}
                  label={'Done'}
                  className="mt-2 p-button-raised"
                />
                
  return visible? 
    <EzDialog
      onHide={onDialogHide}
      header={header}
      height={height}
      width={width}
      footer={footer} >
        <>
          <div className='mb-2 text-danger'>Maximum 10 files & all files should have unique names</div>
          <EzFileUpload {...{onSelectFile, onRemoveFile, files}} />
        </>
    </EzDialog> 
  : null;
} 

EzDialog.defaultProps = {
  height: '500px',
  width: '50vw',
  maximizable: false,
  visible: true,
  header: 'Upload Attachment',
  footer:null
}
export default EzUploadAttachmentDialog;