import {
  INIT_TRACKING_LOGS,
  FETCH_TRACKING_LOGS_LIST_ERROR,
  FETCH_TRACKING_LOGS_LIST_START,
  FETCH_TRACKING_LOGS_LIST_SUCCESS,
} from '../../../constants/action-types/trackingLogs';
import { trackingLogsService } from '../../../Services';


export const initializeTrackingLogScreen = () => ({ type: INIT_TRACKING_LOGS });

export const trackingLogsFetchStart = (payload) => ({
  type: FETCH_TRACKING_LOGS_LIST_START,
  payload,
});

export const trackingLogsFetchSuccess = (payload) => ({
  type: FETCH_TRACKING_LOGS_LIST_SUCCESS,
  payload,
});

export const trackingLogsFetchError = (payload) => ({
  type: FETCH_TRACKING_LOGS_LIST_ERROR,
  payload,
});

export const fetchTrackingLogs = (payload) => {
  return (dispatch) => {
    dispatch(trackingLogsFetchStart(payload));

    trackingLogsService.fetchTrackingLogs(payload).then((trackingLogsData) => {
      if (!trackingLogsData.isError) {
        dispatch(trackingLogsFetchSuccess(trackingLogsData));
      } else {
        dispatch(trackingLogsFetchError(trackingLogsData));
      }
    });
  };
};