import React from 'react';
import './index.css';
import EzTable from '../../../common/EzTable';

const DocumentsGrid = (props) => {
  const {
    areFiltersVisible,
    paginationInfo,
    isFetchingGridData,
    documentsListView,
    fetchDocuments,
    screenPermissions,
    deleteDocumentRecord,
    editDocumentRecord,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    fetchDocumentsAttachmentByDocumentId,
    downloadAttachment,
    moreActionProps
  } = props;

  const filters = {
    global: { value: null },
    date: { value: null },
    info: { value: null },
    ref_no: { value: null},
    type : { value: null }
  };

  const loadLazyData = (params) => {
    fetchDocuments(params);
  };

 const tableProps = {
  areFiltersVisible,
  columns: documentsListView.columns,
  fetchDocuments,
  paginationInfo,
  loadLazyData,
  value: documentsListView.rows,
  filters,
  onDeleteRecord: deleteDocumentRecord,
  onEditRecord: editDocumentRecord,
  loading: isFetchingGridData,
  screenPermissions,
  shouldOpenDeleteConfirmationPopup,
  setDeleteConfirmationPopupVisibility,
  fetchAttachmentsById :  fetchDocumentsAttachmentByDocumentId,
  downloadAllAttachments : downloadAttachment,
  moreActionProps
 }
  return (
    <div>
      <EzTable {...tableProps}/>
    </div>
  );
};

export default DocumentsGrid;
