import {
  INIT_PROVINCE,
  FETCH_PROVINCE_LIST_START,
  FETCH_PROVINCE_LIST_SUCCESS,
  FETCH_PROVINCE_LIST_ERROR,
} from '../../../constants/action-types/common/province';

import { provinceService } from '../../../Services';

export const initializeProvinceScreen = () => ({ type: INIT_PROVINCE });

export const provinceFetchStart = () => ({ type: FETCH_PROVINCE_LIST_START });

export const provinceFetchSuccess = (payload) => ({
  type: FETCH_PROVINCE_LIST_SUCCESS,
  payload,
});

export const provinceFetchError = (payload) => ({
  type: FETCH_PROVINCE_LIST_ERROR,
  payload,
});

export const fetchProvinces = (payload) => {
  return (dispatch) => {
    dispatch(provinceFetchStart());

    provinceService.fetchProvinces(payload).then((provinceData) => {
      if (!provinceData.isError) {
        dispatch(provinceFetchSuccess(provinceData));
      } else {
        dispatch(provinceFetchError(provinceData));
      }
    });
  };
};
