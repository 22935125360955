import { useEffect } from 'react';
import { connect } from 'react-redux';
import { ProgressBar } from 'primereact/progressbar';

import { EzToast, TOAST_SEVERITY } from '../../../common/EzToast';
import CreateVault from '../../../components/Vault/CreateVault';
import { APP_URLS } from '../../../constants/urlConstants';
import { fetchCustomers } from '../../../Redux/Actions/customers';
import {
  initCreateNewVaultScreen,
  createVault,
  editVault,
  fetchVaultByVaultId,
} from '../../../Redux/Actions/vault';
import { selectLoggedInUserInfo, selectLoggedInUserCompany } from '../../../Redux/Selectors/profile';
import { setIsformDirty } from '../../../Redux/Actions/common/form';
import { selectCustomers } from '../../../Redux/Selectors/customers';

const ASSET_TYPE = {
  VEHICLE: {
    TRUCK: 'TRUCK',
    TRAILER: 'TRAILER',
  },
};

const transformCustomersToLabelValuePair = (customers) => {
  return customers?.map(customer => {
    const { name: label, id: value } = customer;
    return { label, value, extraData: customer };
  })
}

const transformEmployees = (employees) => {
  return employees?.map((emp) => {
    const { first, last, code } = emp;
    return {
      label: `${first || ''} ${last || ''}`,
      value: code,
      extraData: emp,
    };
  });
};

const transformAssets = (assets, assetType) => {
  const filteredAssets = [];

  assets?.forEach((asset) => {
    const { asset_code, vehicle_typ } = asset;
    if (vehicle_typ?.code === assetType) {
      filteredAssets.push({
        label: asset_code,
        value: asset_code,
        extraData: asset,
      });
    }
  });
  return filteredAssets;
};

const CreateVaultContainer = (props) => {
  const {
    employees,
    assets,
    history,
    createVault,
    editVault,
    isLoading,
    isCreateVaultSuccess,
    isCreateVaultError,
    isVaultDetailSuccess,
    isVaultDetailError,
    isEditVaultSuccess,
    isEditVaultError,
    isEdit,
    formFieldValueMap,
    fetchVaultByVaultId,
    shouldBlockNavigation,
    loggedInUserCompany,
    fetchCustomers,
    customers
  } = props;

  const { vaultId } = props.match.params;

  useEffect(() => {
    if (isEdit) {
      fetchVaultByVaultId({ vaultId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const navigateToVaultSummaryScreen = () => {
    history.push(APP_URLS.VAULT.HOME);
  };

  const createVaultProps = {
    navigateToVaultSummaryScreen,
    employees: transformEmployees(employees),
    trucks: transformAssets(assets, ASSET_TYPE.VEHICLE.TRUCK),
    trailers: transformAssets(assets, ASSET_TYPE.VEHICLE.TRAILER),
    createVault,
    editVault,
    isLoading,
    isEdit,
    isVaultDetailSuccess,
    isCreateVaultSuccess,
    isEditVaultSuccess,
    isEditVaultError,
    formFieldValueMap,
    vaultId,
    shouldBlockNavigation,
    loggedInUserCompany,
    fetchCustomers,
    customers: transformCustomersToLabelValuePair(customers?._embedded || [])
  };
  
  const getToastProps = () => {
    if (isCreateVaultSuccess || isEditVaultSuccess) {
      const toastTitle = isEdit
        ? 'Vault Updated Successfully'
        : 'Vault Created Successfully';
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (isCreateVaultError || isVaultDetailError || isEditVaultError) {
      let toastTitle = 'Error while Creating Vault';
      if (isEditVaultError) {
        toastTitle = 'Error while updating Vault';
      } else if (isVaultDetailError) {
        toastTitle =
          'Error while performing operation. Please refresh your browser';
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateVault {...createVaultProps} />;
    </>
  );
};  

const mapStateToProps = (state, ownProps) => {
  return {
    employees: selectFromEmployeeReducer(state, 'employees'),
    assets: selectFromAssetsReducer(state, 'assets'),
    isLoading: selectFromVaultReducer(state, 'isLoading'),
    loggedInUserInfo: selectLoggedInUserInfo(state),
    loggedInUserCompany: selectLoggedInUserCompany(state),
    customers: selectCustomers(state),
    isCreateVaultSuccess: selectFromVaultReducer(
      state,
      'isCreateVaultSuccess'
    ),
    isCreateVaultError: selectFromVaultReducer(
      state,
      'isCreateVaultError'
    ),
    isVaultDetailSuccess: selectFromVaultReducer(
      state,
      'isVaultDetailSuccess'
    ),
    isVaultDetailError: selectFromVaultReducer(
      state,
      'isVaultDetailError'
    ),
    isEditVaultSuccess: selectFromVaultReducer(
      state,
      'isEditVaultSuccess'
    ),
    isEditVaultError: selectFromVaultReducer(
      state,
      'isEditVaultError'
    ),
    formFieldValueMap: selectFromVaultReducer(state, 'formFieldValueMap'),
    ...ownProps,
  };
};

const selectFromAssetsReducer = (state, path) => {
  return selectAssetsReducer(state)[path];
};

const selectFromVaultReducer = (state, path) => {
  return selectVaultReducer(state)[path];
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateNewVaultScreen: () => dispatch(initCreateNewVaultScreen()),
    fetchVaultByVaultId: (payload) => dispatch(fetchVaultByVaultId(payload)),
    fetchCustomers: (payload) => dispatch(fetchCustomers(payload)),
    createVault: (payload) => dispatch(createVault(payload)),
    editVault: (payload) => dispatch(editVault(payload)),
    shouldBlockNavigation : isFormDirty => dispatch(setIsformDirty(isFormDirty))
  };
};

const selectVaultReducer = ({ vaultReducer }) => {
  return vaultReducer;
};

const selectAssetsReducer = ({ assetsReducer }) => {
  return assetsReducer;
};

const selectFromEmployeeReducer = (state, path) => {
  return selectEmployeeReducer(state)[path];
};

const selectEmployeeReducer = ({ employeeReducer }) => {
  return employeeReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVaultContainer);
