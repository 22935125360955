import {
  INIT_EMPLOYEES,
  FETCH_EMPLOYEES_LIST_ERROR,
  FETCH_EMPLOYEES_LIST_START,
  FETCH_EMPLOYEES_LIST_SUCCESS
} from '../../../constants/action-types/employees'
import { employeeService } from '../../../Services';
export const initializeEmployeeScreen = () => ({ type: INIT_EMPLOYEES });
export const employeesFetchStart = (payload) => ({
  type: FETCH_EMPLOYEES_LIST_START,
  payload,
});

export const employeesFetchSuccess = (payload) => ({
  type: FETCH_EMPLOYEES_LIST_SUCCESS,
  payload,
});

export const employeesFetchError = (payload) => ({
  type: FETCH_EMPLOYEES_LIST_ERROR,
  payload,
});

export const fetchEmployees = (payload) => {
  return (dispatch) => {
    dispatch(employeesFetchStart(payload));
    employeeService.fetchEmployees(payload).then((employeesData) => {
      if (!employeesData.isError) {
        dispatch(employeesFetchSuccess(employeesData));
      } else {
        dispatch(employeesFetchError(employeesData));
      }
    });
  };
};