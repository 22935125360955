import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Button, Form, FormControl } from 'react-bootstrap';
import Cookies from 'universal-cookie';

import { validateEmail } from '../utils/validation/email';
import { postApiLoginAsyn } from '../Services/PostAPI';
import { getMenuAccess } from "./login-utils";
import Logo from '../images/logo.svg';
import './Login.css';

const cookies = new Cookies();
class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginValue: {},
      error: false,
      errorMessage: '',
      access: {},
    };
  }

  keyUpEventListener = (event) => {
    if (event.key === "Enter" && !this.checkButtonDisabled()) {
      this.handleLoign()
    }
  }

  componentDidMount = () => {
    window.addEventListener("keyup", this.keyUpEventListener)
  }

  componentWillUnmount = () => {
    window.removeEventListener("keyup", this.keyUpEventListener)
  }

  checkError = (value) => {
    if (value && !validateEmail(value)) {
      return 'Please enter a valid email.';
    }
    return false;
  };

  checkButtonDisabled = () => {
    const { loginValue } = this.state;
    return (
      !loginValue.username ||
      !loginValue.password ||
      this.checkError(loginValue.username)
    );
  };

  handleChangeGen = (event) => {
    const { loginValue } = this.state;
    loginValue[event.target.name] = event.target.value;
    this.setState({
      [loginValue]: loginValue,
      error: false,
    });
  };

  handleLoign = async () => {
    const { loginValue } = this.state;
    let reqData = {
      username: loginValue.username,
      password: loginValue.password,
    };
    let res = await postApiLoginAsyn('login', reqData);
    if (res.authorization != null) {
      await getMenuAccess(res.authorization);
      cookies.set('authorization', res.authorization, {
        path: '/',
        sameSite: true,
      });
      this.props.callBack();
    } else {
      console.log(res);
      this.setState({
        error: true,
        errorMessage: 'Invalid Credential',
      });
    }
  };

  render() {
    const { loginValue, error, errorMessage } = this.state;
    if (
      cookies.get('authorization') != null ||
      cookies.get('authorization') !== undefined
    ) {
      return <Redirect to="/Dashboard" />;
    }
    return (
      <div className="login">
        <div className="loginpan">
          <div className="loginlogo">
            <img src={Logo} alt="Easy On Road" />
            <div className="formrow">
              <h2> Sign In.</h2>
              {error === true ? (
                <div className="login-error">
                  {errorMessage} Please try again.
                </div>
              ) : null}
              <Form.Group>
                <Form.Label className="user_icon">Email</Form.Label>
                <FormControl
                  name="username"
                  value={loginValue.username}
                  onChange={this.handleChangeGen}
                  placeholder="Enter Email"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="pass_icon">Password</Form.Label>
                <FormControl
                  type="password"
                  name="password"
                  onChange={this.handleChangeGen}
                  placeholder="Enter Password"
                />
              </Form.Group>
              <Button
                disabled={this.checkButtonDisabled()}
                type="button"
                variant="primary"
                onClick={this.handleLoign}
              >
                Sign in
              </Button>{' '}
              <div className="forget">
                <p>
                  Don't have an account? <Link to="">Sign up.</Link>{' '}
                </p>
                <p>Forgot password?</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
