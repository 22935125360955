import {
  INIT_PROVINCE,
  FETCH_PROVINCE_LIST_START,
  FETCH_PROVINCE_LIST_SUCCESS,
  FETCH_PROVINCE_LIST_ERROR,
} from '../../../../constants/action-types/common/province';

const getInitalState = () => ({
  isFetchingProvince: false,
  errorInFetchingProvince: false,
  provinces: [],
  error: null,
});

const resetBooleanStates = (overrides = {}) => {
  return {
    isFetchingProvince: false,
    errorInFetchingProvince: false,
    ...overrides,
  };
};

export const provinceReducer = (state = getInitalState(), action) => {
  switch (action.type) {
    case INIT_PROVINCE: {
      return { ...getInitalState() };
    }

    case FETCH_PROVINCE_LIST_START: {
      return {
        ...state,
        ...resetBooleanStates({ isFetchingProvince: true }),
      };
    }

    case FETCH_PROVINCE_LIST_SUCCESS: {
      const { content } = action.payload;

      return {
        ...state,
        ...resetBooleanStates(),
        provinces: content,
      };
    }

    case FETCH_PROVINCE_LIST_ERROR: {
      const { error } = action;

      return {
        ...state,
        ...resetBooleanStates({ errorInFetchingProvince: true }),
        error,
      };
    }
    default:
      return state;
  }
};
