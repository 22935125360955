import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import EzTable from '../../common/EzTable';
import { EzToast, TOAST_SEVERITY } from '../../common/EzToast';
import EzNotFound from '../../common/EzNotFound';
import { COMPLIANCE_DATE_DATA_KEY } from '../../Redux/Reducers/compliance';
const getIconPath = (imgPathDelta = '/no-record.png') =>
  process.env.PUBLIC_URL + imgPathDelta;
const Compliance = (props) => {
  const {
    compliancesListView,
    createCompliancesRecord,
    deleteComplianceRecord,
    editComplianceRecord,
    fetchCompliances,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isComplianceDeletionError,
    isComplianceDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    downloadAllAttachmentByComplianceId,
    fetchComplianceAttachmentsByComplianceId,
  } = props;
  const [areFiltersVisible, toggleFilterState] = useState(false);
  const getToastProps = () => {
    if (isComplianceDeletionSuccess) {
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle: 'Compliance Deleted Successfully',
        shouldShowToast: true,
      };
    }
    if (isComplianceDeletionError) {
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle: 'Error while Deleting Compliance',
        shouldShowToast: true,
      };
    }
  };
  const renderPageHeaderSection = () => {
    const breadCrumbs = [{ label: 'Compliances' }, { label: 'Compliance Summary' }];
    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />
        <div className="rightbtn">
          <Button
            label={areFiltersVisible ? 'Hide Filters' : 'Show Filters'}
            icon={areFiltersVisible ? 'pi pi-filter-slash' : 'pi pi-filter'}
            className={`mr-2 p-button-raised p-button-outlined p-button-secondary`}
            onClick={() => {
              toggleFilterState(!areFiltersVisible);
            }}
          />
          <Button
            label={'Create New Compliance'}
            icon={'pi pi-plus'}
            className={`mr-2 p-button-raised`}
            onClick={createCompliancesRecord}
          />
        </div>
      </div>
    );
  };
  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderComplianceRecords = () =>
    compliancesListView?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderComplianceRecords() && !shouldRenderFullPageError();
  const filters = {
    global: { value: null },
    type: { filterKey: 'type.name', value: null },
    cat: {  filterKey: 'cat.name', value: null },
    object_type: {  filterKey: 'object_type.first', value: null },
    desc: {  filterKey: 'desc', value: null },
    due_date: {  filterKey: 'due_date', value: null },
    cmplnc_status: {  filterKey: 'cmplnc_status.name', value: null },
  };
  const loadLazyData = params => {
    fetchCompliances(params)
  }
  const tableProps = {
    areFiltersVisible,
    columns: compliancesListView?.columns,
    paginationInfo,
    screenPermissions,
    setDeleteConfirmationPopupVisibility,
    shouldOpenDeleteConfirmationPopup,
    onEditRecord: editComplianceRecord,
    loadLazyData,
    onDeleteRecord: deleteComplianceRecord,
    value: compliancesListView?.rows,
    emptyMessage: 'No Compliances found.',
    filters,
    loading: isFetchingGridData,
    fetchAttachmentsById: fetchComplianceAttachmentsByComplianceId,
    downloadAllAttachments: downloadAllAttachmentByComplianceId,
    showMoreActions: false,
    sortField: COMPLIANCE_DATE_DATA_KEY,
  };
  return (
    <>
      <EzToast {...getToastProps()} />
      <div className="paddingbox">
        <div className="containerbox">
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: '-70px' }}
                bgImgUrl={getIconPath('something-broke.png')}
                message={'Oops! Something broke, we are working on a fix.'}
              />
            )}
            {shouldRenderComplianceRecords() && (
              <Row>
                <Col md={12}>
                  <EzTable {...tableProps} />
                </Col>
              </Row>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={'No compliances records to show'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Compliance;
