import { DEFAULT_TOAST_LIFE } from '../../../common/EzToast';
import {
  INIT_ASSET_LOCATIONS,
  FETCH_ASSET_LOCATIONS_LIST_ERROR,
  FETCH_ASSET_LOCATIONS_LIST_START,
  FETCH_ASSET_LOCATIONS_LIST_SUCCESS,
  EDIT_ASSET_LOCATION_RECORD,
  FETCH_ASSET_LOCATION_DETAIL,
  TOAST,
} from '../../../constants/action-types/assetLocation';
import * as assetLocationService from '../../../Services/business/assetLocationService';

export const initializeAssetLocationScreen = () => ({ type: INIT_ASSET_LOCATIONS });

export const assetLocationsHistoryFetchStart = (payload) => ({
  type: FETCH_ASSET_LOCATIONS_LIST_START,
  payload,
});

export const assetLocationsHistoryFetchSuccess = (payload) => ({
  type: FETCH_ASSET_LOCATIONS_LIST_SUCCESS,
  payload,
});

export const assetLocationsHistoryFetchError = (payload) => ({
  type: FETCH_ASSET_LOCATIONS_LIST_ERROR,
  payload,
});

export const fetchAssetLocationHistoryByAssetId = (payload) => {
  return (dispatch) => {
    dispatch(assetLocationsHistoryFetchStart(payload));
    assetLocationService.fetchAssetLocationHistoryByAssetId(payload).then((assetLocationsHistoryData) => {
      if (!assetLocationsHistoryData.isError) {
        dispatch(assetLocationsHistoryFetchSuccess(assetLocationsHistoryData));
      } else {
        dispatch(assetLocationsHistoryFetchError(assetLocationsHistoryData));
      }
    });
  };
};

export const assetLocationsFetchStart = (payload) => ({
  type: FETCH_ASSET_LOCATIONS_LIST_START,
  payload,
});

export const assetLocationsFetchSuccess = (payload) => ({
  type: FETCH_ASSET_LOCATIONS_LIST_SUCCESS,
  payload,
});

export const assetLocationsFetchError = (payload) => ({
  type: FETCH_ASSET_LOCATIONS_LIST_ERROR,
  payload,
});

export const fetchAssetLocations = (payload) => {
  return (dispatch) => {
    dispatch(assetLocationsFetchStart(payload));
    assetLocationService.fetchAssetLocation(payload).then((assetLocationsData) => {
      if (!assetLocationsData.isError) {
        dispatch(assetLocationsFetchSuccess(assetLocationsData));
      } else {
        dispatch(assetLocationsFetchError(assetLocationsData));
      }
    });
  };
};


export const assetLocationDetailFetchStart = (payload) => ({
  type: FETCH_ASSET_LOCATION_DETAIL.START,
  payload,
});

export const assetLocationDetailFetchSuccess = (payload) => ({
  type: FETCH_ASSET_LOCATION_DETAIL.SUCCESS,
  payload,
});

export const assetLocationDetailFetchError = (payload) => ({
  type: FETCH_ASSET_LOCATION_DETAIL.ERROR,
  payload,
});

export const fetchAssetLocationByAssetLocationId = (payload) => {
  return (dispatch) => {
    dispatch(assetLocationDetailFetchStart(payload));

    assetLocationService
      .fetchAssetLocationByAssetLocationId(payload)
      .then((assetLocationsData) => {
        if (!assetLocationsData.isError) {
          dispatch(assetLocationDetailFetchSuccess(assetLocationsData));
        } else {
          dispatch(assetLocationDetailFetchError(assetLocationsData));
        }
      });
  };
};

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const editAssetLocationRecordStart = () => ({
  type: EDIT_ASSET_LOCATION_RECORD.START,
});

export const editAssetLocationRecordSuccess = (payload) => ({
  type: EDIT_ASSET_LOCATION_RECORD.SUCCESS,
  payload,
});

export const editAssetLocationRecordError = (payload) => ({
  type: EDIT_ASSET_LOCATION_RECORD.ERROR,
  payload,
});

export const editAssetLocation = (payload) => {
  return (dispatch) => {
    dispatch(editAssetLocationRecordStart());
    assetLocationService.editAssetLocationRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(editAssetLocationRecordSuccess());
        dispatch(fetchAssetLocations());
      } else {
        dispatch(editAssetLocationRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
