import React from 'react'
import { Modal } from 'react-bootstrap'
import DeviceTrackerMap from '../../Fleet/Trips/DeviceTrackerMap'
let googleMapsApiKey = 'AIzaSyDXzpajYWsQgG1UIoWbjBVUpt19Q6NNopY';

export default function MapComponent({title, onHide, show, data}) {
  return (
    <Modal
    onHide={onHide}
    show={show}
    id="directionMap"
  >
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-lg-12 addaddress">
        <DeviceTrackerMap
          data={data}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `500px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          center={{
            lat: data[0]?.lat,
            lng: data[0]?.lng,
          }}
          zoom={9}
        />
      </div>
    </Modal.Body>
  </Modal>
  )
}
