import React, { useState } from 'react' 
import { ConfirmDialog } from 'primereact/confirmdialog'
import { Prompt } from 'react-router-dom'

const message =  `There are some unsaved changes ?`

const ConfirmRouteChange = (props) => {

    const { isFormDirty, history, setIsformDirty} = props;

    const [shouldRouteChangeConfirmationPopUpVisible,
        setShouldRouteChangeConfirmationPopUpVisible ] = useState(false);

    const [nextLocation, setNextLocation] = useState('');
    const handleBlockedNavigation = (nextLocation) => {
        
        if(isFormDirty) {
            setShouldRouteChangeConfirmationPopUpVisible(true);
            setNextLocation(nextLocation);
            return false;
        }    
     }

        return (
        <>
        <Prompt
            when={isFormDirty}
            message={handleBlockedNavigation}
        />

        <ConfirmDialog
            visible={shouldRouteChangeConfirmationPopUpVisible}
            message={message}
            header="Are you sure want to leave ?"
            rejectIcon ='pi pi-exclamation-triangle'
            position={'top'}
            onHide={() => true}
            acceptLabel={'Stay'}
            rejectLabel={'Leave'}
            acceptClassName={'p-button-raised'}
            rejectClassName={'p-button-text p-button-danger'}
            accept={() => {
                setShouldRouteChangeConfirmationPopUpVisible(false);
            }}
            reject={() => {
                    setShouldRouteChangeConfirmationPopUpVisible(false);
                    setIsformDirty(false);
                    const unblockCb = history.block(()=>{})
                    unblockCb();
                    history.push(nextLocation?.pathname);   
                    //window.location.href = '/Documents'
            }}
            />
    </>
    )
}

        
export default ConfirmRouteChange
