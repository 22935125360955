import Cookies from 'universal-cookie';
import { post, put } from 'axios';

import { 
  getApiAsyn,
  deleteApi,
  baseUrl
} from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { API_URL } from '../../../constants/urlConstants';


const cookies = new Cookies();

export const fetchLocationsByCSName = async (query, value) => {
  const url = API_URL.LOCATION.FETCH_BY_CS_NAME.replace(":id", query).replace(":type",value);
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));
    if (result?.error) {
      throw new APIError(result);
    }

    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

