import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';

import EzTable from '../../common/EzTable';
import EzNotFound from '../../common/EzNotFound';
import { FILTER } from '../../Services/common/urlService';
import { convertLabelToType } from '../../utils/string/acronymUtil';

// TODO (Subham): Find why assets path is not working
//const getIconPath = () => process.env.PUBLIC_URL + '/assets/images/acidents/no-record.png';
const getIconPath = (imgPathDelta = '/no-record.png') =>
  process.env.PUBLIC_URL + imgPathDelta;

const TrackingLog = (props) => {
  const {
    trackingLogsListView,
    fetchTrackingLogs,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isPageLevelError,
  } = props;

  const [areFiltersVisible, toggleFilterState] = useState(false);

  const renderPageHeaderSection = () => {
    const breadCrumbs = [{ label: 'Tracking Logs' }, { label: 'Tracking Log Summary' }];

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };

    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />

        <div className="rightbtn">
          <Button
            label={areFiltersVisible ? 'Hide Filters' : 'Show Filters'}
            icon={areFiltersVisible ? 'pi pi-filter-slash' : 'pi pi-filter'}
            className={`mr-2 p-button-raised p-button-outlined p-button-secondary`}
            onClick={() => {
              toggleFilterState(!areFiltersVisible);
            }}
          />
        </div>
      </div>
    );
  };

  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderTrackingLogRecords = () =>
    trackingLogsListView?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderTrackingLogRecords() && !shouldRenderFullPageError();

  const filters = {
    global: { value: null },
    time: { value: null },
    logfor: { value: null, },
    device: { value: null, matchStrategy: FILTER.MATCH_STRATEGY.EXACT_MATCH },
    longitude: { value: null },
    latitude: { value: null },
    type: { value: null, valueMapper: convertLabelToType},
  };

  const tableProps = {
    areFiltersVisible,
    columns: trackingLogsListView.columns,
    paginationInfo,
    screenPermissions,
    loadLazyData: fetchTrackingLogs,
    value: trackingLogsListView.rows,
    emptyMessage: 'No Tracking Logs found.',
    filters,
    sortField: 'time',
    showMoreActions: false,
    loading: isFetchingGridData,
  };
  
  return (
    <>
      <div className="paddingbox">
        <div className="containerbox">
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: '-70px' }}
                bgImgUrl={getIconPath('something-broke.png')}
                message={'Oops! Something broke, we are working on a fix.'}
              />
            )}
            {shouldRenderTrackingLogRecords() && (
              <Row>
                <Col md={12}>
                  <EzTable {...tableProps} />
                </Col>
              </Row>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={'No tracking log records to show'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackingLog;
