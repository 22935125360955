import { DOWNLOADING_ALL_ATTACHEMENT, FETCH_ATTACHMENT_FILES } from "../../../constants/action-types/common/files";

const INIT_STATE = { downloadingAttachment: false};

export const filesAttachmentsReducer = (state = INIT_STATE, action) =>{
    switch(action.type){
        case FETCH_ATTACHMENT_FILES.START : {
            return { 
                ...state,
                isFetchingFiles: true
            };
        }
        case FETCH_ATTACHMENT_FILES.SUCCESS : {
            return { 
                ...state,
                isFetchingFiles: false,
                attachmentsfiles:action.payload }
        }
        case FETCH_ATTACHMENT_FILES.ERROR : {
            return { 
                ...state,
                isFetchingFilesError : true,
                isFetchingFiles: false }
        }
        case DOWNLOADING_ALL_ATTACHEMENT.START :{
            return {
                ...state,
                downloadingAttachment:true
            }
        }
        case DOWNLOADING_ALL_ATTACHEMENT.COMPLETE :{
            return {
                ...state,
                downloadingAttachment:false
            }
        }
        case DOWNLOADING_ALL_ATTACHEMENT.ERROR :{
            return {
                ...state,
                downloadingAttachment:false
            }
        }
        default: return state;
    }
}
