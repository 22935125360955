import React, { Component } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Prompt } from 'react-router-dom';
import { FormLabel } from '../FormLabel';
import SelectSearch from 'react-select-search';
import { Link } from 'react-router-dom';
import { getApi } from '../../Services/GetAPI';
import Cookies from 'universal-cookie';
import {
  postApi,
  putApiRes,
  getApiAsyn,
  normalizeInput,
  numberInput,
  loadingsShow,
} from '../../Services/PostAPI';
import { validateEmail, urlValidation } from '../../utils/validation/email';
import SnackbarError from '../../common/SnackbarError';
import SnackbarSuccess from '../../common/SnackbarSuccess';
import Autosuggestion from '../../common/Autosuggestion';
import { EzToast, TOAST_SEVERITY } from '../../common/EzToast';
const cookies = new Cookies();

class CreateCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: JSON.parse(localStorage.getItem('menuAccess')),
      PromptPrevent: false,
      customerValue: {},
      countryList: [],
      stateList: [],
      cityList: [],
      currencyList: [],
      defaultAssetList: [],
      revenueList: [],
      taxList: [],
      cntryId: '',
      stateId: '',
      errors: {},
      errorBoll: false,
      errorMessage: '',
      legalBoll: false,
      successMessage: '',
      BrokerDropdonw: [],
      bool: false,
      listData: [],
      isDisabled: false,
      showErrorToast: false,
      servirity: TOAST_SEVERITY.ERROR
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }


  getToastProps = (isBool, msg, sev) => {
    return {
      severity: sev,
      toastTitle: msg,
      shouldShowToast: isBool,
    };
  };

  handleReset = () => {
    window.location.reload();
  };
  setWrapperRef(node) {
    this.WrapperRef = node;
  }
  handleClickOutside(event) {
    this.setState({
      bool: false,
    });
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      alert('You clicked outside of me!');
    }
  }

  setAutoRef(node) {
    this.AutoRef = node;
  }
  getAutosuggest = async (id) => {
    let ddbrokder = [];
    if (id !== undefined && id.length > 2) {
      let res = await getApiAsyn(
        `customers?intent=suggest&begin-with=${id}`,
        cookies.get('authorization')
      );
      if (res && res.length > 0) {
        this.setState({
          bool: true,
        });
        ddbrokder = res.filter((x) => x.broker === true);
        ddbrokder = ddbrokder.map((item) => ({
          name: item.name,
          value: item.id,
        }));
        this.setState({
          listData: ddbrokder,
        });
        if (this.props.match.params.id) {
          this.setState({
            bool: false,
          });
          let { customerValue } = this.state;
          ddbrokder = res.filter((x) => x.broker === true);
          ddbrokder = ddbrokder.map((item) => ({
            name: item.name,
            value: item.id,
          }));
          ddbrokder = ddbrokder.filter((x) => x.value === parseInt(id));
          if (ddbrokder.length === 1) {
            this.setState({
              customerValue: {
                ...customerValue,
                broker_ids: ddbrokder[0].name,
                broker_id: ddbrokder[0].value,
              },
            });
          } else {
            this.setState({
              customerValue: {
                ...customerValue,
                broker_ids: '',
                broker_id: '',
              },
            });
          }
        }
      }
    }
  };

  setAutosuggestId = (id, name) => {
    this.setState({
      customerValue: {
        ...this.state.customerValue,
        broker_ids: name,
        broker_id: id,
      },
      bool: false,
    });
  };
  getCustomerData = async () => {
    let ddbrokder = [];
    let istSearchList = '';
    let res = await getApiAsyn(
      `customers?paged=true&page=1&size=50000&fields=id,name,currency_id,broker,email,cont_name,phone`,
      cookies.get('authorization')
    );
    if (res && res.status !== false) {
      istSearchList = res && res.page && res.page.total_elements;
      if (istSearchList > 0) {
        ddbrokder = res._embedded.content.filter((x) => x.broker === true);
        ddbrokder = ddbrokder.map((item) => ({
          name: item.name,
          value: item.id,
          phone: item.phone,
          email: item.email,
          cont_name: item.cont_name,
        }));
        ddbrokder.unshift({
          name: 'select...',
          value: '-1',
        });
        this.setState({
          BrokerDropdown: ddbrokder,
        });
      }
    }
  };
  getCurrency = () => {
    let ddcurrency = [];
    getApi(`currencies`, cookies.get('authorization')).then((res) => {
      //console.log(res);
      ddcurrency = res;
      ddcurrency = ddcurrency.map((item) => ({
        name: item.value,
        value: item.key,
      }));
      this.setState({
        currencyList: ddcurrency,
      });
    });
  };

  getAssets = () => {
    let ddasset = [];
    getApi(
      `assets?paged=true&page=1&size=10000`,
      cookies.get('authorization')
    ).then((res) => {
      console.log('ASSETS-BD', res);
      if (res && res.status !== false) {
        ddasset = res._embedded.content;
        ddasset = ddasset.map((item) => ({
          name: item.asset_code,
          value: item.id,
        }));
        this.setState({
          defaultAssetList: ddasset,
        });
      }
    });
  };

  getRevenue = () => {
    let ddrevenue = [];
    getApi(`types?type=REVENUE_TYPE`, cookies.get('authorization')).then(
      (res) => {
        //console.log(res);
        ddrevenue = res;
        ddrevenue = ddrevenue.map((item) => ({
          name: item.value,
          // value: item.key,
          value: item.code,
        }));
        this.setState({
          revenueList: ddrevenue,
        });
      }
    );
  };
  getTax = () => {
    let ddtax = [];
    getApi(`types?type=TAX_TYPE`, cookies.get('authorization')).then((res) => {
      //console.log(res);
      ddtax = res;
      ddtax = ddtax.map((item) => ({
        name: item.value,
        value: item.key,
      }));
      this.setState({
        taxList: ddtax,
      });
    });
  };
  getCountry = () => {
    let ddCoounty = [];
    getApi(`countries`, cookies.get('authorization')).then((res) => {
      //console.log(res);
      ddCoounty = res;
      ddCoounty = ddCoounty.map((item) => ({
        name: item.value,
        value: item.key,
      }));
      this.setState({
        countryList: ddCoounty,
      });
    });
  };
  getState = (id) => {
    let ddState = [];
    getApi(`countries/${id}/states`, cookies.get('authorization')).then(
      (res) => {
        //console.log(res);
        ddState = res;
        ddState = ddState.map((item) => ({
          name: item.value,
          value: item.key,
        }));
        this.setState({
          stateList: ddState,
        });
      }
    );
  };

  getCity = (id, cid) => {
    let ddCity = [];
    getApi(
      `countries/${id}/states/${cid}/cities`,
      cookies.get('authorization')
    ).then((res) => {
      //console.log(res);
      ddCity = res;
      ddCity = ddCity.map((item) => ({
        name: item.value,
        value: item.key,
      }));
      this.setState({
        cityList: ddCity,
      });
    });
  };
  handleSeachableDropdonw = (val, name) => {
    const { customerValue } = this.state;
    customerValue[name] = val;
    this.setState({
      [customerValue]: customerValue,
      PromptPrevent: true,
    });
    if (name === 'billing_country' || name === 'shipping_country') {
      this.getState(val);
      this.setState({
        cntryId: val,
      });
    }
    if (name === 'billing_state' || name === 'shipping_state') {
      const { cntryId } = this.state;
      this.getCity(cntryId, val);
    }
  };

  handleChangeGen = (event) => {
    const { customerValue } = this.state;
    customerValue[event.target.name] = event.target.value;
    this.setState({
      [customerValue]: customerValue,
      PromptPrevent: true,
    });
    if (
      event.target.name === 'broker' &&
      (event.target.value === 'true' || event.target.value === true)
    ) {
      this.setState({
        customerValue: {
          ...customerValue,
          broker_id: '',
          broker_ids: '',
        },
      });
    }
    if (event.target.name === 'phone' || event.target.name === 'phone2') {
      customerValue[event.target.name] = normalizeInput(event.target.value);
      this.setState({
        [customerValue]: customerValue,
      });
    }
    if (event.target.name === 'due_days') {
      customerValue[event.target.name] = numberInput(event.target.value);
      this.setState({
        [customerValue]: customerValue,
      });
    }

    if (event.target.name === 'broker_ids') {
      this.getAutosuggest(event.target.value);
    }
  };

  handleChangeGenChecked = (event) => {
    const { customerValue } = this.state;
    customerValue[event.target.name] = event.currentTarget.value;
    this.setState({
      [customerValue]: customerValue,
      PromptPrevent: true,
    });
  };

  handleDate = (date, name) => {
    const { customerValue } = this.state;
    customerValue[name] = date;
    this.setState({
      [customerValue]: customerValue,
      PromptPrevent: true,
    });
  };

  handleValidation = () => {
    const { customerValue } = this.state;

    let errors = {};
    let formIsValid = true;
    //Name

    if (!customerValue['name']) {
      formIsValid = false;

      errors['name'] = 'This is a mandatory field.';
    }
    if (
      customerValue['name'] !== '' &&
      customerValue['name'] !== undefined &&
      customerValue['name'].length < 4
    ) {
      formIsValid = false;

      errors['name'] = 'Customer name must at least more than 3 characters.';
    }
    if (validateEmail(customerValue['email']) === false) {
      formIsValid = false;

      errors['email'] = 'This is a mandatory field.';
    }
    if (!customerValue['currency_id']) {
      formIsValid = false;

      errors['currency_id'] = 'This is a mandatory field.';
    }
    if (
      customerValue['site_url'] !== '' &&
      customerValue['site_url'] !== undefined &&
      customerValue['site_url'] != null &&
      !urlValidation(customerValue['site_url'])
    ) {
      formIsValid = false;

      errors['site_url'] = 'Please enter valid url!';
    }
    /* if (!customerValue['asset_id']) {
      formIsValid = false;

      errors['asset_id'] = 'This is a mandatory field.';
    }
    if (!customerValue['tax_id']) {
      formIsValid = false;

      errors['tax_id'] = 'This is a mandatory field.';
    }
    if (
      customerValue['tax_id'] !== null &&
      customerValue['tax_id'] !== undefined &&
      customerValue['tax_id'].length > 11
    ) {
      formIsValid = false;
      errors['tax_id'] = 'Tax Id maximum allowed 11 character';
    }*/

    if (
      customerValue['crm_id'] !== null &&
      customerValue['crm_id'] !== undefined &&
      customerValue['crm_id'].length > 50
    ) {
      formIsValid = false;
      errors['crm_id'] = 'crm Id maximum allowed 50 character';
    }

    if (
      customerValue['crm_id'] !== null &&
      customerValue['crm_id'] !== undefined &&
      customerValue['crm_id'].length > 50
    ) {
      formIsValid = false;
      errors['crm_id'] = 'Customer CRM id maximum allowed 50 character';
    }
    if (
      customerValue['info'] !== null &&
      customerValue['info'] !== undefined &&
      customerValue['info'].length > 200
    ) {
      formIsValid = false;
      errors['info'] = 'Other Information maximum allowed 200 character';
    }
    if (
      customerValue['tax_no'] !== null &&
      customerValue['tax_no'] !== undefined &&
      customerValue['tax_no'].length > 50
    ) {
      formIsValid = false;
      errors['tax_no'] = 'Tax no maximum allowed 50 character';
    }

    /* if (!customerValue['revenue_id']) {
      formIsValid = false;

      errors['revenue_id'] = 'This is a mandatory field.';
    }*/

    if (
      customerValue['broker_id'] !== null &&
      customerValue['broker_id'] !== undefined &&
      customerValue['broker_id'].length > 50
    ) {
      formIsValid = false;
      errors['broker_id'] = 'Broker id maximum allowed 50 character';
    }

    if (!customerValue['address1']) {
      formIsValid = false;

      errors['address1'] = 'This is a mandatory field.';
    }
    if (
      customerValue['address2'] !== null &&
      customerValue['address2'] !== undefined &&
      customerValue['address2'].length > 100
    ) {
      formIsValid = false;
      errors['address2'] = 'Maximum allowed 100 character';
    }
    if (!customerValue['billing_country']) {
      formIsValid = false;

      errors['billing_country'] = 'This is a mandatory field.';
    }
    if (!customerValue['billing_state']) {
      formIsValid = false;

      errors['billing_state'] = 'This is a mandatory field.';
    }
    if (!customerValue['billing_city']) {
      formIsValid = false;

      errors['billing_city'] = 'This is a mandatory field.';
    }
    if (!customerValue['billing_zip_code']) {
      formIsValid = false;

      errors['billing_zip_code'] = 'This is a mandatory field.';
    }
    if (!customerValue['shipping_country']) {
      formIsValid = false;

      errors['shipping_country'] = 'This is a mandatory field.';
    }
    if (!customerValue['shipping_state']) {
      formIsValid = false;

      errors['shipping_state'] = 'This is a mandatory field.';
    }
    if (!customerValue['shipping_city']) {
      formIsValid = false;

      errors['shipping_city'] = 'This is a mandatory field.';
    }
    if (!customerValue['shipping_zip_code']) {
      formIsValid = false;

      errors['shipping_zip_code'] = 'This is a mandatory field.';
    }
    if (
      customerValue['due_days'] !== null &&
      customerValue['due_days'] !== undefined &&
      customerValue['due_days'].length > 2 &&
      isNaN(customerValue['due_days'])
    ) {
      formIsValid = false;
      errors['due_days'] =
        'due days can be number only. -- Characters not allowed';
    }

    if (
      customerValue['due_days'] !== null &&
      customerValue['due_days'] !== undefined &&
      customerValue['due_days'].length > 2
    ) {
      formIsValid = false;
      errors['due_days'] = 'due days maximum allowed 2 character';
    }
    /*if (
      customerValue['eob_id'] !== undefined &&
      customerValue['eob_id'].length > 10
    ) {
      formIsValid = false;

      errors['eob_id'] = 'EOB Logistics maximum allowed 10 character';
    }
    
if(isNaN(customerValue["billing_zip_code"])){
  formIsValid = false;
  errors["billing_zip_code"] = "Postal code can be number only.";
}
if(isNaN(customerValue["shipping_zip_code"])){
  formIsValid = false;
  errors["shipping_zip_code"] = "Postal code can be number only.";
}

if(customerValue["phone"]!=undefined && isNaN(customerValue["phone"])){
  formIsValid = false;
  errors["phone"] = "phone number can be number only.";
}
if(customerValue["phone2"]!=undefined && isNaN(customerValue["phone2"])){
  formIsValid = false;
  errors["phone"] = "alternate phone can be number only.";
}*/
    this.setState({ errors: errors });
    return formIsValid;
  };
  handleSave = () => {
    if (this.handleValidation()) {
      const { customerValue } = this.state;
      let reqData = {
        asset_id: customerValue.asset_id,
        cust_type:
          customerValue.cust_type === 'true' || customerValue.cust_type === true
            ? true
            : false,
        revenue_id: customerValue.revenue_id,
        tax_id: customerValue.tax_id,
        due_days: parseInt(customerValue.due_days),

        name: customerValue.name,
        cont_name: customerValue.cont_name,
        email: customerValue.email,
        phone: customerValue.phone,
        phone2: customerValue.phone2,
        site_url: customerValue.site_url,
        rate: customerValue.rate !== undefined ? customerValue.rate : 0,
        address1: customerValue.address1,
        address2: customerValue.address2,
        billing_country: customerValue.billing_country,
        billing_state: customerValue.billing_state,
        billing_city: customerValue.billing_city,
        currency_id: customerValue.currency_id,
        billing_zip_code: customerValue.billing_zip_code,

        shipping_country: customerValue.shipping_country,
        shipping_state: customerValue.shipping_state,
        shipping_city: customerValue.shipping_city,
        currency_id: customerValue.currency_id,
        shipping_zip_code: customerValue.shipping_zip_code,
        
        tax_no: customerValue.tax_no,
        crm_id: customerValue.crm_id,
        info: customerValue.info,
        eob_id: customerValue.eob_id,
        invoice_by: customerValue.invoice_by,
        service_pln_type: customerValue.service_pln_type,
        broker:
          customerValue.broker === 'true' || customerValue.broker === true
            ? true
            : false,
        broker_id: customerValue.broker_id,
        due_amt: customerValue.due_amt,
        order_cnt: customerValue.order_cnt,
        invoice_cnt: customerValue.invoice_cnt,
      };
      postApi('customers', reqData, cookies.get('authorization')).then(
        (res) => {
          if (res.status === false) {
            this.setState(
              {
                PromptPrevent: false,
                errorMessage: res.error,
                isDisabled: false,
                servirity: TOAST_SEVERITY.ERROR,
                showErrorToast: true,
              }, () => {
                setTimeout(() => {
                  this.setState({
                    errorBoll: false,
                    PromptPrevent: false,
                  });
                  if (window.location.hash === '#/customers/customer/add-new') {
                    window.location.replace('#/customers/customer-and-brokers');
                  }
                }, 500);
              }
            );
          } else {
            this.setState(
              {
                isDisabled: true,
                showErrorToast: true,
                PromptPrevent: false,
                servirity: TOAST_SEVERITY.SUCCESS,
                successMessage: 'Customer added successfully!',
              },
              function () {
                setTimeout(
                  function () {
                    this.setState({
                      legalBoll: false,
                      PromptPrevent: false,
                      showErrorToast: false
                    });

                    if (
                      window.location.hash === '#/customers/customer/add-new'
                    ) {
                      window.location.replace(
                        '#/customers/customer-and-brokers'
                      );
                    }
                  }.bind(this),
                  500
                );
              }
            );
          }
        }
      );
    }
  };

  handleUpdate = () => {
    if (this.handleValidation()) {
      const { customerValue } = this.state;
      let reqData = {
        id: this.props.match.params.id,
        asset_id: customerValue.asset_id,
        cust_type:
          customerValue.cust_type === 'true' || customerValue.cust_type === true
            ? true
            : false,
        revenue_id: customerValue.revenue_id,
        tax_id: customerValue.tax_id,
        due_days: parseInt(customerValue.due_days),

        name: customerValue.name,
        cont_name: customerValue.cont_name,
        email: customerValue.email,
        phone: customerValue.phone,
        phone2: customerValue.phone2,
        site_url: customerValue.site_url,
        rate: customerValue.rate !== undefined ? customerValue.rate : 0,
        address1: customerValue.address1,
        address2: customerValue.address2,
        billing_country: customerValue.billing_country,
        billing_state: customerValue.billing_state,
        billing_city: customerValue.billing_city,
        billing_zip_code: customerValue.billing_zip_code,
        
        shipping_country: customerValue.shipping_country,
        shipping_state: customerValue.shipping_state,
        shipping_city: customerValue.shipping_city,
        shipping_zip_code: customerValue.shipping_zip_code,
        
        currency_id: customerValue.currency_id,
        tax_no: customerValue.tax_no,
        crm_id: customerValue.crm_id,
        info: customerValue.info,
        eob_id: customerValue.eob_id,
        invoice_by: customerValue.invoice_by,
        service_pln_type: customerValue.service_pln_type,
        broker:
          customerValue.broker === 'true' || customerValue.broker === true
            ? true
            : false,
        broker_id: customerValue.broker_id,
        due_amt: customerValue.due_amt,
        order_cnt: customerValue.order_cnt,
        invoice_cnt: customerValue.invoice_cnt,
      };
      putApiRes(
        `customers/${this.props.match.params.id}`,
        reqData,
        cookies.get('authorization')
      ).then((res) => {
        if (res.status === false) {
          this.setState({
            errorBoll: true,
            PromptPrevent: false,
            successMessage: res.error,
          });
        } else {
          this.setState(
            {
              legalBoll: true,
              PromptPrevent: false,
              successMessage: 'Customer updated successfully!',
            },
            function () {
              setTimeout(
                function () {
                  this.setState({
                    legalBoll: false,
                  });
                  window.location.replace('#/customers/customer-and-brokers');
                }.bind(this),
                3000
              );
            }
          );
        }
      });
    }
  };
  getCustomerByID = () => {
    if (this.props.match !== undefined && this.props.match !== '') {
      if (
        this.props.match.params.id !== '' &&
        this.props.match.params.id !== undefined
      ) {
        getApi(
          `customers/${this.props.match.params.id}`,
          cookies.get('authorization')
        ).then((res) => {
          console.log('by id', res);
          if (res.broker_id !== undefined && res.broker_id != null) {
            this.getAutosuggest(res.broker_id);
          }
          this.setState({
            customerValue: {
              asset_id: res.asset_id,
              cust_type: res.cust_type === true ? true : false,
              revenue_id: res.revenue_id.toString(),
              tax_id: res.tax_id,
              due_days: res.due_days,

              name: res.name,
              cont_name: res.cont_name,
              email: res.email,
              phone: res.phone,
              phone2: res.phone2,
              site_url: res.site_url,

              rate: res.rate !== undefined ? res.rate : 0,
              address1: res.address1,
              address2: res.address2,
              billing_country: res.billing_country?.key,
              billing_state: res.billing_state,
              billing_city: res.billing_city,
              billing_zip_code: res.billing_zip_code,



              shipping_country: res.shipping_country?.key,
              shipping_state: res.shipping_state,
              shipping_city: res.shipping_city,
              shipping_zip_code: res.shipping_zip_code,
              currency_id: res.currency_id,
             
              tax_no: res.tax_no,
              crm_id: res.crm_id,
              info: res.info,
              eob_id: res.eob_id,
              invoice_by: res.invoice_by,
              service_pln_type: res.service_pln_type,
              broker: res.broker === true ? true : false,
              broker_id: parseInt(res.broker_id),
              due_amt: res.due_amt,
              order_cnt: res.order_cnt,
              invoice_cnt: res.invoice_cnt,
            },
          });
        });
      }
    }
  };
  componentWillUnmount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  async componentDidMount() {
    await loadingsShow('block');
    await this.getCountry();
    await this.getCurrency();
    await this.getAssets();
    await this.getTax();
    await this.getRevenue();
    await this.getCustomerByID();
    await this.getCustomerData();
    document.addEventListener('click', this.handleClickOutside, true);
    if (this.props.match !== undefined && this.props.match !== '') {
      if (
        this.props.match.params.id !== '' &&
        this.props.match.params.id !== undefined
      ) {
        setTimeout(
          function () {
            const { customerValue } = this.state;
            this.getState(customerValue.billing_country);
            this.getCity(customerValue.billing_country, customerValue.billing_state);
          }.bind(this),
          3000
        );
      }
    }
    await loadingsShow('none');
  }
  render() {
    const {
      revenueList,
      taxList,
      isDisabled,
      errors,
      errorBoll,
      legalBoll,
      errorMessage,
      successMessage,
      customerValue,
      countryList,
      stateList,
      cityList,
      currencyList,
      bool,
      listData,
      PromptPrevent,
      showErrorToast,
      servirity
    } = this.state;
    const mData = servirity === "error" ? errorMessage : successMessage;
    return (
      <div>
        {showErrorToast && <EzToast {...this.getToastProps(showErrorToast, mData, servirity)} />}
        <SnackbarError errorBoll={errorBoll} errorMessage={errorMessage} />
        <SnackbarSuccess
          legalBoll={legalBoll}
          successMessage={successMessage}
        />
        <div className="paddingbox">
          <div className="containerboxform">
            <div className="title bdrb">
              <h3>
                {this.props.match !== undefined &&
                  this.props.match !== '' &&
                  this.props.match.params.id !== '' &&
                  this.props.match.params.id !== undefined
                  ? 'Update Customer'
                  : 'New Customer'}
              </h3>
            </div>

            <div className="form customer">
              <Col sm="12">
                <Row className="bdrb hide">
                  <Col sm="2">
                    <Form.Group>
                      <Form.Label className="text-right">Customer</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col sm="2">
                    <Form.Group as={Row} className="centercheck">
                      <Form.Check
                        type="radio"
                        onChange={this.handleChangeGen}
                        checked={
                          customerValue.cust_type === 'true' ||
                          customerValue.cust_type === true
                        }
                        value="true"
                        name="cust_type"
                        label={FormLabel.An_Individual}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="2">
                    <Form.Group as={Row} className="centercheck">
                      <Form.Check
                        type="radio"
                        onChange={this.handleChangeGen}
                        value="false"
                        checked={
                          customerValue.cust_type === 'false' ||
                          !customerValue.cust_type
                        }
                        name="cust_type"
                        label={FormLabel.An_Organization}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Name*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="name"
                          value={customerValue.name}
                          onChange={this.handleChangeGen}
                        />
                        <span
                          className={
                            errors['name'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['name']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Currency*
                      </Form.Label>
                      <Col sm="8">
                        <SelectSearch
                          container="rowselect"
                          search
                          options={currencyList}
                          select="test"
                          name="currency_id"
                          onChange={(val) =>
                            this.handleSeachableDropdonw(val, 'currency_id')
                          }
                          value={customerValue.currency_id}
                          placeholder="Select..."
                        />
                        <span
                          className={
                            errors['currency_id'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['currency_id']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="bdrb">
                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Email*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="email"
                          value={customerValue.email}
                          onChange={this.handleChangeGen}
                        />
                        <span
                          className={
                            errors['email'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['email']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Web URL
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="site_url"
                          value={customerValue.site_url}
                          onChange={this.handleChangeGen}
                        />
                        <span
                          className={
                            errors['site_url'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['site_url']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="bdrb">
                  <Col md={6}>
                    <Row>
                      <Col sm="3">
                        <Form.Group>
                          <Form.Label className="text-right">
                            Is Broker ?
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col sm="2">
                        <Form.Group as={Row} className="centercheck">
                          <Form.Check
                            type="radio"
                            onClick={this.handleChangeGen}
                            value="true"
                            checked={
                              customerValue.broker === 'true' ||
                              customerValue.broker === true
                            }
                            name="broker"
                            id="Yes"
                            htmlFor="Yes"
                            label="Yes"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="2">
                        <Form.Group as={Row} className="centercheck">
                          <Form.Check
                            checked={
                              customerValue.broker === 'false' ||
                              !customerValue.broker
                            }
                            type="radio"
                            onClick={this.handleChangeGen}
                            value="false"
                            name="broker"
                            label="No"
                            id="No"
                            htmlFor="No"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Broker
                      </Form.Label>
                      <Col sm="8">
                        <Autosuggestion
                          disabled={
                            customerValue.broker === true ||
                            customerValue.broker === 'true'
                          }
                          name="broker_ids"
                          placeholder="Search broker..."
                          value={customerValue.broker_ids}
                          onChange={this.handleChangeGen}
                          ref={this.wrapperRef}
                          style={{ display: bool ? 'block' : 'none' }}
                          bool={bool}
                          getAutosuggest={listData}
                          onClick={(id, name) =>
                            this.setAutosuggestId(id, name)
                          }
                        />

                        {/* <SelectSearch
                          disabled={
                            customerValue.broker === true ||
                            customerValue.broker === 'true'
                          }
                          container="rowselect"
                          search
                          options={BrokerDropdown}
                          select="test"
                          name="broker_id"
                          autoComplete="sdf"
                          onChange={(val) =>
                            this.handleSeachableDropdonw(val, 'broker_id')
                          }
                          value={customerValue.broker_id}
                          placeholder="Type to search"
                        /> */}

                        <span
                          className={
                            errors['broker_id'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['broker_id']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  {/* <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Default Asset
                      </Form.Label>
                      <Col sm="8">
                        <SelectSearch
                          container="rowselect"
                          search
                          options={defaultAssetList}
                          select="test"
                          name="asset_id"
                          onChange={(val) =>
                            this.handleSeachableDropdonw(val, 'asset_id')
                          }
                          value={customerValue.asset_id}
                          placeholder="Select..."
                        />
                        <span
                          className={
                            errors['asset_id'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['asset_id']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col> */}

                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Default Revenue
                      </Form.Label>
                      <Col sm="8">
                        <SelectSearch
                          container="rowselect"
                          search
                          options={revenueList}
                          select="test"
                          name="revenue_id"
                          onChange={(val) =>
                            this.handleSeachableDropdonw(val, 'revenue_id')
                          }
                          value={customerValue.revenue_id}
                          placeholder="Select..."
                        />
                        <span
                          className={
                            errors['revenue_id'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['revenue_id']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Rate
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="rate"
                          value={customerValue.rate}
                          onChange={this.handleChangeGen}
                        />
                        <span
                          className={
                            errors['rate'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['rate']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="bdrb">
                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Default Tax
                      </Form.Label>
                      <Col sm="8">
                        <SelectSearch
                          container="rowselect"
                          search
                          options={taxList}
                          select="test"
                          name="tax_id"
                          onChange={(val) =>
                            this.handleSeachableDropdonw(val, 'tax_id')
                          }
                          value={customerValue.tax_id}
                          placeholder="Select..."
                        />
                        <span
                          className={
                            errors['tax_id'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['tax_id']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Due Days
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          autoComplete="off"
                          name="due_days"
                          value={customerValue.due_days}
                          onChange={this.handleChangeGen}
                        />
                        <span
                          className={
                            errors['due_days'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['due_days']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="bdrb">
                  <Col md={6}>
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Contact Person
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              name="cont_name"
                              value={customerValue.cont_name}
                              onChange={this.handleChangeGen}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                   
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Alternate Number
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              name="phone2"
                              value={customerValue.phone2}
                              onChange={this.handleChangeGen}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                 
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Phone Number
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              name="phone"
                              value={customerValue.phone}
                              onChange={this.handleChangeGen}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                      <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Other Information
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="info"
                          value={customerValue.info}
                          onChange={this.handleChangeGen}
                        />
                        <span
                          className={
                            errors['info'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['info']}
                        </span>
                      </Col>
                    </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                 
                </Row>

                <Row>
                  <Col md={6}>
                 
                    <Row>
                      <Form.Label column sm="3" className="text-right">
                        Billing address*
                      </Form.Label>
                      <Col sm="8">
                        <Row>
                          <Col sm="12">
                            <Form.Control
                              name="address1"
                              value={customerValue.address1}
                              onChange={this.handleChangeGen}
                              placeholder="Billing address..."
                            />
                            <span
                              className={
                                errors['address1'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['address1']}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                    <Col md={12}>
                

                <Form.Group as={Row}>
                  <Form.Label
                    column
                    sm="3"
                    className="text-right"
                  ></Form.Label>
                  <Col sm="8">
                    <Row>
                      <Col sm="6">
                        <SelectSearch
                          container="rowselect"
                          search
                          options={countryList}
                          select="test"
                          name="billing_country"
                          autoComplete="sdf"
                          onChange={(val) =>
                            this.handleSeachableDropdonw(val, 'billing_country')
                          }
                          value={customerValue.billing_country}
                          placeholder="Select Country"
                        />
                        <span
                          className={
                            errors['billing_country']
                              ? 'errorshow'
                              : 'errorshide'
                          }
                        >
                          {errors['billing_country']}
                        </span>
                      </Col>
                      <Col sm="6">

                      <Form.Control
                          name="billing_state"
                          value={customerValue.billing_state}
                          onChange={this.handleChangeGen}
                          placeholder="Billing State"
                        />
                       
                        <span
                          className={
                            errors['billing_state'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['billing_state']}
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6">
                      <Form.Control
                          name="billing_city"
                          value={customerValue.billing_city}
                          onChange={this.handleChangeGen}
                          placeholder="Billing City"
                        />
                        <span
                          className={
                            errors['billing_city'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['billing_city']}
                        </span>
                      </Col>
                      <Col sm="6">
                        <Form.Control
                          name="billing_zip_code"
                          placeholder="Zip/Postal code"
                          value={customerValue.billing_zip_code}
                          onChange={this.handleChangeGen}
                        />
                        <span
                          className={
                            errors['billing_zip_code']
                              ? 'errorshow'
                              : 'errorshide'
                          }
                        >
                          {errors['billing_zip_code']}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </Col>
                    </Row>


                  
                  </Col>
                  <Col md={6}>
                  
                  <Row>
                      <Form.Label column sm="3" className="text-right">
                        Shipping Address
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="address2"
                          value={customerValue.address2}
                          onChange={this.handleChangeGen}
                          placeholder="Service / Shipping address..."
                        />
                        <span
                          className={
                            errors['address2'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['address2']}
                        </span>
                      </Col>
                    </Row>

                    <Row>
                    <Col md={12}>
                

                <Form.Group as={Row}>
                  <Form.Label
                    column
                    sm="3"
                    className="text-right"
                  ></Form.Label>
                  <Col sm="8">
                    <Row>
                      <Col sm="6">
                        <SelectSearch
                          container="rowselect"
                          search
                          options={countryList}
                          select="test"
                          name="shipping_country"
                          autoComplete="sdf"
                          onChange={(val) =>
                            this.handleSeachableDropdonw(val, 'shipping_country')
                          }
                          value={customerValue.shipping_country}
                          placeholder="Select Country"
                        />
                        <span
                          className={
                            errors['shipping_country']
                              ? 'errorshow'
                              : 'errorshide'
                          }
                        >
                          {errors['shipping_country']}
                        </span>
                      </Col>
                      <Col sm="6">


                      <Form.Control
                          name="shipping_state"
                          value={customerValue.shipping_state}
                          onChange={this.handleChangeGen}
                          placeholder="Shipping State"
                        />
                        
                        <span
                          className={
                            errors['shipping_state'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['shipping_state']}
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6">

                      <Form.Control
                          name="shipping_city"
                          value={customerValue.shipping_city}
                          onChange={this.handleChangeGen}
                          placeholder="Shipping City"
                        />
                      
                        <span
                          className={
                            errors['shipping_city'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['shipping_city']}
                        </span>
                      </Col>
                      <Col sm="6">
                        <Form.Control
                          name="shipping_zip_code"
                          placeholder="Zip/Postal code"
                          value={customerValue.shipping_zip_code}
                          onChange={this.handleChangeGen}
                        />
                        <span
                          className={
                            errors['shipping_zip_code']
                              ? 'errorshow'
                              : 'errorshide'
                          }
                        >
                          {errors['shipping_zip_code']}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </Col>
                    </Row>


                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Tax Number
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="tax_no"
                          value={customerValue.tax_no}
                          onChange={this.handleChangeGen}
                        />
                        <span
                          className={
                            errors['tax_no'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['tax_no']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        CRM ID
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="crm_id"
                          value={customerValue.crm_id}
                          onChange={this.handleChangeGen}
                        />
                        <span
                          className={
                            errors['crm_id'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['crm_id']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* <Row className="bdrb">
                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Company ID
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          placeholder="Link ID of other/ broker company"
                          name="eob_id"
                          value={customerValue.eob_id}
                          onChange={this.handleChangeGen}
                        />
                        <span
                          className={
                            errors['eob_id'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['eob_id']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row> */}

              
                <Row>
                  <Col md={12} className="text-right">
                    <Link
                      className="csreset"
                      to="/customers/customer-and-brokers"
                    >
                      <Button className="secondary-button">Cancel</Button>
                    </Link>
                    <Button
                      className="secondary-button"
                      onClick={this.handleReset}
                    >
                      Reset
                    </Button>

                    <Button
                      disabled={isDisabled}
                      onClick={
                        this.props.match !== undefined &&
                          this.props.match.params !== undefined &&
                          this.props.match.params.id !== undefined
                          ? this.handleUpdate
                          : this.handleSave
                      }
                      className="primary-button"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </div>
        {PromptPrevent ? (
          <Prompt
            message={() =>
              'Moving away from this page will remove any unsaved data. Are you sure?'
            }
          />
        ) : null}
      </div>
    );
  }
}

export default CreateCustomer;
