import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';

import Inspection from '../../components/Inspections';
import { loadingsShow } from '../../Services/PostAPI';
import {
  initializeInspectionScreen,
  fetchInspections,
  deleteInspectionRecord,
  setDeleteConfirmationPopupVisibility,
  downloadAllAttachmentForInspectionId,
  inspectionsFetchFilesByInspectionId,
} from '../../Redux/Actions/inspections';
import { APP_URLS } from '../../constants/urlConstants';
import { INSPECTION_DATE_DATA_KEY } from '../../Redux/Reducers/inspections';

const InspectionsScreen = (props) => {
  const {
    inspectionsListView,
    fetchInspections,
    history,
    initInspectionScreen,
    deleteInspectionRecord,
    isFetchingGridData,
    paginationInfo,
    showPageLevelLoader,
    screenPermissions,
    isInspectionDeletionError,
    isInspectionDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    downloadAllAttachmentForInspectionId,
    fetchInspectionAttachmentsByInspectionId,
  } = props;

  const moreActionProps = {
    isMoreActionDisabled : false,
    downloadEnabled :true,
    moreActionOptions : [],
    onMoreActionChange : null
}


  useEffect(() => {
    initInspectionScreen();
    fetchInspections({ sortField: INSPECTION_DATE_DATA_KEY });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editInspectionRecord = (inspectionId) => {
    const path = generatePath(APP_URLS.INSPECTION.EDIT_INSPECTION, {
      inspectionId,
    });
    history.push(path);
  };

  const createInspectionRecord = () => {
    history.push(APP_URLS.INSPECTION.ADD_INSPECTION);
  };
  

  const inspectionProps = {
    inspectionsListView,
    createInspectionRecord,
    fetchInspections,
    deleteInspectionRecord,
    editInspectionRecord,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isInspectionDeletionError,
    isInspectionDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    downloadAllAttachmentForInspectionId,
    fetchInspectionAttachmentsByInspectionId,
    moreActionProps
  };

  if (showPageLevelLoader) {
    loadingsShow('block');
    return null;
  }
  loadingsShow('none');
  return (
    <>
      <Inspection {...inspectionProps} />;
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initInspectionScreen: () => dispatch(initializeInspectionScreen()),
    fetchInspections: (payload) => dispatch(fetchInspections(payload)),
    deleteInspectionRecord: (payload) =>
      dispatch(deleteInspectionRecord(payload)),
    setDeleteConfirmationPopupVisibility: (payload) =>
    dispatch(setDeleteConfirmationPopupVisibility(payload)),
    downloadAllAttachmentForInspectionId : (payload) => dispatch(downloadAllAttachmentForInspectionId(payload)),
    fetchInspectionAttachmentsByInspectionId: payload =>  dispatch(inspectionsFetchFilesByInspectionId(payload))
    
  };
};

// TODO(Subham):  This selection of data needs improvement, See if we can use UseSelector here.
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    inspectionsListView: selectInspectionsList(state.inspectionsReducer),
    isFetchingGridData: selectIsLoading(state.inspectionsReducer),
    showPageLevelLoader: selectIsLoadingPage(state.inspectionsReducer),
    paginationInfo: selectPaginationInfo(state.inspectionsReducer),
    isPageLevelError: selectIsPageLevelError(state.inspectionsReducer),
    isInspectionDeletionError: selectIsInspectionDeletionError(
      state.inspectionsReducer
    ),
    isInspectionDeletionSuccess: selectIsInspectionDeletionSuccess(
      state.inspectionsReducer
    ),
    shouldOpenDeleteConfirmationPopup: selectShouldOpenDeleteConfirmationPopup(
      state.inspectionsReducer
    ),
  };
};

const selectIsPageLevelError = (inspectionReducer) =>
  inspectionReducer.isPageLevelError;
const selectIsInspectionDeletionError = (inspectionReducer) =>
  inspectionReducer.isInspectionDeletionError;
const selectIsInspectionDeletionSuccess = (inspectionReducer) =>
  inspectionReducer.isInspectionDeletionSuccess;
const selectPaginationInfo = (inspectionReducer) =>
  inspectionReducer.paginationInfo;
const selectIsLoading = (inspectionsReducer) => inspectionsReducer.isLoading;
const selectIsLoadingPage = (inspectionsReducer) =>
  inspectionsReducer.isLoadingPage;
const selectShouldOpenDeleteConfirmationPopup = (inspectionsReducer) =>
  inspectionsReducer.shouldOpenDeleteConfirmationPopup;
const selectInspectionsList = (inspectionsReducer) =>
  inspectionsReducer.inspectionsListView;
export default connect(mapStateToProps, mapDispatchToProps)(InspectionsScreen);
