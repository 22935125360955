import { FETCH_DOCUMENT_DETAIL, INIT_DOCUMENTS,
        FETCH_DOCUMENTS_LIST_START,
        FETCH_DOCUMENTS_LIST_SUCCESS, 
        FETCH_DOCUMENTS_LIST_ERROR, 
        SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
        DELETE_DOCUMENT_RECORD_START,
        DELETE_DOCUMENT_RECORD_SUCCESS,
        DELETE_DOCUMENT_RECORD_ERROR,
        CREATE_DOCUMENT_RECORD,
        TOAST,
        EDIT_DOCUMENT_RECORD,
        CREATE_DOCUMENT_TYPE} from "../../../constants/action-types/documents";
import  * as documents_newService  from '../../../Services/business/documents_newService';
import { 
  fetchDocumentTypesComplete,
  fetchDocumentTypesStart,
  fetchOwnerTypesStart,
  fetchOwnerTypesComplete,
  fetchOwnerStart,
  fetchOwnerComplete
} from "../Dropdown/action.plain";

import { OWNER_TYPES_CODE } from "../../../Fleet/Trips/constants";
import { fetchEmployees } from "../../../Services/business/employeeService";
import {
        transformAsset,
        transformCustomersToOwner,
        transformDocumentTypes,
        transformEmployees,
        transformOwnerTypes,
        transformSupplier
      } from '../../../utils/dropDown/transformtoDropdown'
import { DEFAULT_TOAST_LIFE } from '../../../common/EzToast';
import {
  attachmentsFilesFetchStart,
  attachmentsFilesFetchSuccess,
  downloadingAllAttachmentComplete,
  downloadingAllAttachmentStart
 } from "../common/files";
import { goBack } from "../../../utils/gotoback";

export const initializeDocumentsScreen = () => ({ type: INIT_DOCUMENTS });

export const documentsFetchStart = (payload) => ({
    type: FETCH_DOCUMENTS_LIST_START,
    payload,
  });
  
  export const documentsFetchSuccess = (payload) => ({
    type: FETCH_DOCUMENTS_LIST_SUCCESS,
    payload,
  });
  
  export const documentsFetchError = (payload) => ({
    type: FETCH_DOCUMENTS_LIST_ERROR,
    payload,
  });
  

export const fetchDocuments = (payload) => dispatch => {
      dispatch(documentsFetchStart(payload));
  
      documents_newService.fetchDocuments(payload).then((documentsData) => {
        if (!documentsData.isError) {
          dispatch(documentsFetchSuccess(documentsData));
        } else {
          dispatch(documentsFetchError(documentsData));
        }
      });
};

  export const setDeleteConfirmationPopupVisibility = (payload) => ({
    type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
    payload,
  });


  export const deleteDocumentRecordStart = () => ({
    type: DELETE_DOCUMENT_RECORD_START,
  });
  
  export const deleteDocumentRecordSuccess = (payload) => ({
    type: DELETE_DOCUMENT_RECORD_SUCCESS,
    payload,
  });
  
  export const deleteDocumentRecordError = (payload) => ({
    type: DELETE_DOCUMENT_RECORD_ERROR,
    payload,
  });
  

export const documentsFetchFilesBydocumentId = payload => async (dispatch, getstate) => {
  dispatch(attachmentsFilesFetchStart(payload));
  const {files} = await documents_newService.fetchDocumentsAttachmentByDocumentId(payload);

  if(files) dispatch(attachmentsFilesFetchSuccess(files));
}
 
export const downloadAllAttachmentForDocumentId =  (payload) =>  async (dispatch) => {
  dispatch(downloadingAllAttachmentStart())
  await documents_newService.downloadDocumentsAllAttachment(payload);
  dispatch(downloadingAllAttachmentComplete());
}

export const deleteDocumentRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteDocumentRecordStart());
    documents_newService
      .deleteDocumentRecord({ documentId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteDocumentRecordSuccess());
          dispatch(fetchDocuments(payload?.params));
        } else {
          dispatch(deleteDocumentRecordError(data));
        }
      });
  };
};

export const initCreateNewDocumentScreen = () => {
  return (dispatch) => {
    // dispatch(createNewDocumentScreenInitStart());
    dispatch(fetchDocumentTypes());
  };
};


export const fetchDocumentTypes = (payload) => {
  return async (dispatch) => {
    const { query ,filterField } = payload || {};

    dispatch(fetchDocumentTypesStart())
    let  documentTypes = null;
    const { content: docTypes} =  await documents_newService.fetchDocumentTypes(payload);
    documentTypes = transformDocumentTypes(docTypes);
    // documentTypes = documentTypes?.filter(docType =>
    //    (query && filterField) ?  docType[filterField].toUpperCase().includes(query.toUpperCase()) : true)
    dispatch(fetchDocumentTypesComplete({documentTypes}))
  };
};

export const fetchOwners = (ownerType, payload) => async dispatch => {
  let owners = null;
  dispatch(fetchOwnerStart());
  switch(ownerType){
    case OWNER_TYPES_CODE.CST : {
      const customers = await documents_newService.fetchCustomers(payload);
      owners = transformCustomersToOwner(customers?.content);
    }
    break;
    case OWNER_TYPES_CODE.EMP : {
      const { content } = await fetchEmployees(payload);
      owners = transformEmployees(content);
    }
    break;
    case OWNER_TYPES_CODE.SPLR : {
      const suppliers = await documents_newService.fetchSuppliers(payload);
      owners = transformSupplier(suppliers?.content);
    }
    break;
    case OWNER_TYPES_CODE.AST : {
      const { content } = await documents_newService.fetchAsset(payload);
      owners = transformAsset(content);
    }
    break;
    default : console.log('default case');  
  }
 dispatch(fetchOwnerComplete({owners}));
} 

export const createDocumentRecordStart = () => ({
  type: CREATE_DOCUMENT_RECORD.START,
});

export const createDocumentRecordSuccess = (payload) => ({
  type: CREATE_DOCUMENT_RECORD.SUCCESS,
  payload,
});

export const createDocumentRecordError = (payload) => ({
  type: CREATE_DOCUMENT_RECORD.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createDocument = (payload) => {
  return (dispatch) => {
    dispatch(createDocumentRecordStart());
    documents_newService.createDocumentRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(createDocumentRecordSuccess());
        goBack()
      } else {
        dispatch(createDocumentRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const documentDetailFetchStart = (payload) => { 
  return {
  type: FETCH_DOCUMENT_DETAIL.START,
  payload,
}}

export const documentDetailFetchSuccess = (payload) => ({
  type: FETCH_DOCUMENT_DETAIL.SUCCESS,
  payload,
});

export const documentDetailFetchError = (payload) => ({
  type: FETCH_DOCUMENT_DETAIL.ERROR,
  payload,
});

export const fetchDocumentByDocumentId = (payload) => {
  return async (dispatch) => {
    dispatch(documentDetailFetchStart(payload));

    let documentData = await documents_newService.fetchDocumentByDocumentId(payload);
    const ownr_id = await fetchOwner(documentData?.ownr_typ?.name,documentData.owner);
    documentData = {...documentData,ownr_id};

      if (!documentData.isError) {
        dispatch(documentDetailFetchSuccess(documentData));
      } else {
        dispatch(documentDetailFetchError(documentData));
      } 
  };
};

export const editDocumentRecordStart = () => ({
  type: EDIT_DOCUMENT_RECORD.START,
});

export const editDocumentRecordSuccess = (payload) => ({
  type: EDIT_DOCUMENT_RECORD.SUCCESS,
  payload,
});

export const editDocumentRecordError = (payload) => ({
  type: EDIT_DOCUMENT_RECORD.ERROR,
  payload,
});

export const editDocument = (payload) => {
  return (dispatch) => {
    dispatch(editDocumentRecordStart());
    documents_newService.editDocumentRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(editDocumentRecordSuccess());
        goBack()
      } else {
        dispatch(editDocumentRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };  
};

export const fetchOwner = async (ownerType,ownerId) => {
  const id = ownerId;
  switch(ownerType) {
    case OWNER_TYPES_CODE.SPLR :  {
      const {last_name ,middle_name,name} = await documents_newService.fetchSuppliers({id},true);
      return `${name || ''} ${middle_name || ''} ${last_name || ''}`;
    }
    case OWNER_TYPES_CODE.CST: {
      const {name} = await documents_newService.fetchCustomers({id},true);
      return `${name || ''}`;
    }
    case OWNER_TYPES_CODE.EMP : {
      const {first, last} = await fetchEmployees({id},true);
      return `${first || ''} ${last || ''}`;
    }
    case OWNER_TYPES_CODE.AST : {
      const {asset_code} = await documents_newService.fetchAsset({id},true);
      return `${asset_code || ''}`
    }
    default:
      // no-op  
  }

}

export const createdDocumentTypeStart = () => ({
  type: CREATE_DOCUMENT_TYPE.START
});

export const createDocumentTypeSuccess = (payload) => ({
  type: CREATE_DOCUMENT_TYPE.SUCCESS,
  payload,
});

export const createDocumentTypeError = (payload) => ({
  type: CREATE_DOCUMENT_TYPE.ERROR,
  payload,
});

export const createDocumentType = (payload, callBack) => {
  return (dispatch) => {
    dispatch(createdDocumentTypeStart());
    documents_newService.createDocumentType(payload).then((data) => {
      if (!data.isError) {
        dispatch(createDocumentTypeSuccess())
        callBack && callBack()
      } else {
        dispatch(createDocumentTypeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editDocumentType = (payload,documentTypeId, callBack) => {
  return (dispatch) => {
    dispatch(createdDocumentTypeStart());
    documents_newService.editDocumentType(payload, documentTypeId).then((data) => {
      if (!data.isError) {
        dispatch(createDocumentTypeSuccess())
        callBack && callBack();
      } else {
        dispatch(createDocumentTypeError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};