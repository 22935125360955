import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';

import Accident from '../../components/Accidents';
import { loadingsShow } from '../../Services/PostAPI';
import {
  initializeAccidentScreen,
  fetchAccidents,
  deleteAccidentRecord,
  setDeleteConfirmationPopupVisibility,
  downloadAllAttachmentByAccidentId,
  fetchFilesByAccidentId,
} from '../../Redux/Actions/accidents';
import { APP_URLS } from '../../constants/urlConstants';
import { ACCIDENT_DATE_DATA_KEY } from '../../Redux/Reducers/accidents';


const AccidentsScreen = (props) => {
  const {
    accidentsListView,
    fetchAccidents,
    history,
    initAccidentScreen,
    deleteAccidentRecord,
    isFetchingGridData,
    paginationInfo,
    showPageLevelLoader,
    screenPermissions,
    isAccidentDeletionError,
    isAccidentDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    downloadAllAttachmentByAccidentId,
    fetchAccidentAttachmentsByAccidentId,
  } = props;

  
  useEffect(() => {
    initAccidentScreen();
    fetchAccidents({ sortField: ACCIDENT_DATE_DATA_KEY });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editAccidentRecord = (accidentId) => {
    const path = generatePath(APP_URLS.ACCIDENTS.EDIT_ACCIDENT, { accidentId });
    history.push(path);
  };

  const createAccidentRecord = () => {
    history.push(APP_URLS.ACCIDENTS.ADD_ACCIDENT);
  };

  const moreActionProps = {
        isMoreActionDisabled : false,
        downloadEnabled :true,
        moreActionOptions : [],
        onMoreActionChange : null
  }

  const accidentProps = {
    accidentsListView,
    createAccidentRecord,
    fetchAccidents,
    deleteAccidentRecord,
    editAccidentRecord,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isAccidentDeletionError,
    isAccidentDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    downloadAllAttachmentByAccidentId,
    fetchAccidentAttachmentsByAccidentId,
    moreActionProps
  };
  
  if (showPageLevelLoader) {
    loadingsShow('block');
    return null;
  }
  loadingsShow('none');
  return (
    <>
      <Accident {...accidentProps} />;
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initAccidentScreen: () => dispatch(initializeAccidentScreen()),
    fetchAccidents: (payload) => dispatch(fetchAccidents(payload)),
    deleteAccidentRecord: (payload) => dispatch(deleteAccidentRecord(payload)),
    setDeleteConfirmationPopupVisibility: (payload) => dispatch(setDeleteConfirmationPopupVisibility(payload)),
    downloadAllAttachmentByAccidentId : (payload) => dispatch(downloadAllAttachmentByAccidentId(payload)),
    fetchAccidentAttachmentsByAccidentId: payload =>  dispatch(fetchFilesByAccidentId(payload))
  };
};

// TODO(Subham):  This selection of data needs improvement, See if we can use UseSelector here.
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    accidentsListView: selectAccidentsList(state.accidentsReducer),
    isFetchingGridData: selectIsLoading(state.accidentsReducer),
    showPageLevelLoader: selectIsLoadingPage(state.accidentsReducer),
    paginationInfo: selectPaginationInfo(state.accidentsReducer),
    isPageLevelError: selectIsPageLevelError(state.accidentsReducer),
    isAccidentDeletionError: selectIsAccidentDeletionError(
      state.accidentsReducer
    ),
    isAccidentDeletionSuccess: selectIsAccidentDeletionSuccess(
      state.accidentsReducer
    ),
    shouldOpenDeleteConfirmationPopup: selectShouldOpenDeleteConfirmationPopup(
      state.accidentsReducer
    ),
    files:selectAttachmentFiles(state.accidentsReducer)
  };
};

const selectIsPageLevelError = (accidentReducer) =>
  accidentReducer.isPageLevelError;
const selectIsAccidentDeletionError = (accidentReducer) =>
  accidentReducer.isAccidentDeletionError;
const selectIsAccidentDeletionSuccess = (accidentReducer) =>
  accidentReducer.isAccidentDeletionSuccess;
const selectPaginationInfo = (accidentReducer) =>
  accidentReducer.paginationInfo;
const selectIsLoading = (accidentsReducer) => accidentsReducer.isLoading;
const selectIsLoadingPage = (accidentsReducer) =>
  accidentsReducer.isLoadingPage;
const selectAccidentsList = (accidentsReducer) =>
  accidentsReducer.accidentsListView;
const selectShouldOpenDeleteConfirmationPopup = (accidentsReducer) =>
  accidentsReducer.shouldOpenDeleteConfirmationPopup;
export const selectAttachmentFiles = (accidentsReducer) => accidentsReducer.attachmentsfiles

export default connect(mapStateToProps, mapDispatchToProps)(AccidentsScreen);
