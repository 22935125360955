import React, { Component } from 'react';
import SnackbarError from '../common/SnackbarError';
import SnackbarSuccess from '../common/SnackbarSuccess';
import { Col, Form, Row, Button } from 'react-bootstrap';
import SelectSearch from 'react-select-search';
import Cookies from 'universal-cookie';

import { getApiAsyn, loadierShow } from '../Services/PostAPI';
const cookies = new Cookies();
class AddBorderConnect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: JSON.parse(localStorage.getItem('menuAccess')),
      UIValue: {},
      errors: {},
      formOption: {},
      TripDropdonw: [],
      FiltredTrip: {},
      Data: [],
    };
  }
  handelMessage = (Message, Boll) => {
    const { formOption } = this.state;
    formOption[Boll] = true;
    formOption['Message'] = Message;
    this.setState(
      {
        formOption: formOption,
      },
      function () {
        setTimeout(
          function () {
            formOption[Boll] = false;
            this.setState({
              formOption: formOption,
            });
          }.bind(this),
          3000
        );
      }
    );
  };
  handleSeachableDropdonw = (val, name) => {
    const { UIValue, TripDropdonw } = this.state;
    UIValue[name] = val;
    this.setState({
      [UIValue]: UIValue,
      PromptPrevent: true,
    });
    if (name === 'trip') {
      let ds = TripDropdonw.filter((item) => item.value === val);
      console.log(ds[0]);
      this.setState({
        FiltredTrip: ds[0],
      });
    }
  };

  handleChangeGen = (event) => {
    const { UIValue } = this.state;
    UIValue[event.target.name] = event.target.value;
    this.setState({
      [UIValue]: UIValue,
    });
  };
  getTripList = async () => {
    let dddata = [];
    let istSearchList = '';
    let res = await getApiAsyn(
      `trips?intent=load&paged=true&page=1&size=10000`,
      cookies.get('authorization')
    );
    if (res && res.status !== false) {
      console.log('DRV', res);
      istSearchList = res.page.total_elements;
      if (istSearchList > 0) {
        dddata = res._embedded.content;
        dddata = dddata.map((item) => ({
          ...item,
          name: item.trip_no,
          value: item.id,
        }));

        this.setState({
          TripDropdonw: dddata,
        });
      }
    }
  };
  loadWebSocket = (type) => {
    const { FiltredTrip, UIValue } = this.state;
    const API_KEY = 'a-19120-3fd652d1f21d128a';
    loadierShow('block');
    let trailer = [
      {
        number: FiltredTrip.trailer1.vehicle_no,
        type: 'TL',
        vinNumber: FiltredTrip.trailer1.vehicle_vin,
        licensePlate: {
          number: FiltredTrip.trailer1.vehicle_no,
          stateProvince: 'ON',
        },
      },
    ];
    let drivers = [
      {
        driverNumber: FiltredTrip.driver1.code,
        firstName: FiltredTrip.driver1.first,
        lastName: FiltredTrip.driver1.last,
        gender: FiltredTrip.driver1.gender === 165 ? 'M' : 'F',
        dateOfBirth: FiltredTrip.driver1.date_of_birth,
        citizenshipCountry: FiltredTrip.driver1.citizenship,
        fastCardNumber: '42705555555501',
      },
    ];
    if (
      FiltredTrip.driver2 != null &&
      FiltredTrip.driver2 !== '' &&
      FiltredTrip.driver2 !== undefined
    ) {
      drivers.push({
        driverNumber: FiltredTrip.driver2.code,
        firstName: FiltredTrip.driver2.first,
        lastName: FiltredTrip.driver2.last,
        gender: FiltredTrip.driver2.gender === 165 ? 'M' : 'F',
        dateOfBirth: FiltredTrip.driver2.date_of_birth,
        citizenshipCountry: FiltredTrip.driver2.citizenship,
        fastCardNumber: '42705555555501',
      });
    }
    let shipments = [
      {
        data: 'ACE_SHIPMENT',
        sendId: '003',
        companyKey: API_KEY,
        operation: 'CREATE',
        type: 'PAPS',
        shipmentControlNumber: FiltredTrip.trip_no,
        provinceOfLoading: 'NU',
        shipper: {
          name: 'Art place',
          address: {
            addressLine: '1234 Vancity',
            city: 'Vancouver',
            stateProvince: 'BC',
            postalCode: 'V6H 3J9',
          },
        },
        consignee: {
          name: 'Elk Corp of Texas',
          address: {
            addressLine: '401 Weavertown Rd',
            city: 'Myerstown',
            stateProvince: 'PA',
            postalCode: '17067',
          },
        },
        commodities: [
          {
            loadedOn: {
              type: 'TRAILER',
              number: '0456',
            },
            description: 'Books',
            quantity: 35,
            packagingUnit: 'BOX',
            weight: 1500,
            weightUnit: 'L',
          },
        ],
      },
    ];
    if (
      FiltredTrip.trailer2 != null &&
      FiltredTrip.trailer2 !== '' &&
      FiltredTrip.trailer2 !== undefined
    ) {
      trailer.push({
        number: FiltredTrip.trailer2.vehicle_no,
        type: 'TL',
        vinNumber: FiltredTrip.trailer2.vehicle_vin,
        licensePlate: {
          number: FiltredTrip.trailer2.vehicle_no,
          stateProvince: 'ON',
        },
      });
    }
    let reqData = {
      data: 'ACE_TRIP',
      sendId: FiltredTrip.id,
      companyKey: API_KEY,
      operation: type,
      tripNumber: FiltredTrip.trip_no,
      usPortOfArrival: UIValue.usPortOfArrival,
      estimatedArrivalDateTime: FiltredTrip.items[0].arv_date,
      truck: {
        number: FiltredTrip.vehicle.vehicle_no,
        type: 'TR',
        vinNumber: FiltredTrip.vehicle.vehicle_vin,
        licensePlate: {
          number: FiltredTrip.vehicle.vehicle_no,
          stateProvince: 'ON',
        },
      },
      trailers: trailer,
      drivers: drivers,
      shipments: shipments,
      autoSend: false,
    };

    //console.log(reqData);

    const socket = new WebSocket(
      'wss://borderconnect.com/api/sockets/ABLogistics'
    );

    // on connection open, send api key
    socket.addEventListener('open', function (event) {
      // console.log('Sending handshake with API key [' + API_KEY + ']');
      socket.send(`{ "apiKey": "${API_KEY}" }`);
    });

    socket.addEventListener(
      'message',
      function (event) {
        const parsedJson = JSON.parse(event.data);
        // console.log(
        //   'Received response with status [' + parsedJson.status + ']',
        //   parsedJson
        // );
        console.log(parsedJson);
        if (parsedJson.status === 'DATA_ERROR') {
          loadierShow('none');
          this.setState({
            Data: parsedJson.errors,
          });
        }
        if (parsedJson.status === 'IMPORTED') {
          loadierShow('none');
          this.handelMessage(
            'Trip successfully connected with border connect!',
            'legalBoll'
          );
        }

        if (parsedJson.status && parsedJson.status === 'OK') {
          // console.log('Sending example ACE trip');
          socket.send(JSON.stringify(reqData));
        }
      }.bind(this)
    );
  };

  componentDidMount() {
    this.getTripList();
  }
  render() {
    const { errors, UIValue, formOption, TripDropdonw, Data } = this.state;
    return (
      <div>
        <SnackbarError
          errorBoll={formOption.errorBoll}
          errorMessage={formOption.Message}
        />

        <SnackbarSuccess
          legalBoll={formOption.legalBoll}
          successMessage={formOption.Message}
        />
        <div className="paddingbox">
          <div className="containerboxform">
            <div className="title bdrb">
              <h3>Border Connect</h3>
            </div>

            <div className="form">
              <Row>
                <Col sm="6">
                  <Row>
                    <Col md="12" className="mrb30">
                      <Form.Group as={Row}>
                        <Form.Label column sm="3" className="text-right">
                          Trip
                        </Form.Label>
                        <Col sm="8">
                          <SelectSearch
                            container="rowselect"
                            search
                            options={TripDropdonw}
                            select="test"
                            name="trip"
                            autoComplete="sdf"
                            onChange={(val) =>
                              this.handleSeachableDropdonw(val, 'trip')
                            }
                            value={UIValue.trip}
                            placeholder="Select trip"
                          />
                          <span
                            className={
                              errors['trip'] ? 'errorshow' : 'errorshide'
                            }
                          >
                            {errors['trip']}
                          </span>
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col sm="12">
                      <Form.Group as={Row}>
                        <Form.Label column sm="3" className="text-right">
                          Port Of Arrival*
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            name="usPortOfArrival"
                            value={UIValue.usPortOfArrival}
                            onChange={this.handleChangeGen}
                          />
                          <span
                            className={
                              errors['usPortOfArrival']
                                ? 'errorshow'
                                : 'errorshide'
                            }
                          >
                            {errors['usPortOfArrival']}
                          </span>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="3"></Col>
                    <Col md={9} className="text-left">
                      <Button
                        onClick={() => this.loadWebSocket('CREATE')}
                        className="primary-button"
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  {Data.length > 0 ? (
                    <div className="errorMessages">
                      <ul>
                        {Data.map((item, index) => (
                          <li key={'item_' + index}>
                            <strong>{item.identifier}</strong> - {item.note}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddBorderConnect;
