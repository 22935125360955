import Cookies from "universal-cookie";
import { API_URL } from "../../../constants/urlConstants";
import { APIError, handleAPIError } from "../../common/errorHandler";
import { getApiAsyn, postApiAsyn, putApiAsyns } from "../../PostAPI";
import { extractData } from "../../utils";

const cookies = new Cookies();

export const createAssetTrack = async ({reqBody}) => {
  const url = API_URL.ASSET_TRACKING.CREATE_ASSET_TRACK
  try {
    let result = await postApiAsyn(url, reqBody, cookies.get('authorization'));
    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
}

export const fetchAssetTrackerByAssetId = async (payload) => {
  const url = API_URL.ASSET_TRACKING.FETCH_ASSET_TRACK_ID.replace(':id',payload?.assetId);
  try {
    let result = await getApiAsyn(url, cookies.get('authorization'));

    if (result?.error) {
      throw new APIError(result);
    }
    result = extractData(result, !payload?.filters);
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const editAssetTracker = async ({reqBody}) => {
  const { id } = reqBody ;
  const url = API_URL.ASSET_TRACKING.EDIT_ASSET_TRACK_ID.replace(':id', id)
  try {
    let result = await putApiAsyns(url, reqBody, cookies.get('authorization'));
    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
}