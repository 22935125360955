import {
  INIT_TYPES,
  FETCH_TYPES_START,
  FETCH_TYPES_SUCCESS,
  FETCH_TYPES_ERROR,
} from '../../../constants/action-types/types';

const initalState = {
  isFetchingTypes: false,
  errorInFetchingTypes: false,
  types: [],
  typess:{},
  error: null,
};

const resetBooleanStates = (overrides = {}) => {
  return {
    isFetchingTypes: false,
    errorInFetchingTypes: false,
    ...overrides,
  };
};

export const typesReducer = (state = initalState, action) => {
  switch (action.type) {
    case INIT_TYPES: {
      return { ...initalState };
    }

    case FETCH_TYPES_START: {
      return {
        ...state,
        ...resetBooleanStates({ isFetchingTypes: true }),
      };
    }

    case FETCH_TYPES_SUCCESS: {
      const { payload, typesKey } = action;
      return {
        ...state,
        ...resetBooleanStates(),
        types:{
          ...state?.types,
          [typesKey]:payload
        }
      };
    }

    case FETCH_TYPES_ERROR: {
      const { error } = action;

      return {
        ...state,
        ...resetBooleanStates({ errorInFetchingTypes: true }),
        error,
      };
    }
    default:
      return state;
  }
};
