import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';
import AssetLocation from '../../components/AssetLocation';
import { loadingsShow } from '../../Services/PostAPI';
import {
  initializeAssetLocationScreen,
  fetchAssetLocations
} from '../../Redux/Actions/asset-location';
import { APP_URLS } from '../../constants/urlConstants'
import { 
   selectAssetLocationsList,
   selectIsAssetLocationDetailSuccess,
   selectIsEditAssetLocationSuccess,
   selectIsLoading,
   selectIsLoadingPage, 
   selectIsPageLevelError, 
   selectPaginationInfo
  } from '../../Redux/Selectors/assets_new/asset_location';
import { useState } from 'react';
import { createLabelValueObj } from '../../common/ContextMenu/utils';
import EzDialog from '../../common/EzDialog';
import EditAssetLocation from './EditAssetLocation';


const AssetLocationsScreen = (props) => {
  const {
    assetLocationsListView,
    fetchAssetLocations,
    isFetchingGridData,
    paginationInfo,
    showPageLevelLoader,
    screenPermissions,
    isPageLevelError,
    isLoading,
    initAssetLocationScreen,
    history,
    isEditAssetLocationSuccess
  } = props;  
  
  const [showdialog, setShowDialog] = useState(false);
  const [assetLocationId, setAssetLocationId] = useState('');
  
  useEffect(() => {
    initAssetLocationScreen();
    fetchAssetLocations();
  }, [fetchAssetLocations, initAssetLocationScreen]);

  const onHide = () => {
    setShowDialog(false);
    // history.go(0);
    history.push(APP_URLS.ASSETS_LOCATION.HOME)
  };

  const onEditAssetLocation = (id) => {
   setShowDialog(true);
   setAssetLocationId(id)
  }

  const moreActionProps = {
      isMoreActionDisabled: false,
      downloadEnabled: false,
      moreActionOptions: [createLabelValueObj('Show History', 'Show History')],
      onMoreActionChange: (action, rowdata) => {
        const path = generatePath(APP_URLS.ASSETS_LOCATION.HISTORY, { id: rowdata.assetId });
        history.push(path);
      }
  } 
  
  // if(isEditAssetLocationSuccess)  onHide(); 

  const assetLocationProps = {
    assetLocationsListView,
    onEditAssetLocation,  
    fetchAssetLocations,
    isFetchingGridData,
    paginationInfo,
    screenPermissions, 
    isPageLevelError,
    moreActionProps,
  };

  if (showPageLevelLoader) {
    loadingsShow('block');
    return null;
  }
  loadingsShow('none');
  return (
    <>
      <AssetLocation {...assetLocationProps} />;
      {
        showdialog &&
        <EzDialog  
          header = {() => <div> Edit Asset Location </div> }
          width = {'50vw'}
          height = {'300px'}
          onHide = {onHide}
          isLoading = {isLoading}
          maximizable = {false}
          >
          {
            <EditAssetLocation
              closeDialog = {onHide}
              assetLocationId={assetLocationId}
          />
          } 
        </EzDialog>
      }
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initAssetLocationScreen: () => dispatch(initializeAssetLocationScreen()),
    fetchAssetLocations: (payload) => dispatch(fetchAssetLocations(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isEditAssetLocationSuccess: selectIsEditAssetLocationSuccess(state),
    assetLocationsListView: selectAssetLocationsList(state),
    isFetchingGridData: selectIsLoading(state),
    showPageLevelLoader: selectIsLoadingPage(state),
    paginationInfo: selectPaginationInfo(state),
    isPageLevelError: selectIsPageLevelError(state),
    isAssetLocationDetailSuccess: selectIsAssetLocationDetailSuccess(state),
    isLoading: selectIsLoading(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetLocationsScreen);
