import { INIT_DOCUMENTS, FETCH_DOCUMENT_DETAIL ,
  FETCH_DOCUMENTS_LIST_START,
  FETCH_DOCUMENTS_LIST_SUCCESS, 
  FETCH_DOCUMENTS_LIST_ERROR, 
  DELETE_DOCUMENT_RECORD_ERROR,
  DELETE_DOCUMENT_RECORD_SUCCESS,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  CREATE_DOCUMENT_RECORD,
  EDIT_DOCUMENT_RECORD,
  DELETE_DOCUMENT_RECORD_START,
  TOAST} from "../../../constants/action-types/documents";

  import { decorateEmptyGridValues, setColumnDefaultExtraProps } from '../../../utils/table/gridUtils';

  const formFieldValueMap = {
    "type": '',
    "date": '',
    "info": '',
    "ref_no": '',
    "ownr_typ": '',
    "ownr_id": '',
    "files":[]
  }
  
  
  export  const DOCUMENT_DATE_KEY = 'date';
  const isDateField = (key) => {
    const dateFields = [DOCUMENT_DATE_KEY];
    return dateFields.includes(key);
  };

  const tranformToDocumentGridView = (documentData) => {
    const rows = documentData.map((document) => {
      const {
        id,
        type,
        date,
        info,
        ref_no
      } = document;
  
      const transformedValues = {
        id,
        type : type?.name,
        date,
        ref_no,
        info
      };

      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: 'Column Id',
        dataKey: 'id',
        extraProps: setColumnDefaultExtraProps({
          hidden: true,
          isSortable: false,
          shouldFilter: false,
        }),
      },
      {
        colLabel: 'Date',
        dataKey: 'date',
        extraProps: setColumnDefaultExtraProps({
          isDateField: true,
        }),
      },
     
      {
        colLabel: 'Ref No#',
        dataKey: 'ref_no',
        extraProps: setColumnDefaultExtraProps({ filterField: 'ref_no' }),
      },
      {
        colLabel: 'Info',
        dataKey: 'info',
        extraProps: setColumnDefaultExtraProps({ filterField: 'info' }),
      },
      {
        colLabel: 'Document Type',
        dataKey: 'type',
        extraProps: setColumnDefaultExtraProps({
          isSortable: false
        }),
      },
    ];
  
    return {
      documentsListView: { columns, rows }
    };
  };

const INITIAL_STATE = {
  documentsListView: {},
  isLoading: false,
  isLoadingPage: true,
  error: null,
  isCreateDocumentSuccess: false,
  isCreateDocumentError: false,
  formFieldValueMap
};

export const documentsReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case INIT_DOCUMENTS:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case DELETE_DOCUMENT_RECORD_START:
    case EDIT_DOCUMENT_RECORD.START:
    case CREATE_DOCUMENT_RECORD.START:
    case FETCH_DOCUMENTS_LIST_START: return { ...state, isLoading: true }

    case FETCH_DOCUMENTS_LIST_SUCCESS: {
      const { page, content } = action.payload;
      let resetState = {
        documentsListView: null,
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isCreateDocumentSuccess: false,
        isCreateDocumentError: false,
      };

      if (!content) {
        return resetState;
      }
      const { number, size, total_elements: totalElements, total_pages: totalPages } = page;
      const { documentsListView } = tranformToDocumentGridView(content);

      return {
        ...resetState,
        documentsListView,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages,
          pageNumber: number,
        },
      };
    }

    case FETCH_DOCUMENTS_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        documentsListView: {},
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isAccidentDeletionSuccess: false,
        isAccidentDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }
    
    case DELETE_DOCUMENT_RECORD_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isDocumentDeletionSuccess: false,
        isDocumentDeletionError: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_DOCUMENT_RECORD_SUCCESS: {
      return {
        ...state,
        isDocumentDeletionSuccess: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
      const shouldOpenDeleteConfirmationPopup = action.payload;
      return {
        ...state,
        shouldOpenDeleteConfirmationPopup,
        isDocumentDeletionError: false,
        isDocumentDeletionSuccess: false,
      };
    }

    // case INIT_CREATE_NEW_ACCIDENT_SCREEN: {
    //   return { ...INITIAL_STATE };
    // }

    case CREATE_DOCUMENT_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isCreateDocumentSuccess: false,
        isCreateDocumentError: true,
      };
    }
    case CREATE_DOCUMENT_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isCreateDocumentSuccess: true,
        isCreateDocumentError: false,
      };
    }

    case FETCH_DOCUMENT_DETAIL.SUCCESS: {
      const temp = {};

      Object.keys(formFieldValueMap).forEach((key) => {
        const value = action.payload[key];
        temp[key] = isDateField(key) ? new Date(value) : value;
      });

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isDocumentDetailSuccess: true,
      };
    }

    case FETCH_DOCUMENT_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isDocumentDetailSuccess: false,
        isDocumentDetailError: true,
      };
    }

    case EDIT_DOCUMENT_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditDocumentSuccess: false,
        isEditDocumentError: true,
      };
    }

    case EDIT_DOCUMENT_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditDocumentSuccess: true,
        isEditDocumentError: false,
      };
    }

    case TOAST.RESET: {
      return {
        ...state,
        isCreateAccidentSuccess: false,
        isCreateAccidentError: false,
        isEditDocumentError: false,
        isEditDocumentSuccess: false,
        isAccidentDetailError: false,
      };
    }
    default:
      return state;
  }
};