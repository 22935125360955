/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useForm} from 'react-hook-form';
import { Col, Row, Container } from 'react-bootstrap';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import EzControlledCheckBoxWithLabel from '../../../common/EzForm/EzControlledCheckBox/WithLabel';
import EzControlledInputWithFloatingLabel from '../../../common/EzForm/EzControlledInput/WithFloatingLabel';
import EzFileUpload from '../../../common/EzFileUpload';

import { Divider } from 'primereact/divider'; 
import './index.css';
import { EzToast, TOAST_SEVERITY } from '../../../common/EzToast';
import Province from '../../../containers/common/Province';
import EzControlledDropDowntWithFloatingLabel from '../../../common/EzForm/EzControlledDropDown/WithFloatingLabel';
import { getFormBaseValidationRule } from '../../../utils/form/form-utils';
import Suppliers from '../../../containers/common/Suppliers';
import { range } from '../../../utils/helper';
import { createLabelValueObj } from '../../../common/ContextMenu/utils';
import CreateMakeAndModalPopup, { DIALOG_VIEW_MODE } from './CreateMakeAndModalPopup';

const vehicleTypeTrailer = 213;
const CreateAsset = (props) => {
  
  const {
    formFieldValueMap,
    createAsset,
    editAsset,
    navigateToAssetSummaryScreen,
    isCreateAssetSuccess,
    isEditAssetSuccess,
    assetId,
    isEdit,
    setIsformDirty,
    vehcileType,
    equipmentType,
    isAssetDetailSuccess,
    companyId,
    suppliers = [],
    make,
    model
  } = props
  
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [makeAndModelDialogViewMode, setMakeAndModelDialogViewMode] = useState(null);
  const [modelMapToMake, setModelMapToMake] = useState([]);
  const [isMakeAndModalPopupVisble, setMakeAndModalPopupVisibility] = useState(false);
  const removedFiles = [];
  
  const currentYear = new Date().getFullYear();
  const years = range(1990, currentYear)
                .reverse()
                .map(year => createLabelValueObj(year.toString(), year.toString()));
              
  const onSubmit = (data) => {
    const {
      asset_code,
      vehicle_typ,
      vehicle_vin,
      province,
      vehicle_no,
      other_party,
      info,
      equip_type,
      vendor,
      year,
      asset_make,
      asset_model,
      fleet_card1,
      fleet_card2
      } = data || {};

    const dataPayload = {
        files: removedFiles,
        asset_code,
        owner_typ : 'C',
        asset_typ: 74,
        asset_own_typ: 6,
        asset_owner: companyId?.id,      
        vehicle_typ,
        vehicle_vin,
        province,  
        vehicle_no,
        other_party,
        info,
        equip_type:equip_type!==""? equip_type: null,
        vendor: vendor?.extraData?.id || suppliers.find(supplier => supplier.value === vendor)?.extraData?.id,
        year,
        asset_make,
        asset_model,
        fleet_card1,
        fleet_card2
     }

      if (isEdit) {
      editAsset({
        assetId,
        data: dataPayload,
        ...(attachedFiles ? { files: attachedFiles } : {}),
      });
    } else {
      createAsset({
        data: dataPayload,
        files: attachedFiles,
      });
   }
  };

  const {
    control,
    formState: { errors , isDirty},
    handleSubmit,
    setValue,
    watch,
    reset
  } = useForm({ defaultValues: useMemo(()=> formFieldValueMap, [formFieldValueMap]) });
  const vehicleTypeWatcher = watch("vehicle_typ", false);  
  const watchOtherType = watch('other_party');

  const watchMakeSelected = watch('asset_make');

  useEffect( () => {
    const modeltoMake = model?.filter( mod => mod?.extraData.make?.id === watchMakeSelected);
    modeltoMake?.length ? setModelMapToMake(modeltoMake): setModelMapToMake('');
  }, [watchMakeSelected])

  const showModelMapToMake = () => watchMakeSelected && !!modelMapToMake?.length;
  const getFormErrorMessage = (name) => {
    return ( errors[name] && <small className="p-error">{errors[name].message}</small> );
  };

  useEffect(() => {
    if (isEditAssetSuccess || isCreateAssetSuccess) {
      reset();
      //navigateToAssetSummaryScreen()
    }
  }, [isCreateAssetSuccess, isEditAssetSuccess]);
  useEffect(() => {
    if (isAssetDetailSuccess) {
      reset({
        ...formFieldValueMap,
        province:formFieldValueMap?.province?.key,
        vehicle_typ:formFieldValueMap?.vehicle_typ?.id,
        equip_type:formFieldValueMap?.equip_type?.id,
        vendor: formFieldValueMap?.vendor?.name,
        asset_make: formFieldValueMap?.asset_make?.id,
        asset_model: formFieldValueMap?.asset_model?.id,
        ownership: formFieldValueMap?.formFieldValueMap?.name
      });
      setAttachedFiles(formFieldValueMap?.files); 
      }
  }, [isAssetDetailSuccess]);

  useEffect(()=> setIsformDirty(isDirty),[isDirty]);
      
  const [showErrorToast, setErrorToast] = useState(false);
  const getToastProps = () => {
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle: 'Please fill all mandatory fields',
      shouldShowToast: true,
    };
  };

  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrorToast(true);
    }
  }, [errors]);

  const renderPageHeaderSection = () => {
    const breadCrumbs = [
      { label: 'Asset' },
      { label: isEdit ? 'Edit Asset' : 'Create Asset' },
    ];

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };

    return (  
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />
        <div className="rightbtn">
          <Button
            tooltip={'Back to asset summary'}
            tooltipOptions={{ position: 'left' }}
            label={''}
            icon={'pi pi-arrow-left'}
            className={`mr-3 p-button-rounded p-button-outlined p-button-info`}
            onClick={navigateToAssetSummaryScreen}
          />
        </div>
      </div>
    );
  };

  const renderAsset = () => {
    const name = 'asset_code';
    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        disabled = {isEdit}
        control={control}
        label={'Asset Code'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderVehicleVinNo = () => {
    const name = 'vehicle_vin';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'VIN'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderFileUploader = () => {
    const onSelectFile = (e, field) => {
      setAttachedFiles([...e.files]);
    };
  
    const onRemoveFile = (fileTobeRemoved) => {
      removedFiles.push(fileTobeRemoved)
    }
    
    return (
      <EzFileUpload
        files={attachedFiles}
        onSelectFile={onSelectFile}
        onRemoveFile={onRemoveFile}
      />
    );
  };
  
  const renderPlateNo = () => {
    const name = 'vehicle_no';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Plate No'}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      /> 
    );
  };
  const renderVehicleTypes = () => {
    const name = 'vehicle_typ';
    
    return (<EzControlledDropDowntWithFloatingLabel
      name={name}
      control={control}
      rules = {getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
      optionLabel={'label'}
      optionValue={'extraData.key'}
      options={vehcileType}
      label={`Vehicle Type`}
      isError={errors[name]}
      errorMsg={getFormErrorMessage(name)}
      labelClassName={'ml-2'}
    />)

  };

  const renderOtherPartyAssetCheckBox = () => {
    const name = 'other_party';

    return (
      <EzControlledCheckBoxWithLabel
        name={name}
        control={control}
        label="Is Owner operator"
        isError={errors[name]}
        labelClassName={'ml-2'}
      />
    );
  };

  const renderSuppliers = () => {
    const name = 'vendor';

    return <Suppliers name={name}
    control={control}
    label={'Owner Operator'}
    isError={errors[name]}
    errorMsg={getFormErrorMessage(name)}
    />

  }

  const renderEquipmentTypes = () => {
    const name = 'equip_type';
    return (<EzControlledDropDowntWithFloatingLabel
      name={name}
      control={control}
      rules = {vehicleTypeWatcher === vehicleTypeTrailer && getFormBaseValidationRule().DEFAULT.VALIDATION_RULE }
      optionLabel={'label'}
      optionValue={'extraData.key'}
      options={equipmentType}
      label={`Equipment Type`}
      isError={errors[name]}
      errorMsg={getFormErrorMessage(name)}
      labelClassName={'ml-2'}
    />
    );
  };

  const renderMake = () => {
    const name = 'asset_make';
    const btnClass =
      'p-button-sm p-button-raised p-button-rounded p-button-icon-only';
    return (<span style={{ display: "flex" }}>
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules = {getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
        options={make}
        label={'Make'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        labelClassName={'ml-2'}
      />
      <Button
        icon="pi pi-plus"
        type="button"
        className={`${btnClass} ml-2`}
        onClick={() => {
          setMakeAndModelDialogViewMode(DIALOG_VIEW_MODE.MAKE);
          setMakeAndModalPopupVisibility(true);
        }}/>
    </span>);
  };

  const renderModel = () => {
    const name = 'asset_model';
    const btnClass =
      'p-button-sm p-button-raised p-button-rounded p-button-icon-only';
    return (
      <span style={{ display: "flex" }}>
        <EzControlledDropDowntWithFloatingLabel
          name={name}
          control={control}
          rules = {getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
          options={modelMapToMake}
          label={`Model`}
          isError={errors[name]}
          errorMsg={getFormErrorMessage(name)}
          labelClassName={'ml-2'}
        />
          <Button
          icon="pi pi-plus"
          type="button"
          className={`${btnClass} ml-2`}
          onClick={() => {
            setMakeAndModelDialogViewMode(DIALOG_VIEW_MODE.MODEL);
            setMakeAndModalPopupVisibility(true);
          }}/>
      </span>  
    );
  };

  const renderYear = () => {
    const name = 'year';
    return (<EzControlledDropDowntWithFloatingLabel
      name={name}
      control={control}
      rules = {getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
      options={years}
      label={`Year`}
      isError={errors[name]}
      errorMsg={getFormErrorMessage(name)}
      labelClassName={'ml-2'}
    />
    );
  };

  const renderProvince = () => {
    const name = 'province';

    return (
      <Province
        name={name}
        control={control}
        onChange={(e) => console.log(e)}
        isFloatingLabel={true}
        isControlled={true}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Asset Province"
      />
    );
  };

  const renderInfo = () => {
    const name = 'info';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Info'}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderOwnedStatus = () => {
    const name = 'ownership';
    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        disabled
        control={control}
        label={'Owned status'}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderFuelCard = (name, label) => {
    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={label}
        rules={getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  return (
    <> 
    <div className="paddingbox">
      <div
        className={classNames('containerboxform', { 'p-disabled': false })}
      >
        {renderPageHeaderSection()}
        <div
          className="form-demo"
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
        >
          <div className="card" style={{ background: '#f3faf4BF' }}>
            {showErrorToast && <EzToast {...getToastProps() } />}
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <Container fluid>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderAsset()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderVehicleVinNo()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderPlateNo()}</Col>
                </Row>
                <br></br>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderVehicleTypes()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderProvince()}</Col>
                  <Col md="1" />
                { vehicleTypeWatcher === vehicleTypeTrailer && <Col md="3">{renderEquipmentTypes()}</Col> }
                  <Col md="1" />
                </Row>
                <br></br>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderInfo()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderFuelCard('fleet_card1', "Fuel Card 1")}</Col>
                  <Col md="1" />
                  <Col md="3">{renderFuelCard('fleet_card2', "Fuel Card 2")}</Col>
                </Row>
                <br></br>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderOwnedStatus()}</Col>
                  <Col md="1" />
                  <Col md="3" className={'mt-3'}>{renderOtherPartyAssetCheckBox()}</Col>
                  <Col md="1" />
                  <Col md="3">{watchOtherType && renderSuppliers()}</Col>
                </Row>
                <Divider align={'center'} type={'solid'}>
                <b>Make Information</b>
                </Divider>
                <br></br>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderYear()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderMake()}</Col>
                  <Col md="1" />
                  {/* <Col md="3">{showModelMapToMake() &&  renderModel()}</Col> */}
                  <Col md="3">{renderModel()}</Col>
                  <Col md="1" />
                </Row>
                <br></br>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="6" />
                  <Col md="6">{renderFileUploader()}</Col>
                </Row>
              </Container>
              <div
                className={'make-flex mt-3 mr-2'}
                style={{ flexDirection: 'row-reverse', background: 'white' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      label="Cancel"
                      onClick={navigateToAssetSummaryScreen}
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      type="reset"
                      label="Reset"
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '170px' }}>
                    <Button
                      loading={false}
                      icon={'pi pi-plus'}
                      type="submit"
                      label={isEdit ? 'Edit Asset' : 'Create Asset'}
                      className="mt-2 p-button-raised"
                    />    
                  </div>
                </div>
              </div>
            </form>
            {isMakeAndModalPopupVisble &&
              <CreateMakeAndModalPopup
                makeList={make}
                selectedMake={formFieldValueMap?.asset_make?.id}
                dialogView={makeAndModelDialogViewMode}
                onHide={() => {setMakeAndModalPopupVisibility(false)}} />}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CreateAsset;
